"use client";

import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QualificationsManager = ({ setUpdateUi, updateUi }: any) => {
  const [qualifications, setQualifications] = useState<any[]>([
    { id: `new-${Date.now()}`, institution: "", qualification: "" },
  ]);
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();
  // Fetch existing qualifications and override default if data exists
  useEffect(() => {
    const fetchQualifications = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        const fetchedQualifications = response.data.qualifications || [];
        if (fetchedQualifications.length > 0) {
          // Only override default if qualifications exist
          setQualifications(
            fetchedQualifications.map((qual: any, index: number) => ({
              ...qual,
              id: qual.id || `qual-${index}-${Date.now()}`, // Unique ID if not present
            }))
          );
        }
        // If no qualifications are fetched, keep the default empty field
      } catch (error) {
        console.error("Error fetching qualifications:", error);
        toast.error("Failed to load qualifications.");
        // Keep the default empty field even if fetch fails
      }
    };

    fetchQualifications();
  }, [updateUi]);

  // Add new qualification
  const handleAddQualification = () => {
    setQualifications([
      ...qualifications,
      { id: `new-${Date.now()}`, institution: "", qualification: "" },
    ]);
  };

  // Remove a specific qualification
  const handleRemoveQualification = (id: number | string) => {
    setQualifications(qualifications.filter((qual) => qual.id !== id));
  };

  // Handle input changes for qualifications
  const handleInputChange = (
    id: number | string,
    field: string,
    value: string
  ) => {
    setQualifications(
      qualifications.map((qual) =>
        qual.id === id ? { ...qual, [field]: value } : qual
      )
    );
  };

  // Save qualifications to the API
  const handleSaveQualifications = async () => {
    setIsSaving(true);
    const loadingToast = toast.loading("Saving qualifications...");

    try {
      const payload = { qualifications };

      const response = await axios.patch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        payload,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Qualifications updated successfully!");
        setUpdateUi(!updateUi);
      }
    } catch (error) {
      console.error("Error saving qualifications:", error);
      toast.error("Failed to save qualifications. Please try again.");
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-2xl">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl text-[#272727] font-semibold">
          {t("qualifications")}
        </h2>
        <button
          onClick={handleAddQualification}
          className="flex items-center gap-2 bg-[#008847] text-white px-3 md:px-4 py-2 md:py-2 hover:bg-green-700 rounded-xl"
        >
          <span className="text-xl">+</span>
          {t("add_qualification")}
        </button>
      </div>

      <div className="space-y-4">
        {qualifications.map((qual) => (
          <div key={qual.id} className="grid grid-cols-2 gap-4">
            <div className="relative space-y-3">
              <label className="block text-base font-normal text-[#272727] mb-1">
                {t("institution")}
              </label>
              <input
                type="text"
                value={qual.institution}
                onChange={(e) =>
                  handleInputChange(qual.id, "institution", e.target.value)
                }
                className="w-full p-2 text-base font-medium border border-gray-300 rounded-md focus:outline-none"
                placeholder="Enter institution name"
              />
            </div>

            <div className="relative space-y-3">
              <label className="block text-base font-normal text-[#272727] mb-1">
                {t("qualification_subject")}
              </label>
              <input
                type="text"
                value={qual.qualification}
                onChange={(e) =>
                  handleInputChange(qual.id, "qualification", e.target.value)
                }
                className="w-full p-2 text-base font-medium border border-gray-300 rounded-md focus:outline-none"
                placeholder="Enter qualification"
              />
            </div>

            <div className="col-span-2 flex justify-end">
              <button
                onClick={() => {
                  if (qualifications.length === 1) {
                    toast.info(
                      "Please add another qualification to delete this one."
                    );
                  } else {
                    handleRemoveQualification(qual.id);
                  }
                }}
                className={`text-base font-medium hover:underline ${qualifications.length === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-[#EE201C]"
                  }`}
              >
                {t("remove")}
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-6">
        <button
          onClick={handleSaveQualifications}
          disabled={isSaving}
          className={`px-6 py-2 rounded-xl text-white font-medium ${isSaving
            ? "bg-gray-400 cursor-not-allowed"
            : "bg-[#008847] hover:bg-green-700"
            }`}
        >
          {isSaving ? "Saving..." : t("save_changes")}
        </button>
      </div>
    </div>
  );
};

export default QualificationsManager;