import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

export const RejectLessonModal = ({ isOpen, onClose, id, setUpdateUi, setReject, updateUi }: any) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  if (!isOpen) return null;
  const cenceled = async () => {
    try {
      await axios.post(
        `https://api.nadwa-uae.com/api/v2/customer/bookings/${id}/cancel/`,
        {
          reason: message,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      toast.success("Lesson Rejected Successfully", {
        onClose: () => {
          onClose();
        },
      });
      setTimeout(() => {
        setUpdateUi(!updateUi);
        setReject(false);
      }, 1000);
    } catch (error) {
      toast.error("Failed to reject lesson. Try again");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold">{t("reject_lesson")}</h2>
        <p className="text-gray-600 text-sm mt-1">
          {t("reject_text")}
        </p>
        <textarea
          className="w-full mt-3 p-2 border rounded-md focus:ring-2 focus:ring-blue-500"
          rows={3}
          placeholder={t("message_placeholder")}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        ></textarea>

        <div className="flex justify-end gap-3 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border rounded-md text-gray-600 hover:bg-gray-100"
          >
            {t("buttons.cancel")}
          </button>
          <button
            disabled={message.length < 15}
            onClick={() => {
              cenceled();
            }}
            className={` ${message.length < 15
              ? "cursor-not-allowed bg-gray-400"
              : "bg-[#008847]"
              } px-4 py-2  text-white rounded-xl`}
          >
            {t("reject_lesson")}
          </button>
        </div>
      </div>
    </div>
  );
};
