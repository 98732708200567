import React, { useState, useRef, useEffect } from 'react';
import image3 from "../../../images/popups/code.png";
import EmailSentModal from './EmailSend';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';

interface VerificationCodeModalProps {
    isOpen: boolean;
    onClose: () => void;
    onVerify: (code: string) => void;
    onResend: () => void;
    phone: string;
}

export default function PhoneVerificationCode({
    phone,
    isOpen,
    onClose,
    onVerify,
    onResend,
}: VerificationCodeModalProps) {
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [resendTimer, setResendTimer] = useState(60); // 60 seconds timer
    const [canResend, setCanResend] = useState(false); // Tracks if resend is allowed

    // Focus the first input when the modal opens and start the timer
    useEffect(() => {
        if (isOpen) {
            inputRefs.current[0]?.focus();
            setResendTimer(60); // Start with 60 seconds
            setCanResend(false); // Disable resend initially
        }
    }, [isOpen]);

    // Countdown timer logic
    useEffect(() => {
        if (!isOpen || resendTimer <= 0) {
            setCanResend(true); // Enable resend when timer reaches 0
            return;
        }

        const interval = setInterval(() => {
            setResendTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    setCanResend(true);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount or when timer changes
    }, [isOpen, resendTimer]);

    // Trigger verification when all digits are filled
    useEffect(() => {
        if (code.every(digit => digit !== '')) {
            verifyCode(code.join(''));
        }
    }, [code]);

    const handleChange = (index: number, value: string) => {
        if (!/^\d*$/.test(value)) return; // Allow only numbers

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value !== '' && index < 5) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && code[index] === '' && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text');
        const digits = pastedData.match(/\d/g)?.slice(0, 6) || [];

        const newCode = [...code];
        digits.forEach((digit, index) => {
            if (index < 6) newCode[index] = digit;
        });
        setCode(newCode);

        const nextEmptyIndex = newCode.findIndex(digit => digit === '');
        const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;
        inputRefs.current[focusIndex]?.focus();
    };

    const verifyCode = async (code: string) => {
        if (loading) return; // Prevent multiple simultaneous verifications
        setLoading(true);
        setError(null);
        try {
            const response = await fetch("https://api.nadwa-uae.com/api/v2/customer/phonenumber/confirm/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`
                },
                body: JSON.stringify({
                    phone_number_send_to: phone,
                    key: code
                }),
            });

            const text = await response.text();
            let data;

            if (text) {
                data = JSON.parse(text);
            }

            if (!response.ok) {
                throw new Error(data?.non_field_errors || data?.email || "Verification failed.");
            }

            console.log("Verification successful:", data);
            setIsSuccessModalOpen(true);
            onVerify(code);
            setTimeout(() => window.location.reload(), 500);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resendCode = async () => {
        if (!canResend) return; // Prevent resend if timer is active

        try {
            const response = await fetch('https://api.nadwa-uae.com/api/v2/customer/phonenumber/resend/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
                },
                body: JSON.stringify({ phone_number_send_to: phone }),
            });
            const text = await response.text();
            let data;

            if (text) {
                data = JSON.parse(text);
            }

            const { non_field_errors, phone_number_send_to } = data || {};
            if (response.ok) {
                toast.success("OTP Successfully Resent");
                setResendTimer(60); // Reset timer to 60 seconds
                setCanResend(false); // Disable resend again
                setCode(['', '', '', '', '', '']); // Reset code input
                inputRefs.current[0]?.focus(); // Refocus on first input
                onResend(); // Notify parent component
            } else {
                toast.error(non_field_errors?.[0] || phone_number_send_to?.[0] || "Failed to resend OTP");
            }
        } catch (error) {
            toast.error("Failed to resend OTP");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="relative bg-white rounded-lg max-w-[425px] w-full p-6 animate-fade-in">
                <button onClick={onClose} className="absolute right-4 top-4 text-gray-400 hover:text-gray-600">×</button>

                <div className="flex flex-col items-center gap-4">
                    <div className="w-16 h-16 rounded-full bg-emerald-100 flex items-center justify-center">
                        <img src={image3} alt="None" className='w-16 h-14' />
                    </div>

                    <div className="text-center space-y-2">
                        <h2 className="text-xl font-semibold">{i18n.language == "ar" ? "رمز التحقق" : "Verification Code"}</h2>
                        <p className="text-sm text-gray-500">
                            {i18n.language == "ar" ? "تم إرسال رمز التحقق إلى جهازك المحمول" : "A verification code has been sent to your mobile device."}
                        </p>
                    </div>

                    <div className="flex gap-2 my-4">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                onPaste={handlePaste}
                                ref={(el) => inputRefs.current[index] = el}
                                className="w-12 h-12 text-center text-2xl font-semibold border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:border-transparent"
                            />
                        ))}
                    </div>

                    {error && <p className="text-red-500 text-sm">{error}</p>}
                    {loading && <p className="text-gray-500">{i18n.language == "ar" ? "التحقق" : "Verifying"}...</p>}

                    <div className="text-sm">
                        {i18n.language == "ar" ? "لم أستلم الرمز." : "I didn't receive the code."}{' '}
                        <button
                            onClick={resendCode}
                            disabled={!canResend}
                            className={`font-medium ${canResend ? 'text-emerald-600 hover:text-emerald-700' : 'text-gray-400 cursor-not-allowed'}`}
                        >
                            {i18n.language == "ar" ? "إعادة الإرسال" : "Resend"}{' '}
                            {!canResend && `(${resendTimer}s)`}
                        </button>
                    </div>
                </div>
            </div>
            <EmailSentModal text={"Phone"} isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)} />
        </div>
    );
}