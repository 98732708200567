"use client";

import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

const CountrySelector = ({ setUpdateUi, updateUi }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [countries, setCountries] = useState<
    { code: string; name: string; flag: string }[]
  >([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [isSaving, setIsSaving] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const token = localStorage.getItem("TH_AUTH_TOKEN");

  // Fetch countries from API
  useEffect(() => {
    const fetchCountries = async () => {
      if (!token) {
        toast.error("Authentication token is missing.");
        return;
      }

      try {
        const response = await fetch(
          "https://api.nadwa-uae.com/api/v2/countries/",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setCountries(
            data.map((country) => ({
              code: country.iso_code,
              name: country.name,
              flag: country.country_icon,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
        toast.error("Failed to load countries.");
      }
    };

    fetchCountries();
  }, [token, updateUi]);

  // Fetch user’s selected country from profile API
  useEffect(() => {
    const fetchProfileCountry = async () => {
      if (!token) {
        toast.error("Authentication token is missing.");
        return;
      }

      try {
        const response = await fetch(
          "https://api.nadwa-uae.com/api/v2/tutor/profile",
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        const profileData = await response.json();
        console.log(profileData);

        if (profileData?.country) {
          setSelectedCountry(countries[profileData.country - 1]);
        }
      } catch (error) {
        console.error("Error fetching profile country:", error);
        toast.error("Failed to fetch profile country.");
      }
    };

    if (countries.length > 0) {
      fetchProfileCountry();
    }
  }, [countries, token, updateUi]);

  // Handle country selection
  const handleCountrySelect = (country: any) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };

  // Save selected country using PATCH API
  const handleSave = async () => {
    if (!selectedCountry) {
      toast.error("Please select a country first.");
      return;
    }

    if (!token) {
      toast.error("Authentication token is missing.");
      return;
    }

    setIsSaving(true);
    const loadingToast = toast.loading("Saving selected country...");

    const countryIndex = countries.findIndex(
      (val) => val.name === selectedCountry.name
    );
    const countryId = countryIndex + 1;
    console.log(countryId);

    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
          body: JSON.stringify({ country: countryId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      toast.success(`Country updated successfully to ${selectedCountry.name}!`);
      setUpdateUi(!updateUi)
    } catch (error) {
      console.error("Error saving country:", error);
      toast.error("Failed to save country. Please try again.");
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="space-y-8">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        transition={Bounce}
      />
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">{t("country")}</h1>
      </div>
      <div className="relative w-full max-w-md" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between focus:outline-none transition-all"
        >
          {selectedCountry ? (
            <div className="flex items-center gap-2">
              <picture>
                <source srcSet={selectedCountry.flag} type="image/webp" />
                <img
                  src={
                    selectedCountry.flag?.replace(".webp", ".jpg") ||
                    "fallback.jpg"
                  }
                  alt={selectedCountry.name}
                  className="w-9 h-9 rounded-full"
                />
              </picture>
              <span className="text-xs sm:text-base font-medium">
                {selectedCountry.name}
              </span>
            </div>
          ) : (
            <span className="text-base text-gray-400">Select a country</span>
          )}
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? "rotate-180" : ""
              }`}
          />
        </button>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg">
            <ul className="py-1 max-h-60 overflow-auto">
              {countries.map((country) => (
                <li
                  key={country.code}
                  onClick={() => handleCountrySelect(country)}
                  className="flex items-center text-xs sm:text-xl gap-2 px-3 py-2 hover:bg-gray-50 cursor-pointer"
                >
                  <picture>
                    <source srcSet={country.flag} type="image/webp" />
                    <img
                      src={
                        country.flag?.replace(".webp", ".jpg") ||
                        "fallback.jpg"
                      }
                      alt={country.name}
                      className="w-9 h-9 rounded-full"
                    />
                  </picture>
                  <span className="text-base font-medium">{country.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`px-6 py-2 rounded-[28px] text-sm font-medium ${isSaving
            ? "bg-gray-400 border-gray-300 text-gray-700"
            : "bg-[#008847] text-white px-3 sm:px-6 py-2 sm:py-3 rounded"
            }`}
        >
          {isSaving ? "Saving..." : t("save_changes")}
        </button>
      </div>
    </div>
  );
};

export default CountrySelector;