import DynamicBlog from '../../components/Blogs/DynamicBlog';
import BannerSection from '../../components/AboutPages/Banner';
import bannerImage from '../../images/Blogs/Hero.png';
import BottomSec from '../../components/Blogs/BottomSec';
import { useEffect } from 'react';

function SingleBlog() {
    useEffect(() => {
        const lang = localStorage.getItem("i18nextLng");
        if (!lang || lang === "en-US") {
            localStorage.setItem("i18nextLng", "en");
            window.location.reload();
        };
    }, [])
    return (
        <div className='w-full'>
            <BannerSection title={`Blogs`} image={bannerImage} />
            <DynamicBlog />
            <BottomSec />
        </div>
    )
}

export default SingleBlog;