import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

interface JobApplicationPopupProps {
  Jobid: string;
}

const JobApplicationPopup: React.FC<JobApplicationPopupProps> = ({ Jobid }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const authToken = localStorage.getItem("TH_AUTH_TOKEN");

    if (!authToken) {
      toast.error("Authentication error: No token found. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `https://api.nadwa-uae.com/api/v2/schoolsupply/jobs/${Jobid}/apply/`,
        { tutor_message: message },
        {
          headers: {
            Authorization: `Token ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Application submitted successfully!");
        setMessage("");
        setIsOpen(false);
      } else {
        toast.error("Failed to submit application. Please try again.");
      }
    } catch (err: any) {
      if (err.response) {
        toast.error(
          err.response.data.job_post?.[0] ||
          err.response.data.tutor_message?.[0] ||
          err.response.data.non_field_errors[0]
        );
      } else {
        toast.error("An error occurred. Please check your network and try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} />

      {/* Apply Button */}
      <button
        onClick={() => setIsOpen(true)}
        className={`w-full bg-[#008847] hover:bg-green-700 text-white py-2 rounded-md `}
      >
        {t("apply_job")}
      </button>

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/2 lg:w-1/3">
            <div className="text-center">
              <h2 className="text-xl font-bold mb-4">
                {t("tutor_application")}
              </h2>
              <p className="mb-4 text-base">
                {t("tutor_description")}
              </p>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <h3 className="font-bold text-lg">{t("send_message")}</h3>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t("message_placeholder")}
                  className="w-full p-2 border rounded mb-4"
                  rows={4}
                  required
                />
              </div>

              {/* Buttons */}
              <div className="flex items-center justify-between">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="mt-4 text-gray-600 hover:text-gray-800 border-2 border-gray-400 rounded-lg px-3 py-2"
                >
                  {t("back")}
                </button>
                <button
                  type="submit"
                  className="bg-[#008847] hover:bg-green-700 text-white px-1 sm:px-3 py-2 rounded-md"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : t("submit_application")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default JobApplicationPopup;
