import {
  Briefcase,
  Clipboard,
  Clock,
  Home,
  Inbox,
  LanguagesIcon,
  LogOut,
  Settings,
  User,
  User2,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import LogoImage from "../../images/Homepageassets/Nadwa-Color.png";
import TutorAccountSettings from "../TutorAccountSettings/TutorAccountSettings";
import TutorUnreadBookings from "./TutorUnreadBookings";
import Unread from "./Unread";

const Sidebar = ({
  activeTab,
  onTabChange,
  menuIsOpen,
  setIsMenuOpen,
}: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Logout handler
  const handleLogout = () => {
    localStorage.removeItem("TH_AUTH_TOKEN");
    navigate("/login");
  };

  // Tab click handler with toast for "inbox"
  const handleTabClick = (tabId: string) => {
    if (tabId === "inbox") {
      toast(
        <div>
          <p className="font-semibold">{t("hello_tutor")}</p>
          <p className="text-sm text-gray-500">
            {t("hello_text")}{" "}
            <span
              onClick={() => handleTabClick("available")}
              className="text-blue-700 cursor-pointer"
            >
              {t("update_now")}
            </span>
          </p>
        </div>,
        { duration: 2000, position: "top-right" }
      );
    }
    onTabChange(tabId);
    setIsMenuOpen(false);
  };

  // Sidebar tabs
  const tabs = [
    { id: "dashboard", name: t("dashboard"), icon: <Home className="w-5 h-5" /> },
    { id: "applypermit", name: t("apply_for_permit"), icon: <Clipboard className="w-5 h-5" /> },
    { id: "available", name: t("availability"), icon: <Clock className="w-5 h-5" /> },
    { id: "jobsboard", name: t("jobs_board"), icon: <Briefcase className="w-5 h-5" /> },
    { id: "inbox", name: t("inbox"), icon: <Inbox className="w-5 h-5" />, unread: <Unread message={0} setMessage={() => { }} /> },
    {
      id: "booking",
      name: t("bookings"),
      icon: <User className="w-5 h-5" />,
      unread: <TutorUnreadBookings />,
    },
  ];

  // Language options
  const Languages = [
    { code: "en", lng: "English" },
    { code: "ar", lng: "Arabic" },
  ];

  // State management
  const [isOpen, setIsOpen] = useState(false); // For account settings modal
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);

  // Toggle language dropdown
  const toggleLanguageDropdown = () => setIsLanguageDropdownVisible(!isLanguageDropdownVisible);

  // Change language and update direction
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
    setIsLanguageDropdownVisible(false);
  };

  // Update document direction based on language
  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <Toaster />
      <aside
        className={`fixed lg:relative h-screen top-0 left-0 transition-all duration-300 ease-in-out ${menuIsOpen ? "translate-x-0" : "lg:translate-x-0 -translate-x-full"
          } bg-white rounded-lg shadow-lg w-64 lg:z-0 z-50 overflow-y-auto lg:p-6 p-4`}
      >
        {/* Header with Logo and Close Button */}
        <div className="sticky top-0 z-10 bg-white pb-4 flex justify-between items-center">
          <img src={LogoImage} className="w-40" alt="Nadwa Logo" />
          <X
            className="lg:hidden cursor-pointer w-6 h-6"
            onClick={() => setIsMenuOpen(false)}
          />
        </div>

        {/* Overview Section */}
        <div className="space-y-6">
          <h1 className="font-medium text-gray-500 text-xs uppercase tracking-wide">
            {t("overview") || "OVERVIEW"}
          </h1>
          <ul className="space-y-2">
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`flex items-center justify-between gap-3 p-2 rounded-lg cursor-pointer transition-colors ${activeTab === tab.id
                  ? "bg-[#008847] text-white"
                  : "text-gray-800 hover:bg-gray-100"
                  }`}
              >
                <div className="flex items-center gap-3">
                  {tab.icon}
                  <span className="text-sm">{tab.name}</span>
                </div>
                {tab.unread && <span>{tab.unread}</span>}
              </li>
            ))}
          </ul>
        </div>

        {/* Settings Section */}
        <div className="mt-10">
          <h1 className="font-medium text-gray-500 text-xs uppercase tracking-wide mb-3">
            {t("settings") || "SETTINGS"}
          </h1>
          <div className="space-y-2">
            <Link
              to="/EditProfile"
              className="flex items-center gap-3 p-2 text-gray-800 hover:bg-gray-100 rounded-lg no-underline"
            >
              <User2 className="w-5 h-5" />
              <span className="text-sm">{t("view_profile")}</span>
            </Link>
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center gap-3 p-2 text-gray-800 hover:bg-gray-100 rounded-lg cursor-pointer"
            >
              <Settings className="w-5 h-5" />
              <span className="text-sm">{t("account_settings")}</span>
            </div>
            <div className="relative">
              <button
                onClick={toggleLanguageDropdown}
                className="flex items-center gap-3 p-2 w-full text-gray-800 hover:bg-gray-100 rounded-lg bg-transparent"
              >
                <LanguagesIcon className="w-5 h-5" />
                <span className="text-sm">{t("header")}</span>
              </button>
              {isLanguageDropdownVisible && (
                <ul className="absolute left-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
                  {Languages.map((language) => (
                    <li
                      key={language.code}
                      onClick={() => changeLanguage(language.code)}
                      className="py-2 px-4 hover:bg-[#008847] hover:text-white cursor-pointer"
                    >
                      {language.lng}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div
              onClick={handleLogout}
              className="flex items-center gap-3 p-2 text-[#FD5823] hover:bg-gray-100 rounded-lg cursor-pointer"
            >
              <LogOut className="w-5 h-5" />
              <span className="text-sm">{t("logout")}</span>
            </div>
          </div>
        </div>
      </aside>
      {isOpen && <TutorAccountSettings />}
    </>
  );
};

export default Sidebar;