import { useTranslation } from "react-i18next";
import BannerSection from "../../components/AboutPages/Banner";
import FilterSection from "../../components/TutorPages/FilterSection";
import TutorsResult from "../../components/TutorPages/TutorsResult";
import bannerImage from "../../images/asserts/Website-Assets/Find a Tutor/Hero_image.webp";

const Tutor = () => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <main>
      <BannerSection title={t('HireTutor')} image={bannerImage} />
      <FilterSection />
      <TutorsResult />
    </main>
  );
};

export default Tutor;
