import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import { CreditCard, Shield, Users, X } from "lucide-react";
import { useState } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import PaymentSuccessModal from "../AboutTutorComponents/PaymentSuccess";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { DateTime } from "luxon";

export function Checkout({
  setPayment,
  invitationId,
  tutor,
  bookingDetails,
  setInvitationId,
  subject,
  time,
}: any) {
  console.log(bookingDetails);
  const [discountCode, setDiscountCode] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [originalPrice, setOriginalPrice] = useState<number | null>(null); // Unit price per lesson before VAT
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null); // Unit price per lesson before VAT
  const [desc, setDesc] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const [couponId, setCouponId] = useState<null | number>(null);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });
  const price = bookingDetails.payment_amount_in_cents ?? 0;
  console.log(bookingDetails);

  const handlePayment = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      toast.error("Stripe has not loaded yet. Please wait.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Invalid card details. Please check and try again.");
      setLoading(false);
      return;
    }

    try {
      const { data } = await axios.post(
        "https://api.nadwa-uae.com/api/v2/card/setupintent/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      const clientSecret = data.client_secret;
      if (!clientSecret) throw new Error("No client secret returned");

      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: { name: formData.name, email: formData.email },
          },
        }
      );

      if (error) throw new Error(error.message);

      const res = await axios.post("https://api.nadwa-uae.com/api/v2/card/", {
        stripe_id: setupIntent.payment_method,
        stripe_token: setupIntent.id,
        user: 62,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
        },
      });

      const response = await axios.get("https://api.nadwa-uae.com/api/v2/card/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
        },
      });
      const cards = response.data;
      if (!cards || cards.length === 0) {
        throw new Error("No saved cards found.");
      }

      const defaultCard = cards.find((card: any) => card.is_default);
      const selectedCard = defaultCard ? defaultCard.id : cards[0].id;

      if (!selectedCard) {
        throw new Error("No valid credit card found.");
      }

      const resp = await fetch(`https://api.nadwa-uae.com/api/v2/customer/invitations/${invitationId}/accept_invitation/`, {
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          "Authorization": `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`
        },
        body: JSON.stringify({
          coupon: couponId,
          credit_card: res.data.id,
        })
      });

      const responses = await resp.json();

      if (resp.ok) {
        toast.success("Payment Successful");
        setTimeout(() => {
          setConfirmed(true);
        }, 500);
      } else {
        throw new Error(responses.non_field_errors[0] || "Failed");
      }
    } catch (error: any) {
      if (error instanceof Error)
        toast.error(error.message || "Payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const stripe = useStripe();
  const elements = useElements();

  const pairTimes = (times: string[]) => {
    const timePairs = [];
    for (let i = 0; i < times.length; i += 2) {
      if (i + 1 < times.length) {
        const startTime = new Date(times[i]).getTime();
        const endTime = new Date(times[i + 1]).getTime();
        timePairs.push({ start_time: startTime, end_time: endTime });
      }
    }
    return timePairs;
  };

  const selectedTimes2 = [
    "2025-02-07T20:00:00.000Z",
    "2025-02-07T21:00:00.000Z",
    "2025-02-07T22:00:00.000Z",
    "2025-02-07T23:00:00.000Z",
  ];

  const pairedTimes = pairTimes(selectedTimes2);

  const discountedCode = async () => {
    try {
      const res = await axios.post(
        `https://api.nadwa-uae.com/api/v2/coupon/check-code/?code=${discountCode}`,
        {
          invitation_dates: pairedTimes,
          customer: bookingDetails.customer.id,
          subject: bookingDetails.subject,
          tutor: bookingDetails.tutor,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      const original = res.data.original_unit_price_in_cents / 100; // Per lesson, before VAT
      const discounted = res.data.present_unit_price_in_cents / 100; // Per lesson, before VAT
      console.log("Discount API Response:", { original, discounted }); // Debug log
      setOriginalPrice(original);
      setDiscountedPrice(discounted);
      toast.success("Discount applied successfully!");
      setDesc(res.data.description);
      setCouponId(res.data.id);
    } catch (error: any) {
      toast.error(error.response?.data?.error_message || "Invalid discount code");
    }
  };

  // Calculate duration in hours
  const calculateDurationHours = (startTime: string, endTime: string) => {
    const start: any = new Date(startTime);
    const end: any = new Date(endTime);
    return (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
  };

  // Calculate total hours for the booking
  const totalHours = calculateDurationHours(
    bookingDetails.start_time,
    bookingDetails.end_time
  ); // 2 hours in your example

  const numLessons = bookingDetails.invitation_dates.length; // 1 lesson
  const vatRate = 0.05;

  // Base price (before VAT)
  const basePrice = price / 100; // 100 AED
  const vatAmount = basePrice * vatRate; // 100 * 0.05 = 5 AED
  const totalPrice = (basePrice + vatAmount).toFixed(2); // 100 + 5 = "105.00" AED

  // Base price per hour (before VAT)
  const basePricePerHour = totalHours > 0 ? (basePrice / totalHours).toFixed(1) : "0.0"; // 100 / 2 = "50.0" AED/hour
  const pricePerHourWithVat = totalHours > 0 ? ((basePrice / totalHours) * (1 + vatRate)).toFixed(1) : "0.0"; // 50 * 1.05 = "52.5" AED/hour

  // Discounted pricing (assuming API returns prices before VAT)
  const originalTotalPrice = originalPrice !== null
    ? (originalPrice * numLessons * (1 + vatRate)).toFixed(2)
    : totalPrice;
  const discountedTotalPrice = discountedPrice !== null
    ? (discountedPrice * numLessons * (1 + vatRate)).toFixed(2)
    : null;
  const originalPricePerHour = originalPrice !== null && totalHours > 0
    ? ((originalPrice / (totalHours / numLessons)) * (1 + vatRate)).toFixed(1)
    : pricePerHourWithVat;
  const discountedPricePerHour = discountedPrice !== null && totalHours > 0
    ? ((discountedPrice * numLessons * (1 + vatRate)) / totalHours).toFixed(1)
    : null;

  console.log("Pricing Debug:", {
    basePrice,
    totalPrice,
    basePricePerHour,
    pricePerHourWithVat,
    originalTotalPrice,
    discountedTotalPrice,
    originalPricePerHour,
    discountedPricePerHour,
    totalHours,
    numLessons,
  });

  const handleClose = () => {
    setPayment(false);
    setInvitationId(0);
  };

  return (
    <div className="bg-white inset-0 top-0 rounded-xl shadow-lg max-h-[80vh] overflow-y-scroll mt-5 max-w-[90%] mx-auto fixed">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <X onClick={handleClose} className={`absolute ${i18n.language == "ar" ? "left-4" : "right-4"} top-2 cursor-pointer`} />
      <div className="grid md:grid-cols-2 gap-8 p-6">
        {/* Left Column */}
        <div className="space-y-5">
          <div className="flex items-center gap-3">
            {tutor.photo_card !== null ? (
              <picture>
                <source srcSet={tutor?.photo_card} type="image/webp" />
                <img
                  src={tutor.photo_card?.replace(".webp", ".jpg") || "fallback.jpg"}
                  alt="img"
                  className="w-10 h-10 rounded-full"
                />
              </picture>
            ) : (
              <div className="w-10 h-10 border border-black rounded-full bg-white flex justify-center items-center">
                {tutor.user.first_name.slice(0, 2)}
              </div>
            )}
            <div>
              <h3 className="font-medium relative top-2">{tutor?.name}</h3>
              <p className="text-gray-600 text-sm">{subject}</p>
            </div>
          </div>

          {/* Total Amount */}
          <div>
            <div className="text-sm text-gray-600 mb-1">
              {i18n.language === "en" ? "Total" : "الإجمالي"}
            </div>
            <div className="text-2xl font-medium">
              {t("AED")}{" "}
              {discountedPrice !== null ? (
                <>
                  <span className="line-through text-gray-400">{totalPrice}</span>{" "}
                  <span className="text-green-600">{discountedTotalPrice}</span>
                </>
              ) : (
                totalPrice
              )}
            </div>
            <div className="text-sm text-gray-500">
              {t("AED")}{" "}
              {discountedPrice !== null ? (
                <>
                  <span className="line-through text-gray-400 mr-2">{pricePerHourWithVat}</span>
                  <span className="text-green-600 font-semibold">{discountedPricePerHour}</span>
                </>
              ) : (
                <span className="font-semibold">{pricePerHourWithVat}</span>
              )}{" "}
              {i18n.language === "en" ? "/per hour including 5% VAT" : "/لكل ساعة شامل 5% ضريبة القيمة المضافة"}
            </div>
          </div>

          {/* Discount Code */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              {i18n.language === "en" ? "Discount Code" : "كود الخصم"}
            </label>
            {!desc ? (
              <div className="flex gap-2">
                <input
                  type="text"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholder={i18n.language === "en" ? "You can enter discount code here" : "يمكنك إدخال كود الخصم هنا"}
                  className="flex-1 px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-green-600"
                />
                <button
                  onClick={discountedCode}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg text-sm hover:bg-green-700 transition-colors"
                >
                  {i18n.language === "en" ? "Apply" : "تطبيق"}
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-between bg-gray-100 border rounded-md px-3 py-2 w-64 shadow-sm">
                <div>
                  <div className="flex items-center gap-2 font-semibold">
                    <span className="text-gray-600">💎 {discountCode}</span>
                  </div>
                  <div className="text-sm text-gray-700">{desc}</div>
                </div>
                <button
                  onClick={() => {
                    setDesc(null);
                    setDiscountCode("");
                    setDiscountedPrice(null);
                    setOriginalPrice(null);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={16} />
                </button>
              </div>
            )}
          </div>

          {/* Lesson Details */}
          <div className="bg-gray-50 rounded-lg p-4 space-y-2">
            <div className="font-medium">
              {bookingDetails.invitation_dates.length} {t("lesson")}
            </div>
            <div className="text-sm text-gray-600 max-h-[100px] overflow-y-auto flex flex-col-reverse gap-1">
              {bookingDetails.invitation_dates.map((invitation: { start_time: string }, index: number) => {
                const startDateTime = DateTime.fromISO(invitation.start_time, { setZone: true }).setZone(time);
                if (!startDateTime.isValid) {
                  return (
                    <div key={index} className="text-lg">
                      <span className="font-bold">{bookingDetails.invitation_dates.length - index} {t("lesson")}</span>{" "}
                      Invalid Date at --:--
                    </div>
                  );
                }
                const formattedDate = startDateTime.toLocaleString({
                  weekday: "long",
                  year: "numeric",
                  month: "short",
                  day: "2-digit",
                }, { locale: "en-US" });
                const formattedTime = startDateTime.toFormat("HH:mm");
                return (
                  <div key={index} className="text-lg">
                    <span className="font-bold">{bookingDetails.invitation_dates.length - index} {t("lesson")}</span>{" "}
                    {formattedDate} at {formattedTime}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Trust Badges */}
          <div className="grid grid-cols-3 gap-4">
            {[
              { icon: Shield, text: i18n.language === "en" ? "Money Back Guarantee" : "ضمان استرداد الأموال" },
              { icon: Users, text: i18n.language === "en" ? "Curated Quality Tutors" : "مدربين مُختارون بجودة عالية" },
              { icon: CreditCard, text: i18n.language === "en" ? "Secure Online Payment System" : "نظام دفع إلكتروني آمن" },
            ].map((badge, index) => (
              <div key={index} className="flex flex-col items-center text-center p-3 bg-gray-50 rounded-lg">
                <badge.icon className="w-5 h-5 text-green-600 mb-2" />
                <span className="text-xs text-gray-600 leading-tight">{badge.text}</span>
              </div>
            ))}
          </div>
        </div>

        <form className="space-y-6 my-auto" onSubmit={handlePayment}>
          <div className="space-y-2">
            <label className="text-sm font-medium">{t("email")}</label>
            <input
              type="email"
              name="email"
              placeholder={i18n.language === "en" ? "Enter your email" : "أدخل بريدك الإلكتروني"}
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-green-600"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">{i18n.language === "en" ? "Name on Card" : "الاسم على البطاقة"}</label>
            <input
              type="text"
              name="name"
              placeholder={i18n.language === "en" ? "Your Name" : "اسمك"}
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-green-600"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">{i18n.language === "en" ? "Card Information" : "معلومات البطاقة"}</label>
            <CardElement className="w-full px-3 py-2 border rounded-lg text-sm" />
          </div>
          <button
            type="submit"
            className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
            disabled={loading || !stripe}
          >
            {loading ? "Processing..." : i18n.language === "en" ? "Confirm Lesson" : "تأكيد التجريبي"}
          </button>
        </form>

        {confirmed && (
          <PaymentSuccessModal isOpen={confirmed} onClose={() => setConfirmed(false)} />
        )}
      </div>
    </div>
  );
}

export default Checkout;