"use client"

import { X } from "lucide-react"
import payment_image from "../../images/popups/payment.png";
import i18n from "../../i18n";

interface PaymentSuccessModalProps {
    onClose: () => void
    isOpen: boolean
}

export default function PaymentSuccessModal({ onClose, isOpen = true }: PaymentSuccessModalProps) {
    if (!isOpen) return null

    return (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md relative border-2 border-blue-400">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
                    aria-label="Close"
                >
                    <X size={20} />
                </button>

                <div className="p-6 flex flex-col items-center">
                    {/* Image placeholder - you'll add the src */}
                    <div className="mb-4 w-16 h-16 flex items-center justify-center">
                        <img src={payment_image} alt="Payment Success" className="w-full h-full" />
                    </div>

                    {/* Success message */}
                    <h2 className="text-xl font-semibold text-center mb-2">{i18n.language == "en" ? "Your Payment is successful!" : "تمت عملية الدفع بنجاح!"}</h2>

                    {/* Description */}
                    <p className="text-gray-600 text-center mb-6">
                        {i18n.language == "en" ? "Thank you for completing your payment. You can now view the sessions you have booked by visiting the 'Bookings' section." : "شكرًا على إتمام عملية الدفع. يمكنك الآن عرض الجلسات التي قمت بحجزها من خلال زيارة قسم 'الحجوزات'."}
                    </p>

                    {/* Action button */}
                    <button
                        onClick={() => window.location.reload()}
                        className="w-full py-3 bg-green-600 hover:bg-green-700 text-white font-medium rounded-md transition-colors"
                    >
                        {i18n.language == "en" ? "Go to Bookings" : "انتقل إلى قسم الحجوزات"}
                    </button>
                </div>
            </div>
        </div>
    )
}

