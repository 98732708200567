import { Eye, EyeOff, LanguagesIcon } from "lucide-react";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import countryData from "../../Data/country.mjs";
import i18n from "../../i18n";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";

export const languages = [
  { code: "en", lng: "English" },
  { code: "ar", lng: "Arabic" },
];

const LoginStudentForm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("TH_AUTH_TOKEN");
    if (token) navigate("/");
  }, [navigate]);

  // Language changer state
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] =
    useState(false);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
    setIsLanguageDropdownVisible(false);
  };

  // Login related states
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    telephone: "",
    otp: "",
  });
  const [loginMethod, setLoginMethod] = useState("email");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCode, setSelectedCode] = useState("+971"); // Default country code
  const [phoneLoginMethod, setPhoneLoginMethod] = useState("password");
  const [otpSent, setOtpSent] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCode(e.target.value);
  };

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    let payload;
    if (loginMethod === "email") {
      payload = {
        method: "email",
        password: credentials.password,
        phoneLoginMethod: "password",
        sms_verification_code: "",
        username: credentials.email,
      };
    } else {
      payload = {
        method: "phone",
        password: phoneLoginMethod === "password" ? credentials.password : "",
        phoneLoginMethod: phoneLoginMethod,
        sms_verification_code: phoneLoginMethod === "otp" ? credentials.otp : "",
        username: selectedCode + credentials.telephone,
      };
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/users/login_new/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("TH_AUTH_TOKEN", data.token);
        toast.success("Login successful! Redirecting...", {
          position: "top-right",
          autoClose: 3000,
          transition: Bounce,
        });
        setTimeout(() => {
          if (data.user.is_customer) navigate("/customer-dashboard");
          else navigate("/dashboard");
        }, 500);
      } else {
        const errorData = await response.json();
        const getErrorMessage = (errorData: any): string => {
          if (!errorData || typeof errorData !== "object")
            return "Invalid error response.";
          if (errorData.error_msg) return errorData.error_msg;
          if (errorData.phone?.[0]) return errorData.phone[0];
          if (errorData.email?.[0]) return errorData.email[0];
          return `Something went wrong. (Status: ${response.status})`;
        };
        throw new Error(getErrorMessage(errorData));
      }
    } catch (error: any) {
      if (error instanceof Error) toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendOtp = async () => {
    try {
      setIsLoading(true);
      const payload = {
        phone: selectedCode + credentials.telephone,
        recaptcha_token: "YOUR_RECAPTCHA_TOKEN_HERE",
      };
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/users/sms_verification_code/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        setOtpSent(true);
        setOtpTimer(60);
        toast.success("OTP sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          transition: Bounce,
        });
      } else {
        const errorData = await response.json();
        toast.error(
          errorData.message || "Failed to send OTP. Please try again.",
          {
            position: "top-right",
            autoClose: 4000,
            transition: Bounce,
          }
        );
      }
    } catch (error: any) {
      toast.error("An error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 4000,
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let interval: any;
    if (otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpTimer]);

  return (
    <div className="min-h-screen bg-white relative z-10">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        transition={Bounce}
      />

      {/* Header with Language Changer */}
      <header className={`flex ${i18n.language == "ar" ? "flex-row-reverse" : "flex-row"} justify-between items-center py-3 px-8 border-b border-gray-200 sticky top-0`}>
        <img src={LogoImage} alt="Logo" className="w-32" />
        <div className="relative">
          <button
            onClick={() =>
              setIsLanguageDropdownVisible(!isLanguageDropdownVisible)
            }
            className={`px-3 py-1 border border-[#008847] rounded flex items-center ${i18n.language === "ar" ? "flex-row-reverse" : "flex-row"} gap-2`}
          >
            <LanguagesIcon className="w-4 h-4" />
            {i18n.language === "ar" ? "العربية" : "English"}
          </button>
          {isLanguageDropdownVisible && (
            <ul className="absolute right-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
              {languages.map((language) => (
                <li
                  key={language.code}
                  onClick={() => changeLanguage(language.code)}
                  className="py-2 px-4 hover:bg-[#008847] hover:text-white cursor-pointer transition-colors duration-200"
                >
                  {language.lng}
                </li>
              ))}
            </ul>
          )}
        </div>
      </header>

      {/* Main Login Form */}
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className="flex justify-center items-center lg:h-[140vh] xl:h-[110vh] gap-1 py-4 xl:py-0 xl:gap-4"
      >
        <div className="space-y-4 px-4">
          {/* <div className="xl:w-44 2xl:w-52 h-20 md:block hidden">
            <img src={LogoImage} className="w-52" alt="Logo" />
          </div> */}
          <div className="flex flex-col space-y-5 px-10">
            <div className="space-y-2">
              <h1 className="text-4xl font-normal">
                {i18n.language === "ar" ? "مرحبا" : "Hey, Hello"}{" "}
                <span role="img" aria-label="wave">
                  👋
                </span>
              </h1>
              <p className="text-[#393939] font-normal text-lg leading-6">
                {i18n.language === "ar"
                  ? "أدخل بياناتك لتسجيل الدخول إلى حسابك"
                  : "Enter your credentials to log in to your Account"}
              </p>
            </div>

            <div className="flex justify-center items-center w-[253px] h-[50px] rounded-[30px] bg-[#e8f4ea]">
              <button
                onClick={() => setLoginMethod("email")}
                className={`py-2 px-6 ${loginMethod === "email"
                  ? "bg-[#008847] text-white h-10 rounded-[33px]"
                  : "bg-transparent text-[#4D4D4D]"
                  }`}
              >
                {i18n.language === "ar"
                  ? "البريد الالكتروني"
                  : "Email"}
              </button>
              <button
                onClick={() => setLoginMethod("telephone")}
                className={`py-2 px-6 ${loginMethod === "telephone"
                  ? "bg-[#008847] text-white h-10 rounded-[33px]"
                  : "bg-transparent text-[#4D4D4D]"
                  }`}
              >
                {i18n.language === "ar"
                  ? "التليفون"
                  : "Telephone"}
              </button>
            </div>

            <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
              {loginMethod === "email" && (
                <div className="space-y-3">
                  <label className="block font-bold text-base text-black">
                    {i18n.language === "ar"
                      ? "البريد الإلكتروني"
                      : "Email"}
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={credentials.email}
                    onChange={handleChange}
                    placeholder={
                      i18n.language === "ar"
                        ? "ادخل البريد الالكتروني"
                        : "Enter your email"
                    }
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  />
                  <label className="block font-bold text-base text-black">
                    {i18n.language === "ar"
                      ? "كلمة المرور"
                      : "Password"}
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={credentials.password}
                    onChange={handleChange}
                    placeholder={
                      i18n.language === "ar"
                        ? "ادخل كلمة المرور"
                        : "Enter your password"
                    }
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  />
                </div>
              )}

              {loginMethod === "telephone" && (
                <div className="space-y-3">
                  <label className="block font-bold text-base text-black">
                    {i18n.language === "ar"
                      ? "التليفون"
                      : "Telephone"}
                  </label>
                  <div className="group w-full flex items-center border border-[#D2D2D2] rounded-[40px] text-[#101828] font-normal text-base placeholder-[#667085] focus-within:ring-2 focus-within:ring-[#008847] overflow-hidden">
                    <select
                      className="px-4 py-2 outline-none bg-white text-black border-r border-gray-300 rounded-l-[40px] w-[120px] focus:ring-0 focus:border-none"
                      value={selectedCode}
                      onChange={handleCountryCodeChange}
                    >
                      {countryData.map((country) => (
                        <option key={country.iso_code} value={country.dial_code}>
                          ({country.dial_code}) {country.country}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      name="telephone"
                      value={credentials.telephone}
                      onChange={handleChange}
                      placeholder="555 000-0000"
                      className="flex-1 px-4 py-2 font-normal text-[#101828] placeholder-gray-500 text-base focus:outline-none rounded-r-[40px]"
                    />
                  </div>

                  <div className="flex justify-center items-center w-[230px] h-[50px] rounded-[30px] bg-[#e8f4ea]">
                    <button
                      type="button"
                      onClick={() => setPhoneLoginMethod("password")}
                      className={`py-2 px-6 ${phoneLoginMethod === "password"
                        ? "bg-[#008847] text-white h-10 rounded-[33px]"
                        : "bg-transparent text-[#4D4D4D]"
                        }`}
                    >
                      {i18n.language === "ar"
                        ? "كلمة المرور"
                        : "Password"}
                    </button>
                    <button
                      type="button"
                      onClick={() => setPhoneLoginMethod("otp")}
                      className={`py-2 px-6 ${phoneLoginMethod === "otp"
                        ? "bg-[#008847] text-white h-10 rounded-[33px]"
                        : "bg-transparent text-[#4D4D4D]"
                        }`}
                    >
                      {i18n.language == "ar" ? "أوتيبي" : "OTP"}
                    </button>
                  </div>

                  {phoneLoginMethod === "password" && (
                    <div className="space-y-3">
                      <label className="block font-bold text-base text-black">
                        {i18n.language === "ar"
                          ? "كلمة المرور"
                          : "Password"}
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={credentials.password}
                          onChange={handleChange}
                          placeholder={
                            i18n.language === "ar"
                              ? "ادخل كلمة المرور"
                              : "Enter your password"
                          }
                          className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className={`absolute inset-y-0 ${i18n.language == "ar" ? "left-4" : "right-4"} flex items-center text-[#667085]`}
                        >
                          {showPassword ? (
                            <EyeOff size={20} />
                          ) : (
                            <Eye size={20} />
                          )}
                        </button>
                      </div>
                    </div>
                  )}

                  {phoneLoginMethod === "otp" && (
                    <div className="space-y-3">
                      <label className="block font-bold text-base text-black">
                        {i18n.language === "ar"
                          ? "رمز التحقق"
                          : "OTP"}
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="otp"
                          value={credentials.otp}
                          onChange={handleChange}
                          placeholder="Enter OTP"
                          className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                        />
                        <button
                          type="button"
                          onClick={handleSendOtp}
                          disabled={
                            otpTimer > 0 ||
                            isLoading ||
                            !credentials.telephone
                          }
                          className={`absolute bg-[#008847] rounded-3xl text-white inset-y-0 ${i18n.language === "ar" ? "left-0" : "right-0"
                            } flex items-center px-3 ${otpTimer > 0 || !credentials.telephone
                              ? "opacity-50"
                              : ""
                            }`}
                        >
                          {otpTimer > 0
                            ? `Retry in ${otpTimer}s`
                            : `${i18n.language === "ar"
                              ? "رمز التحقق"
                              : "Send OTP"
                            }`}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="flex justify-end items-center">
                <Link
                  to="/reset"
                  className="text-sm text-[#008847] font-semibold hover:underline"
                >
                  {i18n.language === "ar"
                    ? "نسيت كلمة المرور؟"
                    : "Forgot Password?"}
                </Link>
              </div>

              <button
                type="submit"
                className="px-6 py-2 w-full bg-[#008847] text-white font-medium text-xl rounded-[28px] border-[5px] border-[#66b891]"
                disabled={isLoading}
              >
                {isLoading
                  ? ` ${i18n.language === "ar"
                    ? "تسجيل الدخول"
                    : "Loading..."
                  }`
                  : `${i18n.language === "ar"
                    ? "تسجيل الدخول"
                    : "Login"
                  }`}
              </button>
            </form>

            <div className="lg:w-[90%] px-[1%]">
              <div className={`flex lg:flex-row flex-col items-center lg:space-x-4 lg:space-y-0 space-y-3`}>
                <Link
                  to="/SignupTutor"
                  className="border-2 border-[#008847] w-full lg:w-[78%] h-[45%] xl:w-[240px] text-center no-underline bg-[#e5f3ed] rounded-[40px] text-[#008847] text-sm font-semibold py-2"
                >
                  {i18n.language === "ar"
                    ? "تسجيل حساب كمعلم"
                    : "Sign Up as Tutor"}
                </Link>
                <Link
                  to="/SignupStudent"
                  className={`border-2 border-[#008847] w-full lg:w-[80%] h-[35%] xl:w-[240px] text-center no-underline bg-[#e5f3ed] rounded-[40px] text-[#008847] text-sm font-semibold py-2 ${i18n.language == "ar" ? "me-3" : ""}`}
                >
                  {i18n.language === "ar"
                    ? "تسجيل حساب كطالب"
                    : "Sign Up as a Student"}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-[400px] lg:w-[450px] xl:w-[500px] 2xl:w-[600px] h-auto lg:block hidden">
          <img src={img2} alt="Right Section" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default LoginStudentForm;
