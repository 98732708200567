import axios from "axios";
import { GraduationCap, TriangleAlert } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

type TutorData = {
  photo: string;
  subjects: [price_in_cents: any];
  user: {
    first_name: string;
    id: number;
  };
  incomplete_sections: string[];
  qualifications: any;
  availability: any;
  completed_sections: any;
  postcode: any;
  is_live: boolean;
  user_timezone: any;
};

type Job = {
  status: string;
  job_type: string;
  subject: string;
  level: string;
  start_date: string;
  experience: string | null;
  duration: string;
  id: number;
  icon: string | null;
  description: string;
  location: string | null;
  is_open: boolean;
  slug: string;
  subject_id: number;
  level_id: number;
  postcode: string;
  budget: string;
  price_min: number;
  price_max: number;
  tags: string[];
  applied_at: string | null;
};

type JobsResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Job[];
};

const ProfileCard = ({
  TutorData,
  onSeeAll,
}: // seeButtonHandler
  any) => {
  const [jobs, setJobs] = useState<JobsResponse | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get<JobsResponse>(
          "https://api.nadwa-uae.com/api/v2/schoolsupply/jobs/?online=true&postcode=&pricing_max=400&pricing_min=50",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        if (!response.data) {
          return;
        }
        setJobs(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch jobs");
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  if (!jobs) {
    return <div>Loading...</div>;
  }
  function formatDubaiTime(utcTime: any, showDate = false) {
    const date = new Date(utcTime);

    // Format options
    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: TutorData?.user_timezone || "Asia/Dubai",
    };

    if (showDate) {
      options.weekday = "short";
      options.month = "short";
      options.day = "numeric";
    }

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }
  function getTutorTimeGreeting() {
    const utcNow = new Date().toISOString(); // Get current UTC time
    const tutorTime = formatDubaiTime(utcNow); // Convert to tutor's timezone

    const hour = parseInt(tutorTime?.split(":")[0]); // Extract hour

    return hour < 12 ? "Good Morning" : "Good Evening";
  }

  return (
    <div className="bg-white p-2 sm:p-4 space-y-5 rounded-lg shadow ">
      <div className="flex items-center">
        <h1 className="text-[#000606] text-2xl">{t("my_profile")}</h1>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        <div className="rounded-full p-2 border w-40 h-40 flex items-center justify-center bg-white">
          {TutorData.is_live ? (
            <Link to={`/Tutor/${TutorData.user.id}`}>
              {TutorData.photo ? (
                <picture>
                  <source srcSet={TutorData.photo} type="image/webp" />
                  <img
                    src={
                      TutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"
                    }
                    alt={TutorData.user.first_name}
                    className="w-32 h-32 rounded-full no-underline"
                  />
                </picture>
              ) : (
                <div className="w-32 h-32 bg-[#e5dfe9] text-black text-2xl rounded-full flex items-center justify-center">
                  {TutorData.user.first_name
                    ? TutorData.user.first_name.substring(0, 2).toUpperCase()
                    : "U"}
                </div>
              )}
            </Link>
          ) : TutorData.photo ? (
            <picture>
              <source srcSet={TutorData.photo} type="image/webp" />
              <img
                src={TutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"}
                alt={TutorData.user.first_name}
                className="w-32 h-32 rounded-full no-underline"
              />
            </picture>
          ) : (
            <div className="w-32 h-32 bg-[#e5dfe9] text-black text-2xl rounded-full flex items-center justify-center">
              {TutorData.user.first_name
                ? TutorData.user.first_name.substring(0, 2).toUpperCase()
                : "U"}
            </div>
          )}
        </div>
        <div className="">
          <div className="flex items-center justify-center">
            <h2>
              AED{" "}
              {TutorData.subjects[0]?.price_in_cents
                ? TutorData.subjects[0].price_in_cents / 100
                : 0.0}{" "}
            </h2>
            <span className="text-lg text-gray-400">/hour</span>
          </div>
          {TutorData.qualifications &&
            TutorData.availability &&
            TutorData.completed_sections &&
            TutorData.school == null &&
            TutorData.experience == null &&
            TutorData.experience_years == 0 &&
            TutorData.video == null &&
            TutorData.subjects &&
            TutorData.tagline == null &&
            TutorData.user?.email &&
            TutorData.user?.first_name &&
            TutorData.user?.last_name &&
            TutorData.user?.phone_number &&
            TutorData.postcode == null && (
              <Link
                to={"/EditProfile"}
                className="no-underline gap-2 flex items-center justify-center text-black"
              >
                <TriangleAlert className="text-red-500" />
                {/* <X className="mb-3 text-red-500" /> */}
                <p className="my-[-12px]">Profile Not Completed</p>
              </Link>
            )}
          <h2 className="text-lg font-semibold">
            {getTutorTimeGreeting()} {""}
            {/* {new Date().getHours() < 12 ? "Good Morning" : "Good Evening"}{" "} */}
            {TutorData.user.first_name}
          </h2>
          {
            <h2 className="text-red-500 text-base text-center">
              {" "}
              {TutorData.is_live ? "Live" : "Not Live"}
            </h2>
          }
        </div>
      </div>
      <div>
        <h1 className="text-[#000606] text-xl">{t("apply_for_jobs")}</h1>
        <div className="bg-[#ebf6f1] p-2 rounded-lg shadow-md max-w-md mx-auto">
          {loading ? (
            <p>Loading jobs...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            jobs.results.slice(0, 2).map((job) => (
              <div
                key={job.id}
                className="flex flex-col 2xl:flex-row gap-2 items-center justify-between p-3 rounded-lg border-b border-dashed border-[#8dcaad]"
              >
                <div className="flex items-center justify-center gap-3 xl:max-w-[80%]">
                  <div className="flex justify-center overflow-hidden bg-black text-white items-center w-16 h-16 rounded-full ">
                    <h3 className="text-[12px]">{job.subject}</h3>
                  </div>
                  <div>
                    <h3 className="font-medium text-[13px]">
                      {job.subject} Tutor Requests
                    </h3>
                    <div className="flex items-center justify-between text-sm text-green-600 mt-1">
                      <div className="px-2 py-1 flex items-center bg-[#008847] text-white rounded">
                        <GraduationCap />
                        <span className="text-xs sm:text-sm">{job.level}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  // onClick={()=>seeButtonHandler(job.id)}
                  className="px-2 py-2 rounded-2xl text-[#008847] text-[12px] border-2 border-[#008847]"
                >
                  View Job
                </button> */}
              </div>
            ))
          )}
          <button
            onClick={onSeeAll}
            className="bg-[#d4ece0] text-green-600 w-full py-2 rounded-2xl mt-4 hover:bg-green-200"
          >
            See All
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
