import { useTranslation } from "react-i18next";
import logo from "../images/Homepageassets/Nadwa-Color.png";
// import Icon1 from "../images/icons/Social_icon_1.png";
// import Icon4 from "../images/icons/Social_icon_4.png";
// import Icon5 from "../images/icons/Social_icon_5.png";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  console.log(location.pathname);

  const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <footer
      // className={`relative ${(location.pathname == "/customer-dashboard" || location.pathname == "/dashboard") ? "-z-50" : ""} bg-no-repeat text-gray-800 pt-6 lg:py-0 rounded-t-[50px]`}
      className={`relative ${(location.pathname === "/customer-dashboard" || location.pathname === "/dashboard") ? "" : ""}`}
    // style={{
    //   backgroundImage: `url(${FooterImage})`,
    //   backgroundSize: 'cover',
    //   backgroundPosition: 'top center',
    //   backgroundRepeat: 'no-repeat',
    //   backgroundAttachment: 'scroll',
    // }}
    >
      <div className="footer-background" />
      <div className=" py-10 flex flex-col items-center lg:max-w-[94%] max-w-[98%] mx-auto relative z-[999]">
        <div className="max-w-7xl mx-auto text-center flex flex-col justify-center items-center">
          {/* Logo Section */}

          <img
            src={logo}
            alt="Logo"
            className="w-1/2"
          />

          {/* Navigation Links */}
          <nav className={`flex justify-center flex-col lg:flex-row items-center space-y-6 lg:space-y-0 lg:space-x-6 mb-8 mt-5 ${isArabic ? "" : "font-['Helvetica']"}`}>
            <a href="/about" className={`${isArabic ? "lg:ml-6" : ""} text-gray-700 hover:text-gray-900 text-lg font-normal capitalize leading-[18px] no-underline`}>
              {t('footer')}
            </a>
            <a href="/how-it-works" className="text-gray-700 hover:text-gray-900 text-lg font-normal capitalize leading-[18px] no-underline">
              {t('footer2')}
            </a>
            <a href="/blogs" className="text-gray-700 hover:text-gray-900 text-lg font-normal capitalize leading-[18px] no-underline">
              {t('footer4')}
            </a>
          </nav>


          {/* Newsletter Subscription */}
          {(location.pathname !== "/dashboard" && location.pathname !== "/customer-dashboard" && location.pathname !== "/login" && location.pathname !== "/SignupTutor" && location.pathname !== "/SignupStudent") && (
            <div className="lg:max-w-[600px] mx-auto mb-8">
              <h3 className={`lg:text-4xl text-2xl font-bold ${isArabic ? "" : "font-['Helvetica']"} lg:leading-[58px]`}>
                {t('footer5')}
              </h3>
              <form className={`flex ${isArabic ? "flex-row-reverse" : ""} items-center rounded-2xl shadow-xl`}>
                <input
                  type="email"
                  placeholder={t('form7')}
                  className="flex-1 lg:px-4 px-2 py-2 focus:outline-none focus:ring-2 focus:ring-green-600 bg-white placeholder-black placeholder:text-black placeholder:text-base placeholder:font-normal placeholder:font-['Helvetica'] placeholder:leading-none"
                />
                <button
                  type="submit"
                  className="bg-green-600 text-white lg:px-6 px-2 py-2 rounded-r-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600 lg:text-right lg:text-lg text-sm font-bold font-['Helvetica']"
                >
                  {t('footer6')}
                </button>
              </form>
            </div>
          )}


          {/* Copyright and Links */}
        </div>
        <div className="border-t pt-4 w-[90%] sapce-y-4 lg:space-y-0 text-center lg:text-start flex lg:flex-row justify-between flex-col">
          <p className="text-sm text-gray-600">
            {t('footer7')}
          </p>
          {/* Social Media Links */}
          {/* This is all done */}
          <div className={` ${isArabic ? "flex-row-reverse" : ""} flex justify-center space-x-4 mb-8 `}>
            {/* Facebook */}
            {/* <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
            >
              <img
                src={Icon2} // Adjust the path as per your project structure
                alt="Facebook"
                className="w-[9.42px] h-[16.53px]"
              />
            </a> */}

            {/* LinkedIn */}
            {/* <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
            >
              <img
                src={Icon4} // Adjust the path as per your project structure
                alt="LinkedIn"
                className="w-[16.54px] h-[16.54px]"
              />
            </a> */}

            {/* Instagram */}
            {/* <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
            >
              <img
                src={Icon1}// Adjust the path as per your project structure
                alt="Instagram"
                className="w-[16.54px] h-[15.54px]"
              />
            </a> */}
            {/* YouTube */}
            {/* <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-900"
            >
              <img
                src={Icon5}// Adjust the path as per your project structure
                alt="YouTube"
                className="w-[16.54px] h-[15.54px]"
              />
            </a> */}
          </div>
          <div className="flex justify-center space-x-6 mt-1">
            <a href="/term-conditions" className="text-gray-700 hover:text-gray-900">
              {t('footer8')}
            </a>
            <a href="/privacy-policy" className="text-gray-700 hover:text-gray-900">
              {t('footer9')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;



