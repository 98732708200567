import React from "react";
import { X } from "lucide-react";
import { useTranslation } from "react-i18next";

interface DelLessonPopupProps {
  isOpen: boolean;
  onClose: () => void;
  startTime: string;
  endTime: string;
  studentName: string;
  onDelete: (id: number) => Promise<void>;
  id: number;
  isDeleting: boolean;
}

const DelLessonPopup: React.FC<DelLessonPopupProps> = ({
  isOpen,
  onClose,
  startTime,
  endTime,
  studentName,
  onDelete,
  id,
  isDeleting,
}) => {
  const { t } = useTranslation();

  const isWithin48Hours = () => {
    const now = new Date();
    const lessonStart = new Date(startTime);
    const hoursUntilLesson =
      (lessonStart.getTime() - now.getTime()) / (1000 * 60 * 60);
    return hoursUntilLesson < 48;
  };

  const handleDelete = async () => {
    if (isWithin48Hours()) {
      // We'll let LessonInterface handle the toast
      return;
    }

    try {
      await onDelete(id); // This will trigger the toast in LessonInterface
      // Delay closing to let the toast show (optional, can remove if not needed)
      setTimeout(() => {
        onClose();
      }, 500); // Small delay to ensure toast renders
    } catch (error: any) {
      console.error("Error in delete handler:", error);
      // Error toast is handled in LessonInterface
    }
  };

  if (!isOpen) return null;

  const cannotDelete = isWithin48Hours();

  const formatDateTime = (date: string) => {
    return new Intl.DateTimeFormat("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Dubai",
    }).format(new Date(date));
  };

  return (
    <div className="fixed inset-0 h-screen z-30 bg-gray-700 bg-opacity-5 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-base sm:text-lg font-semibold">
            {t("cancel_lesson")} {studentName}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 disabled:opacity-50"
            disabled={isDeleting}
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-3 mb-6 text-sm sm:text-1xl">
          <div className="text-gray-600">
            {t("start_time")}: {formatDateTime(startTime)}
          </div>
          <div className="text-gray-600">
            {t("end_time")}: {formatDateTime(endTime)}
          </div>

          {cannotDelete && (
            <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md text-sm sm:text-1xl">
              <p className="text-red-600 font-medium">
                This lesson cannot be cancelled as it is within 48 hours of the
                start time.
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-end gap-3">
          <button
            onClick={onClose}
            disabled={isDeleting}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm sm:text-base hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {t("back")}
          </button>
          <button
            onClick={handleDelete}
            disabled={isDeleting || cannotDelete}
            className={`px-2 sm:px-4 py-1 sm:py-2 rounded-md text-sm sm:text-base text-white
              ${isDeleting || cannotDelete
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-500 hover:bg-red-600"
              } disabled:opacity-50`}
          >
            {isDeleting ? "Cancelling..." : t("cancel_lesson_action")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DelLessonPopup;