import { useEffect, useState } from "react";
import { X, Check, ChevronDown } from "lucide-react";
import axios from "axios";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { useTranslation } from "react-i18next";
interface NotificationSetting {
  id: string;
  label: string;
  checked: boolean;
}

export default function AccountSettings() {

  const [isOpen, setIsOpen] = useState(true);
  const [timeZone, setTimeZone] = useState("");
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationSetting[]>([
    { id: "new_message", label: t("account_settings_page.when_i_receive_a_new_message"), checked: true },
    { id: "lesson_canceled", label: t("account_settings_page.tutor_cancels_a_lesson"), checked: true },
    {
      id: "news_from_tutorhouse",
      label: t("account_settings_page.any_offers_or_news_from_nadwa"),
      checked: true,
    },
    {
      id: "sms_new_message",
      label: t("account_settings_page.send_me_an_sms_when_i_receive_a_new_message"),
      checked: true,
    },
    { id: "payment_made", label: t("account_settings_page.payment_is_made_to_a_tutor"), checked: true },
    {
      id: "new_invitation",
      label: t("account_settings_page.i_receive_a_lesson_invitation_from_a_tutor"),
      checked: true,
    },
  ]);
  const timeZones = [
    { "label": "(GMT-11:00) Pago Pago", "key": "Pacific/Pago_Pago" },
    { "label": "(GMT+04:00) Asia/Dubai", "key": "Asia/Dubai" },
    { "label": "(GMT-10:00) Hawaii Time", "key": "Pacific/Honolulu" },
    { "label": "(GMT-08:00) Pacific Time", "key": "America/Los_Angeles" },
    { "label": "(GMT-08:00) Pacific Time - Tijuana", "key": "America/Tijuana" },
    { "label": "(GMT-07:00) Mountain Time", "key": "America/Denver" },
    { "label": "(GMT-07:00) Mountain Time - Arizona", "key": "America/Phoenix" },
    { "label": "(GMT-07:00) Mountain Time - Chihuahua, Mazatlan", "key": "America/Mazatlan" },
    { "label": "(GMT-06:00) Central Time", "key": "America/Chicago" },
    { "label": "(GMT-06:00) Central Time - Mexico City", "key": "America/Mexico_City" },
    { "label": "(GMT-06:00) Central Time - Regina", "key": "America/Regina" },
    { "label": "(GMT-06:00) Guatemala", "key": "America/Guatemala" },
    { "label": "(GMT-05:00) Bogota", "key": "America/Bogota" },
    { "label": "(GMT-05:00) Eastern Time", "key": "America/New_York" },
    { "label": "(GMT-05:00) Lima", "key": "America/Lima" },
    { "label": "(GMT-04:30) Caracas", "key": "America/Caracas" },
    { "label": "(GMT-04:00) Atlantic Time - Halifax", "key": "America/Halifax" },
    { "label": "(GMT-04:00) Guyana", "key": "America/Guyana" },
    { "label": "(GMT-04:00) La Paz", "key": "America/La_Paz" },
    { "label": "(GMT-03:00) Buenos Aires", "key": "America/Argentina/Buenos_Aires" },
    { "label": "(GMT-03:00) Godthab", "key": "America/Godthab" },
    { "label": "(GMT-03:00) Montevideo", "key": "America/Montevideo" },
    { "label": "(GMT-03:30) Newfoundland Time - St. Johns", "key": "America/St_Johns" },
    { "label": "(GMT-03:00) Santiago", "key": "America/Santiago" },
    { "label": "(GMT-02:00) Sao Paulo", "key": "America/Sao_Paulo" },
    { "label": "(GMT-02:00) South Georgia", "key": "Atlantic/South_Georgia" },
    { "label": "(GMT-01:00) Azores", "key": "Atlantic/Azores" },
    { "label": "(GMT-01:00) Cape Verde", "key": "Atlantic/Cape_Verde" },
    { "label": "(GMT+00:00) Casablanca", "key": "Africa/Casablanca" },
    { "label": "(GMT+00:00) Dublin", "key": "Europe/Dublin" },
    { "label": "(GMT+00:00) Lisbon", "key": "Europe/Lisbon" },
    { "label": "(GMT+00:00) London", "key": "Europe/London" },
    { "label": "(GMT+00:00) Monrovia", "key": "Africa/Monrovia" },
    { "label": "(GMT+01:00) Algiers", "key": "Africa/Algiers" },
    { "label": "(GMT+01:00) Amsterdam", "key": "Europe/Amsterdam" },
    { "label": "(GMT+01:00) Berlin", "key": "Europe/Berlin" },
    { "label": "(GMT+01:00) Brussels", "key": "Europe/Brussels" },
    { "label": "(GMT+01:00) Budapest", "key": "Europe/Budapest" },
    { "label": "(GMT+01:00) Central European Time - Belgrade", "key": "Europe/Belgrade" },
    { "label": "(GMT+01:00) Central European Time - Prague", "key": "Europe/Prague" },
    { "label": "(GMT+01:00) Copenhagen", "key": "Europe/Copenhagen" },
    { "label": "(GMT+01:00) Madrid", "key": "Europe/Madrid" },
    { "label": "(GMT+01:00) Paris", "key": "Europe/Paris" },
    { "label": "(GMT+01:00) Rome", "key": "Europe/Rome" },
    { "label": "(GMT+01:00) Stockholm", "key": "Europe/Stockholm" },
    { "label": "(GMT+01:00) Vienna", "key": "Europe/Vienna" },
    { "label": "(GMT+01:00) Warsaw", "key": "Europe/Warsaw" },
    { "label": "(GMT+02:00) Athens", "key": "Europe/Athens" },
    { "label": "(GMT+02:00) Bucharest", "key": "Europe/Bucharest" },
    { "label": "(GMT+02:00) Cairo", "key": "Africa/Cairo" },
    { "label": "(GMT+02:00) Jerusalem", "key": "Asia/Jerusalem" },
    { "label": "(GMT+02:00) Johannesburg", "key": "Africa/Johannesburg" },
    { "label": "(GMT+02:00) Helsinki", "key": "Europe/Helsinki" },
    { "label": "(GMT+02:00) Kiev", "key": "Europe/Kiev" },
    { "label": "(GMT+02:00) Moscow-01 - Kaliningrad", "key": "Europe/Kaliningrad" },
    { "label": "(GMT+02:00) Riga", "key": "Europe/Riga" },
    { "label": "(GMT+02:00) Sofia", "key": "Europe/Sofia" },
    { "label": "(GMT+02:00) Tallinn", "key": "Europe/Tallinn" },
    { "label": "(GMT+02:00) Vilnius", "key": "Europe/Vilnius" },
    { "label": "(GMT+03:00) Istanbul", "key": "Europe/Istanbul" },
    { "label": "(GMT+03:00) Baghdad", "key": "Asia/Baghdad" },
    { "label": "(GMT+03:00) Nairobi", "key": "Africa/Nairobi" },
    { "label": "(GMT+03:00) Minsk", "key": "Europe/Minsk" },
    { "label": "(GMT+03:00) Riyadh", "key": "Asia/Riyadh" },
    { "label": "(GMT+03:00) Moscow+00 - Moscow", "key": "Europe/Moscow" },
    { "label": "(GMT+03:30) Tehran", "key": "Asia/Tehran" },
    { "label": "(GMT+04:00) Baku", "key": "Asia/Baku" },
    { "label": "(GMT+04:00) Moscow+01 - Samara", "key": "Europe/Samara" },
    { "label": "(GMT+04:00) Tbilisi", "key": "Asia/Tbilisi" },
    { "label": "(GMT+04:00) Yerevan", "key": "Asia/Yerevan" },
    { "label": "(GMT+04:30) Kabul", "key": "Asia/Kabul" },
    { "label": "(GMT+05:00) Karachi", "key": "Asia/Karachi" },
    { "label": "(GMT+05:00) Moscow+02 - Yekaterinburg", "key": "Asia/Yekaterinburg" },
    { "label": "(GMT+05:00) Tashkent", "key": "Asia/Tashkent" },
    { "label": "(GMT+05:30) Colombo", "key": "Asia/Colombo" },
    { "label": "(GMT+06:00) Almaty", "key": "Asia/Almaty" },
    { "label": "(GMT+06:00) Dhaka", "key": "Asia/Dhaka" },
    { "label": "(GMT+06:30) Rangoon", "key": "Asia/Rangoon" },
    { "label": "(GMT+07:00) Bangkok", "key": "Asia/Bangkok" },
    { "label": "(GMT+07:00) Jakarta", "key": "Asia/Jakarta" },
    { "label": "(GMT+07:00) Moscow+04 - Krasnoyarsk", "key": "Asia/Krasnoyarsk" },
    { "label": "(GMT+08:00) China Time - Beijing", "key": "Asia/Shanghai" },
    { "label": "(GMT+08:00) Hong Kong", "key": "Asia/Hong_Kong" },
    { "label": "(GMT+08:00) Kuala Lumpur", "key": "Asia/Kuala_Lumpur" },
    { "label": "(GMT+08:00) Moscow+05 - Irkutsk", "key": "Asia/Irkutsk" },
    { "label": "(GMT+08:00) Singapore", "key": "Asia/Singapore" },
    { "label": "(GMT+08:00) Taipei", "key": "Asia/Taipei" },
    { "label": "(GMT+08:00) Ulaanbaatar", "key": "Asia/Ulaanbaatar" },
    { "label": "(GMT+08:00) Western Time - Perth", "key": "Australia/Perth" },
    { "label": "(GMT+09:00) Moscow+06 - Yakutsk", "key": "Asia/Yakutsk" },
    { "label": "(GMT+09:00) Seoul", "key": "Asia/Seoul" },
    { "label": "(GMT+09:00) Tokyo", "key": "Asia/Tokyo" },
    { "label": "(GMT+09:30) Central Time - Darwin", "key": "Australia/Darwin" },
    { "label": "(GMT+10:00) Eastern Time - Brisbane", "key": "Australia/Brisbane" },
    { "label": "(GMT+10:00) Guam", "key": "Pacific/Guam" },
    { "label": "(GMT+10:00) Moscow+07 - Magadan", "key": "Asia/Magadan" },
    { "label": "(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk", "key": "Asia/Vladivostok" },
    { "label": "(GMT+10:00) Port Moresby", "key": "Pacific/Port_Moresby" },
    { "label": "(GMT+10:30) Central Time - Adelaide", "key": "Australia/Adelaide" },
    { "label": "(GMT+11:00) Eastern Time - Hobart", "key": "Australia/Hobart" },
    { "label": "(GMT+11:00) Eastern Time - Melbourne, Sydney", "key": "Australia/Sydney" },
    { "label": "(GMT+11:00) Guadalcanal", "key": "Pacific/Guadalcanal" },
    { "label": "(GMT+11:00) Noumea", "key": "Pacific/Noumea" },
    { "label": "(GMT+12:00) Majuro", "key": "Pacific/Majuro" },
    { "label": "(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy", "key": "Asia/Kamchatka" },
    { "label": "(GMT+13:00) Auckland", "key": "Pacific/Auckland" },
    { "label": "(GMT+13:00) Fakaofo", "key": "Pacific/Fakaofo" },
    { "label": "(GMT+13:00) Fiji", "key": "Pacific/Fiji" },
    { "label": "(GMT+13:00) Tongatapu", "key": "Pacific/Tongatapu" },
    { "label": "(GMT+14:00) Apia", "key": "Pacific/Apia" }
  ]
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  // Filter timezones based on search term
  const filteredTimeZones = timeZones.filter(
    (zone) =>
      zone.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
      zone.key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle timezone selection
  const handleSelect = (zone: any) => {
    setTimeZone(zone.key);
    setSearchTerm(zone.label); // Show selected label in input
    // Dropdown remains open as we don't toggle isOpen here
  };
  const toggleNotification = (id: string) => {
    setNotifications(
      notifications.map((notification) =>
        notification.id === id
          ? { ...notification, checked: !notification.checked }
          : notification
      )
    );
  };
  useEffect(() => {
    const getSettings = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/customer/settings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setNotifications((prev) =>
          prev.map(({ id, label }) => ({
            id,
            label,
            checked: res.data[id],
          }))
        );
        const res2 = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTimeZone(res2.data.data.user.user_timezone);
      } catch (error) {
        console.log(error);
      }
    };
    getSettings();
  }, []);

  const setttings = async () => {
    try {
      const notificationData = notifications.reduce((acc, notification) => {
        acc[notification.id] = notification.checked;
        return acc;
      }, {} as Record<string, boolean>);

      const payload = {
        notifications: notificationData,
      };

      await axios.patch(
        "https://api.nadwa-uae.com/api/v2/customer/settings/",
        payload.notifications,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      const res = await axios.put(
        "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
        { user_timezone: timeZone },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      console.log(res.data)
      toast.success("Changes Successfully Saved", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => setIsOpen(false),
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/70 z-30 flex items-center justify-center p-4">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover
        draggable
        theme="light"
        transition={Bounce}
      />
      <div className="bg-white rounded-2xl lg:max-w-[50%] w-full p-6">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold"> {t('account_settings')} </h2>
          <button
            onClick={() => setIsOpen(false)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Time Zone Selector */}
        <div className="mb-6">
          <label className="block text-sm mb-2">{t('select_your_time_zone')}</label>
          <div className="relative">
            {/* Input field with search functionality */}
            <input
              type="text"
              value={searchTerm === "" && !isFocused ? timeZone : searchTerm} // Default show timeZone if searchTerm is empty and not focused
              onChange={(e) => {
                setSearchTerm(e.target.value); // Update searchTerm on input change
                setDropdownOpen(true); // Open dropdown when typing
              }}
              onFocus={() => {
                setDropdownOpen(true); // Open dropdown on focus
                setIsFocused(true); // Mark input as focused
              }}
              onBlur={() => setIsFocused(false)} // Reset isFocused when input loses focus
              placeholder={timeZone}
              className="w-full p-3 pr-10 bg-white border rounded-lg cursor-text"
            />

            <ChevronDown className="w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none text-gray-500" />

            {/* Dropdown menu */}
            {dropdownOpen && (
              <div className="absolute z-10 w-full mt-1 max-h-60 overflow-y-auto bg-white border rounded-lg shadow-lg">
                {filteredTimeZones.length > 0 ? (
                  filteredTimeZones.map((zone, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        handleSelect(zone)
                        setDropdownOpen(false)
                      }}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {zone.label}
                    </div>
                  ))
                ) : (
                  <div className="p-2 text-gray-500">No results found</div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Notification Settings */}
        <div className="mb-6">
          <h3 className="text-base font-medium mb-4">{t('notification_settings')}</h3>
          <div className="space-y-4">
            {notifications.map((notification) => (
              <label
                key={notification.id}
                className="flex items-center gap-3 cursor-pointer group"
              >
                <div
                  className={`w-5 h-5 rounded border flex items-center justify-center transition-colors
                    ${notification.checked
                      ? "bg-[#008847] border-green-600"
                      : "border-gray-300 group-hover:border-green-600"
                    }`}
                  onClick={() => {
                    toggleNotification(notification.id);
                  }}
                >
                  {notification.checked && (
                    <Check className="w-3.5 h-3.5 text-white" />
                  )}
                </div>
                <span className="text-sm">{notification.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button
            onClick={setttings}
            className="px-6 py-2 bg-[#008847] text-white rounded-xl hover:bg-green-700 transition-colors"
          >
            {t("save_changes")}
          </button>
        </div>
      </div>
    </div>
  );
}
