import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MutatingDots } from "react-loader-spinner";
import i18n from "../../i18n";
import { stripePromise } from "../AboutTutorComponents/bookingTrial";
import { RejectLessonModal } from "../AboutTutorComponents/RejectLesson";
import RescheduleModal from "../DashBoardStudent/popups/Reschedule";
import ReviewModal from "../DashBoardStudent/popups/ReviewTutor";
import ApplicationModal2 from "../DashBoardStudent/sendMessagePart2";
import CancelLessonPopup from "./CancelLesson";
import Checkout from "./Checkout";

// TutorAvatar component (unchanged)
export const TutorAvatar = ({ photo_card, name }: any) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const nameParts = name ? name.trim().split(" ") : [];
  const initials =
    nameParts.length > 1
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : nameParts[0]
        ? nameParts[0][0]
        : "";

  const jpgSrc = photo_card
    ? photo_card.replace(".webp", ".jpg")
    : "fallback.jpg";

  return (
    <div className="min-w-12 h-12 rounded-full overflow-hidden ">
      {imageError || !photo_card ? (
        <div className="inset-0 h-full flex items-center justify-center bg-green-500 text-white text-lg font-bold">
          {initials.toUpperCase()}
        </div>
      ) : (
        <picture className="w-full h-full">
          {photo_card && <source srcSet={photo_card} type="image/webp" />}
          <img
            src={jpgSrc}
            alt={name || "Tutor"}
            className="w-full h-full object-cover"
            onError={handleImageError}
          />
        </picture>
      )}
    </div>
  );
};

const StudentBooking = ({
  setActiveTabs2,
  setIsTrue,
  setTutorChatId,
  setUnreadMessage,
  setActiveTab3,
  activeTab3,
}: any) => {
  const [trialCall, setTrialCall] = useState([]);
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [pastLessons, setPastLessons] = useState([]);
  const [notification, setNotification] = useState<any>(null);
  const [sendMessage, setSendMessage] = useState(false);
  const [singleTutor] = useState({});
  const [reject, setReject] = useState(false);
  const [bookingId, setBookingId] = useState(0);
  const [reschedule, setReschedule] = useState(false);
  const [review, setReview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(false);
  const [time, setTime] = useState("Asia/Dubai");
  const [feedBackId, setFeedBackId] = useState(0);
  const [invitationId, setInvitationId] = useState(0);
  const [tutorData, setTutorData] = useState({});
  const [updateUi, setUpdateUi] = useState(false);
  const [subject, setSubject] = useState<string>("");
  const [bookingDetails, setBookingDetails] = useState<any>({
    customer: null,
    start_time: null,
    end_time: null,
    subject: null,
    tutor: null,
    credit_card: null,
    location: "online",
    num_weeks: null,
    invitation_dates: [{ start_time: null, end_time: null }],
    payment_amount_in_cents: 0,
  });
  const [price, setPrice] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [lessonTiming, setLessonTiming] = useState<any>({});
  const [loadingStates, setLoadingStates] = useState<any>({}); // Per-lesson loading state
  const [pendingConfirmation, setPendingConfirmation] = useState(null); // Track lesson being confirmed

  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  // Fetch user timezone
  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        let userTimeZone = res.data.data?.user?.user_timezone;
        if (typeof userTimeZone === "string") {
          try {
            new Intl.DateTimeFormat("en-US", { timeZone: userTimeZone });
            setTime(userTimeZone);
          } catch (error) {
            console.warn(
              `Invalid time zone: ${userTimeZone}. Defaulting to "Asia/Dubai".`
            );
            setTime("Asia/Dubai");
          }
        } else {
          console.error("Invalid timezone format:", userTimeZone);
          setTime("Asia/Dubai");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setTime("Asia/Dubai");
      }
    };
    getUser();
    return () => setBookingDetails(null);
  }, []);

  // Mark bookings as read
  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(
          "https://api.nadwa-uae.com/api/v2/customer/bookings/mark_booking_as_read/",
          {
            method: "POST",
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        if (res.ok) setUnreadMessage(null);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  // Fetch invitation data when invitationId changes
  useEffect(() => {
    if (invitationId !== 0 && !isFetching) {
      const fetchData = async () => {
        setIsFetching(true);
        if (pendingConfirmation) {
          setLoadingStates((prev: any) => ({
            ...prev,
            [pendingConfirmation]: true,
          }));
        }
        try {
          await getData();
        } finally {
          setIsFetching(false);
          if (pendingConfirmation) {
            setLoadingStates((prev: any) => ({
              ...prev,
              [pendingConfirmation]: false,
            }));
            setPendingConfirmation(null);
          }
        }
      };
      fetchData();
    }
  }, [invitationId]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/customer/invitations/${invitationId}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setBookingDetails({
        ...bookingDetails,
        customer: res.data.customer,
        start_time: res.data.start_time,
        end_time: res.data.end_time,
        subject: res.data.subject.id,
        credit_card: res.data.credit_card,
        invitation_dates: res.data.invitation_dates,
        num_weeks: res.data.num_weeks,
        tutor: res.data.tutor.id,
        payment_amount_in_cents: res.data.payment_amount_in_cents,
      });
      setTutorData(res.data.tutor);
      setPrice(res.data.payment_amount_in_cents);
      setPayment(true);
    } catch (error) {
      console.error("Error fetching invitation data:", error);
    }
  };

  const rejectData = async (id: number) => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/customer/invitations/${id}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setBookingDetails({
        ...bookingDetails,
        customer: res.data.customer,
        start_time: res.data.start_time,
        end_time: res.data.end_time,
        subject: res.data.subject.id,
        credit_card: res.data.credit_card,
        invitation_dates: res.data.invitation_dates,
        num_weeks: res.data.num_weeks,
        tutor: res.data.tutor.id,
      });
      setTutorData(res.data.tutor);
      setPrice(res.data.payment_amount_in_cents);
      setReject(true);
    } catch (error) {
      console.error("Error fetching invitation data:", error);
    }
  };

  // Updated formatUserTime function
  function formatUserTime(utcTime: any, showDate = false) {
    const date = new Date(utcTime);
    try {
      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
        timeZone: time,
      });
      if (showDate) {
        const dateFormatter = new Intl.DateTimeFormat("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          timeZone: time,
        });
        return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;
      } else {
        return timeFormatter.format(date);
      }
    } catch (error) {
      console.error("Error formatting date:", error);
      const timeFormatter = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Dubai",
      });
      if (showDate) {
        const dateFormatter = new Intl.DateTimeFormat("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "Asia/Dubai",
        });
        return `${dateFormatter.format(date)} at ${timeFormatter.format(date)}`;
      } else {
        return timeFormatter.format(date);
      }
    }
  }

  // Fetch lessons
  useEffect(() => {
    const isToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!isToken) return;
    const fetchLessons = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/customer/bookings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        const allLessons = res.data;
        const currentTime = new Date().toISOString();

        setTrialCall(allLessons.filter((lesson: any) => lesson.is_trial_call));
        setUpcomingLessons(
          allLessons.filter(
            (lesson: any) =>
              lesson.end_time > currentTime && !lesson.is_trial_call
          )
        );
        setPastLessons(
          allLessons.filter(
            (lesson: any) =>
              lesson.is_accepted_by_customer &&
              lesson.end_time <= currentTime &&
              !lesson.is_trial_call
          )
        );
      } catch (error) {
        console.error("Error fetching lessons:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
    const interval = setInterval(fetchLessons, 60000);
    return () => clearInterval(interval);
  }, [updateUi]);

  const renderContent = () => {
    switch (activeTab3) {
      case "upcoming":
        return upcomingLessons.length > 0 ? (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {upcomingLessons.map((lesson: any) => (
                <div
                  key={lesson.id}
                  className="border-2 border-[#008847] rounded-xl bg-[#F6FCF9] p-4"
                >
                  <div className="flex lg:gap-3 gap-1 flex-col lg:flex-row justify-between w-full">
                    <div className="flex gap-3">
                      <TutorAvatar
                        photo_card={lesson.tutor.photo_card}
                        name={lesson.tutor.name}
                      />
                      <div className="flex flex-col">
                        <h3 className="font-medium text-[15px]">
                          {lesson.subject.name}
                        </h3>
                        <p className="text-gray-600 text-[15px]">
                          {lesson.tutor.name}
                        </p>
                        {lesson.is_accepted_by_tutor &&
                          lesson.is_accepted_by_customer ? (
                          <p className="text-gray-600 text-[12px] max-w-[90%]">
                            {i18n.language == "ar" ? "سيتم فتح مساحة الدرس عبر الإنترنت قبل 15 دقيقة من بداية حجزك." : "The online lesson space will unlock 15 minutes before your booking begins."}
                          </p>
                        ) : (
                          <p className="text-gray-600 text-[12px] max-w-[90%]">
                            {i18n.language == "ar" ? "إذا كنت بحاجة إلى إعادة جدولة في أي وقت، أرسل رسالة إلى معلمك." : "If you need to reschedule at any time, message your tutor."}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex gap-1">
                        <span className="bg-red-100 text-red-600 px-3 py-1 rounded-md text-xs">
                          {t("online")}
                        </span>
                      </div>
                      <span
                        className={`${lesson.is_accepted_by_tutor &&
                          lesson.is_accepted_by_customer
                          ? "bg-green-100 text-green-600"
                          : "bg-yellow-100 text-yellow-600"
                          } px-3 py-1 rounded-md flex items-center gap-1 text-xs`}
                      >
                        <span>
                          {lesson.is_accepted_by_tutor &&
                            lesson.is_accepted_by_customer
                            ? "✅"
                            : "⚠️"}
                        </span>{" "}
                        {lesson.is_accepted_by_tutor &&
                          lesson.is_accepted_by_customer
                          ? t("confirmed")
                          : t("unconfirmed")}
                      </span>
                      <div className="flex items-center lg:justify-end gap-2 text-[#1A5AFF] bg-[#dde9f7] border-[#1A5AFF] rounded px-2 h-10">
                        <Calendar />
                        <span className="bg-blue-100 rounded-md flex items-center gap-2 border border-blue-500 text-xs">
                          {formatUserTime(lesson.start_time, true)} -{" "}
                          {formatUserTime(lesson.end_time)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mt-3">
                    {lesson.is_accepted_by_customer &&
                      lesson.is_accepted_by_tutor &&
                      (() => {
                        const currentTime = new Date();
                        const startTime = new Date(lesson.start_time);
                        const endTime = new Date(lesson.end_time);
                        const canJoin =
                          currentTime >=
                          new Date(startTime.getTime() - 15 * 60 * 1000) &&
                          currentTime < endTime;
                        const isPast = currentTime >= endTime;

                        return (
                          <a
                            href={
                              canJoin
                                ? `https://whiteboard.tutorhouse.co.uk/?id=${lesson.id}&startime=${lesson.start_time}&endtime=${lesson.end_time}`
                                : undefined
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button
                              className={`w-full p-1 lg:py-3 px-3 rounded-lg text-sm lg:text-lg mb-2 sm:mb-0 ${isPast
                                ? "bg-gray-500/80 text-white cursor-not-allowed"
                                : canJoin
                                  ? "bg-[#c9f7e2] text-[#008847] border-2 border-[#008847] cursor-pointer"
                                  : "bg-gray-300 text-gray-500 border-2 border-gray-400 cursor-not-allowed"
                                }`}
                              disabled={!canJoin}
                            >
                              {t("join_online_classroom")}
                            </button>
                          </a>
                        );
                      })()}
                    <div className="w-full group inline-block relative">
                      {lesson.is_accepted_by_customer &&
                        lesson.is_accepted_by_tutor && (
                          <button
                            onClick={async () => {
                              setLessonTiming({
                                startTime: lesson.start_time,
                                endTime: lesson.end_time,
                              });
                              setBookingId(lesson.id);
                              await rejectData(lesson?.invitation);
                            }}
                            disabled={!lesson.can_cancel_customer}
                            className={`mt-3 lg:w-full p-1 lg:py-2 w-1/2 text-sm lg:text-base rounded-lg border-2 ${lesson.can_cancel_customer
                              ? "bg-[#DDF0E7] text-[#008847] border-[#008847] cursor-pointer"
                              : "bg-gray-300 text-gray-500 border-gray-400 cursor-not-allowed"
                              } ${new Date(lesson.start_time) < new Date()
                                ? "hidden"
                                : ""
                              }`}
                          >
                            {t("cancel_lesson")}
                          </button>
                        )}
                      {!lesson.can_cancel_customer &&
                        new Date(lesson.start_time) > new Date() && (
                          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-black text-white text-xs rounded-md py-1 px-2 shadow-md">
                            {i18n.language == "ar" ? "عذراً، لا يمكن إلغاء الجلسة لأنها تمت بعد مرور أكثر من 48 ساعة على الحجز. يرجى الاتصال بالدعم للحصول على المساعدة." : "Sorry, you can't cancel the session as it's been more than 48 hours since booking. Please contact support for assistance."}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="flex gap-3 lg:gap-6">
                    <button
                      onClick={() => {
                        setTutorChatId(lesson.chat);
                        setActiveTabs2("inbox");
                      }}
                      className="mt-4 lg:w-full p-1 lg:py-2 bg-[#DDF0E7] text-[#008847] rounded-lg border-2 border-[#008847] w-1/2 text-sm lg:text-base"
                    >
                      {t("send_a_message")}
                    </button>
                    {setIsTrue(
                      lesson.is_accepted_by_customer &&
                      lesson.is_accepted_by_tutor
                    )}
                    {!lesson.is_accepted_by_customer && (
                      <button
                        onClick={() => {
                          setPendingConfirmation(lesson.id);
                          setInvitationId(lesson?.invitation);
                          setSubject(lesson.subject.name);
                        }}
                        disabled={loadingStates[lesson.id] || false}
                        className={`mt-4 lg:w-full p-1 lg:py-2 rounded-lg border-2 border-[#008847] w-1/2 text-sm lg:text-base ${loadingStates[lesson.id]
                          ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                          : "bg-[#008847] text-white cursor-pointer"
                          }`}
                      >
                        {loadingStates[lesson.id]
                          ? "Loading..."
                          : i18n.language == "en"
                            ? "Confirm Lesson"
                            : "تأكيد التجريبي"}
                      </button>
                    )}
                    {lesson.is_accepted_by_customer &&
                      lesson.is_accepted_by_tutor &&
                      lesson.start_time > new Date().toISOString() && (
                        <button
                          onClick={() => {
                            setReschedule(true);
                            setBookingId(lesson.id);
                          }}
                          className="mt-4 lg:w-full p-1 lg:py-2 bg-[#008847] text-white rounded-lg border-2 border-[#008847] w-1/2 text-sm lg:text-base"
                        >
                          {t("change_lesson")}
                        </button>
                      )}
                  </div>
                  {notification &&
                    lesson.is_accepted_by_customer &&
                    lesson.is_accepted_by_tutor && (
                      <div className="top-5 z-50 bg-[#008847] text-white p-4 mb-4 rounded-lg fixed right-10">
                        <strong>{i18n.language == "ar" ? "تذكير" : "Reminder"}:</strong> {notification}
                      </div>
                    )}
                </div>
              ))}
              {reject && (
                <CancelLessonPopup
                  id={bookingId}
                  isOpen={reject}
                  setReject={setReject}
                  setUpdateUi={setUpdateUi}
                  updateUi={updateUi}
                  bookingDetails={bookingDetails}
                  price={price}
                  formattedStartTime={formatUserTime(
                    lessonTiming.startTime,
                    true
                  )}
                  formattedEndTime={formatUserTime(lessonTiming.endTime, true)}
                  onClose={() => setReject(false)}
                />
              )}
              {payment && (
                <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
                  <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
                    <Elements stripe={stripePromise}>
                      <Checkout
                        time={time}
                        setActiveTab={setActiveTab3}
                        invitationId={invitationId}
                        subject={subject}
                        setPayment={setPayment}
                        bookingDetails={bookingDetails}
                        tutor={tutorData}
                        setInvitationId={setInvitationId}
                      />
                    </Elements>
                  </div>
                </div>
              )}
              {reschedule && (
                <RescheduleModal
                  instantBook={true}
                  setUpdateUi={setUpdateUi}
                  updateUi={updateUi}
                  id={bookingId}
                  isOpen={reschedule}
                  time={time}
                  onClose={() => setReschedule(false)}
                />
              )}
            </div>
          </>
        ) : (
          <div className="text-gray-600">{i18n.language == "en" ? "No upcoming lessons scheduled" : "لا توجد دروس قادمة مخطط لها"}</div>
        );

      case "past":
        return pastLessons.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {pastLessons.map((lesson: any) => (
              <div
                key={lesson.id}
                className="border-2 border-[#008847] rounded-xl p-4 bg-[#F6FCF9]"
              >
                <div className="flex justify-between w-full flex-col lg:flex-row">
                  <div className="flex gap-2">
                    <TutorAvatar
                      photo_card={lesson.tutor.photo_card}
                      name={lesson.tutor.name}
                    />
                    <div className="flex-grow">
                      <h3 className="font-medium text-[15px]">
                        {lesson.subject.name}
                      </h3>
                      <p className="text-gray-600 text-[15px]">
                        {lesson.tutor.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex lg:gap-1 gap-2 flex-col">
                    <div className="flex gap-1">
                      <span className="bg-red-100 text-red-600 px-3 py-1 rounded-md text-xs">
                        {t("online")}
                      </span>
                    </div>
                    <span
                      className={`${lesson.is_accepted_by_tutor
                        ? "bg-green-100 text-green-600"
                        : "bg-yellow-100 text-yellow-600"
                        } px-3 py-1 rounded-md flex items-center gap-1 text-xs`}
                    >
                      <span>{!lesson.is_accepted_by_tutor ? "⚠️" : "✅"}</span>{" "}
                      {lesson.is_accepted_by_tutor
                        ? t("confirmed")
                        : t("unconfirmed")}
                    </span>
                    {lesson.end_time < new Date().toISOString() && (
                      <span className="bg-[#0088471A] border border-[#008847] font-bold text-xs text-[#008847] px-3 py-1 rounded-md text-center w-full">
                        {t("lesson_completed")}
                      </span>
                    )}
                    <div className="flex items-center gap-2 text-[#1A5AFF] bg-[#dde9f7] border-[#1A5AFF] rounded px-2 py-1">
                      <Calendar />
                      <span className="bg-blue-100 text-blue-600 px-3 py-1 rounded-md flex items-center gap-2 border border-blue-500 text-xs">
                        {formatUserTime(lesson.start_time, true)} -{" "}
                        {formatUserTime(lesson.end_time)}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex lg:gap-6 gap-3">
                  <button
                    onClick={() => {
                      setTutorChatId(lesson.chat);
                      setActiveTabs2("inbox");
                    }}
                    className="mt-4 lg:w-full w-1/2 py-2 bg-[#008847] text-white rounded-lg text-sm lg:text-base"
                  >
                    {t("send_a_message")}
                  </button>
                  <button
                    onClick={() => {
                      setReview(true);
                      setFeedBackId(lesson.feedback_id);
                    }}
                    className="mt-4 lg:w-full w-1/2 py-2 bg-[#008847] text-white border-2 border-[#008847] rounded-lg text-sm lg:text-base"
                  >
                    {i18n.language == "en" ? "Rate Tutor" : "قيم المعلم."}
                  </button>
                </div>
              </div>
            ))}
            {review && (
              <ReviewModal
                feedBackId={feedBackId}
                isOpen={review}
                onClose={() => setReview(false)}
              />
            )}
            {sendMessage && (
              <ApplicationModal2
                tutor={singleTutor}
                isOpen={sendMessage}
                onClose={() => setSendMessage(false)}
                onSubmit={() => setSendMessage(false)}
              />
            )}
          </div>
        ) : (
          <div className="text-gray-600">{t("no_past")}</div>
        );

      case "trial-call":
        return trialCall.length > 0 ? (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            {loading ? (
              <div className="flex justify-center items-center">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#4fa94d"
                  secondaryColor="#4fa94d"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                />
              </div>
            ) : (
              trialCall.map((trial: any) => (
                <div
                  key={trial.id}
                  className="border-2 border-[#008847] bg-[#F6FCF9] rounded-2xl p-3 flex flex-col gap-3"
                >
                  <div className="flex justify-between w-full flex-col lg:flex-row">
                    <div className="flex gap-3 w-full">
                      <TutorAvatar
                        photo_card={trial.tutor.photo_card}
                        name={trial.tutor.name}
                      />
                      <div className="flex flex-col">
                        <h3 className="font-medium text-[15px]">
                          {trial.tutor.name}
                        </h3>
                        <p className="text-gray-600 text-[14px] lg:max-w-[100%]">
                          {trial.subject.name}
                        </p>
                        <span className="text-gray-600 text-xs lg:max-w-[95%]">
                          If you need to re-schedule your lesson please message
                          the tutor using the 'message' button.
                        </span>
                      </div>
                    </div>
                    <div className="flex gap-2 flex-col">
                      <div className="flex gap-1">
                        <span className="bg-red-100 text-red-600 px-3 py-1 rounded-md text-xs">
                          {t("online")}
                        </span>
                        <span
                          className={`${!trial.is_accepted_by_tutor &&
                            trial.start_time < new Date().toISOString()
                            ? "bg-red-100 text-red-600"
                            : trial.is_accepted_by_tutor
                              ? "bg-green-100 text-green-600"
                              : "bg-yellow-100 text-yellow-600"
                            } px-3 py-1 rounded-md flex items-center gap-1 text-xs`}
                        >
                          <span>
                            {!trial.is_accepted_by_tutor &&
                              trial.start_time < new Date().toISOString()
                              ? ""
                              : !trial.is_accepted_by_tutor
                                ? "⚠️"
                                : "✅"}
                          </span>{" "}
                          {!trial.is_accepted_by_tutor &&
                            trial.start_time < new Date().toISOString()
                            ? "Expired"
                            : trial.is_accepted_by_tutor
                              ? t("confirmed")
                              : t("unconfirmed")}
                        </span>
                      </div>
                      <span className="bg-blue-100 text-blue-600 px-3 py-1 rounded-md flex items-center gap-2 border border-blue-500 text-xs">
                        {formatUserTime(trial.start_time, true)} -{" "}
                        {formatUserTime(trial.end_time)}
                      </span>
                      {trial.end_time < new Date().toISOString() && (
                        <span className="bg-[#0088471A] border border-[#008847] font-bold text-xs text-[#008847] px-3 py-1 rounded-md text-center w-full">
                          {t("lesson_completed")}
                        </span>
                      )}
                      {notification &&
                        trial.is_accepted_by_customer &&
                        trial.is_accepted_by_tutor &&
                        (() => {
                          const currentTime = new Date();
                          const startTime = new Date(trial.start_time);
                          const timeDifference =
                            (startTime.getTime() - currentTime.getTime()) /
                            (1000 * 60);
                          if (timeDifference > 0 && timeDifference <= 15) {
                            return (
                              <a href="https://whiteboard.tutorhouse.co.uk/?id=356254&startime=870&endtime=930">
                                <button className="bg-[#c9f7e2] text-[#008847] border-2 border-[#008847] cursor-pointer rounded-xl text-sm px-3 font-semibold py-1">
                                  Join online classroom
                                </button>
                              </a>
                            );
                          }
                          return null;
                        })()}
                    </div>
                  </div>
                  <div className="flex justify-between gap-2">
                    {trial.can_cancel_customer && (
                      <button
                        onClick={() => {
                          setReject(true);
                          setBookingId(trial.id);
                        }}
                        className="flex-1 bg-[#0088471A] border-2 border-[#008847] text-[#008847] py-2 rounded-xl lg:text-base text-sm"
                      >
                        {t("cancel_lesson")}
                      </button>
                    )}
                    <button
                      onClick={() => {
                        setTutorChatId(trial.chat);
                        setActiveTabs2("inbox");
                      }}
                      className="flex-1 bg-[#008847] text-white py-2 rounded-xl lg:text-base text-sm"
                    >
                      {t("send_a_message")}
                    </button>
                    {trial.end_time > new Date().toISOString() &&
                      <button
                        onClick={() => {
                          setReschedule(true);
                          setBookingId(trial.id);
                        }}
                        className={`${trial.reschedule_count_by_customer > 0 &&
                          "bg-gray-400 text-zinc-950 cursor-not-allowed"
                          } flex-1 bg-[#008847] text-white py-2 rounded-xl lg:text-base text-sm`}
                      >
                        {t("change_lesson")}
                      </button>}
                    {setIsTrue(
                      trial.is_accepted_by_customer &&
                      trial.is_accepted_by_tutor
                    )}
                  </div>
                  {notification &&
                    trial.is_accepted_by_customer &&
                    trial.is_accepted_by_tutor && (
                      <div
                        onClick={() => setActiveTabs2("booking")}
                        className="top-5 bg-[#008847] text-white p-4 mb-4 rounded-lg absolute right-10"
                      >
                        <strong>Reminder:</strong> {notification}
                      </div>
                    )}
                </div>
              ))
            )}
            {reject && (
              <RejectLessonModal
                id={bookingId}
                isOpen={reject}
                setReject={setReject}
                setUpdateUi={setUpdateUi}
                updateUi={updateUi}
                onClose={() => setReject(false)}
              />
            )}
            {reschedule && (
              <RescheduleModal
                setUpdateUi={setUpdateUi}
                updateUi={updateUi}
                id={bookingId}
                time={time}
                isOpen={reschedule}
                onClose={() => setReschedule(false)}
              />
            )}
          </div>
        ) : (
          <div className="text-gray-600">{t("no_trial")}</div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="lg:p-6">
      <div className="lg:p-5 p-3 rounded-lg bg-white md:mt-10">
        <div className="flex gap-4 lg:gap-6 mb-6">
          {["upcoming", "trial-call", "past"].map((tab) => (
            <button
              key={tab}
              className={`${activeTab3 === tab
                ? "text-[#008847] font-semibold border-b-2 border-[#008847]"
                : "text-gray-400 hover:text-gray-600"
                } lg:text-base text-sm`}
              onClick={() => setActiveTab3(tab)}
            >
              {tab === "upcoming"
                ? t("upcoming_lessons")
                : tab === "trial-call"
                  ? t("trial_call")
                  : t("past_lessons")}
            </button>
          ))}
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default StudentBooking;
