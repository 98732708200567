import type React from "react";
import { useRef, useState, useEffect } from "react";
import image2 from "../../../images/popups/phone.png";
import PhoneVerificationCode from "./PhoneVarificationCode";
import VerificationErrorModal from "./ErrorPopup";
import i18n from "../../../i18n";

interface CountryData {
  country: string;
  dial_code: string;
  iso_code: string;
}

interface PhoneVerificationModalProps { 
  isOpen: boolean;
  onClose: () => void;
  countryData?: CountryData[];
  phone?: string;
}

export default function PhoneVerificationModal({
  isOpen,
  onClose,
  countryData: propCountryData,
  phone,
}: PhoneVerificationModalProps) {
  // Initialize with default values or from props
  const [selectedCode, setSelectedCode] = useState<string>("+971"); // Default to UAE
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const phoneRef = useRef<HTMLInputElement>(null);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [phones, setPhones] = useState("");
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const selectRef = useRef<HTMLSelectElement>(null);

  // Set default values based on props when component mounts or props change
  useEffect(() => {
    if (phone && propCountryData && propCountryData.length > 0) {
      // Find the country that matches the phone's country code
      const country = propCountryData.find((c) =>
        phone.startsWith(c.dial_code)
      );
      if (country) {
        setSelectedCode(country.dial_code);
        setPhoneNumber(phone.slice(country.dial_code.length)); // Remove country code from phone number
      } else {
        // Fallback: split manually if no match found
        const codeMatch = phone.match(/^\+\d{1,4}/);
        if (codeMatch) {
          setSelectedCode(codeMatch[0]);
          setPhoneNumber(phone.slice(codeMatch[0].length));
        }
      }
    }
  }, [phone, propCountryData]);

  // Fetch country data if not provided via props
  useEffect(() => {
    if (propCountryData && propCountryData.length > 0) {
      setCountryData(propCountryData);
    } else {
      const fetchCountryData = async () => {
        try {
          const response = await fetch("https://restcountries.com/v3.1/all");
          const data = await response.json();
          const countries = data
            .map((country: any) => ({
              country: country.name.common,
              dial_code: `+${country.idd.root}${country.idd.suffixes?.[0] || ""}`,
              iso_code: country.cca2,
            }))
            .sort((a: CountryData, b: CountryData) =>
              a.country.localeCompare(b.country)
            );
          setCountryData(countries);
        } catch (error) {
          console.error("Failed to fetch country data:", error);
          setErrorMessage("Failed to load country codes. Please try again.");
        }
      };
      fetchCountryData();
    }
  }, [propCountryData]);

  const handleVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    const fullPhone = `${selectedCode}${phoneNumber}`.trim();

    const token = localStorage.getItem("TH_AUTH_TOKEN");
    if (!token) {
      setErrorMessage("Authentication token is missing. Please log in again.");
      setIsErrorModalOpen(true);
      return;
    }

    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/customer/phonenumber/resend/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
          },
          body: JSON.stringify({ phone_number_send_to: fullPhone }),
        }
      );

      const text = await response.text();
      let data;

      if (text) {
        data = JSON.parse(text);
      }

      if (!response.ok) {
        throw new Error(
          data?.detail || "Failed to send verification code. Please try again."
        );
      }

      setPhones(fullPhone);
      setIsCodeModalOpen(true);
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (error instanceof Error) {
        errorMessage = error.message || "Failed to send phone verification";
      } else if (typeof error === "string") {
        errorMessage = error;
      }
      setErrorMessage(errorMessage);
      setIsErrorModalOpen(true);
    }
  };

  const handleResend = () => {
    setIsCodeModalOpen(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="relative bg-white rounded-2xl max-w-[425px] w-full p-6 shadow-lg animate-fade-in overflow-hidden">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700 font-bold text-2xl p-1 rounded-full hover:bg-gray-100 transition-colors"
        >
          ×
        </button>

        <div className="flex flex-col items-center gap-6">
          <div className="w-20 h-20 rounded-full bg-emerald-100 flex items-center justify-center">
            <div className="relative">
              <div className="rounded-full flex items-center justify-center">
                <img src={image2} alt="Verification" className="w-16 h-16" />
              </div>
              <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-6 h-3 bg-emerald-600 rounded-b-lg" />
            </div>
          </div>

          <div className="text-center space-y-3">
            <h2 className="text-2xl font-bold text-gray-800">{i18n.language == "en" ? "Verify Your Phone" : "تحقق من هاتفك"}</h2>
            <p className="text-base text-gray-600">
              {i18n.language == "en" ? "Confirm your phone number for instant notifications from your tutor(s)" : "أكد رقم هاتفك للحصول على إشعارات فورية من مدرسيك"}
            </p>
          </div>

          <form onSubmit={handleVerification} className="w-full space-y-4">
            <div className={`flex ${i18n.language === "ar" && "flex-row-reverse"} gap-2`}>
              <select
                ref={selectRef}
                className="py-3 px-4 border border-gray-300 text-center rounded-[40px] focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:border-transparent bg-white text-sm"
                value={selectedCode}
                onChange={(e) => setSelectedCode(e.target.value)}
                style={{
                  minWidth: "140px",
                  maxWidth: "141px",
                }}
              >
                {countryData.length > 0 ? (
                  countryData.map((country) => (
                    <option
                      key={country.iso_code}
                      value={country.dial_code}
                      className="text-sm"
                    >
                      {`${country.country} (${country.dial_code})`}
                    </option>
                  ))
                ) : (
                  <option value="+971">Loading...</option>
                )}
              </select>
              <input
                type="tel"
                ref={phoneRef}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ""))} // Only allow numbers
                className="flex-1 px-4 py-3 border border-gray-300 rounded-[40px] focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:border-transparent text-sm"
                placeholder="Enter Your Number"
              />
            </div>
            <button
              type="submit"
              className="w-full px-4 py-3 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg text-sm font-medium transition-colors duration-200 shadow-md hover:shadow-lg"
            >
              {i18n.language == "en" ? "Send Verification Code" : "إرسال رمز التحقق"}
            </button>
          </form>
        </div>
      </div>

      <PhoneVerificationCode
        phone={phones}
        isOpen={isCodeModalOpen}
        onClose={() => setIsCodeModalOpen(false)}
        onVerify={(code) => {
          console.log("Verification code:", code);
          setIsCodeModalOpen(false);
        }}
        onResend={handleResend}
      />

      <VerificationErrorModal
        errorMessage={errorMessage}
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
        onTryAgain={() => {
          setIsErrorModalOpen(false);
        }}
      />
    </div>
  );
}