"use client"
import i18n from "../../../i18n"
import errorImg from "../../../images/popups/error.png"

interface VerificationErrorModalProps {
    isOpen: boolean
    onClose: () => void
    onTryAgain: () => void
    errorMessage: string;
}

export default function VerificationErrorModal({ isOpen, onClose, onTryAgain, errorMessage }: VerificationErrorModalProps) {
    if (!isOpen) return null

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="relative bg-white rounded-lg max-w-[425px] w-full p-6 animate-fade-in">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className={`absolute ${i18n.language == "ar" ? "left-4" : "right-4"} top-4 hover:text-gray-600 text-2xl text-zinc-950`}
                >
                    ×
                </button>
                <div className="flex flex-col items-center gap-4">
                    {/* Error Icon */}
                    <div className="w-16 h-16 rounded-full bg-red-500 flex items-center justify-center">
                        <img src={errorImg} alt="None" className="w-14 h-14" />
                    </div>

                    {/* Content */}
                    <div className="text-center space-y-2">
                        <h2 className="text-xl font-semibold">{i18n.language == "ar" ? "عذرا" : "Oops"}</h2>
                        <p className="text-lg text-gray-500">{errorMessage}</p>
                    </div>

                    {/* Try Again Button */}
                    <button
                        onClick={onTryAgain}
                        className="w-full px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors duration-200"
                    >
                        {i18n.language == "ar" ? "حاول مرة أخرى" : "Try Again"}
                    </button>
                </div>
            </div>
        </div>
    )
}

