import type React from "react";
import { useRef, useState, useEffect } from "react";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import { Eye, EyeOff, LanguagesIcon } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import countryData from "../../Data/country.mjs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Ensure toast styles are imported
import i18n from "../../i18n";

const languages = [
  { code: "en", lng: "English" },
  { code: "ar", lng: "Arabic" },
];

const SignupTutorForm = () => {
  // Header states and language changer
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
    setIsLanguageDropdownVisible(false);
  };

  // Form states
  const [showPassword, setShowPassword] = useState(false);
  const [showGetOtp, setShowGetOtp] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [selectedDialCode, setSelectedDialCode] = useState("+971");
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timer, setTimer] = useState(0);

  const getName = useRef<HTMLInputElement>(null);
  const getLastName = useRef<HTMLInputElement>(null);
  const getEmail = useRef<HTMLInputElement>(null);
  const getPhone = useRef<HTMLInputElement>(null);
  const getPassword = useRef<HTMLInputElement>(null);
  const getDOB = useRef<HTMLInputElement>(null);
  const getOtp = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  /** Handle phone input change to show/hide OTP field */
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = e.target.value;
    if (phoneValue.length > 7) {
      setShowGetOtp(true);
    } else {
      setShowGetOtp(false);
      setOtpError("");
    }
  };

  /** Handle country dial code selection */
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDialCode(e.target.value);
    toast.info(`Dial code changed to ${e.target.value}`);
  };

  /** Timer Effect */
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  /** Request OTP from the server */
  const handleOtp = async () => {
    if (!getPhone.current?.value) {
      toast.error("Please enter a phone number to get OTP.");
      return;
    }
    setIsSendingOtp(true);
    try {
      const response = await fetch("https://api.nadwa-uae.com/api/v2/users/sms_verification_code/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: selectedDialCode + getPhone.current.value,
          recaptcha_token: process.env.REACT_APP_RECAPCHA_TOKEN,
        }),
      });

      if (response.ok) {
        const res = await response.json();
        console.log("OTP sent:", res);
        toast.success("OTP has been sent to your phone!");
        setTimer(60);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.error_msg || "Failed To Send An OTP");
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
    } finally {
      setIsSendingOtp(false);
    }
  };

  /** Handle form submission */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Extract values
    const name = getName.current?.value?.trim();
    const lastName = getLastName.current?.value?.trim();
    const email = getEmail.current?.value?.trim();
    const password = getPassword.current?.value;
    const phone = getPhone.current?.value?.trim();
    const dob = getDOB.current?.value;
    const otp = getOtp.current?.value?.trim();

    if (!name || !lastName || !email || !password || !phone || !dob || !otp) {
      toast.error(i18n.language == "en" ? "Please fill in all fields, including OTP." : "يرجى ملء جميع الحقول، بما في ذلك رمز التحقق.");
      return;
    }

    setIsSubmitting(true);

    try {
      const registerPayload = {
        agreed_to_terms: true,
        date_of_birth: dob,
        email,
        name: `${name} ${lastName}`,
        password,
        phone_number: selectedDialCode + phone,
        sms_verification_code: otp,
        verification_code: "",
      };

      const registerResponse = await fetch(
        "https://api.nadwa-uae.com/api/v2/tutor/smscreate/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(registerPayload),
        }
      );

      if (!registerResponse.ok) {
        const errorData = await registerResponse.json();
        throw new Error(
          errorData.sms_verification_code ||
          errorData.email ||
          errorData.phone_number ||
          "Failed to create an account. Please try again later."
        );
      }

      const registerData = await registerResponse.json();
      console.log("Register response:", registerData);

      // Login after successful registration
      const loginPayload = {
        method: "email",
        password,
        phoneLoginMethod: "password",
        sms_verification_code: "",
        username: email,
      };

      const loginResponse = await fetch(
        "https://api.nadwa-uae.com/api/v2/users/login_new/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(loginPayload),
        }
      );

      const loginData = await loginResponse.json();
      localStorage.setItem("TH_AUTH_TOKEN", loginData.token);
      if (!loginResponse.ok) {
        throw new Error(
          loginData.phone?.[0] ||
          loginData.email?.[0] ||
          loginData.error_msg ||
          "Login failed. Please try again."
        );
      }

      if (!loginData.user.is_customer) {
        toast.success("Account created successfully!");
        setTimeout(() => {
          navigate("/EditProfile");
        }, 500);
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : "An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {/* Header Section */}
      <header className={`flex justify-between ${i18n.language == "ar" ? "flex-row-reverse" : "flex-row"} items-center py-3 px-8 border-b border-gray-200 sticky top-0 bg-white z-20`}>
        <img src={LogoImage} alt="Logo" className="w-32" />
        <div className="relative">
          <button
            onClick={() => setIsLanguageDropdownVisible(!isLanguageDropdownVisible)}
            className={`flex items-center gap-2 border border-[#008847] px-3 py-1 rounded ${i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
          >
            <LanguagesIcon className="w-4 h-4" />
            {i18n.language === "ar" ? "العربية" : "English"}
          </button>
          {isLanguageDropdownVisible && (
            <ul className="absolute right-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
              {languages.map((language) => (
                <li
                  key={language.code}
                  onClick={() => changeLanguage(language.code)}
                  className="cursor-pointer px-4 py-2 hover:bg-[#008847] hover:text-white transition-colors duration-200"
                >
                  {language.lng}
                </li>
              ))}
            </ul>
          )}
        </div>
      </header>

      {/* Main Signup Content */}
      <div
        className="flex justify-center items-center h-auto gap-10 xl:gap-20 py-20 xl:py-8 bg-white"
        dir={i18n.language == "ar" ? "rtl" : "ltr"}
      >
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="space-y-5 px-4">
          <div className="w-52 h-20">
            <img src={LogoImage || "/placeholder.svg"} className="w-52" alt="Logo" />
          </div>
          <div className="flex flex-col space-y-5 px-10 bg-white">
            <div className="space-y-2">
              <h1 className="text-2xl font-semibold">
                {i18n.language == "en" ? "Create Tutor Account" : "إنشاء حساب مدرس."}
              </h1>
              <p className="text-[#393939] font-normal text-lg leading-6">
                {i18n.language == "en" ? "Already have an account?" : "هل لديك حساب مسبقًا؟"}{" "}
                <Link to={"/login"} className="text-[#008847] font-medium underline">
                  {i18n.language == "en" ? "Log in" : "تسجيل الدخول."}
                </Link>
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className={`flex space-x-4`}>
                <div className={`space-y-3 ${i18n.language == "ar" ? "me-4" : ""}`}>
                  <label className="block font-bold text-base text-black">
                    {i18n.language == "en" ? "First Name" : "الاسم الأول"}
                  </label>
                  <input
                    type="text"
                    ref={getName}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language == "ar" ? "أدخل اسمك الأول" : "Enter First Name"}
                  />
                </div>
                <div className="space-y-3">
                  <label className="block font-bold text-base text-black">
                    {i18n.language == "ar" ? "الاسم الأخير" : "Last Name"}
                  </label>
                  <input
                    type="text"
                    ref={getLastName}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language == "ar" ? "أدخل اسمك الأخير" : "Enter Last Name"}
                  />
                </div>
              </div>

              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  {i18n.language == "ar" ? "البريد الإلكتروني" : "Email"}
                </label>
                <input
                  type="email"
                  ref={getEmail}
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  placeholder={i18n.language == "ar" ? "أدخل بريدك الإلكتروني" : "Enter Your Email"}
                />
              </div>

              <div className="space-y-3 rounded-[40px]">
                <label className="block font-bold text-base text-black">
                  {i18n.language == "ar" ? "الهاتف" : "Telephone"}
                </label>
                <div className="flex items-center w-full border border-[#D2D2D2] rounded-[40px] overflow-hidden focus-within:ring-2 focus-within:ring-[#008847]">
                  <select
                    className="px-2 py-2 outline-none rounded-l-[40px] bg-gray-100 text-sm text-black"
                    value={selectedDialCode}
                    onChange={handleCountryChange}
                  >
                    {countryData.map((country) => (
                      <option key={country.iso_code} value={country.dial_code}>
                        {country.country.length > 15
                          ? `${country.country.slice(0, 15)}...`
                          : country.country} ({country.dial_code})
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    ref={getPhone}
                    onChange={handlePhoneChange}
                    className="w-full px-4 py-2 rounded-r-[40px] focus:outline-none"
                    placeholder={i18n.language == "ar" ? "أدخل رقمك الهاتفي" : "Enter Your Number"}
                  />
                </div>
              </div>

              {showGetOtp && (
                <div className="relative space-y-3">
                  <label className="block font-bold text-base text-black">
                    {i18n.language == "ar" ? "رمز التحقق" : "OTP"}
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      ref={getOtp}
                      className={`w-full px-4 py-2 border ${otpError ? "border-red-500" : "border-[#D2D2D2]"} rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]`}
                      placeholder={i18n.language == "ar" ? "أدخل رمز التحقق الخاص بك" : "Enter Your OTP"}
                    />
                    <button
                      type="button"
                      onClick={handleOtp}
                      disabled={isSendingOtp || timer > 0}
                      className={`absolute right-2 top-1/2 transform -translate-y-1/2 px-6 py-2 font-medium rounded-[28px] ${isSendingOtp || timer > 0
                        ? "bg-gray-400 text-white cursor-not-allowed"
                        : "bg-[#008847] text-white hover:bg-[#00773a]"
                        }`}
                    >
                      {isSendingOtp
                        ? "Sending..."
                        : timer > 0
                          ? `${i18n.language == "ar" ? "إعادة إرسال في" : "Resend in"} ${timer}s`
                          : i18n.language == "ar"
                            ? "حصل على رمز التحقق"
                            : "Get OTP"}
                    </button>
                  </div>
                  {otpError && <p className="text-red-500 text-sm mt-1">{otpError}</p>}
                </div>
              )}

              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  {i18n.language == "ar" ? "كلمة المرور" : "Password"}
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    ref={getPassword}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language == "ar" ? "أدخل كلمة المرور الخاصة بك" : "Enter your password"}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute inset-y-0 ${i18n.language == "en" ? "right-4" : "left-4"} flex items-center text-[#667085]`}
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>

              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  {i18n.language == "ar" ? "تاريخ الميلاد" : "Date of Birth"}
                </label>
                <input
                  type="date"
                  ref={getDOB}
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                />
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className={`px-6 py-2 w-full bg-[#008847] text-white font-medium text-xl rounded-[28px] ${isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
              >
                {isSubmitting
                  ? "Creating Account..."
                  : i18n.language == "ar"
                    ? "إنشاء الحساب"
                    : "Create Account"}
              </button>
            </form>
          </div>
        </div>

        <div className="w-[400px] lg:w-[450px] xl:w-[500px]">
          <img src={img2 || "/placeholder.svg"} alt="Visual" className="w-full" />
        </div>
      </div>
    </>
  );
};

export default SignupTutorForm;
