import React, { useEffect } from "react";

const TermlyScript = () => {
  useEffect(() => {
    // Function to check if the user has already declined or accepted the banner
    const hasUserDecided = () => {
      const cookies = document.cookie.split(";");
      return cookies.some((cookie) => cookie.trim().startsWith("termly"));
    };

    // Only inject the script if the user hasn't made a decision yet
    if (!hasUserDecided()) {
      // Create the script element
      const script = document.createElement("script");
      script.id = "termly-script";
      script.src = "https://app.termly.io/embed.min.js";
      script.setAttribute("data-auto-block", "on");
      script.setAttribute("data-website-uuid", "a0cf738f-5566-495b-85c8-6e38a12e0213");
      script.setAttribute("data-language", "en");

      // Append the script to the document body
      document.body.appendChild(script);

      // Cleanup function to remove the script on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return null; // This component doesn't render anything
};

export default TermlyScript;
