import { useState, useEffect } from "react"
import Price from "./Price";
import AvailabilitySelector from "./Days";
import TutorMatchPopup from "./TutorMatchPopup";
import icon from '../../images/asserts/Dashboard Assets/complete.png'
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
export default function SubjectPopup() {
  const [isOpen, setIsOpen] = useState(false)
  const [subject, setSubject] = useState([]);
  const [selectSubject, setSelectSubject] = useState('');
  const [level, setLevel] = useState([]);
  const [selectLevel, setSelectLevel] = useState('');
  const [showBudgetPopup, setShowBudgetPopup] = useState(false);
  const [showDays, setShowDays] = useState(false);
  const [tutorMatch, setTutorMatch] = useState(false);
  const [complete, setComplete] = useState(false);
  const [availableDays, setAvailableDays] = useState<{ slot: string; days: number[] }[]>([]);
  const [min, setMin] = useState(50);
  const [max, setMax] = useState(400);
  const { t }: { t: (key: string) => string } = useTranslation();
  const features = [
    {
      icon: (
        <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
          <span className="text-[8px] text-white font-medium leading-tight">
            {i18n.language == "en" ? "FREE" : "مجانًا"}
            <br />
            {t("trial")}
          </span>
        </div>
      ),
      title: t("free_trial"),
      subtitle: t("lesson"),
    },
    {
      icon: (
        <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
          <svg viewBox="0 0 24 24" fill="none" className="w-5 h-5 text-white" stroke="currentColor" strokeWidth="2">
            <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
            <circle cx="9" cy="7" r="4" />
            <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
          </svg>
        </div>
      ),
      title: i18n.language == "en" ? "100% Satisfaction" : "رضا بنسبة 100%",
      subtitle: i18n.language == "en" ? "Guarantee" : "ضمان",
    },
    {
      icon: (
        <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
          <svg viewBox="0 0 24 24" fill="none" className="w-5 h-5 text-white" stroke="currentColor" strokeWidth="2">
            <path d="M21 12a9 9 0 0 1-9 9m9-9a9 9 0 0 0-9-9m9 9H3m9 9a9 9 0 0 1-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 0 1 9-9" />
          </svg>
        </div>
      ),
      title: i18n.language == "en" ? "Free Customer Support" : "دعم العملاء المجاني",
      subtitle: i18n.language == "en" ? "(Forever!)" : "الأبد!",
    },
  ]

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [isOpen])
  useEffect(() => {
    const tutorLevels = async () => {
      try {
        const res = await axios.get("https://api.nadwa-uae.com/api/v2/teachinglevels");
        setLevel(res.data);
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };

    tutorLevels();
    const tutorSubjects = async () => {
      try {
        const res2 = await axios.get('https://api.nadwa-uae.com/api/v3/subjects')
        setSubject(res2.data);
      } catch (error) {
        console.log(error)
      }
    }
    tutorSubjects()
  }, []);
  return (
    <div>
      <button onClick={() => setIsOpen(true)} className="lg:px-4 px-2 text-sm lg:text-lg py-1 bg-[#008847] text-white rounded">
        {t('dashboard_page.request_a_tutor')}
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center lg:p-4 p-1 z-40">
          <div className="w-full lg:max-w-[50%]">
            <div className="bg-white rounded-[20px] overflow-hidden">
              <div className="p-6 ">
                <div className="relative w-full my-6">
                  <div className="h-2 bg-[#00884733] w-full" />
                  <div className="h-2 bg-green-600 w-[10%] absolute top-0 left-0" >
                    <div className="absolute -top-5 -right-5">
                      <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">10%</div>
                    </div>
                  </div>
                </div>
                <h2 className="text-2xl font-medium mb-2">{t("choose_subject_and_level")}</h2>
                <p className=" text-black mb-8 lg:max-w-[80%]"> {t("intro_text")}
                </p>

                {/* Subject Select */}
                <div className="space-y-4 mb-6">
                  <div>
                    <label className="block mb-1 text-black">{t("c_subject")}</label>
                    <select
                      value={selectSubject}
                      onChange={(e: any) => setSelectSubject(e.target.value)}
                      className="lg:w-1/2 p-2 border rounded text-gray-600"
                    >
                      <option value="">{t("find_a_tutor_page.all_subjects")}</option>
                      {subject.map((ele: any) => (
                        <optgroup key={ele.id} label={ele.name}>
                          {ele.subjects.map((sub: any) => (
                            <option key={sub.id} value={sub.id}>
                              {sub.name}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-black mb-1">{t("c_level")}</label>
                    <select
                      value={selectLevel}
                      disabled={selectSubject === ''}
                      onChange={(e: any) => setSelectLevel(e.target.value)}
                      className={`lg:w-1/2 w-[90%] p-2 border rounded text-gray-600 ${selectSubject === '' ? 'bg-gray-200' : ''}`}
                    >
                      <option value="">{t("find_a_tutor_page.all_levels")}</option>
                      {level.map((ele: any) => (
                        <option value={ele.id} key={ele.id}>
                          {ele.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex gap-3 lg:w-[50%]">
                  <button
                    onClick={() => setIsOpen(false)}
                    className="flex-1 py-2 px-4 text-gray-700 rounded-xl bg-[#0088471A] border-2 border-[#008847]"
                  >
                    {t("buttons.cancel")}
                  </button>
                  <button
                    onClick={() => {
                      setShowBudgetPopup(true);
                      setIsOpen(false);
                    }}
                    disabled={!selectLevel || selectSubject === ''}
                    className={`flex-1 py-2 px-4 rounded-xl transition-colors ${!selectLevel || selectSubject === ''
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-[#008847] text-white hover:bg-green-700'
                      }`}
                  >
                    {t("buttons.next")}
                  </button>

                </div>
              </div>
            </div>
            <div className="flex justify-between gap-4 p-4 bg-white rounded-[20px] shadow-lg mt-4 flex-col lg:flex-row">
              {features.map((feature, index) => (
                <div key={index} className="flex items-center gap-3 px-4 py-3 bg-[#e6f7ef] rounded-lg">
                  {feature.icon}
                  <div className="text-sm">
                    <div className="font-medium">{feature.title}</div>
                    <div>{feature.subtitle}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showBudgetPopup && (
        <Price min={min} setIsOpen={setIsOpen} setMin={setMin} max={max} setMax={setMax} setShowBudgetPopup={setShowBudgetPopup} setShowDays={setShowDays} />
      )}
      {showDays && (
        <AvailabilitySelector setShowBudgetPopup={setShowBudgetPopup} availableDays={availableDays} setAvailableDays={setAvailableDays} setTutorMatch={setTutorMatch} setShowDays={setShowDays} />
      )}
      {tutorMatch && (
        <TutorMatchPopup setShowDays={setShowDays} setComplete={setComplete} setTutorMatch={setTutorMatch}
          selectSubject={selectSubject}
          selectLevel={selectLevel}
          availableDays={availableDays}
          min={min}
          max={max}
        />
      )}
      {complete && (
        <div className="fixed inset-0 bg-black/70 z-40 flex items-center justify-center lg:p-4">
          <div className="bg-white rounded-xl lg:max-w-[30%] w-full px-8 py-10 text-center">
            {/* Success Icon */}
            <div className="mb-6 flex justify-center">
              <img src={icon} alt="icon" />
            </div>

            {/* Message */}
            <h2 className="text-xl font-medium mb-2">
              {i18n.language == "en" ? "Your job has been" : "لقد تمت وظيفتك"} <span className="text-[#008847">{i18n.language == "en" ? "Posted" : "الوظائف"}</span>
            </h2>
            <p className="text-gray-500 text-sm mb-8">{i18n.language == "en" ? "We'll notify you as soon as a tutor applies." : "سوف نُخبرك بمجرد تقدم معلم"}</p>

            {/* Action Button */}
            <button
              onClick={() => setComplete(false)}
              className="w-1/2 bg-[#008847] text-white rounded-lg py-3 px-1 transition-colors"
            >
              {t("go_to_dashboard")}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

