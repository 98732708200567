export default {
    en: {
        "dashboard_page": {
            "student": "STUDENT",
            "dashboard": "Dashboard",
            "tutor_applications": "Tutor Applications",
            "request_a_tutor": "Request a Tutor",
            "browse_other_tutors": "Browse Other Tutors",
            "view_message": "View Message", // Extra key not in second file
            "no_applications": "No Applications", // Extra key not in second file
            "reminder": "Reminder:", // Extra key not in second file
            "filters": {
                "tutor": "Tutor",
                "message": "Message",
                "subject": "Subject",
                "date": "Date",
                "action": "Action"
            },
            "overview_menu": {
                "overview": "OVERVIEW",
                "dashboard": "Dashboard",
                "find_a_tutor": "Find a Tutor",
                "inbox": "Inbox",
                "bookings": "Bookings",
                "posted_jobs": "Posted Jobs"
            },
            "settings_menu": {
                "settings": "SETTINGS",
                "account_settings": "Account Settings",
                "log_out": "Log Out"
            },
            "profile_section": {
                "profile": "Profile",
                "edit_profile": "Edit Profile",
                "good_evening": "Good Evening",
                "your_schedule": "Your Schedule",
                "upcoming_lessons": "Upcoming Lessons",
                "past_lessons": "Past Lessons",
                "tutor": "Tutor",
                "type": "Type",
                "lesson": "Lesson"
            }
        },
        "lesson": "Lesson",
        "find_a_tutor_page": {
            "find_a_tutor": "Find A Tutor",
            "enter_tutor_name": "Enter Tutor Name",
            "search": "Search",
            "subject": "Subject",
            "all_subjects": "All Subjects",
            "level": "Level",
            "all_levels": "All Levels",
            "countries": "Countries",
            "all_countries": "All Countries",
            "reset_now": "Reset Now",
            "search_results": "Search Results",
            "contact_us": "Contact us",
            "view_profile": "View Profile",
            "tutoring_in_person": "Tutoring: In person tutoring",
            "tutoring_online": "Tutoring: Online",
            "per_hour": "Per Hour",
            "including_vat": "Including VAT",
            "years_of_experience": "## Years of Experience"
        },
        "inbox_page": {
            "inbox": "Inbox",
            "messages": "Messages",
            "latest_messages": "Latest Messages",
            "archived": "Archived",
            "archive": "Archive",
            "search_chat": "Search Chat",
            "view_profile": "View Profile"
        },
        "trial": "Trial",
        "book_a_lesson": "Book a lesson",
        "unarchive": "Unarchive",
        "bookings": "Bookings",
        "upcoming_lessons": "Upcoming Lessons",
        "trial_call": "Trial Call",
        "past_lessons": "Past Lessons",
        "online": "Online",
        "confirmed": "Confirmed",
        "unconfirmed": "Unconfirmed",
        "lesson_completed": "Lesson Completed",
        "send_a_message": "Send a Message",
        "cancel_lesson": "Cancel Lesson",
        "change_lesson": "Change Lesson",
        "pending": "Pending",
        "no_trial": "No trial call scheduled",
        "no_past": "No PastData call scheduled",
        "no_messages": "No Messages",
        "type_message": "Type a message to send",
        "mins": "We Are waiting for customer for confirm your lesson request",
        "jobs": "Jobs Board",
        "c_level": "Choose Level",
        "c_subject": "Choose Subject",
        "req": "Request",
        "apply_job": "Apply for Job",
        "AED": "AED",
        "hour": "/hour",
        "p_range": "Price Range Per Hour (AED)",
        "posted_jobs_page": {
            "here_are_the_jobs_you_have_posted": "Here are the jobs you have posted",
            "applications_so_far": "## Applications So Far",
            "delete_job_post": "Delete Job Post",
            "view_job": "View Job"
        },
        "account_settings_page": {
            "account_settings": "Account Settings",
            "select_your_time_zone": "Select your time zone",
            "notification_settings": "Notification Settings",
            "when_i_receive_a_new_message": "When I receive a new message",
            "tutor_cancels_a_lesson": "Tutor cancels a lesson",
            "any_offers_or_news_from_nadwa": "There's any offers or news from Nadwa",
            "send_me_an_sms_when_i_receive_a_new_message": "Send me an SMS when I receive a new message",
            "payment_is_made_to_a_tutor": "Payment is made to a tutor",
            "i_receive_a_lesson_invitation_from_a_tutor": "I receive a lesson invitation from a tutor"
        },
        "footer_page": {
            "about_nadwa": "About Nadwa",
            "how_it_works": "How It Works",
            "blogs": "Blogs",
            "copyright": "Copyright © 2024, All Rights Reserved.",
            "terms_and_conditions": "Terms & Conditions",
            "privacy_policy": "Privacy Policy"
        }
    },
    ar: {
        "dashboard_page": {
            "student": "طالب",
            "dashboard": "لوحة التحكم",
            "tutor_applications": "طلبات المعلم",
            "request_a_tutor": "طلب معلم",
            "browse_other_tutors": "تصفح معلمين آخرين",
            "view_message": "عرض الرسالة",
            "no_applications": "لا يوجد تطبيق",
            "reminder": "تذكير ",
            "filters": {
                "tutor": "معلم",
                "message": "رسالة",
                "subject": "الموضوع",
                "date": "التاريخ",
                "action": "الإجراء"
            },
            "overview_menu": {
                "overview": "نظرة عامة",
                "dashboard": "لوحة التحكم",
                "find_a_tutor": "ابحث عن معلم",
                "inbox": "صندوق الوارد",
                "bookings": "الحجوزات",
                "posted_jobs": "الوظائف المنشورة"
            },
            "settings_menu": {
                "settings": "الإعدادات",
                "account_settings": "إعدادات الحساب",
                "log_out": "تسجيل الخروج"
            },
            "profile_section": {
                "profile": "الملف الشخصي",
                "edit_profile": "تعديل الملف الشخصي",
                "good_evening": "مساء الخير أيها",
                "your_schedule": "جدول مواعيدك",
                "upcoming_lessons": "الدروس القادمة",
                "past_lessons": "الدروس السابقة",
                "tutor": "معلم",
                "type": "نوع",
            }
        },
        "lesson": "الدرس",
        "trial": "تجربة",
        "find_a_tutor_page": {
            "find_a_tutor": "ابحث عن معلم",
            "enter_tutor_name": "أدخل اسم المعلم",
            "search": "بحث",
            "subject": "الموضوع",
            "all_subjects": "كل المواد",
            "level": "المستوى",
            "all_levels": "جميع المستويات",
            "countries": "الدول",
            "all_countries": "جميع الدول",
            "reset_now": "إعادة التعيين الآن",
            "search_results": "نتائج البحث",
            "contact_us": "اتصل بنا",
            "view_profile": "عرض الملف الشخصي",
            "tutoring_in_person": "الدروس: التدريس الشخصي",
            "tutoring_online": "الدروس: عبر الإنترنت",
            "per_hour": "لكل ساعة",
            "including_vat": "شامل ضريبة القيمة المضافة",
            "years_of_experience": "سنوات من الخبرة"
        },
        "inbox_page": {
            "inbox": "صندوق الوارد",
            "messages": "الرسائل",
            "latest_messages": "أحدث الرسائل",
            "archived": "المؤرشف",
            "archive": "أرشيف",
            "search_chat": "بحث في الدردشة",
            "view_profile": "عرض الملف الشخصي"
        },
        "unarchive": "إستخراج من الأرشيف",
        "book_a_lesson": "احجز درساً",
        "bookings": "الحجوزات",
        "upcoming_lessons": "الدروس القادمة",
        "trial_call": "مكالمة تجريبية",
        "past_lessons": "الدروس السابقة",
        "online": "عبر الإنترنت",
        "confirmed": "تم التأكيد",
        "unconfirmed": "غير مؤكد",
        "lesson_completed": "تم الانتهاء من الدرس",
        "send_a_message": "إرسال رسالة",
        "cancel_lesson": "إلغاء الدرس",
        "change_lesson": "تغيير الدرس",
        "pending": "معلقة",
        "no_trial": "لا مكالمة تجريبية محجوزة",
        "no_past": "لا مكالمة بيانات ماضية محجوزة",
        "mins": "نحن في انتظار العميل لتأكيد طلبك للدرس",
        "no_messages": "لا رسائل",
        "type_message": "اكتب رسالة لإرسالها",
        "jobs": "لوحة الوظائف",
        "c_level": "اختر المستوى",
        "c_subject": "اختر المادة",
        "req": "طلب",
        "apply_job": "تقدم بطلب للوظيفة",
        "p_range": "مدي الفترة السعرية للساعة (درهم إماراتي)",
        "posted_jobs_page": {
            "here_are_the_jobs_you_have_posted": "هنا الوظائف التي قمت بنشرها",
            "applications_so_far": "عدد الطلبات حتى الآن",
            "delete_job_post": "حذف إعلان الوظيفة",
            "view_job": "عرض الوظيفة"
        },
        "AED": "درهم إماراتي",
        "hour": "في الساعة",
        "account_settings_page": {
            "account_settings": "إعدادات الحساب",
            "select_your_time_zone": "حدد منطقتك الزمنية",
            "notification_settings": "إعدادات الإشعارات",
            "when_i_receive_a_new_message": "عندما أستلم رسالة جديدة",
            "tutor_cancels_a_lesson": "يقوم المعلم بإلغاء درس",
            "any_offers_or_news_from_nadwa": "يوجد أي عروض أو أخبار من ندوى",
            "send_me_an_sms_when_i_receive_a_new_message": "أرسل لي رسالة نصية عندما أستلم رسالة جديدة",
            "payment_is_made_to_a_tutor": "تم دفع المبلغ للمعلم",
            "i_receive_a_lesson_invitation_from_a_tutor": "أستلم دعوة درس من معلم"
        },
        "footer_page": {
            "about_nadwa": "عن ندوى",
            "how_it_works": "كيف تعمل",
            "blogs": "المدونات",
            "copyright": "حقوق النشر © 2024، جميع الحقوق محفوظة.",
            "terms_and_conditions": "الشروط والأحكام",
            "privacy_policy": "سياسة الخصوصية"
        }
    }
};