import { BadgeAlert } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Interface for subjects received from the API
interface ApiSubject {
  id: number;
  experience: number;
  level: number;
  subject: number;
  price_in_cents: number;
}

// Interface for subjects sent in the PATCH request
interface SubjectUpdate {
  experience: number;
  level: number;
  subject: number;
}

function Pricing({ updateUi, setUpdateUi }: any) {
  const [price, setPrice] = useState<string>("");
  const [lastSavedPrice, setLastSavedPrice] = useState<string>("");
  const [currentSubjects, setCurrentSubjects] = useState<ApiSubject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const priceOptions: number[] = [50, 100, 150, 200, 250, 300, 350, 400];

  const priceToExperience: Record<number, number> = {
    50: 1,
    100: 8,
    150: 9,
    200: 3,
    250: 5,
    300: 7,
    350: 10,
    400: 11,
  };

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const response = await fetch("https://api.nadwa-uae.com/api/v2/tutor/profile/", {
          method: "GET",
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch pricing data");
        }

        const data = await response.json();

        if (data.subjects && data.subjects.length > 0) {
          const apiSubjects: ApiSubject[] = data.subjects.map((subject: any) => ({
            id: subject.id,
            experience: subject.experience,
            level: subject.level,
            subject: subject.subject,
            price_in_cents: subject.price_in_cents ?? 0,
          }));
          setCurrentSubjects(apiSubjects);
          const savedPrice = (apiSubjects[0].price_in_cents / 100).toFixed(2);
          setLastSavedPrice(savedPrice);
          setPrice(savedPrice);
        } else {
          setPrice("");
          setLastSavedPrice("");
          setCurrentSubjects([]);
        }
      } catch (error) {
        console.error("Error fetching pricing:", error);
        toast.error("Failed to load pricing data.");
      }
    };

    fetchPricing();
  }, [updateUi]);

  // Handle saving the updated price by updating experience
  const handleSavePrice = async () => {
    const priceValue = parseFloat(price);

    // Validation checks
    if (isNaN(priceValue) || priceValue <= 0) {
      toast.error("Please enter a valid price.");
      return;
    }

    if (price === lastSavedPrice) {
      toast.info("No changes detected.");
      return;
    }

    const selectedPrice = Math.round(priceValue);
    if (!priceOptions.includes(selectedPrice)) {
      toast.error("Price must be a multiple of 50 between 50 and 400.");
      return;
    }

    const newExperience = priceToExperience[selectedPrice];
    if (newExperience === undefined) {
      toast.error("Invalid price selected.");
      return;
    }

    setLoading(true);

    // Update only the experience, keeping levels and subjects from currentSubjects
    const updatedSubjects: SubjectUpdate[] = currentSubjects.map(subject => ({
      experience: newExperience,
      level: subject.level,
      subject: subject.subject,
    }));

    try {
      const response = await fetch("https://api.nadwa-uae.com/api/v2/tutor/profile/", {
        method: "PATCH",
        headers: {
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subjects: updatedSubjects }),
      });

      if (!response.ok) {
        throw new Error("Failed to save price");
      }

      setLastSavedPrice(price);
      toast.success("Price updated successfully!");
      setUpdateUi(!updateUi); // Trigger UI refresh
    } catch (error) {
      console.error("Error saving price:", error);
      toast.error("Failed to save price.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md space-y-6">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        theme="light"
        transition={Bounce}
      />

      <h1 className="text-2xl text-[#272727] font-semibold">{t("pricing")}</h1>

      <div className="space-y-5">
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          {t("save_subjects")}
          <BadgeAlert className="text-[#008847]" />
        </div>
        <input
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          className="w-full p-2 text-base font-medium text-[#272727] border border-gray-300 rounded-md focus:outline-none"
          placeholder={lastSavedPrice || "Enter price"}
        />
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSavePrice}
          disabled={loading}
          className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 disabled:bg-gray-400"
        >
          {loading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </div>
  );
}

export default Pricing;