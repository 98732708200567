import BannerSection from '../../components/AboutPages/Banner'
import bannerImage from '../../images/asserts/Website-Assets/About Us/Hero_image.webp'
import AboutSection from '../../components/AboutPages/AboutSection'
import AboutMission from '../../components/AboutPages/AboutMission'
import AboutWork from '../../components/AboutPages/AboutWork'
import AboutLast from '../../components/AboutPages/AboutLast'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const About = () => {
    useEffect(() => {
        const lang = localStorage.getItem("i18nextLng");
        if (!lang || lang === "en-US") {
            localStorage.setItem("i18nextLng", "en");
            window.location.reload();
        };
    }, [])
    const { t }: { t: (key: string) => string } = useTranslation();
    return (
        <main className=''>
            <BannerSection title={t('aboutpage')} image={bannerImage} />
            <AboutSection />
            <AboutMission />
            <AboutWork />
            <AboutLast />
        </main>
    )
}

export default About