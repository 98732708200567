import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import { ArrowLeft, LanguagesIcon } from "lucide-react";
import i18n from "../../i18n";

const languages = [
    { code: "en", lng: "English" },
    { code: "ar", lng: "Arabic" },
];

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage("");

        try {
            const response = await axios.post(
                "https://api.nadwa-uae.com/api/v2/users/password/reset/",
                { email }
            );

            if (response.status === 200) {
                if (response.data.error_msg) setMessage(response.data.error_msg);
                else
                    setMessage(
                        i18n.language == "ar"
                            ? "تم ارسال الرابط بنجاح"
                            : "Email Send"
                    );
            } else {
                setMessage("Failed to send reset link. Please try again.");
            }
        } catch (error) {
            setMessage("An error occurred. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleBack = () => {
        navigate(-1);
    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("i18nextLng", lng);
        document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
        setIsLanguageDropdownVisible(false);
    };

    return (
        <>
            {/* Header Section */}
            <header className="flex justify-between items-center py-3 px-8 border-b border-gray-200 sticky top-0 bg-white z-20">
                <img src={LogoImage} alt="Logo" className="w-32" />
                <div className="relative">
                    <button
                        onClick={() =>
                            setIsLanguageDropdownVisible(!isLanguageDropdownVisible)
                        }
                        className={`flex items-center gap-2 border border-[#008847] px-3 py-1 rounded ${i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
                            }`}
                    >
                        <LanguagesIcon className="w-4 h-4" />
                        {i18n.language === "ar" ? "العربية" : "English"}
                    </button>
                    {isLanguageDropdownVisible && (
                        <ul className="absolute right-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
                            {languages.map((language) => (
                                <li
                                    key={language.code}
                                    onClick={() => changeLanguage(language.code)}
                                    className="cursor-pointer px-4 py-2 hover:bg-[#008847] hover:text-white transition-colors duration-200"
                                >
                                    {language.lng}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </header>

            {/* Main Forgot Password Content */}
            <div
                dir={i18n.language == "ar" ? "rtl" : "ltr"}
                className="flex justify-center items-center h-screen"
            >
                {/* Left Section */}
                <div className="w-[40%] h-[80%] flex flex-col justify-center p-10 bg-white relative">
                    {/* <img
                        src={LogoImage}
                        className={`w-52 mb-6 absolute ${i18n.language == "ar" ? "right-10 top-10" : "left-10 top-10"
                            }`}
                        alt="Logo"
                    /> */}
                    <h2 className="text-3xl font-semibold mb-2">
                        {i18n.language == "ar"
                            ? "إعادة تعيين كلمة المرور"
                            : "Reset your Password"}
                    </h2>
                    <p className="text-gray-600 mb-6">
                        {i18n.language == "ar"
                            ? "أدخل البريد الإلكتروني الذي سجلت به"
                            : "Enter your Email you signed up with"}
                    </p>
                    <form onSubmit={handleSubmit} className="w-full">
                        <input
                            type="email"
                            placeholder={
                                i18n.language == "ar" ? "ادخل البريد الإلكتروني" : "Enter your Email"
                            }
                            className="w-4/5 py-2 px-3 border border-gray-300 rounded-3xl mb-4 focus:outline-none focus:ring-2 focus:ring-green-500"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button
                            type="submit"
                            className="w-4/5 bg-green-700/90 text-white py-2 rounded-3xl text-lg hover:bg-green-700"
                            disabled={isLoading}
                        >
                            {isLoading
                                ? i18n.language == "ar"
                                    ? "جار التحميل"
                                    : "Sending..."
                                : i18n.language == "ar"
                                    ? "ارسل رابط اعادة التعيين"
                                    : "Send Reset Link"}
                        </button>
                    </form>
                    {message && <p className="mt-4 text-sm text-green-600">{message}</p>}
                </div>
                <button
                    dir={i18n.language == "ar" ? "rtl" : "ltr"}
                    type="button"
                    className={`absolute top-1/3 ${i18n.language == "ar" ? "right-[170px]" : "left-[170px]"
                        } flex items-center text-md gap-2`}
                    onClick={handleBack}
                >
                    <ArrowLeft size={20} />
                    {i18n.language == "ar" ? "رجوع" : "Back"}
                </button>

                {/* Right Section */}
                <div className="w-[42%] h-[90%]">
                    <img
                        src={img2}
                        alt="Green Section"
                        className="w-full h-full object-cover rounded-3xl"
                    />
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
