import { useEffect } from 'react';
import SignupStudentForm from '../../components/AuthComponents/SignupStudentForm'
export default function Signup() {
  
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])
  
  return (
    <div className="h-screen mt-[-50px]">
      <SignupStudentForm />
    </div>
  )
}
