import axios from "axios";
import { GraduationCap, MapPin, ShieldCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Modal from "./Modal";
import BookingInterface from "./bookingPart1";
import BookingInterface2 from "./InstantBooking";

const TutorProfileCard = (props) => {
  const { t } = useTranslation();
  const [tutorData, setTutorData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility\
  const [showModal2, setShowModal2] = useState(false); // Modal visibility\
  const { id } = useParams();
  const [token, setToken] = useState();
  const [instant, setInstant] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("TH_AUTH_TOKEN");
    setToken(token);
  }, []);

  useEffect(() => {
    if (!id && !props.tutorId) return;
    const fetchTutorData = async () => {
      try {
        const tutorId = id ?? props.tutorId;
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${tutorId}/`,
          {
            headers: {
              authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(res.data);
      } catch (error) {
        setError("Failed to fetch tutor data. Please try again later.");
      }
    };

    fetchTutorData();
  }, [id, props.tutorId]);


  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-red-500 font-bold">{error}</p>
      </div>
    );
  }

  if (!tutorData) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">Loading tutor profile...</p>
      </div>
    );
  }

  const {
    user,
    photo,
    country,
    tagline,
    selected_subject,
    experience_years,
    qualifications,
    experience,
    school_name,
    subjects,
    has_stripe_account,
    is_trialed,
    enable_instant,
    response_time_display,
  } = tutorData;
  console.log(has_stripe_account);

  return (
    <div className="px-4 py-10 bg-gradient-to-t from-white to-[#d3eadf] shadow-sm rounded-lg">
      {/* Modal */}
      {showModal2 && token === null ? (
        <Modal
          message={"You Need To Login Before Booking The Tutor"}
          onClose={() => setShowModal(false)}
          onConfirm={() => setShowModal(false)}
        />
      ) : showModal && token !== null && <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
          <BookingInterface
            showModal={showModal}
            setShowModal={setShowModal}
            tutorData={tutorData}
          />
        </div>
      </div>
      }
      {showModal2 && token === null ? (
        <Modal
          message={"You Need To Login Before Booking The Tutor"}
          onClose={() => setShowModal(false)}
          onConfirm={() => setShowModal(false)}
        />
      ) : instant && token !== null && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface2
              setActiveTab={props.setActiveTab}
              showModal={showModal}
              setInstant={setInstant}
              tutorData={tutorData}
            />
          </div>
        </div>
      )}
      {/* Main Profile Card */}
      <div className="sm:p-6 flex lg:flex-row flex-col gap-6 justify-between">
        <div className="flex md:flex-row flex-col gap-6 bg-white p-6 rounded-lg shadow-sm flex-grow">
          <picture>
            <source srcSet={photo} type="image/webp" />
            <img
              src={photo?.replace(".webp", ".jpg") || "fallback.jpg"}
              alt="img"
              className="rounded-[20px] w-52 h-52"
            />
          </picture>
          <div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-medium">
                {user.first_name} {user.last_name}
              </h2>
              {country?.name && (
                <div className="flex gap-2 items-center">
                  <div
                    className="w-8 h-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${country.country_icon})`,
                    }}
                  ></div>
                  <span className="text-sm font-bold text-zinc-900">
                    {country.iso_code}
                  </span>
                </div>
              )}
            </div>
            <p className="text-[#008847] font-semibold text-lg">
              AED { }
              <span className="font-semibold">
                {(() => {
                  const priceInCents = selected_subject?.price_in_cents;
                  if (!priceInCents) return "0";

                  const basePrice = priceInCents / 100;

                  let additionalPrice = 0;
                  if (basePrice >= 50) {
                    additionalPrice += Math.floor(basePrice / 50) * 2.5;
                  }

                  const finalPrice = basePrice + additionalPrice;

                  return finalPrice.toFixed(1);
                })()}
              </span>
              { } /per Hour
            </p>
            <div className="flex md:flex-row flex-col gap-4">
              <p className="text-zinc-950">
                <span className="font-bold">{t("teaches")}: </span>
                {Array.from(
                  new Set(subjects.map((subject) => subject.subject_name))
                ).join(", ")}
              </p>
              <p className="text-zinc-950">
                <span className="font-semibold">{t("Experience")}: </span>
                {`${experience_years} Years Completed` || "N/A"}
              </p>
            </div>
            <div className="flex md:w-[95%] md:flex-row flex-col justify-between">
              <p className="font-bold">{tagline}</p>
              <p className="font-bold">
                {experience_years * 50}+ Lessons Completed
              </p>
            </div>
            {school_name && (
              <div className="flex md:flex-row flex-col md:items-center gap-6 text-sm">
                <div className="flex gap-1 items-center">
                  <GraduationCap color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t("school")}: </span>
                    {school_name}
                  </span>
                </div>
                <div className="flex gap-1 items-center">
                  <MapPin color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t("tutoring")}: </span>
                    {tutorData?.online_tutoring &&
                      tutorData?.in_person_tutoring &&
                      tutorData.commute !== 0
                      ? "Online and In-person Tutoring"
                      : tutorData?.online_tutoring && tutorData.commute === 0
                        ? "Online"
                        : tutorData?.in_person_tutoring && tutorData.commute !== 0
                          ? "In-person Tutoring"
                          : "Online"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Actions */}
        <div className="bg-white p-7 rounded-lg shadow-sm flex-shrink-0">
          {!tutorData.is_verified && (
            <div className="flex items-center border-2 border-[#008847] gap-2 text-[#008847] w-fit p-2 rounded-xl mb-3">
              <ShieldCheck />
              <span> {t("verifiedTutor")}</span>
            </div>
          )}
          <p className="text-[#008847] font-semibold text-lg">
            {t("AED")} { }
            <span className="font-semibold">
              {(() => {
                const priceInCents = selected_subject?.price_in_cents;
                if (!priceInCents) return "0";

                const basePrice = priceInCents / 100;

                let additionalPrice = 0;
                if (basePrice >= 50) {
                  additionalPrice += Math.floor(basePrice / 50) * 2.5;
                }

                const finalPrice = basePrice + additionalPrice;

                return finalPrice.toFixed(1);
              })()}
            </span>
            { } /per Hour
          </p>
          <div className="space-y-3">
            {!is_trialed && (
              <button
                className="w-full bg-[#ED1D24] text-white text-xs md:text-[18px] py-2 px-4 md:rounded-2xl rounded-3xl"
                onClick={() =>
                  !token
                    ? setShowModal2(true)
                    : setShowModal(true)
                }
              >
                {t("tryNow")}
              </button>
            )}
            <div className="flex flex-col items-center gap-2 md:gap-5">
              {enable_instant && (
                <button
                  disabled={!has_stripe_account}
                  onClick={() =>
                    !token
                      ? setShowModal2(true)
                      : setInstant(true)
                  }
                  className={` ${!has_stripe_account ? "opacity-50 cursor-not-allowed" : ""
                    } bg-black text-white rounded-3xl md:rounded-2xl w-full py-2 px-4`}
                  title={
                    !has_stripe_account
                      ? "Tutor has not setup bank account for payments. You can't book this tutor"
                      : ""
                  }
                >
                  {t("instantbook")}
                </button>
              )}
              <button
                className={` bg-red-500 w-full text-white rounded-3xl md:rounded-2xl py-2 px-4 hover:bg-red-600`}
                onClick={() =>
                  token
                    ? (window.location.href = "/customer-dashboard")
                    : setShowModal(true)
                }
              >
                {t("SendMessage")}
              </button>
            </div>
            <p className="text-sm text-center text-[#008847] font-bold">
              {t("TypicallyRespond")} {response_time_display || "<24 hours"}
            </p>
          </div>
        </div>
      </div>
      <div className="p-6 flex items-center gap-6">
        <div className="md:w-[65%] space-y-4 flex justify-between w-full">
          <div>
            <h3 className="text-3xl font-bold">{t("introduction")}</h3>
            <p>{experience || "Experience not provided."}</p>
            <p>
              <span className="font-semibold">{t("qualification")}: </span>
              {qualifications.length > 0
                ? qualifications
                  .map(
                    (qual) => `${qual.qualification} (${qual.institution})`
                  )
                  .join(", ")
                : "No qualifications listed."}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorProfileCard;
