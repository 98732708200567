// TutorJobsBoard.tsx
import axios from "axios";
import { useEffect, useState } from "react";
import TutorFilterSection from "./TutorJobFilterSection";
import { useTranslation } from "react-i18next";

function TutorJobsBoard({ viewId, isJobBoard, setViewJobId, onTabChange }: any) { // Add onTabChange prop
  const [tutorData, setTutorData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(response.data);
      } catch (err) {
        setError("Failed to fetch tutor data");
      } finally {
        setLoading(false);
      }
    };
    fetchTutorData();
  }, [viewId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="">
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="flex justify-between items-center">
        <h1 className="text-[#000606] font-normal text-3xl">{t("jobs")}</h1>
        <div className="items-center gap-3 lg:flex hidden">
          <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
            <picture>
              <source srcSet={tutorData.photo} type="image/webp" />
              <img
                src={tutorData.photo?.replace(".webp", ".jpg") || "fallback.jpg"}
                alt={tutorData.user?.first_name || "Profile"}
                className="w-9 h-9 rounded-full"
              />
            </picture>
            <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
              {tutorData.user.first_name}
            </h1>
          </div>
        </div>
      </div>
      <TutorFilterSection
        isJobBoard={isJobBoard}
        viewId={viewId}
        setViewJobId={setViewJobId}
        onTabChange={onTabChange} // Pass the prop down
      />
    </div>
  );
}

export default TutorJobsBoard;