import { X } from "lucide-react";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

export default function TrialConfirmationModal({
  isOpen,
  onClose,
  tutor,
  bookingDetails,
  selectedDate,
  selectedTime,
}: any) {
  const { t } = useTranslation();

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-2 lg:p-4 z-50">
      <div className="bg-white rounded-2xl lg:max-w-[60%] w-full p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className={`absolute top-4 ${i18n.language == "en" ? "right-4" : "left-4"} p-2 hover:bg-gray-100 rounded-full transition-colors`}
        >
          <X className="w-5 h-5 text-gray-500" />
        </button>

        {/* Header */}
        <h2 className="text-xl font-medium mb-4">
          {i18n.language == "en" ? "Your Free Online Trial Request Has Been Sent" : "لقد تم إرسال طلب تجربة مجانية عبر الإنترنت."}
        </h2>
        <p className="text-gray-600 text-sm mb-6">
          {i18n.language == "en" ? "We've sent your request to your tutor. Tutors will usually confirm your request within 12 hours. If they don't please either message them directly on the platform or contact us below and we will assist you." : "لقد أرسلنا طلبك إلى معلمك. عادة ما يؤكد المعلمون طلبك في غضون 12 ساعة. إذا لم يفعلوا ذلك، يرجى إرسال رسالة إليهم مباشرة على المنصة أو الاتصال بنا أدناه وسنساعدك."}
        </p>

        {/* Tutor Card */}
        <div className="bg-gray-50 rounded-lg p-4 mb-6">
          <div className="flex items-center gap-4 mb-3">
            <picture>
              <source srcSet={tutor.photo} type="image/webp" />
              <img
                src={tutor.photo.replace(".webp", ".jpg") || "fallback.jpg"}
                alt={tutor.user?.first_name || tutor.first_name}
                className="w-12 h-12 rounded-full object-cover"
              />
            </picture>
            <div>
              <h3 className="font-medium">{tutor.first_name}</h3>
              <div className="text-sm text-gray-600">
                {tutor.selected_subject?.name} | {i18n.language == "en" ? "Free" : "مجاناً."}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="bg-amber-50 text-amber-600 px-3 py-1 rounded-full text-sm">
              {i18n.language == "en" ? "Trial" : "مُحاكمة"}
            </span>
          </div>
          <div className="flex items-center justify-between mt-3">
            <div className="flex items-center gap-2">
              <span className="inline-block w-3 h-3 bg-blue-500 rounded-full" />
              <span className="text-sm">
                {selectedDate} {i18n.language == "en" ? "at" : "في"} {selectedTime}
              </span>
            </div>
          </div>
        </div>

        {/* What Happens Next */}
        <div className="mb-6">
          <h3 className="font-medium mb-2">{i18n.language == "en" ? "What happens next?" : "ماذا سيحدث بعد ذلك؟"}</h3>
          <p className="text-sm text-gray-600">
            {i18n.language == "en" ? "Request a Free Online Trial at a time and date that suits you. This request will then be sent to the tutor. Once the tutor has confirmed your request, you will receive an email with the link to the online classroom. This link will become active 15 minutes before your trial begins." : "اطلب تجربة مجانية عبر الإنترنت في الوقت والتاريخ الذي يناسبك. سيتم إرسال هذا الطلب إلى المعلم. بمجرد تأكيد المعلم لطلبك، ستتلقى رسالة بريد إلكتروني تحتوي على الرابط إلى الفصل الدراسي عبر الإنترنت. سيصبح هذا الرابط نشطًا قبل 15 دقيقة من بدء التجربة."}
          </p>
        </div>
        <button
          onClick={() => {
            onClose();
            window.location.reload();
          }}
          className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          {t("go_to_dashboard")}
        </button>
      </div>
    </div>
  );
}