"use client";

import axios from "axios";
import { ChevronDown } from "lucide-react";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import i18n from "../../i18n";
import Frame from "../../images/asserts/Dashboard Assets/Frame.png";

interface TimeSlot {
  time: string;
  selected: boolean;
}

interface TimeOff {
  date: string;
  start_datetime: string;
  end_datetime: string;
}

const currentDay = new Date().getDay();

const AvailabilityCalendar = ({ updateUi, setUpdateUi }: any) => {
  const { t } = useTranslation();
  const days = [
    t("monday"),
    t("tuesday"),
    t("wednesday"),
    t("thursday"),
    t("friday"),
    t("saturday"),
    t("sunday"),
  ];
  const adjustedDayIndex = currentDay === 0 ? 6 : currentDay - 1;
  const [selectedDay, setSelectedDay] = useState(days[adjustedDayIndex]);
  const [selectAll, setSelectAll] = useState(false);
  const [timeSlots, setTimeSlots] = useState<{ [key: string]: TimeSlot[] }>(
    generateInitialTimeSlots()
  );
  const [isSaving, setIsSaving] = useState(false);
  const [mode, setMode] = useState<"availability" | "timeoff">("availability");
  const [timeOff, setTimeOff] = useState<TimeOff[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tutorData, setTutorData] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const route = useLocation();

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch availability");

        const data = await response.json();
        setTutorData(data);

        const updatedTimeSlots = generateInitialTimeSlots();
        data.availability.forEach(
          (slot: { day: number; start_time: string; end_time: string }) => {
            const dayName = days[slot.day - 1];
            const startTime = moment(slot.start_time, "HH:mm:ss");
            const endTime = moment(slot.end_time, "HH:mm:ss");

            if (updatedTimeSlots[dayName]) {
              updatedTimeSlots[dayName] = updatedTimeSlots[dayName].map(
                (timeSlot) => {
                  const slotStart = moment(timeSlot.time, "HH:mm");
                  const slotEnd = slotStart.clone().add(30, "minutes");
                  if (
                    slotStart.isSameOrAfter(startTime) &&
                    slotEnd.isSameOrBefore(endTime)
                  ) {
                    return { ...timeSlot, selected: true };
                  }
                  return timeSlot;
                }
              );
            }
          }
        );

        setTimeSlots(updatedTimeSlots);

        if (data.time_off) {
          setTimeOff(data.time_off);
        }
      } catch (error) {
        console.error("Error fetching schedule:", error);
        toast.error("Failed to load schedule.");
      }
    })();
  }, [updateUi]);

  useEffect(() => {
    setSelectAll(false);
  }, [selectedDay]);

  function generateInitialTimeSlots() {
    const slots: { [key: string]: TimeSlot[] } = {};
    days.forEach((day) => {
      const daySlots: TimeSlot[] = [];
      for (let hour = 6; hour <= 23; hour++) {
        for (let min = 0; min <= 30; min += 30) {
          if (hour === 23 && min === 30) break;
          daySlots.push({
            time: `${hour.toString().padStart(2, "0")}:${min
              .toString()
              .padStart(2, "0")}`,
            selected: false,
          });
        }
      }
      slots[day] = daySlots;
    });
    return slots;
  }

  const toggleTimeSlot = (time: string) => {
    if (mode === "availability") {
      setTimeSlots((prev) => ({
        ...prev,
        [selectedDay]: prev[selectedDay].map((slot) =>
          slot.time === time ? { ...slot, selected: !slot.selected } : slot
        ),
      }));
    } else {
      const timeOffEntry: TimeOff = {
        date: selectedDate,
        start_datetime: `${selectedDate}T${time}:00+04:00`,
        end_datetime: `${selectedDate}T${time.split(":")[0]}:${(
          parseInt(time.split(":")[1]) + 29
        )
          .toString()
          .padStart(2, "0")}:59+04:00`,
      };

      setTimeOff((prev) => {
        const exists = prev.some(
          (item) =>
            item.date === timeOffEntry.date &&
            item.start_datetime === timeOffEntry.start_datetime
        );
        console.log(`Toggling ${time}: Exists=${exists}`, timeOffEntry); // Debug log
        return exists
          ? prev.filter(
            (item) =>
              item.date !== timeOffEntry.date ||
              item.start_datetime !== timeOffEntry.start_datetime
          )
          : [...prev, timeOffEntry];
      });
    }
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (mode === "availability") {
      setTimeSlots((prev) => ({
        ...prev,
        [selectedDay]: prev[selectedDay].map((slot) => ({
          ...slot,
          selected: newSelectAll,
        })),
      }));
    } else {
      if (newSelectAll) {
        const allTimeOffs = timeSlots[selectedDay].map((slot) => ({
          date: selectedDate,
          start_datetime: `${selectedDate}T${slot.time}:00+04:00`,
          end_datetime: `${selectedDate}T${slot.time.split(":")[0]}:${(
            parseInt(slot.time.split(":")[1]) + 29
          )
            .toString()
            .padStart(2, "0")}:59+04:00`,
        }));
        setTimeOff((prev) => [
          ...prev.filter((item) => item.date !== selectedDate),
          ...allTimeOffs,
        ]);
      } else {
        setTimeOff((prev) => prev.filter((item) => item.date !== selectedDate));
      }
    }
  };

  const isTimeSlotSelected = (time: string) => {
    if (mode === "availability") {
      return timeSlots[selectedDay].find((slot) => slot.time === time)
        ?.selected;
    } else {
      const slotTime = `${selectedDate}T${time}:00+04:00`;
      const isSelected = timeOff.some(
        (item) => item.date === selectedDate && item.start_datetime === slotTime
      );
      console.log(`Checking ${time}: Selected=${isSelected}`); // Debug log
      return isSelected;
    }
  };

  const isTimeSlotBlocked = (time: string) => {
    const slotStart = moment(`${selectedDate}T${time}:00+04:00`);
    return tutorData.time_off?.some((to: TimeOff) =>
      slotStart.isBetween(
        moment(to.start_datetime),
        moment(to.end_datetime),
        undefined,
        "[)"
      )
    );
  };

  const groupTimeOff = (timeOffEntries: TimeOff[]): TimeOff[] => {
    const grouped: { [date: string]: TimeOff[] } = {};
    timeOffEntries.forEach((entry) => {
      if (!grouped[entry.date]) {
        grouped[entry.date] = [];
      }
      grouped[entry.date].push(entry);
    });

    const mergedTimeOff: TimeOff[] = [];
    for (const date in grouped) {
      const entries = grouped[date].sort((a, b) =>
        a.start_datetime.localeCompare(b.start_datetime)
      );
      if (entries.length === 0) continue;

      let currentStart = entries[0].start_datetime;
      let currentEnd = entries[0].end_datetime;

      for (let i = 1; i < entries.length; i++) {
        const nextStart = entries[i].start_datetime;
        const nextEnd = entries[i].end_datetime;
        const currentEndMoment = moment(currentEnd);
        const nextStartMoment = moment(nextStart);
        if (
          currentEndMoment.isSame(nextStartMoment) ||
          currentEndMoment.add(1, "second").isSame(nextStartMoment)
        ) {
          currentEnd = nextEnd;
        } else {
          mergedTimeOff.push({
            date,
            start_datetime: currentStart,
            end_datetime: currentEnd,
          });
          currentStart = nextStart;
          currentEnd = nextEnd;
        }
      }
      mergedTimeOff.push({
        date,
        start_datetime: currentStart,
        end_datetime: currentEnd,
      });
    }
    return mergedTimeOff;
  };

  const saveAvailability = async () => {
    setIsSaving(true);
    const loadingToast = toast.loading("Saving schedule...");

    const availability: any = [];
    days.forEach((day) => {
      const selectedSlots = timeSlots[day]
        .filter((slot) => slot.selected)
        .map((slot) => slot.time);

      if (selectedSlots.length > 0) {
        selectedSlots.sort((a, b) => timeToMinutes(a) - timeToMinutes(b));
        let groups = [];
        let currentGroup = [selectedSlots[0]];

        for (let i = 1; i < selectedSlots.length; i++) {
          const prevTime = timeToMinutes(selectedSlots[i - 1]);
          const currentTime = timeToMinutes(selectedSlots[i]);
          if (currentTime - prevTime === 30) {
            currentGroup.push(selectedSlots[i]);
          } else {
            groups.push(currentGroup);
            currentGroup = [selectedSlots[i]];
          }
        }
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }

        groups.forEach((group) => {
          const startTime = group[0] + ":00";
          const lastSlotTime = group[group.length - 1];
          const endTime = addMinutes(lastSlotTime, 30);
          availability.push({
            day: days.indexOf(day) + 1,
            start_time: startTime,
            end_time: endTime,
          });
        });
      }
    });

    const mergedTimeOff = groupTimeOff(timeOff);
    const payload = {
      availability,
      time_off: mergedTimeOff,
    };

    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Failed to save schedule");

      setTutorData((prev: any) => ({
        ...prev,
        time_off: mergedTimeOff,
      }));

      toast.success(
        `${mode === "availability" ? "Availability" : "Time off"} saved successfully!`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
      if (route.pathname === `/EditProfile`) {
        setUpdateUi(!updateUi);
      }
    } catch (error) {
      toast.error("Failed to save schedule. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  const timeToMinutes = (time: string): number => {
    const [hour, min] = time.split(":").map(Number);
    return hour * 60 + min;
  };

  const addMinutes = (time: string, minutes: number): string => {
    const [hour, min] = time.split(":").map(Number);
    const totalMinutes = hour * 60 + min + minutes;
    if (totalMinutes >= 1440) {
      return "23:59:59";
    } else {
      const newHour = Math.floor(totalMinutes / 60);
      const newMin = totalMinutes % 60;
      return `${newHour.toString().padStart(2, "0")}:${newMin
        .toString()
        .padStart(2, "0")}:00`;
    }
  };

  return (
    <div className="max-w-[1200px] mx-auto p-6 bg-white min-h-screen">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-[#000606] font-normal text-3xl">
          {t("availability")}
        </h1>
        <div className="items-center gap-3 lg:flex hidden">
          <div className="flex items-center bg-white rounded-full py-1 px-3 gap-2">
            <div className="w-8 h-8 rounded-full bg-gray-200 overflow-hidden">
              <img
                src={tutorData?.photo}
                alt={tutorData?.user?.first_name}
                width={32}
                height={32}
              />
            </div>
            <span className="text-sm text-[#292D32]">
              {tutorData?.user?.first_name}
            </span>
          </div>
        </div>
      </div>

      <div className="mb-6">
        <div className="flex flex-col md:flex-row justify-center items-center gap-y-4 md:gap-y-0">
          <div className="flex-1 bg-[#F5F5F5] w-full sm:rounded-full p-1">
            {days.map((day) => (
              <button
                key={day}
                onClick={() => setSelectedDay(day)}
                className={`py-2 px-[60px] md:px-[18px] rounded-full sm:text-xl transition-colors ${selectedDay === day
                  ? "bg-[#008847] text-white"
                  : "text-[#008847] hover:bg-[#E0E0E0]"
                  }`}
              >
                {day}
              </button>
            ))}
          </div>
          <div className="relative ml-4">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="bg-[#008847] text-white px-4 py-2 rounded-full flex items-center gap-2"
            >
              {mode === "availability" ? t("available") : t("time_off")}
              <ChevronDown className="w-4 h-4" />
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 md:w-40 bg-white rounded-lg shadow-lg z-10">
                <button
                  onClick={() => {
                    setMode("availability");
                    setIsDropdownOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-t-lg"
                >
                  {t("available")}
                </button>
                <button
                  onClick={() => {
                    setMode("timeoff");
                    setIsDropdownOpen(false);
                  }}
                  className="w-full text-left px-4 py-2 hover:bg-gray-100 rounded-b-lg"
                >
                  {t("time_off")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <button
          onClick={handleSelectAll}
          className="flex items-center gap-2 text-[#008847]"
        >
          <div
            className={`w-4 h-4 border-2 rounded-sm ${selectAll ? "bg-[#008847] border-[#008847]" : "border-[#008847]"
              }`}
          >
            {selectAll && (
              <svg
                viewBox="0 0 24 24"
                fill="none"
                className="text-white w-3 h-3"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          {t("select_all")}
        </button>
      </div>
      <div className="space-y-4">
        {mode === "timeoff" && (
          <input
            type="date"
            name="timeOffDate"
            id="timeOffDate"
            value={selectedDate}
            min={new Date().toISOString().split("T")[0]}
            onChange={(e) => setSelectedDate(e.target.value)}
            className="border border-[#008847] rounded-lg px-2 py-1"
          />
        )}
        <div className="grid sm:grid-cols-2 grid-cols-4 md:grid-cols-6 gap-2 mb-8">
          {timeSlots[selectedDay]?.map((slot) => {
            const isSelected = isTimeSlotSelected(slot.time);
            const isBlocked = mode === "timeoff" && isTimeSlotBlocked(slot.time);
            return (
              <button
                key={slot.time}
                onClick={() => toggleTimeSlot(slot.time)}
                className={`${mode === "timeoff"
                  ? isSelected
                    ? "bg-[#008847] text-white"
                    : isBlocked
                      ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                      : "bg-[#E8F5E9] text-[#008847]"
                  : isSelected
                    ? "bg-[#008847] text-white"
                    : "bg-[#E8F5E9] text-[#008847]"
                  } py-[35px] rounded-lg text-sm font-medium relative overflow-hidden transition-opacity duration-200 hover:opacity-90`}
                style={{
                  backgroundImage: `url(${isSelected && mode !== "timeoff" ? Frame : ""
                    })`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
                disabled={mode === "timeoff" && isBlocked}
              >
                {slot.time}
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={saveAvailability}
          className={`px-3 sm:px-6 py-2 sm:py-3 rounded-full sm:text-lg ${isSaving ? "bg-gray-400" : "bg-[#008847] text-white"
            }`}
          disabled={isSaving}
        >
          {isSaving
            ? "Saving..."
            : `${mode === "availability" ? t("save_availability") : "Save Time Off"}`}
        </button>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;