import axios from "axios";
import { ArrowLeft, GraduationCap, MapPin, ShieldCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BookingInterface from "../../components/AboutTutorComponents/bookingPart1";
import BookingInterface2 from "../AboutTutorComponents/InstantBooking";
import ApplicationModal2 from "../DashBoardStudent/sendMessagePart2";
import MessageTutorModal from "../DashBoardStudent/sendMessagePopup";
import ReactStars from "react-stars";
const TutorProfileCard2 = (props: any) => {
  const { t } = useTranslation();
  const [tutorData, setTutorData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const [messageOpen, setMessageOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [instant, setInstant] = useState(false);
  
  useEffect(() => {
    if (!id && !props.tutorId) return;
    const fetchTutorData = async () => {
      try {
        const tutorId = id ?? props.tutorId;
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${tutorId}/`,
          {
            headers: {
              authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(res.data);
      } catch (error) {
        setError("Failed to fetch tutor data. Please try again later.");
      }
    };

    fetchTutorData();
  }, [id, props.tutorId]);

  const handleSendMessage = () => {
    setMessageOpen(true);
  };

  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-red-500 font-bold">{error}</p>
      </div>
    );
  }

  if (!tutorData) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">Loading tutor profile...</p>
      </div>
    );
  }

  const {
    user,
    photo,
    country,
    tagline,
    selected_subject,
    experience_years,
    qualifications,
    experience,
    school_name,
    subjects,
    has_stripe_account,
    is_trialed,
    enable_instant,
    response_time_display,
    rating
  } = tutorData;
  return (
    <div 
    className="px-4 py-10 shadow-sm rounded-lg ">
      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface
              showModal={showModal}
              setShowModal={setShowModal}
              tutorData={tutorData}
            />
          </div>
        </div>
      )}
      {instant && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface2
              setActiveTab={props.setActiveTab}
              showModal={showModal}
              setInstant={setInstant}
              tutorData={tutorData}
            />
          </div>
        </div>
      )}
      {messageOpen && (
        <MessageTutorModal
          isOpen={messageOpen}
          onClose={() => setMessageOpen(false)}
          tutorImage={tutorData.photo}
          onSendMessage={() => {
            setSendMessage(true);
            setMessageOpen(false);
          }}
        />
      )}

      {/* Application Modal */}
      {sendMessage && (
        <ApplicationModal2
          tutor={tutorData}
          isOpen={sendMessage}
          onClose={() => setSendMessage(false)}
          onSubmit={() => setSendMessage(false)}
        />
      )}

      {/* 🔹 Back Button */}
      <button
        onClick={() => props.setTutor(false)}
        className="flex items-center gap-2 text-gray-700 hover:text-black transition-all mb-4"
      >
        <ArrowLeft className="w-5 h-5" />
        <span className="text-lg font-medium">{t("back")}</span>
      </button>

      {/* Main Profile Card */}
      <div className="sm:p-6 flex lg:flex-row flex-col gap-6 justify-between">
        <div className="flex md:flex-row flex-col gap-6 bg-white p-6 rounded-lg shadow-sm flex-grow w-[87vw] md:w-auto">
          <picture>
            <source srcSet={photo} type="image/webp" />
            <img
              src={photo?.replace(".webp", ".jpg") || "fallback.jpg"}
              alt="img"
              className="rounded-[20px] w-52 h-52 mx-auto"
            />
          </picture>
          <div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-medium">
                {user.first_name} {user.last_name}
              </h2>
              {country?.name && (
                <div className="flex gap-2 items-center">
                  <div
                    className="w-8 h-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${country.country_icon})`,
                    }}
                  ></div>
                  <span className="text-sm font-bold text-zinc-900">
                    {country.iso_code}
                  </span>
                </div>
              )}
            </div>
            <p className="text-[#008847] font-semibold text-lg">
              AED { }
              <span className="font-semibold">
                {(() => {
                  const priceInCents = selected_subject?.price_in_cents;
                  if (!priceInCents) return "0"; // In case price_in_cents is undefined or null

                  const basePrice = priceInCents / 100; // Convert cents to the base price

                  // Calculate the additional price based on increments of 50
                  let additionalPrice = 0;
                  if (basePrice >= 50) {
                    additionalPrice += Math.floor(basePrice / 50) * 2.5; // Add 2.5 for each 50 increment
                  }

                  // Final price after adding the increment
                  const finalPrice = basePrice + additionalPrice;

                  return finalPrice.toFixed(1); // Ensure the result is rounded to two decimal places
                })()}
              </span>
              { } /per Hour
            </p>
            {rating &&  <div className="flex gap-1">  
          <ReactStars
            count={5}
            value={rating}
            size={19}
            edit={false}
            color2={"#ffd700"}
          />
          <p className="">({rating})</p>
          </div>}
            <div className="flex md:flex-row flex-col gap-4">
              <p className="text-zinc-950">
                <span className="font-bold">{t("teaches")}: </span>
                {Array.from(
                  new Set(subjects.map((subject: any) => subject.subject_name))
                ).join(", ")}
              </p>
              <p className="text-zinc-950">
                <span className="font-semibold">{t("Experience")}: </span>
                {`${experience_years} Years Completed` || "N/A"}
              </p>
            </div>
            <div className="flex md:w-[95%] md:flex-row flex-col justify-between">
              <p className="font-bold">{tagline}</p>
              <p className="font-bold">
                {experience_years * 50}+ Lessons Completed
              </p>
            </div>
            {school_name && (
              <div className="flex md:flex-row flex-col md:items-center gap-6 text-sm">
                <div className="flex gap-1 items-center">
                  <GraduationCap color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t("school")}: </span>
                    {school_name}
                  </span>
                </div>
                <div className="flex gap-1 items-center">
                  <MapPin color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t("tutoring")}: </span>
                    {tutorData?.online_tutoring &&
                      tutorData?.in_person_tutoring &&
                      tutorData.commute !== 0
                      ? "Online and In-person Tutoring"
                      : tutorData?.online_tutoring && tutorData.commute === 0
                        ? "Online"
                        : tutorData?.in_person_tutoring && tutorData.commute !== 0
                          ? "In-person Tutoring"
                          : "Online"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Actions */}
        <div className="bg-white p-7 w-[87vw] md:w-[340px] rounded-lg shadow-sm flex-shrink-0">
          {!tutorData.is_verified && (
            <div className="flex items-center border-2 border-[#008847] gap-2 text-[#008847] w-fit p-2 rounded-xl mb-1">
              <ShieldCheck />
              <span> {t("verifiedTutor")}</span>
            </div>
          )}
          <ReactStars
              count={5}
              value={rating}
              size={19}
              edit={false}
              color2={"#ffd700"}
            />
          <p className="text-[#008847] font-semibold text-lg">
            AED { }
            <span className="font-semibold">
              {(() => {
                const priceInCents = selected_subject?.price_in_cents;
                if (!priceInCents) return "0"; // In case price_in_cents is undefined or null

                const basePrice = priceInCents / 100; // Convert cents to the base price

                // Calculate the additional price based on increments of 50
                let additionalPrice = 0;
                if (basePrice >= 50) {
                  additionalPrice += Math.floor(basePrice / 50) * 2.5; // Add 2.5 for each 50 increment
                }

                // Final price after adding the increment
                const finalPrice = basePrice + additionalPrice;

                return finalPrice.toFixed(1); // Ensure the result is rounded to two decimal places
              })()}
            </span>
            { } /per Hour
            <br />{" "}
            <span className="text-sm font-bold text-black">
              {t("includigVat")}
            </span>
          </p>
          <div className="space-y-3">
            {!is_trialed && (
              <button
                className="w-full bg-[#ED1D24] text-white text-xs md:text-[18px] py-2 px-4 md:rounded-2xl rounded-3xl"
                onClick={() => setShowModal(true)}
              >
                {t("tryNow")}
              </button>
            )}
            <div className="flex md:flex-row flex-col items-center gap-2 md:gap-5">
              {enable_instant && (
                <button
                  disabled={!has_stripe_account}
                  className={` ${!has_stripe_account ? "opacity-50 cursor-not-allowed" : ""
                    } w-full bg-black text-white rounded-3xl md:rounded-2xl py-2 px-4`}
                  onClick={() => setInstant(true)}
                  title={
                    !has_stripe_account
                      ? "Tutor has not setup bank account for payments. You can't book this tutor"
                      : ""
                  }
                >
                  {t("instantbook")}
                </button>
              )}
              <button
                className="w-full bg-red-500 text-white rounded-3xl md:rounded-2xl py-2 px-2 hover:bg-red-600"
                onClick={handleSendMessage}
              >
                {t("SendMessage")}
              </button>
            </div>
            <p className="text-sm text-center text-[#008847] font-bold">
              {t("TypicallyRespond")} {response_time_display || "<24 hours"}
            </p>
          </div>
        </div>
        {/* Introduction Section */}
      </div>
      <div className="p-6 flex items-center gap-6 w-[87vw] md:w-auto">
        <div className="md:w-[65%] space-y-4">
          <h3 className="text-3xl font-bold">{t("introduction")}</h3>
          <p>{experience || "Experience not provided."}</p>
          <p>
            <span className="font-semibold">{t("qualification")}: </span>
            {qualifications.length > 0
              ? qualifications
                .map(
                  (qual: any) => `${qual.qualification} (${qual.institution})`
                )
                .join(", ")
              : "No qualifications listed."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TutorProfileCard2;
