import b1 from "../images/Blogs/blog1.png";
import b2 from "../images/Blogs/blog2.png";
import b3 from "../images/Blogs/blog3.png";
import bl1 from "../images/Blogs/bl1.webp";
import bl2 from "../images/Blogs/bl2.jpg";
import bl3 from "../images/Blogs/bl3.jpg";
import bl4 from "../images/Blogs/bl4.jpg";
import b4 from "../images/Blogs/blog4.png";
export const blogs = [{
    title: "Top 10 Benefits of Hiring a Private Tutor in Dubai",
    image: bl1,
}, {
    title: "How to Find the Best Tutor Website in Dubai",
    image: bl2,
}, {
    title: "Most Essential Study Tips for Students in Dubai",
    image: bl3,
}, {
    title: "Why Online Tutoring Is Becoming Popular in Dubai",
    image: bl4,
}, {
    title: "Your guide to creating the perfect language learning schedule",
    image: b2,
}, {
    title: "5 best language exchange apps and websites in 2024",
    image: b1,
}, {
    title: "Modern Physics with AI: Revolutionize Your Physics Learning",
    image: b4,
}, {
    title: "6 Key Digital Literacy Tips For Online Tutors To Develop",
    image: b3,
},]