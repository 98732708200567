import { useEffect } from "react";
import SignupTutorForm from "../../components/AuthComponents/SignupTutorForm"

export default function Signup() {

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, []);
  
  return (
    <div className=" ">
      <SignupTutorForm />
    </div>
  )
}
