/* eslint-disable react-hooks/exhaustive-deps */
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { ChevronDown, X } from "lucide-react";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../i18n";
import CheckoutForm, { stripePromise } from "./bookingTrial";

interface TimeSlot {
  time: string;
  isBooked: boolean;
}

interface BookingInterface2Props {
  setInstant: (value: boolean) => void;
  tutorData: any;
  showModal: boolean;
  initialSelectedTimesByDate?: { [date: string]: string[] };
  initialDate?: string;
  setActiveTab?: any;
}

export default function BookingInterface2({
  setInstant,
  tutorData = {},
  initialSelectedTimesByDate = {},
  initialDate,
  setActiveTab,
}: BookingInterface2Props) {
  const [selectedDate, setSelectedDate] = useState(() => {
    return initialDate || new Date().toISOString().split("T")[0];
  });
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<any>({
    customer: null,
    start_time: null,
    end_time: null,
    subject: null,
    tutor: tutorData.user?.id,
    credit_card: null,
    location: "online",
    num_weeks: 1,
    invitation_dates: [],
  });
  const [time, setTime] = useState("Asia/Dubai");
  const [price, setPrice] = useState(0);
  const [selectedTimesByDate, setSelectedTimesByDate] = useState<{
    [date: string]: string[];
  }>(initialSelectedTimesByDate);
  const [bookedSlots, setBookedSlots] = useState<any[]>([]);
  const currentSelectedTimes = selectedTimesByDate[selectedDate] || [];
  const { t } = useTranslation();

  useEffect(() => {
    const getSelectedSlot = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${tutorData.user?.id}/bookings/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setBookedSlots(res.data);
      } catch (error) {
        console.log(error);
        toast.error("Error fetching booked slots");
      }
    };
    getSelectedSlot();

    return () => setTimeSlots([]);
  }, [tutorData.user?.id]);

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("TH_AUTH_TOKEN");
        if (!token) throw new Error("Authentication token not found.");
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          { headers: { Authorization: `Token ${token}` } }
        );
        setTime(res.data.data.user.user_timezone);
        if (res.data?.data?.user?.id) {
          setBookingDetails((prev: any) => ({
            ...prev,
            customer: parseInt(res.data.data.user.id, 10),
          }));
        }
      } catch (error: any) {
        toast.error(error.message || "Error fetching user data");
      }
    };
    getUser();
  }, []);

  const bookedPeriods = useMemo(() => {
    return bookedSlots.map((booking) => ({
      start: DateTime.fromISO(booking.start_time, { setZone: true }).setZone(time),
      end: DateTime.fromISO(booking.end_time, { setZone: true }).setZone(time),
    }));
  }, [bookedSlots, time]);

  const selectedBookingPeriods = useMemo(() => {
    const periods = [];
    const times = selectedTimesByDate[selectedDate] || [];
    for (let i = 0; i < times.length; i += 2) {
      const start = DateTime.fromFormat(
        `${selectedDate} ${times[i]}`,
        "yyyy-MM-dd HH:mm",
        { zone: time }
      );
      const end = start.plus({ minutes: 60 });
      periods.push({ start, end });
    }
    return periods;
  }, [selectedTimesByDate, selectedDate, time]);

  const isSlotSelectable = (slotTime: string) => {
    const slotStart = DateTime.fromFormat(
      `${selectedDate} ${slotTime}`,
      "yyyy-MM-dd HH:mm",
      { zone: time }
    );
    const slotEnd = slotStart.plus({ minutes: 60 });
    const nextSlotTime = slotStart.plus({ minutes: 30 }).toFormat("HH:mm");
    const nextSlot = timeSlots.find((s) => s.time === nextSlotTime);

    if (!nextSlot || nextSlot.isBooked) {
      return false;
    }

    const isStartBooked = bookedPeriods.some(
      (bp) => bp.start <= slotStart && slotStart < bp.end
    );
    if (isStartBooked) {
      return false;
    }

    const nextSlotStart = slotStart.plus({ minutes: 30 });
    const isNextBooked = bookedPeriods.some(
      (bp) => bp.start <= nextSlotStart && nextSlotStart < bp.end
    );
    if (isNextBooked) {
      return false;
    }

    const overlapsSelected = selectedBookingPeriods.some(
      (sp) => slotStart < sp.end && sp.start < slotEnd
    );
    if (overlapsSelected) {
      return false;
    }

    return true;
  };

  const generateTimeSlots = (selectedDate: string) => {
    if (!selectedDate || !tutorData?.availability || !tutorData.user_timezone) return;
    const userTz = time;
    const tutorTz = tutorData.user_timezone;

    const slots: TimeSlot[] = [];
    const startOfDay = DateTime.fromISO(selectedDate, { zone: userTz }).startOf("day");
    const endOfDay = startOfDay.endOf("day");
    const now = DateTime.now().setZone(userTz);
    const isToday = startOfDay.hasSame(now, "day");

    // Filter time_off periods that overlap with the selected day
    const timeOffPeriods = tutorData.time_off?.filter((to: any) => {
      const toStart = DateTime.fromISO(to.start_datetime, { setZone: true }).setZone(userTz);
      const toEnd = DateTime.fromISO(to.end_datetime, { setZone: true }).setZone(userTz);
      return toStart < endOfDay && toEnd > startOfDay;
    }).map((to: any) => ({
      start: DateTime.fromISO(to.start_datetime, { setZone: true }).setZone(userTz),
      end: DateTime.fromISO(to.end_datetime, { setZone: true }).setZone(userTz),
    })) || [];

    for (let min = 0; min < 24 * 60; min += 30) {
      const slotTime = startOfDay.plus({ minutes: min });
      // Skip slots that are in the past if the selected date is today
      if (isToday && slotTime < now) {
        continue;
      }
      const slotTimeInTutorTz = slotTime.setZone(tutorTz);
      const dayOfWeek = slotTimeInTutorTz.weekday;

      // Get all availability slots for the day
      const availabilityForDay = tutorData.availability.filter(
        (slot: any) => slot.day === (dayOfWeek === 7 ? 0 : dayOfWeek)
      );

      // Check if the slot falls within any availability period
      const isAvailable = availabilityForDay.some((avail: any) => {
        const [startHour, startMinute] = avail.start_time.split(":").map(Number);
        const [endHour, endMinute] = avail.end_time.split(":").map(Number);
        const slotMinutes = slotTimeInTutorTz.hour * 60 + slotTimeInTutorTz.minute;
        const startMinutes = startHour * 60 + startMinute;
        const endMinutes = endHour * 60 + endMinute;
        return slotMinutes >= startMinutes && slotMinutes < endMinutes;
      });

      if (isAvailable) {
        const isTimeOff = timeOffPeriods.some(
          (to: any) => slotTime >= to.start && slotTime < to.end
        );
        if (!isTimeOff) {
          const slotUtc = slotTime.toUTC();
          const isBooked = bookedSlots.some((booking) => {
            const bookingStart = DateTime.fromISO(booking.start_time, { setZone: true }).toUTC();
            const bookingEnd = DateTime.fromISO(booking.end_time, { setZone: true }).toUTC();
            return slotUtc >= bookingStart && slotUtc < bookingEnd;
          });
          slots.push({
            time: slotTime.toFormat("HH:mm"),
            isBooked,
          });
        }
      }
    }

    setTimeSlots(slots);
    setAllSubjects(tutorData.subjects || []);
  };
  useEffect(() => {
    generateTimeSlots(selectedDate);
  }, [tutorData, selectedDate, time, bookedSlots]);

  const handleDateChange = (e: any) => {
    const date = e.target.value;
    setSelectedDate(date);
  };

  const handleTimeSelection = (time: string) => {
    setSelectedTimesByDate((prev) => {
      const currentTimes = prev[selectedDate] || [];
      let newSelectedTimes = [...currentTimes];
      const slotIndex = timeSlots.findIndex((slot) => slot.time === time);
      const selectedTimeIndex = newSelectedTimes.indexOf(time);
      let nextTime = time.includes(":00")
        ? time.replace(":00", ":30")
        : `${parseInt(time.split(":")[0], 10) + 1}:00`.padStart(5, "0");
      const nextSlotIndex = timeSlots.findIndex((slot) => slot.time === nextTime);

      console.log("Clicked time:", time, "Selected index:", selectedTimeIndex, "Current times:", currentTimes);

      if (selectedTimeIndex !== -1) {
        // Deselect the pair if the slot is already selected
        const pairIndex = Math.floor(selectedTimeIndex / 2) * 2;
        newSelectedTimes.splice(pairIndex, 2);
      } else {
        // Select the pair if the slot and its next slot are available
        if (
          nextSlotIndex !== -1 &&
          !timeSlots[slotIndex].isBooked &&
          !timeSlots[nextSlotIndex].isBooked &&
          isSlotSelectable(time)
        ) {
          newSelectedTimes.push(time);
          newSelectedTimes.push(nextTime);
          console.log("Selecting pair:", time, nextTime, "New times:", newSelectedTimes);
        } else {
          toast.error("Selected slots are not available for booking.");
          return prev;
        }
      }

      const updatedTimesByDate = {
        ...prev,
        [selectedDate]: newSelectedTimes,
      };
      updatePriceAllDates(updatedTimesByDate, bookingDetails.subject);
      return updatedTimesByDate;
    });
  };

  const handleRemoveSlot = (date: string, startTime: string) => {
    setSelectedTimesByDate((prev) => {
      const currentTimes = [...(prev[date] || [])];
      const startTimeIndex = currentTimes.indexOf(startTime);

      if (startTimeIndex !== -1) {
        const pairIndex = Math.floor(startTimeIndex / 2) * 2;
        currentTimes.splice(pairIndex, 2);
        const updatedTimesByDate = { ...prev, [date]: currentTimes };
        updatePriceAllDates(updatedTimesByDate, bookingDetails.subject);
        return updatedTimesByDate;
      }
      return prev;
    });
  };

  const handleSubjectChange = (e: any) => {
    const selectedSubject = parseInt(e.target.value, 10);
    setBookingDetails((prev: any) => ({
      ...prev,
      subject: selectedSubject,
    }));
    updatePriceAllDates(selectedTimesByDate, selectedSubject);
  };

  const updatePriceAllDates = (
    allSelectedTimes: { [date: string]: string[] },
    subjectId: number | null
  ) => {
    if (!subjectId) {
      setPrice(0);
      return;
    }
    const subject = tutorData.subjects.find((sub: any) => sub.id === subjectId);
    if (!subject) {
      setPrice(0);
      return;
    }
    let totalPairs = 0;
    Object.values(allSelectedTimes).forEach((times) => {
      if (times.length % 2 === 0) {
        totalPairs += times.length / 2;
      }
    });
    let basePrice = subject.price_in_cents / 100;
    if (basePrice >= 50 && basePrice < 100) basePrice += 2.5;
    else if (basePrice >= 100 && basePrice < 150) basePrice += 5;
    else if (basePrice >= 150 && basePrice < 200) basePrice += 7.5;
    else if (basePrice >= 200 && basePrice < 250) basePrice += 10;
    else if (basePrice >= 250 && basePrice < 300) basePrice += 12.5;
    else if (basePrice >= 300 && basePrice < 350) basePrice += 15;
    else if (basePrice >= 350 && basePrice < 400) basePrice += 17.5;
    else if (basePrice >= 400) basePrice += 20;
    const totalPrice = totalPairs * basePrice;
    setPrice(parseFloat(totalPrice.toFixed(2)));
  };

  const handleBooking = async () => {
    let invalidDate = null;
    for (const [date, times] of Object.entries(selectedTimesByDate)) {
      if (times.length % 2 !== 0) {
        invalidDate = date;
        break;
      }
    }
    if (invalidDate || bookingDetails.subject === null) {
      toast.error(
        invalidDate
          ? `Please select valid time slot pairs for ${DateTime.fromISO(invalidDate).toFormat("MMMM d, yyyy")}.`
          : "Please select a subject."
      );
      return;
    }

    let invitationDates: any = [];
    Object.entries(selectedTimesByDate).forEach(([date, times]) => {
      for (let i = 0; i < times.length; i += 2) {
        const startMoment = DateTime.fromFormat(
          `${date} ${times[i]}`,
          "yyyy-MM-dd HH:mm",
          { zone: time }
        );
        const endMoment = startMoment.plus({ minutes: 60 });
        invitationDates.push({
          start_time: startMoment.toUTC().toISO(),
          end_time: endMoment.toUTC().toISO(),
        });
      }
    });

    setBookingDetails((prev: any) => ({
      ...prev,
      invitation_dates: invitationDates,
      start_time: invitationDates[0]?.start_time || null,
      end_time: invitationDates[invitationDates.length - 1]?.end_time || null,
    }));

    setIsSecondModalOpen(true);
  };

  const getTimeRange = (startTime: string, endTime: string) => {
    const start = DateTime.fromFormat(startTime, "HH:mm");
    const end = start.plus({ minutes: 60 });
    return `${startTime} to ${end.toFormat("HH:mm")}`;
  };

  const isBookingValid = () => {
    const hasValidSlots = Object.values(selectedTimesByDate).some(
      (times) => times.length > 0 && times.length % 2 === 0
    );
    const hasSubject = bookingDetails.subject !== null;
    return hasValidSlots && hasSubject;
  };

  return (
    <div className="mx-auto lg:p-6 max-h-[70vh] overflow-y-auto z-[100]">
      <ToastContainer />
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-medium mb-3">
              {i18n.language === "en" ? "Available Calendar Days" : "أيام التقويم المتاحة"}
            </h2>
            <input
              type="date"
              value={selectedDate}
              min={DateTime.now().toISODate()}
              onChange={handleDateChange}
              className="w-full px-4 py-2.5 border rounded-lg"
            />
          </div>
          <div>
            <h2 className="text-lg font-medium mb-3">
              {i18n.language === "en" ? "Available Tutoring Sessions" : "الجلسات التعليمية المتاحة"}
            </h2>
            <div className="grid grid-cols-4 gap-3">
              {timeSlots.length === 0 ? (
                <p className="col-span-4 text-gray-500">No available slots.</p>
              ) : (
                timeSlots.map((slot, index) => (
                  <button
                    key={index}
                    disabled={slot.isBooked || (!isSlotSelectable(slot.time) && !currentSelectedTimes.includes(slot.time))}
                    onClick={() => handleTimeSelection(slot.time)}
                    className={`px-4 py-2 rounded-xl text-sm font-medium transition-colors duration-200
                      ${currentSelectedTimes.includes(slot.time)
                        ? "bg-[#008847] text-white hover:bg-[#006b38]"
                        : slot.isBooked || !isSlotSelectable(slot.time)
                          ? "bg-gray-200 text-gray-500 line-through opacity-70 cursor-not-allowed"
                          : "bg-[#0088471A] text-black hover:bg-[#00884733]"
                      }`}
                  >
                    {slot.isBooked ? "Booked" : slot.time}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl p-6 space-y-6">
          <X
            size={24}
            className={`absolute top-4 ${i18n.language == "ar" ? "left-4" : "right-4"} cursor-pointer`}
            onClick={() => setInstant(false)}
          />
          <h2 className="text-lg font-medium">{i18n.language === "en" ? "Booking Summary" : "ملخص الحجز"}</h2>
          <div className="space-y-2">
            <label className="block text-sm font-medium">{t("subject")}</label>
            <select
              value={bookingDetails.subject || ""}
              onChange={handleSubjectChange}
              className="w-full px-4 py-2.5 border rounded-lg"
            >
              <option value="">{t("popup_book_lesson_select_a_subject")}</option>
              {allSubjects.map((subject: any, index: number) => (
                <option key={index} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
          </div>
          {currentSelectedTimes.length > 0 && (
            <div className="mt-4">
              <h3 className="text-sm font-medium text-gray-700">
                {i18n.language === "en" ? "Selected Time Slots for" : "الفترات الزمنية المُختارة"}{" "}
                {DateTime.fromISO(selectedDate).toFormat("MMMM d, yyyy")}
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {Array.from(
                  { length: Math.floor(currentSelectedTimes.length / 2) },
                  (_, i) => (
                    <div
                      key={i}
                      className={`relative px-3 py-1 rounded-md text-sm flex items-center
                        ${timeSlots.find((slot) => slot.time === currentSelectedTimes[i * 2])?.isBooked
                          ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                          : "bg-green-100 text-green-700"
                        }`}
                    >
                      {getTimeRange(currentSelectedTimes[i * 2], currentSelectedTimes[i * 2 + 1])}
                      <X
                        size={16}
                        className="ml-2 cursor-pointer hover:text-red-500"
                        onClick={() => handleRemoveSlot(selectedDate, currentSelectedTimes[i * 2])}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-700">
              {i18n.language === "en" ? "All Selected Time Slots" : "جميع الفترات الزمنية المُختارة"}
            </h3>
            {Object.entries(selectedTimesByDate)
              .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime())
              .map(([date, times]) =>
                times.length > 0 ? (
                  <div key={date} className="mt-2">
                    <p className="text-sm font-medium text-gray-600">
                      {DateTime.fromISO(date).toFormat("MMMM d, yyyy")}
                    </p>
                    <div className="flex flex-wrap gap-2 mt-1">
                      {Array.from(
                        { length: Math.floor(times.length / 2) },
                        (_, i) => (
                          <div
                            key={i}
                            className="relative px-3 py-1 rounded-md text-sm flex items-center bg-green-100 text-green-700"
                          >
                            {getTimeRange(times[i * 2], times[i * 2 + 1])}
                            <X
                              size={16}
                              className="ml-2 cursor-pointer hover:text-red-500"
                              onClick={() => handleRemoveSlot(date, times[i * 2])}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ) : null
              )}
          </div>
          {!tutorData.is_triled && (
            <div className="flex items-center justify-between pt-4 border-t">
              <span className="text-sm font-medium">
                {i18n.language === "en" ? "Total Price (Including VAT)" : "المبلغ الإجمالي (شامل الضريبة المضافة)"}
              </span>
              <span className="font-medium">{t("AED")} {price}</span>
            </div>
          )}
          <div className="flex gap-3 pt-4">
            <button
              onClick={() => setInstant(false)}
              className="flex-1 px-4 py-2.5 bg-gray-200 text-gray-700 rounded-lg"
            >
              {t("buttons.cancel")}
            </button>
            <button
              disabled={!isBookingValid()}
              onClick={handleBooking}
              className={`flex-1 px-4 py-2.5 rounded-lg ${isBookingValid()
                ? "bg-green-600 text-white hover:bg-green-700"
                : "bg-gray-400 text-white cursor-not-allowed"
                }`}
            >
              {t("book_now")}
            </button>
          </div>
        </div>
      </div>
      {isSecondModalOpen && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            setActiveTab={setActiveTab}
            setShowModal={setInstant}
            setIsSecondModalOpen={setIsSecondModalOpen}
            price={price}
            selectedTimes={selectedTimesByDate}
            bookingDetails={bookingDetails}
            tutor={tutorData}
            time={time}
            setPaymentPage={setIsSecondModalOpen}
          />
        </Elements>
      )}
    </div>
  );
}