export default {
    "en": {
        "overview": "Overview",
        "dashboard": "Dashboard",
        "apply_for_permit": "Apply for Permit",
        "availability": "Availability",
        "jobs_board": "Jobs Board",
        "inbox": "Inbox",
        "bookings": "Bookings",
        "settings": "Settings",
        "view_profile": "View Profile",
        "account_settings": "Account Settings",
        "logout": "Logout",
        "jobs_applied": "Jobs you've applied for",
        "student": "Student",
        "request": "Request",
        "date": "Date",
        "status": "Status",
        "profile": "Profile",
        "hello_tutor": "Hello Tutors!",
        "hello_text": "Tutors with frequently updated calendars and fast response times are  receiving significantly more bookings. The more you interact with the platform, the higher your profile will appear in the search results.",
        "update_now": "Update now!",
        "my_profile": "My Profile",
        "apply_for_jobs": "Apply for Jobs",
        "apply_license": "Apply to the UAE private tutoring license",
        "private_tutoring_permit": "Private Tutoring Permit - UAE",
        "how_to_apply": "How to apply?",
        "where_apply": "Where do I apply?",
        "apply_online_now": "Apply Online Now",
        "about_permit": "About the permit",
        "permit_cost": "How much does it cost?",
        "who_eligible": "Who is eligible?",
        "required_documents": "What documents do you need?",
        "what_if_not_apply": "What if you don't apply?",
        "work_from_home": "Can you work from home?",
        "processing_time": "How long does it take to get the Private Tutor visa?",
        "reapply": "My application was declined, may I reapply?",
        "permit_validity": "How long is the permit valid for?",
        "permit_fees": "What are the permit fees?",
        "work_from_country": "If I get a license, can I work from my home country?",
        "online_classes": "Can I use this permit to take online classes?",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "save_availability": "Save Availability",
        "availability": "Save Availability",
        "about_the_permit_paragraph": "A permit has been introduced to protect the rights of private teachers and to “ensure students receive supplemental education that meets their learning style and needs\", said Muhammad Al Mualla, undersecretary for academic affairs at the Ministry of Education. The move is also intended to combat illegal private tuition. \n “The introduction of a permit for individuals qualified to provide private lessons will help curb illegal and unregulated practices when recruiting private teachers, which risk affecting the learning process as a whole,” Mr Al Mualla added.",
        "what_if_not_apply_paragraph": "Anyone offering private lessons without a permit will be subject to fines and penalties. The cost of these penalties has not been confirmed but, when private tuition was previously banned, educators could face fines as high as Dh 50,000.",
        "work_from_home_paragraph": "A single permit covers online and in-person tutoring. Educators can also work from their home country, provided they have a valid residency.",
        "basics": "Basics",
        "country": "Country",
        "school": "School",
        "experience": "Experience",
        "travel_time": "Travel Time",
        "qualifications": "Qualifications",
        "teaching_subjects": "Teaching Subjects",
        "pricing": "Pricing",
        "record_video": "Record a Video",
        "back": "Back",
        "change_picture": "Change Picture",
        "personal_information": "Personal Information",
        "first_name": "FIRST NAME",
        "last_name": "LAST NAME",
        "phone_number": "PHONE NUMBER",
        "email": "EMAIL",
        "tagline": "TAGLINE",
        "edit_school": "Edit School",
        "choose_school": "Please choose your school",
        "description": "Description",
        "experience_years": "Experience (Years)",
        "postcode": "Enter Your Postcode",
        "travel_to_lesson": "How long would you be happy to travel to do a face-to-face lesson?",
        "save_changes": "Save Changes",
        "add_qualification": "+Add Qualification",
        "institution": "Institution",
        "qualification_subject": "Qualification & Subject",
        "remove": "Remove",
        "subjects": "Subjects",
        "which_subjects": "Which subjects and levels would you like to teach?",
        "save_subjects": "Save Subjects",
        "hourly_rate": "Please enter your preferred hourly rate",
        "record_or_upload_video": "Record or Upload a Video",
        "video_preview": "Video Preview",
        "current_video": "Current Video",
        "start_recording": "Start Recording",
        "watch_demo_video": "Watch Demo Video",
        "upload_link": "Upload Link",
        "select_timezone": "Select your time zone",
        "bank_details_settings": "Bank Details Settings",
        "pay_to_nadwa": "Pay to Nadwa, Nadwa Pays me Monthly",
        "get_paid_stripe": "Get Paid Using my Stripe Account",
        "notification_settings": "Notification Settings",
        "new_message": "When I receive a new message",
        "lesson_cancel": "Customer cancels a lesson",
        "offers_news": "There's any offers or news from Nadwa",
        "sms_notification": "Send me an SMS when I receive a new message",
        "lesson_pending": "Lesson Pending",
        "new_enquiry": "When I receive a new enquiry",
        "lesson_accept_reject": "A customer accepts or rejects a lesson",
        "payment_received": "I am paid",
        "join_online_classroom": "Join Online Classroom",
        "you_have_already_recheduled": "You have already rescheduled",
        "tutoring_permit_where_d_i_apply": {
            "where_apply": "Where do I apply?",
            "apply_online_intro": "You can apply online. The process is simple:",
            "apply_step1": "1. Enter your Emirates ID number and then click on 'Send OTP' (One-time password).",
            "apply_step1_detail": "The system will then show you some of the details on your file with the Ministry, including your full name, nationality, date of birth and phone number.",
            "apply_step2": "2. You will then have four categories under which you can apply for the permit:",
            "apply_categories": [
                "Student category (university student/school student)",
                "Category of the unemployed",
                "Category of workers in different sectors",
                "A teacher registered in a public or private school"
            ],
            "apply_step3": "3. Select the category relevant to you and upload the required documents.",
            "apply_step4": "4. Download the 'Code of Conduct' document next, sign it and upload a digital copy to attach to your application.",
            "apply_step5": "5. Click on 'Submit'."
        },
        "how_long_does_it_take_to_get_the_private_tutor_visa": "The application will be processed within five working days.",
        "it_has_been_more_than_5_days_and_i_have_not_heard_anything": "You can reach out to MOHRE through their support website - or by calling them on 600 590000.",
        "my_application_was_declined_may_i_reapply": "Yes, but only after a period of six months.",
        "how_long_is_the_permit_valid_for": "Once your application is approved, your permit will be valid for two years, and needs to be renewed accordingly.",
        "what_are_the_permit_fees": "The permit application is free of cost.",
        "if_i_get_a_license_can_i_work_from_my_home_country": "According to MOHRE, this is also an option available to residents, provided that your UAE residence visa is valid, and you comply with the agreements between the two parties.",
        "can_i_use_this_permit_to_take_online_classes": "According to MOHRE, this permit covers both online and in-person activities.",
        "what_documents_do_you_need": {
            "intro": "Documents required vary depending on your current position and status.",
            "sections": {
                "A": {
                    "title": "If you're a student (university student/school student) you need:",
                    "items": [
                        "A certificate of continuity of study for a student in the secondary stage/indicating that the student is enrolled at the university",
                        "The student's last academic certificate",
                        "No Objection Certificate (NOC) from the guardian",
                        "Certificate of good conduct",
                        "Medical fitness certificate",
                        "Valid identification documents (passport/residence visa/Emirates ID)",
                        "A clear personal photo with a white background"
                    ]
                },
                "B": {
                    "title": "If you're unemployed and are not a student you need:",
                    "items": [
                        "Latest academic degree",
                        "Certificate of good conduct",
                        "Valid identification documents (passport/residence visa/Emirates ID)",
                        "Medical fitness certificate",
                        "Experience certificate (if applicable)",
                        "A clear personal photo with a white background"
                    ]
                },
                "C": {
                    "title": "If you're employed but don't work in education directly:",
                    "items": [
                        "Latest academic degree",
                        "Certificate of good conduct",
                        "No Objection Certificate (NOC) from the employer",
                        "Medical fitness certificate",
                        "Experience certificate (if applicable)",
                        "Valid identification documents (passport/residence visa/Emirates ID)",
                        "A clear personal photo with a white background"
                    ]
                },
                "D": {
                    "title": "If you're a teacher registered in a public or private school:",
                    "items": [
                        "Certificate of good conduct",
                        "No Objection Certificate (NOC) from the employer",
                        "Medical fitness certificate",
                        "Experience certificate (if any)",
                        "Valid identification documents (passport/residence visa/Emirates ID)",
                        "A clear personal photo with a white background"
                    ]
                }
            }
        },
        "how_much_does_it_cost": "The permit is free and is valid for two years, after which holders can apply for it to be renewed. Rather than a fee, educators are asked to sign a code of conduct document approved by the Ministry of Human Resources and Emiratisation.",
        "who_is_eligible": "The new permit allows \"different groups of specialised and qualified professionals from the community to offer private lessons for students, individually or in groups,\" the Ministry of Human Resources and Emiratisation says.",
        "who_can_apply": {
            "description": "It is open to all over-15s to apply, subject to approval:",
            "list": [
                "Registered teachers in government or private schools",
                "Employees in the government and private sectors",
                "Unemployed individuals",
                "School students aged 15 to 18",
                "University students"
            ]
        },
        // Added missing keys from thinking trace
        "view": "View",
        "awaiting": "Awaiting",

        // Interactions
        "request_a_tutor": "Request A Tutor",
        "choose_subject_and_level": "Choose Subject and Level",
        "intro_text": "At Nadwa we know how hard it is to find the right tutor. So we decided to make it easy! It’s 3 simple steps you can book for Free Text Lesson with a tutor to make sure they are the right fit for you. Let's start with choosing your subject and level.",
        "all_levels": "All Levels",
        "levels": ["7+", "Graduate", "Post Graduate", "Primary Level", "A-Level", "GCSE/IGCSE", "IB", "Primary (KS1, KS2)", "Secondary (KS3, KS4)", "KS5", "Cycle 1 (Primary, Grade 1-5)", "Cycle 2 (Preparatory, Grade 6-9)", "Cycle 3 (Secondary, Grade 10-12)", "Undergraduate", "Postgraduate"],
        "buttons": {
            "next": "Next",
            "cancel": "Cancel",
            "back": "Back"
        },
        "reject_lesson": "Reject Lesson",
        "reject_text": "Please enter a reason for rejecting the lesson.",
        "choose_budget": "Choose Budget (Drag the slider to select)",
        "price_range": "Price Range Per Hour (AED)",
        "free_trial": "Free 'Best Fit' Trial Lesson",
        "availability": "When are you available?",
        "days": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        "select_all": "Select All",
        "clear": "Clear",
        "times_of_day": ["Morning", "Afternoon", "Evening"],
        "tailor_match": "Tailor your tutor match",
        "tags": ["Aiming for 'A' grade", "Exam help", "Dyslexia", "SEN", "Last-minute revision", "Essay writing skills", "Sibling support", "Coursework support", "Outgoing", "Rational", "Sensitive", "Efficient", "Emotional"],
        "go_to_dashboard": "Go to Dashboard",
        "subsections": {
            "A_Art": {
                "title": "Art",
                "items": ["Politics", "Philosophy", "Psychology"]
            },
            "B_Languages": {
                "title": "Languages",
                "items": ["Science", "Spanish", "Greek", "Coding", "Chemistry", "Business Management", "Arabic", "Accounts", "Computer", "Computer Science", "Economics", "Finances", "French", "History", "German", "Income Tax Law", "Islamic Education", "Italian", "Java", "Law", "Management", "Marketing", "Maths", "Personal Development", "Physics", "Accounting", "English", "Social Sciences", "Chinese", "Mandarin", "Biology", "Geography", "Korean", "Turkish"]
            },
            "C_Music": {
                "title": "Music",
                "items": ["Music Practical", "Music Theory"]
            },
            "D_Sport": {
                "title": "Sport",
                "items": ["Yoga"]
            }
        },

        // Tutor_Side_Page
        "tutor_application": "Your Application For This Job",
        "tutor_description": "Our unique Job Posting function allows tutors to apply for jobs that suit them. Find & connect with students easily with Nadwa.",
        "send_message": "Send Message",
        "message_placeholder": "Type your message here",
        "back": "Back",
        "confirm_heading": "You are confirming a Free 15-minute trial call with your student.",
        "confirm_note": "*Note that the window for this call is 30 minutes.",
        "confirm": "Confirm",
        "confirm_trial": "Confirm Trial Lesson",
        "job_text_1": "Responding to the customer quickly; customers love tutors who get back to them ASAP.",
        "job_text_2": "Improve your profile; make sure you sell your experience and past success stories.",
        "job_text_3": "Make sure your photo looks great and your strap-line is informative.",
        "submit_application": "Submit Job Application",
        "jobs_board_info": "Customers post 100s of jobs every week with Nadwa, so we encourage you to apply to as many jobs as you would like. Once you apply for a job, your profile will be sent to the customer, who will then message you if they wish to work with you. Just so you know, up to five other tutors can apply to the same job (so don’t be discouraged if you don’t hear back from the client). To improve your chances of being selected, we recommend: Responding to the customer quickly; customers love tutors who get back to them ASAP. Improve your profile; make sure you sell your experience and past success stories. Make sure your photo looks great and your strap-line is informative.",
        "tutor_availability": "Availability",
        "available": "Available",
        "time_off": "Time off",
        "apply_job": "Apply for this job",
        "min_price": "MIN PRICE /hour",
        "max_price": "MAX PRICE /hour",
        "apply_for_job": "Apply for Job",
        "reschedule_lesson": "Reschedule lesson with",
        "reschedule_limitation": "Important: You can only reschedule this lesson once and it must be within 30days from today.",
        "select_date": "Select Date",
        "start_time": "Start Time",
        "end_time": "End Time",
        "cancel": "Cancel",
        "reschedule": "Reschedule",
        "book_lesson_student": "Student",
        "select_students": "Select from your students",
        "subject": "Subject",
        "select_subject": "Select a subject",
        "select_a_date": "Select a date",
        "select_duration": "Select Duration",
        "one_hour": "1 Hour",
        "ninety_minutes": "90 Minutes",
        "two_hours": "2 Hours",
        "number_weeks": "Number of weeks (Same time/day every week)",
        "number_lessons": "Number of Lessons",
        "select_start_time": "Select Start Time",
        "select_end_time": "Select End Time",
        "book_lesson": "Book Lesson",
        "cancel_lesson": "Cancel Lesson with",
        "cancel_lesson_action": "Cancel Lesson"
    },
    "ar": {
        "overview": "نظرة عامة",
        "dashboard": "لوحة التحكم",
        "apply_for_permit": "التقدم بطلب للحصول على تصريح",
        "availability": "التوفر",
        "jobs_board": "لوحة الوظائف",
        "inbox": "صندوق الوارد",
        "bookings": "الحجوزات",
        "settings": "الإعدادات",
        "view_profile": "عرض الملف الشخصي",
        "account_settings": "إعدادات الحساب",
        "logout": "تسجيل الخروج",
        "jobs_applied": "الوظائف التي تقدمت لها",
        "student": "طالب",
        "request": "طلب",
        "date": "التاريخ",
        "status": "الحالة",
        "profile": "الملف الشخصي",
        "my_profile": "ملفي الشخصي",
        "apply_for_jobs": "التقدم للوظائف",
        "apply_license": "التقدم بطلب للحصول على رخصة التدريس الخصوصي في دولة الإمارات العربية المتحدة",
        "private_tutoring_permit": "تصريح التدريس الخصوصي",
        "how_to_apply": "كيف تقدم؟",
        "where_apply": "أين أقدم؟",
        "apply_online_now": "قدم الآن عبر الإنترنت",
        "about_permit": "حول التصريح",
        "permit_cost": "كم يكلف؟",
        "who_eligible": "من المؤهل؟",
        "hello_tutor": "مرحباً بالأساتذة!",
        "hello_text": "الأساتذة الذين يُحدثون جداولهم بانتظام ويستجيبون بسرعة يحصلون على حجوزات أكثر بكثير. كلما تفاعلت مع المنصة، زادت فرص ظهور ملفك الشخصي في نتائج البحث.",
        "update_now": "تحديث الآن",
        "required_documents": "ما هي المستندات المطلوبة؟",
        "what_if_not_apply": "ماذا لو لم تتقدم؟",
        "work_from_home": "هل يمكنك العمل من المنزل؟",
        "processing_time": "كم من الوقت يستغرق الحصول على تأشيرة المعلم الخاص؟",
        "reapply": "تم رفض طلبي، هل يمكنني إعادة التقديم؟",
        "permit_validity": "كم مدة صلاحية التصريح؟",
        "permit_fees": "ما هي رسوم التصريح؟",
        "work_from_country": "إذا حصلت على ترخيص، هل يمكنني العمل من بلدي؟",
        "online_classes": "هل يمكنني استخدام هذا التصريح لإعطاء دروس عبر الإنترنت؟",
        "monday": "الاثنين",
        "tuesday": "الثلاثاء",
        "wednesday": "الأربعاء",
        "thursday": "الخميس",
        "friday": "الجمعة",
        "saturday": "السبت",
        "sunday": "الأحد",
        "save_availability": "حفظ التوفر",
        "availability": "التوفر",
        "about_the_permit_paragraph": "تم إتاحة تصريح لحماية حقوق المعلمين الخصوصيين ولـ\"ضمان حصول الطلاب على التعليم الإضافي الذي يناسب أسلوب تعلمهم واحتياجاتهم\"، بحسب ما قال محمد المعلا، وكيل وزارة التربية والتعليم للشؤون الأكاديمية. ويهدف القرار أيضًا إلى مكافحة الدروس الخصوصية غير القانونية. وأضاف المعلا: \"سيساعد تقديم تصريح للأفراد المؤهلين لتقديم الدروس الخصوصية في الحد من الممارسات غير القانونية وغير المنظمة عند تعيين المعلمين الخصوصيين، مما قد يؤثر على العملية التعليمية ككل\".",
        "what_if_not_apply_paragraph": "أي شخص يقدم دروسًا خصوصية دون تصريح سيكون عرضة للغرامات والعقوبات. لم يتم تأكيد قيمة هذه الغرامات بعد، ولكن عندما كانت الدروس الخصوصية محظورة سابقًا، كان يمكن للمعلمين مواجهة غرامات تصل إلى 50,000 درهم.",
        "work_from_home_paragraph": "يغطي التصريح الواحد التدريس عبر الإنترنت وشخصيًا. يمكن للمعلمين أيضًا العمل من بلدهم الأصلي بشرط أن يكون لديهم إقامة سارية المفعول.",
        "basics": "الأساسيات",
        "country": "الدولة",
        "school": "المدرسة",
        "experience": "الخبرة",
        "travel_time": "مدة السفر",
        "qualifications": "المؤهلات",
        "teaching_subjects": "المواد التي يتم تدريسها",
        "pricing": "التسعير",
        "record_video": "تسجيل فيديو",
        "back": "رجوع",
        "confirm_heading": "تأكيد مكالمة تجريبية مجانية لمدة 15 دقيقة معك.",
        "confirm_note": "ملاحظة: نافذة هذه المكالمة هي 30 دقيقة",
        "confirm": "تأكيد",
        "confirm_trial": "تأكيد الدرس التجريبي",
        "job_text_1": "الرد على العملاء بسرعة؛ العملاء يحبون المدربين الذين يردون عليهم بأسرع ما يمكن.",
        "job_text_2": "تحسين ملفك الشخصي؛ تأكد من تسويق خبرتك وقصص نجاحك السابقة.",
        "job_text_3": "تأكد من أن صورتك تبدو رائعة وأن شعارك (الخط العريض) يحتوي على معلومات مفيدة.",
        "change_picture": "تغيير الصورة",
        "personal_information": "المعلومات الشخصية",
        "first_name": "الاسم الأول",
        "last_name": "اسم العائلة",
        "phone_number": "رقم الهاتف",
        "email": "البريد الإلكتروني",
        "tagline": "الشعار",
        "edit_school": "تعديل المدرسة",
        "choose_school": "يرجى اختيار مدرستك",
        "description": "الوصف",
        "experience_years": "الخبرة (بالسنوات)",
        "postcode": "أدخل الرمز البريدي",
        "travel_to_lesson": "ما المدة التي ترغب في السفر خلالها لإجراء درس حضوري؟",
        "save_changes": "حفظ التغييرات",
        "add_qualification": "+إضافة مؤهل",
        "institution": "المؤسسة",
        "qualification_subject": "المؤهل والمادة",
        "remove": "إزالة",
        "subjects": "المواد",
        "which_subjects": "ما المواد والمستويات التي ترغب في تدريسها؟",
        "save_subjects": "حفظ المواد",
        "hourly_rate": "يرجى إدخال السعر المفضل للساعة",
        "record_or_upload_video": "تسجيل أو رفع فيديو",
        "video_preview": "معاينة الفيديو",
        "current_video": "الفيديو الحالي",
        "start_recording": "بدء التسجيل",
        "watch_demo_video": "مشاهدة فيديو توضيحي",
        "upload_link": "رفع الرابط",
        "select_timezone": "اختر منطقتك الزمنية",
        "bank_details_settings": "إعدادات تفاصيل البنك",
        "pay_to_nadwa": "أدفع لندوة، وندوة تدفع لي شهرياً",
        "get_paid_stripe": "استلام المدفوعات عبر حساب Stripe الخاص بي",
        "notification_settings": "إعدادات الإشعارات",
        "new_message": "عند استلام رسالة جديدة",
        "lesson_cancel": "عندما يلغي العميل درسًا",
        "offers_news": "عند وجود عروض أو أخبار من ندوة",
        "sms_notification": "أرسل لي رسالة نصية عند استلام رسالة جديدة",
        "lesson_pending": "الدرس معلق",
        "new_enquiry": "عند استلام استفسار جديد",
        "lesson_accept_reject": "عندما يقبل أو يرفض العميل درسًا",
        "payment_received": "تم الدفع لي",
        "join_online_classroom": "انضم إلى الفصل الدراسي عبر الإنترنت",
        "you_have_already_recheduled": "لقد قمت بإعادة جدولة بالفعل",
        "tutoring_permit_where_d_i_apply": {
            "where_apply": "أين أتقدم بطلب؟",
            "apply_online_intro": "يمكنك التقديم عبر الإنترنت. العملية بسيطة:",
            "apply_step1": "1. أدخل رقم بطاقة الهوية الإماراتية الخاصة بك ثم انقر على 'إرسال رمز OTP' (كلمة المرور لمرة واحدة).",
            "apply_step1_detail": "سيظهر النظام بعد ذلك بعض التفاصيل الموجودة في ملفك لدى الوزارة، بما في ذلك اسمك الكامل، الجنسية، تاريخ الميلاد ورقم الهاتف.",
            "apply_step2": "2. سيكون لديك بعد ذلك أربع فئات يمكنك التقديم تحتها للحصول على التصريح:",
            "apply_categories": [
                "فئة الطلاب (طالب جامعي/طالب مدرسة)",
                "فئة العاطلين عن العمل",
                "فئة العاملين في قطاعات مختلفة",
                "معلم مسجل في مدرسة عامة أو خاصة"
            ],
            "apply_step3": "3. اختر الفئة المناسبة لك وقم بتحميل المستندات المطلوبة.",
            "apply_step4": "4. قم بتحميل مستند 'مدونة السلوك' التالي، وقّعه وقم بتحميل نسخة رقمية لإرفاقها مع طلبك.",
            "apply_step5": "5. انقر على 'إرسال'."
        },
        "how_long_does_it_take_to_get_the_private_tutor_visa": "سيتم معالجة الطلب خلال خمسة أيام عمل.",
        "it_has_been_more_than_5_days_and_i_have_not_heard_anything": "يمكنك التواصل مع وزارة الموارد البشرية والتوطين عبر موقع الدعم الخاص بهم - أو بالاتصال على الرقم 600590000.",
        "my_application_was_declined_may_i_reapply": "نعم، ولكن فقط بعد مرور ستة أشهر.",
        "how_long_is_the_permit_valid_for": "بمجرد الموافقة على طلبك، سيكون التصريح صالحًا لمدة عامين، ويجب تجديده وفقًا لذلك.",
        "what_are_the_permit_fees": "طلب التصريح مجاني تمامًا.",
        "if_i_get_a_license_can_i_work_from_my_home_country": "وفقًا لوزارة الموارد البشرية والتوطين، هذا الخيار متاح أيضًا للمقيمين بشرط أن تكون تأشيرتك الإماراتية سارية المفعول، وأن تلتزم بالاتفاقيات بين الطرفين.",
        "can_i_use_this_permit_to_take_online_classes": "وفقًا لوزارة الموارد البشرية والتوطين، يغطي هذا التصريح الدروس عبر الإنترنت والحضور الشخصي.",
        "what_documents_do_you_need": {
            "intro": "تختلف المستندات المطلوبة حسب وضعك الحالي وحالتك.",
            "sections": {
                "A": {
                    "title": "إذا كنت طالبًا (طالب جامعي/طالب مدرسي) تحتاج إلى:",
                    "items": [
                        "شهادة استمرارية الدراسة لطالب في المرحلة الثانوية/توضيح أن الطالب مسجل في الجامعة",
                        "آخر شهادة أكاديمية للطالب",
                        "شهادة عدم ممانعة من ولي الأمر",
                        "شهادة حسن سيرة وسلوك",
                        "شهادة اللياقة الطبية",
                        "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
                        "صورة شخصية واضحة بخلفية بيضاء"
                    ]
                },
                "B": {
                    "title": "إذا كنت عاطلًا عن العمل ولست طالبًا تحتاج إلى:",
                    "items": [
                        "أحدث شهادة أكاديمية",
                        "شهادة حسن سيرة وسلوك",
                        "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
                        "شهادة اللياقة الطبية",
                        "شهادة خبرة (إذا كانت متوفرة)",
                        "صورة شخصية واضحة بخلفية بيضاء"
                    ]
                },
                "C": {
                    "title": "إذا كنت موظفًا ولا تعمل في مجال التعليم بشكل مباشر:",
                    "items": [
                        "أحدث شهادة أكاديمية",
                        "شهادة حسن سيرة وسلوك",
                        "شهادة عدم ممانعة من جهة العمل",
                        "شهادة اللياقة الطبية",
                        "شهادة خبرة (إذا كانت متوفرة)",
                        "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
                        "صورة شخصية واضحة بخلفية بيضاء"
                    ]
                },
                "D": {
                    "title": "إذا كنت معلمًا مسجلًا في مدرسة حكومية أو خاصة:",
                    "items": [
                        "شهادة حسن سيرة وسلوك",
                        "شهادة عدم ممانعة من جهة العمل",
                        "شهادة اللياقة الطبية",
                        "شهادة خبرة (إن وجدت)",
                        "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
                        "صورة شخصية واضحة بخلفية بيضاء"
                    ]
                }
            }
        },
        "how_much_does_it_cost": "التصريح مجاني وصالح لمدة عامين، وبعد ذلك يمكن لحامليه التقدم بطلب لتجديده. بدلاً من دفع رسوم، يُطلب من المعلمين التوقيع على وثيقة قواعد السلوك المعتمدة من وزارة الموارد البشرية والتوطين.",
        "who_is_eligible": "يتيح التصريح الجديد \"لمجموعات مختلفة من المهنيين المتخصصين والمؤهلين من المجتمع تقديم دروس خصوصية للطلاب، بشكل فردي أو ضمن مجموعات\"، وفقًا لوزارة الموارد البشرية والتوطين.",
        "who_can_apply": {
            "description": "يمكن لأي شخص فوق سن 15 عامًا التقديم، وذلك بعد الحصول على الموافقة:",
            "list": [
                "المعلمون المسجلون في المدارس الحكومية أو الخاصة",
                "الموظفون في القطاعين الحكومي والخاص",
                "الأفراد العاطلون عن العمل",
                "طلاب المدارس الذين تتراوح أعمارهم بين 15 و18 عامًا",
                "طلاب الجامعات"
            ]
        },
        "view": "منظر",
        "awaiting": "انتظار",

        // Interactions

        "request_a_tutor": "طلب معلم",
        "choose_subject_and_level": "اختر المادة والمستوى",
        "intro_text": "في Nadwa، ندرك مدى صعوبة العثور على المعلم المناسب. لذلك قررنا جعل الأمر سهلاً! في 3 خطوات بسيطة، يمكنك حجز درس نصي مجاني مع معلم للتأكد من أنه مناسب لك. لنبدأ باختيار المادة والمستوى.",
        "all_levels": "جميع المستويات",
        "levels": ["7+", "الخريجين", "الدراسات العليا", "المرحلة الابتدائية", "المستوى A", "GCSE/IGCSE", "البكالوريا الدولية", "المرحلة الابتدائية (KS1, KS2)", "المرحلة الثانوية (KS3, KS4)", "KS5", "الدورة 1 (ابتدائي، الصف 1-5)", "الدورة 2 (إعدادي، الصف 6-9)", "الدورة 3 (ثانوي، الصف 10-12)", "البكالوريوس", "الدراسات العليا"],
        "buttons": {
            "next": "التالي",
            "cancel": "إلغاء",
            "back": "رجوع"
        },
        "reject_lesson": "رفض الدرس",
        "reject_text": "أرفض الدرس لعدم وجود مواد كافية",
        "choose_budget": "اختر الميزانية (اسحب الشريط للتحديد)",
        "price_range": "نطاق السعر لكل ساعة (درهم إماراتي)",
        "free_trial": "درس تجريبي مجاني 'أنسب اختيار'",
        "availability": "متى تكون متاحًا؟",
        "days": ["الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت", "الأحد"],
        "select_all": "تحديد الكل",
        "clear": "مسح",
        "times_of_day": ["الصباح", "بعد الظهر", "المساء"],
        "tailor_match": "خصص معلمك المثالي",
        "tags": ["استهداف درجة 'A'", "مساعدة في الامتحانات", "عسر القراءة", "الاحتياجات الخاصة", "مراجعة اللحظات الأخيرة", "مهارات كتابة المقالات", "دعم الأشقاء", "دعم الأعمال الدراسية", "اجتماعي", "منطقي", "حساس", "فعال", "عاطفي"],
        "go_to_dashboard": "الانتقال إلى لوحة التحكم",
        "subsections": {
            "A_Art": {
                "title": "الفنون",
                "items": ["السياسة", "الفلسفة", "علم النفس"]
            },
            "B_Languages": {
                "title": "اللغات",
                "items": ["العلوم", "الإسبانية", "اليونانية", "البرمجة", "الكيمياء", "إدارة الأعمال", "العربية", "المحاسبة", "الحاسوب", "علوم الحاسوب", "الاقتصاد", "التمويل", "الفرنسية", "التاريخ", "الألمانية", "قانون الضرائب", "التربية الإسلامية", "الإيطالية", "جافا", "القانون", "الإدارة", "التسويق", "الرياضيات", "التطوير الشخصي", "الفيزياء", "المحاسبة", "الإنجليزية", "العلوم الاجتماعية", "الصينية", "الماندرين", "الأحياء", "الجغرافيا", "الكورية", "التركية"]
            },
            "C_Music": {
                "title": "الموسيقى",
                "items": ["التطبيقات الموسيقية", "النظريات الموسيقية"]
            },
            "D_Sport": {
                "title": "الرياضة",
                "items": ["اليوغا"]
            }
        },

        // Tutor_Side_Page
        "tutor_application": "طلبك لهذا الوظيفة",
        "tutor_description": "وظيفة النشر الفريدة لدينا تسمح للمعلمين بالتقدم للوظائف التي تناسبهم. ابحث واتصل بالطلاب بسهولة من خلال نادوا.",
        "send_message": "إرسال رسالة",
        "message_placeholder": "اكتب رسالتك هنا",
        "back": "رجوع",
        "submit_application": "إرسال طلب الوظيفة",
        "jobs_board_info": "يقوم العملاء بنشر مئات الوظائف كل أسبوع مع نادوا، لذلك نشجعك على التقدم لأكبر عدد ممكن من الوظائف التي ترغب فيها. بمجرد أن تتقدم لوظيفة، سيتم إرسال ملفك الشخصي إلى العميل، الذي سيقوم بالتواصل معك إذا رغب في العمل معك. فقط لعلمك، يمكن لما يصل إلى خمسة معلمين آخرين التقدم لنفس الوظيفة (لذلك لا تيأس إذا لم تتلقى ردًا من العميل). لتحسين فرص اختيارك، نوصي بما يلي: الرد سريعًا على العميل؛ العملاء يحبون المعلمين الذين يعودون إليهم في أسرع وقت ممكن. قم بتحسين ملفك الشخصي؛ تأكد من تسويق خبرتك وقصص نجاحك السابقة. تأكد من أن صورتك تبدو رائعة وأن شعارك مفيد.",
        "tutor_availability": "التوافر",
        "available": "متاح أو متوفر",
        "time_off": "وقت إجازة",
        "apply_job": "التقدم لهذه الوظيفة",
        "min_price": "الحد الأدنى للسعر / ساعة",
        "max_price": "الحد الأقصى للسعر / ساعة",
        "apply_for_job": "التقدم للوظيفة",
        "reschedule_lesson": "عادة جدولة الدرس مع",
        "reschedule_limitation": "مهم: يمكنك إعادة جدولة هذه الدرس مرة واحدة فقط، ويجب أن تكون في غضون 30 يومًا من اليوم.",
        "select_date": "اختر التاريخ",
        "start_time": "وقت البدء",
        "end_time": "وقت الانتهاء",
        "cancel": "إلغاء",
        "reschedule": "إعادة الجدولة",
        "book_lesson_student": "طالب",
        "select_students": "اختر من بين طلابك",
        "subject": "المادة",
        "select_subject": "اختر مادة",
        "select_a_date": "اختر تاريخ",
        "select_duration": "اختر المدة",
        "one_hour": "ساعة واحدة",
        "ninety_minutes": "90 دقيقة",
        "two_hours": "ساعتان",
        "number_weeks": "عدد الأسابيع (نفس الوقت/اليوم كل أسبوع)",
        "number_lessons": "عدد الدروس",
        "select_start_time": "اختر وقت البدء",
        "select_end_time": "اختر وقت الانتهاء",
        "book_lesson": "حجز الدرس",
        "cancel_lesson": "إلغاء الدرس مع",
        "cancel_lesson_action": "إلغاء الدرس"
    }
}