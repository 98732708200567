import { Link } from 'react-router-dom';
import fifthBg from '../../images/Homepageassets/Testimonial-Background.png';
import CarouselSlider from '../ui/CarouselSlider';
import { useTranslation } from 'react-i18next';

export default function Section5() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <section
      id="testimonials"
      className="relative w-full bg-gradient-to-r from-green-600 via-green-300 to-green-200 pb-16 md:pb-28"
      style={{
        backgroundImage: `url(${fifthBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Done Mechanism  */}
      {/* Header Section */}
      <header className="text-center py-8">
        <div className="flex justify-center items-center gap-2 mb-3">
          <div className="w-2.5 -mt-1 h-2.5 bg-[#ed1d24] rounded-full"></div>
          <h6 className="text-[#ed1d24] text-lg font-semibold uppercase tracking-wider">
            {t('testimonial')}
          </h6>
        </div>
        <h1 className="text-black text-4xl md:text-5xl font-bold leading-tight">
          {t('testimonial2')}
        </h1>
      </header>

      {/* Slider Section */}
      <div className="flex flex-col items-center gap-6 px-4 md:px-10">
        <CarouselSlider />

        <h2 className="text-center text-black text-3xl md:text-4xl font-semibold leading-snug max-w-3xl">
          {t('testimonial3')}
        </h2>
        <p className="text-center text-[#12182d] text-lg md:text-xl leading-relaxed max-w-2xl">
          {t('testimonial4')}
        </p>

        {/* Call-to-Action Buttons */}
        <div className="flex flex-wrap justify-center items-center gap-4 mt-6">
          <Link to='/SignupTutor'>
            <button className="px-6 py-3 bg-[#ed1d24] text-white font-bold rounded-full hover:bg-black transition duration-200">
            {t('testimonialbt1')}
            </button>
          </Link>
          <Link to='/SignupStudent'>
            <button className="px-6 py-3 bg-black text-white font-bold rounded-full hover:bg-[#ed1d24] transition duration-200">
            {t('testimonialbt2')}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
