import React, { useState } from "react";
import { X } from "lucide-react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BookingPopup = ({
  onClose,
  bookingData,
  data,
  onCloseBack,
  setUpdateUi,
  updateUi,
  timeZone,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAllDates, setShowAllDates] = useState(false);

  const handleToggleDates = () => {
    setShowAllDates((prevState) => !prevState);
  };

  if (!bookingData) {
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-65 flex items-center justify-center p-4">
        <div className="bg-white p-5 rounded-lg">Loading...</div>
      </div>
    );
  }

  // Calculate lesson dates based on the first lesson's start and end times.
  const firstStartTime = new Date(bookingData.start_time);
  const firstEndTime = new Date(bookingData.end_time);
  const durationMs = firstEndTime.getTime() - firstStartTime.getTime();

  const lessonDates = Array.from({ length: bookingData.num_weeks }, (_, i) => {
    const lessonStart = new Date(
      firstStartTime.getTime() + i * 7 * 24 * 60 * 60 * 1000
    );
    const lessonEnd = new Date(lessonStart.getTime() + durationMs);
    return { start_time: lessonStart, end_time: lessonEnd };
  });

  // Formatter functions using Intl.DateTimeFormat with the specified time zone.
  const formatDateTime = (date: Date) => {
    return new Intl.DateTimeFormat("en-US", {
      timeZone,
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(date);
  };

  const formatTime = (date: Date) => {
    return new Intl.DateTimeFormat("en-US", {
      timeZone,
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }).format(date);
  };

  const sendInvitation = async () => {
    setIsLoading(true);

    try {
      const payload = {
        customer: bookingData.customer,
        location: bookingData.location || "online",
        subject: bookingData.subject,
        tutor: bookingData.tutor,
        num_weeks: bookingData.num_weeks,
        start_time: bookingData.start_time, // First lesson start time
        end_time: bookingData.end_time,     // First lesson end time
      };

      await axios.post(
        "https://api.nadwa-uae.com/api/v2/tutor/invitations/",
        payload,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      toast.success("Lesson booked successfully!");
      setTimeout(() => {
        onClose();
        if (setUpdateUi) {
          setUpdateUi(!updateUi);
        }
        if (onCloseBack) {
          onCloseBack();
        }
      }, 2000);
    } catch (error: any) {
      console.error("Error booking lesson:", error);
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.non_field_errors?.[0] ||
        "Failed to book lesson.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0 bg-gray-800 bg-opacity-65 flex flex-col md:flex-row items-center justify-center p-4">
        <div className="w-full max-w-5xl bg-white relative p-3 sm:p-5 h-[96%] sm:h-fit overflow-y-scroll">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>

          <div>
            <h1 className="text-2xl text-center">
              Book a lesson{" "}
              {data && <span>with {data.customer.first_name}</span>}
            </h1>
          </div>

          <div>
            <div className="flex flex-col md:flex-row items-center gap-4">
              <div className="md:w-[50%]">
                <h2 className="text-base sm:text-xl font-semibold mb-4">
                  {bookingData.num_weeks} Lesson Package Confirmation
                </h2>

                <div className="border rounded-lg p-6 mb-6">
                  <div className="flex flex-col space-y-4 h-[180px] overflow-y-scroll">
                    {lessonDates
                      .slice(0, showAllDates ? lessonDates.length : 1)
                      .map((lesson, index) => (
                        <div key={index}>
                          <span className="text-pink-500 font-medium">
                            Lesson {index + 1}
                          </span>
                          <div className="text-gray-600">
                            {formatDateTime(lesson.start_time)} -{" "}
                            {formatTime(lesson.end_time)}
                          </div>
                        </div>
                      ))}

                    {lessonDates.length > 1 && (
                      <button
                        onClick={handleToggleDates}
                        className="text-blue-600 underline text-center"
                      >
                        {showAllDates ? "Show fewer dates" : "Show all dates"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-blue-50 p-6 rounded-lg space-y-4 md:w-[50%]">
                <h3 className="text-lg font-medium text-gray-700">Details</h3>
                <p className="text-gray-600">
                  You're sending the customer a package of{" "}
                  {bookingData.num_weeks} lessons. This will remain at the same
                  time and day every week for the selected period.
                </p>
                <p className="text-gray-600">
                  You will be paid every week - directly into your bank account.
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-y-4 sm:flex-row justify-between sm:mt-0 mt-4">
              <button onClick={onClose} className="px-8 border rounded-md">
                Back
              </button>
              <button
                onClick={sendInvitation}
                disabled={isLoading}
                className="px-8 py-2 bg-[#008847] rounded-md hover:bg-green-700 text-white"
              >
                {isLoading ? "Booking..." : "Book Lesson"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingPopup;
