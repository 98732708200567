import axios from "axios";
import { Info } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer, Bounce } from "react-toastify";
import i18n from "../../i18n";

export default function ApplicationModal2({ isOpen, onClose, onSubmit, tutor }: any) {
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  if (!isOpen) return null;

  const sendMessage = async () => {
    try {
      const res = await axios.post(`https://api.nadwa-uae.com/api/v3/customer/chat/tutor/${tutor.id}/`, {
        create_job_post: false,
        level_id: tutor.selected_subject.level,
        subject_id: tutor.selected_subject.subject,
        tutoring_type: tutor.online_tutoring ? "online" : "offline"
      }, { headers: { Authorization: `Token ${localStorage.getItem('TH_AUTH_TOKEN')}` } })
      return res.data.chat_id;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (id: number) => {
    if (!id || !message) return;

    try {
      await axios.post(
        "https://api.nadwa-uae.com/api/v2/chat/messages/",
        {
          chat: id,
          content: message,
          socket_id: "",
          type: "text",
        },
        {
          headers: { Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}` },
        }
      );
      toast.success("Message sent successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          onClose();
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Failed to send message. Try again", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          // Call the onClose function after the toast is closed
          onClose();
        }
      });
    }
  };

  const handleSendApplication = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form submission to avoid unnecessary page refresh

    const id = await sendMessage();
    if (id) {
      await handleSubmit(id);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4 z-50">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="bg-white rounded-2xl max-w-md w-full p-6">
        <h2 className="text-xl font-medium mb-2">{t("popup_apply_for_job_title")}</h2>
        <p className="text-gray-600 text-sm mb-6">
          {i18n.language == "en" ? "Find & connect with students easily with Nadwa." : "ابحث عن الطلاب بسهولة وارتبط بهم في ندوة."}
        </p>

        {/* Message Form */}
        <form onSubmit={handleSendApplication}>
          <div className="">
            <select className="w-full p-2 border border-gray-200 rounded-lg mb-4">
              {
                tutor.subjects.map((sub: any) => <option value={sub.id} key={sub.id}>{sub.name} {sub.level_name}</option>)
              }
            </select>
          </div>
          <div className="mb-6">
            <div className="flex items-center gap-2 mb-2">
              <label htmlFor="message" className="text-sm font-medium">
                {t("popup_apply_for_job_send_message")}
              </label>
              <Info className="w-4 h-4 text-gray-400" />
            </div>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full h-32 p-3 border border-gray-200 rounded-lg resize-none focus:ring-green-600"
            />
          </div>

          <div className="flex gap-3">
            <button type="button" onClick={onClose} className="flex-1 py-2.5 bg-gray-100 rounded-lg">
              {t("buttons.cancel")}
            </button>
            <button
              type="submit"
              className="flex-1 py-2.5 bg-green-600 text-white rounded-lg"
            >
              {i18n.language == "en" ? "Send Application" : "أرسل طلبًا."}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

