import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";

const ConfirmTrialModal = ({
  isOpen,
  onClose,
  id,
  updateUi,
  setUpdateUi,
}: any) => {
  const { t } = useTranslation();
  if (!isOpen) return null;

  const handleConfirm = async () => {
    try {
      const response = await fetch(
        `https://api.nadwa-uae.com/api/v2/tutor/invitations/${id}/accept_invitation/`,
        {
          method: "POST",
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        toast.success("Invitation accepted successfully!");
        setTimeout(() => {
          onClose();
          setUpdateUi(!updateUi);
        }, 500);
      } else {
        toast.error("Failed to accept invitation.");
      }
    } catch (error: any) {
      console.error("Error accepting invitation:", error);
      toast.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg w-80 sm:w-96 sm:h-52 relative">
          <X
            className="absolute top-2 right-2 text-gray-600"
            onClick={onClose}
          />
          <p className="text-lg font-semibold mb-4">
            {t("confirm_heading")}
            <br />
            <span className="text-sm font-light">
              {t("confirm_note")}
            </span>
          </p>
          <div className="flex justify-end gap-2">
            <button
              className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={onClose}
            >
              {t("buttons.cancel")}
            </button>
            <button
              className="px-4 py-2 bg-[#008847] text-white rounded-lg hover:bg-green-700"
              onClick={handleConfirm}
            >
              {t("confirm")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmTrialModal;
