import type React from "react";
import { useState, useEffect } from "react";
import { sendEmailVerification } from "../../../Service/api";
import VerificationCodeModal from "./VerificationCode";
import VerificationErrorModal from "./ErrorPopup";
import image1 from "../../../images/popups/email.png";
import EmailSentModal from "./EmailSend";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../../i18n";

interface EmailVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  emails: string;
}

export default function EmailVerificationModal({
  isOpen,
  onClose,
  emails,

}: EmailVerificationModalProps) {
  const [email, setEmail] = useState<string | null>(null);
  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  // Timer states
  const [cooldown, setCooldown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  // Timer logic
  useEffect(() => {
    let timer: any;
    if (cooldown && remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
    } else if (remainingTime === 0 && cooldown) {
      setCooldown(false);
    }
    return () => clearInterval(timer);
  }, [cooldown, remainingTime]);

  const handleVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    if (cooldown) {
      toast.error("Please wait for one minute before resending the code.");
      return;
    }
    try {
      const response = await sendEmailVerification(email ? email : emails);
      if (response.status === "ok") {
        setIsCodeModalOpen(true);
        setCooldown(true);
        setRemainingTime(60);
      } else {
        throw new Error(response.email[0]);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
      setIsErrorModalOpen(true);
    }
  };

  const handleResend = async () => {
    if (cooldown) {
      toast.error("Please wait for one minute before resending the code.");
      return;
    }

    try {
      const response = await sendEmailVerification(email ? email : emails);
      if (response.status === "ok") {
        toast.success("Code Resend Successfully");
        setCooldown(true);
        setRemainingTime(60); // Start 1-minute cooldown
      } else {
        throw new Error(response.email[0]);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  // Format time for display (MM:SS)
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <ToastContainer />
      <div className="relative bg-white rounded-lg max-w-[425px] w-full p-6 animate-fade-in">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        >
          ×
        </button>

        <div className="flex flex-col items-center gap-4">
          <div className="w-32 h-18 flex items-center justify-center">
            <img
              src={image1 || "/placeholder.svg"}
              alt="Email verification"
              className="w-full h-full object-contain"
            />
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-xl font-semibold">{i18n.language == "ar" ? "تحقق من بريدك الإلكتروني" : "Verify your Email"}</h2>
            <p className="text-sm text-gray-500">
              {i18n.language == "ar" ? "أكد عنوان بريدك الإلكتروني لتلقي الإشعارات الفورية من معلميك" : "Confirm your email address for instant notifications from your tutor(s)"}
            </p>
          </div>

          <form onSubmit={handleVerification} className="w-full space-y-4">
            <input
              type="email"
              value={email ? email : emails}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:border-transparent placeholder:text-zinc-950"
            />
            <button
              type="submit"
              className="w-full px-4 py-2 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg transition-colors duration-200"
            >
              {i18n.language == "ar" ? "إرسال رمز التحقق" : "Send Verification Code"}
            </button>
          </form>
        </div>
      </div>

      <VerificationCodeModal
        email={email || emails}
        isOpen={isCodeModalOpen}
        onClose={() => setIsCodeModalOpen(false)}
        onVerify={(code) => {
          console.log("Verification code:", code);
          setIsCodeModalOpen(false);
          setIsSuccessModal(true);
        }}
        onResend={handleResend}
        cooldown={cooldown}
        remainingTime={remainingTime}
        formatTime={formatTime}
      />

      <VerificationErrorModal
        isOpen={isErrorModalOpen}
        errorMessage={errorMessage}
        onClose={() => setIsErrorModalOpen(false)}
        onTryAgain={() => {
          setIsErrorModalOpen(false);
        }}
      />

      <EmailSentModal
        isOpen={isSuccessModal}
        onClose={() => setIsSuccessModal(false)}
      />
    </div>
  );
}
