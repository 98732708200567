import axios from "axios";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMemo } from "react";
import { DateTime } from "luxon";

const ChangeLesson = ({
  isOpen,
  onClose,
  studentName,
  lessonId,
  defaultStartTime,
  defaultEndTime,
  timezone,
  updateUi,
  setUpdateUi
}: any) => {
  // Extract default date from start or end time
  const defaultDate = defaultStartTime
    ? DateTime.fromISO(defaultStartTime, { setZone: true }).setZone(timezone).toFormat("yyyy-MM-dd")
    : defaultEndTime
      ? DateTime.fromISO(defaultEndTime, { setZone: true }).setZone(timezone).toFormat("yyyy-MM-dd")
      : "";

  const [date, setDate] = useState(defaultDate);
  const [startTime, setStartTime] = useState(
    defaultStartTime
      ? DateTime.fromISO(defaultStartTime, { setZone: true }).setZone(timezone).toFormat("HH:mm")
      : ""
  );
  const [endTime, setEndTime] = useState(
    defaultEndTime
      ? DateTime.fromISO(defaultEndTime, { setZone: true }).setZone(timezone).toFormat("HH:mm")
      : ""
  );
  const { t } = useTranslation();
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [lessonDuration, setLessonDuration] = useState(0); // Duration in minutes

  // Calculate duration from default times using Luxon
  const calculateDuration = (start: string, end: string) => {
    const startDt = DateTime.fromISO(start, { setZone: true });
    const endDt = DateTime.fromISO(end, { setZone: true });
    return endDt.diff(startDt, "minutes").minutes;
  };

  // Calculate new end time based on start time and duration using Luxon
  const calculateEndTime = (start: string, duration: number) => {
    const startDt = DateTime.fromFormat(`${date} ${start}`, "yyyy-MM-dd HH:mm", { zone: timezone });
    const endDt = startDt.plus({ minutes: duration });
    return endDt.toFormat("HH:mm");
  };

  // Handle start time change
  const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    if (lessonDuration > 0) {
      setEndTime(calculateEndTime(newStartTime, lessonDuration));
    }
  };

  // Format times with timezone offset for the payload
  const formattedStartTime = date && startTime
    ? DateTime.fromFormat(`${date} ${startTime}`, "yyyy-MM-dd HH:mm", { zone: timezone }).toISO()
    : null;
  const formattedEndTime = date && endTime
    ? DateTime.fromFormat(`${date} ${endTime}`, "yyyy-MM-dd HH:mm", { zone: timezone }).toISO()
    : null;

  useEffect(() => {
    // Set default start and end time and calculate duration when component mounts
    if (defaultStartTime && defaultEndTime) {
      const start = DateTime.fromISO(defaultStartTime, { setZone: true }).setZone(timezone).toFormat("HH:mm");
      const end = DateTime.fromISO(defaultEndTime, { setZone: true }).setZone(timezone).toFormat("HH:mm");

      setStartTime(start);
      setEndTime(end);

      const duration = calculateDuration(defaultStartTime, defaultEndTime);
      setLessonDuration(duration);
    }
    // Check if the lesson has already been rescheduled
    const rescheduledLessons = JSON.parse(localStorage.getItem("rescheduledLessons") || "{}");
    if (rescheduledLessons[lessonId]) {
      setIsRescheduled(true);
    }
  }, [lessonId, defaultStartTime, defaultEndTime, timezone]);

  const handleReschedule = async () => {
    if (!date || !startTime || !endTime) {
      toast.error("Please select date and time.");
      return;
    }

    const authToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!authToken) {
      toast.error("Authentication token is missing!");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.nadwa-uae.com/api/v2/tutor/bookings/${lessonId}/reschedule/`,
        {
          student_name: studentName,
          date,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          duration: lessonDuration, // Duration in minutes
        },
        {
          headers: {
            Authorization: `Token ${authToken}`,
          },
        }
      );
      const rescheduledLessons = JSON.parse(localStorage.getItem("rescheduledLessons") || "{}");
      rescheduledLessons[lessonId] = true;
      localStorage.setItem("rescheduledLessons", JSON.stringify(rescheduledLessons));
      setIsRescheduled(true);

      toast.success("Lesson rescheduled successfully! 🎉");
      setTimeout(() => {
        onClose();
      }, 500);
      setUpdateUi(!updateUi);
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.non_field_errors?.length > 0
          ? error.response.data.non_field_errors.join(", ")
          : error?.response?.data?.detail || "Something went wrong.";

      toast.error(errorMessage);
    }
  };

  // Generate time options with Luxon
  const timeOptions = useMemo(() => {
    const interval = 15;
    const includedTimes = new Set<string>();

    // Add current startTime and endTime to ensure they're available
    if (startTime) includedTimes.add(startTime);
    if (endTime) includedTimes.add(endTime);

    const startOfDay = DateTime.fromObject({ hour: 0, minute: 0 }, { zone: timezone });
    for (let minute = 0; minute < 24 * 60; minute += interval) {
      const time = startOfDay.plus({ minutes: minute }).toFormat("HH:mm");
      includedTimes.add(time);
    }

    // Convert set to array and sort chronologically
    return Array.from(includedTimes)
      .sort((a, b) => {
        const aDt = DateTime.fromFormat(a, "HH:mm");
        const bDt = DateTime.fromFormat(b, "HH:mm");
        return aDt.toUnixInteger() - bDt.toUnixInteger();
      })
      .map((time) => (
        <option key={time} value={time}>
          {time}
        </option>
      ));
  }, [startTime, endTime, timezone]);

  if (!isOpen) return null;

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <div className="fixed inset-0 bg-gray-700 bg-opacity-5 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg">
              {t("reschedule_lesson")} {studentName}
            </h2>
            <X
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 cursor-pointer"
            />
          </div>

          <h1 className="text-red-700 text-base">{t("reschedule_limitation")}</h1>

          {/* Date Selection */}
          <div>
            <label className="block text-base font-medium mb-2">
              {t("select_date")}
            </label>
            <input
              type="date"
              className="w-full p-2 border rounded-md text-sm"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              min={DateTime.now().setZone(timezone).toFormat("yyyy-MM-dd")}
              onFocus={(e) => e.target.showPicker()}
            />
          </div>

          {/* Time Selection */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
            <div>
              <label className="block text-base font-medium mb-2">
                {t("start_time")}
              </label>
              <select
                className="w-full p-2 border rounded-md text-sm"
                value={startTime}
                onChange={handleStartTimeChange}
              >
                {timeOptions}
              </select>
            </div>

            <div>
              <label className="block text-base font-medium mb-2">
                {t("end_time")}
              </label>
              <input
                type="text"
                readOnly
                value={endTime}
                className="w-full p-2 border rounded-md text-sm cursor-not-allowed"
              />
            </div>
          </div>

          <div className="flex justify-end gap-3 mt-4">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {t("cancel")}
            </button>
            <button
              onClick={handleReschedule}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={isRescheduled}
            >
              {isRescheduled ? "Already Rescheduled" : t("reschedule")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeLesson;