/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { ChevronLeft, ChevronRight } from "lucide-react";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import BookingInterface2 from "./InstantBooking";

export default function WeeklyCalendarExact(props) {
  const [userTimeZone, setUserTimeZone] = useState("Asia/Dubai");
  const [tutorData, setTutorData] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [instant, setInstant] = useState(false);
  const [tempSelection, setTempSelection] = useState(null);
  const [bookedSlots, setBookedSlots] = useState([]);
  const { id } = useParams();

  // Fetch user timezone
  useEffect(() => {
    if (localStorage.getItem("TH_AUTH_TOKEN") === null) return;
    const fetchUserData = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setUserTimeZone(res.data.data.user.user_timezone);
      } catch (error) {
        console.log("Failed to fetch user data.");
      }
    };
    fetchUserData();
  }, []);

  // Fetch tutor data
  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${props.tutorId || id}/`
        );
        setTutorData(res.data);
      } catch (error) {
        console.log("Failed to fetch tutor data.");
      }
    };
    fetchTutorData();
  }, [props.tutorId, id]);

  // Fetch booked slots for the tutor
  useEffect(() => {
    if (!tutorData?.user?.id) return;
    const fetchBookedSlots = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${tutorData.user.id}/bookings/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setBookedSlots(res.data);
      } catch (error) {
        console.log("Error fetching booked slots.");
      }
    };
    fetchBookedSlots();
  }, [tutorData?.user?.id]);

  // Generate available time slots
  const generateTimeSlots = useCallback(() => {
    if (!tutorData || !userTimeZone) return;

    const allSlots = [];
    const tutorTimeZone = tutorData.user_timezone;

    // Generate all 30-minute slots from availability in tutor's time zone
    tutorData.availability.forEach(({ start_time, end_time, day }) => {
      // Set the date to the specified day in the tutor's time zone
      const date = moment.tz(tutorTimeZone).add(weekOffset, "weeks").day(day);
      let start = moment.tz(
        `${date.format("YYYY-MM-DD")} ${start_time}`,
        tutorTimeZone
      );
      let end = moment.tz(
        `${date.format("YYYY-MM-DD")} ${end_time}`,
        tutorTimeZone
      );

      while (start.isBefore(end)) {
        const userTime = start.clone().tz(userTimeZone);
        allSlots.push({
          time: userTime.format("HH:mm"),
          day: userTime.isoWeekday(),
          rowStart: userTime.hour(),
          fullDateTime: userTime.format("YYYY-MM-DD HH:mm:ss"),
          isMorning: userTime.hour() < 12,
        });
        start.add(30, "minutes");
      }
    });

    // Filter out slots within tutor's time off periods
    const availableSlots = allSlots.filter((slot) => {
      const slotStart = moment.tz(slot.fullDateTime, userTimeZone);
      const isTimeOff = tutorData.time_off?.some((to) => {
        const toStart = moment.parseZone(to.start_datetime).tz(userTimeZone);
        const toEnd = moment.parseZone(to.end_datetime).tz(userTimeZone);
        return slotStart.isBetween(toStart, toEnd, undefined, "[)");
      });
      return !isTimeOff;
    });

    // Remove past time slots for the current day
    const now = moment.tz(userTimeZone);
    const availableSlotsNoPast = availableSlots.filter((slot) => {
      const slotMoment = moment.tz(slot.fullDateTime, userTimeZone);
      if (slotMoment.isSame(now, "day")) {
        return slotMoment.isAfter(now);
      }
      return true;
    });

    // Remove slots that overlap with booked slots
    const availableSlotsNoBooked = availableSlotsNoPast.filter((slot) => {
      if (!bookedSlots || bookedSlots.length === 0) return true;
      const slotMoment = moment.tz(slot.fullDateTime, userTimeZone);
      // A slot is booked if it falls within any booked slot's start and end.
      const isBooked = bookedSlots.some((booking) => {
        const bookingStart = moment.utc(booking.start_time).tz(userTimeZone);
        const bookingEnd = moment.utc(booking.end_time).tz(userTimeZone);
        return slotMoment.isBetween(bookingStart, bookingEnd, undefined, "[)");
      });
      return !isBooked;
    });

    // Create a set of available times per day for 1-hour block check
    const availableTimesByDay = {};
    availableSlotsNoBooked.forEach((slot) => {
      if (!availableTimesByDay[slot.day]) {
        availableTimesByDay[slot.day] = new Set();
      }
      availableTimesByDay[slot.day].add(slot.time);
    });

    // Filter for slots where the next 30-minute slot exists (i.e. 1-hour blocks)
    const filteredSlots = availableSlotsNoBooked.filter((slot) => {
      const nextTime = moment(slot.fullDateTime)
        .add(30, "minutes")
        .format("HH:mm");
      return availableTimesByDay[slot.day]?.has(nextTime);
    });

    setTimeSlots(filteredSlots);
  }, [tutorData, userTimeZone, weekOffset, bookedSlots]);

  useEffect(() => {
    generateTimeSlots();
  }, [generateTimeSlots]);

  const today = moment().add(weekOffset, "weeks");
  const days = [...Array(7)].map((_, index) => {
    const date = today.clone().add(index, "days");
    return {
      date: date.date(),
      day: date.format("ddd"),
      dayNumber: date.isoWeekday(),
      fullDate: date.format("YYYY-MM-DD"),
    };
  });

  const getSlots = (dayNumber, rowIndex) => {
    return timeSlots.filter(
      (slot) => slot.day === dayNumber && slot.rowStart === rowIndex
    );
  };

  const goToPreviousWeek = () => setWeekOffset((prev) => Math.max(prev - 1, 0));
  const goToNextWeek = () => setWeekOffset((prev) => prev + 1);

  const handleSlotClick = (slot) => {
    if (!tutorData.has_stripe_account) {
      return toast.error(
        "Tutor has not set up bank account for payments. You can't book this tutor"
      );
    }
    const selectedDate = moment(slot.fullDateTime).format("YYYY-MM-DD");
    const slotTime = slot.time;
    const nextTime = moment(slot.fullDateTime)
      .add(30, "minutes")
      .format("HH:mm");

    setTempSelection({ date: selectedDate, times: [slotTime, nextTime] });
    setInstant(true);
  };

  const activeHours = [...new Set(timeSlots.map((slot) => slot.rowStart))].sort(
    (a, b) => a - b
  );

  return (
    <div className="px-10 py-10 sm:block hidden">
      <ToastContainer />
      <div className="flex justify-between items-center mb-4 bg-[#F0F3F599] p-3">
        <p>{userTimeZone}</p>
        <div className="flex items-center gap-4">
          <button
            onClick={goToPreviousWeek}
            className="px-2 py-1 bg-gray-200 rounded"
          >
            <ChevronLeft />
          </button>
          <button
            onClick={goToNextWeek}
            className="px-2 py-1 bg-gray-200 rounded"
          >
            <ChevronRight />
          </button>
        </div>
      </div>
      {activeHours.length > 0 ? (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
             
                {days.map((day) => (
                  <th
                    key={day.dayNumber}
                    className="border border-gray-200 p-2 text-center"
                    style={{ width: "14.28%" }}
                  >
                    <div className="font-bold">{day.date}</div>
                    <div className="text-sm text-gray-500">{day.day}</div>
                    <div className="text-xs text-gray-400">{day.fullDate}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {activeHours.map((rowIndex) => (
                <tr key={rowIndex} style={{ height: "40px" }}>
                 
                  {days.map((day) => {
                    const slots = getSlots(day.dayNumber, rowIndex);
                    return (
                      <td
                        key={day.dayNumber}
                        className="border border-gray-200 p-2"
                        style={{ width: "14.28%", height: "40px" }}
                      >
                        <div className="flex flex-col gap-1 h-full items-center justify-center">
                          {slots.map((slot, index) => (
                            <div
                              onClick={() => handleSlotClick(slot)}
                              key={index}
                              className={`text-white cursor-pointer font-semibold text-center rounded-md border p-2 text-[11px] w-full transition-opacity duration-200 hover:opacity-90 ${
                                slot.isMorning
                                  ? "bg-[#FF9C34] border-[#FF9C34]"
                                  : "bg-[#053752] border-[#053752]"
                              }`}
                            >
                              <span>{slot.time}</span>
                            </div>
                          ))}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-gray-500 py-10">
          No available slots for this week.
        </div>
      )}
      {instant && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface2
              showModal={instant}
              setInstant={(value) => {
                setInstant(value);
                if (!value) setTempSelection(null);
              }}
              tutorData={tutorData}
              initialSelectedTimesByDate={
                tempSelection ? { [tempSelection.date]: tempSelection.times } : {}
              }
              initialDate={tempSelection?.date}
            />
          </div>
        </div>
      )}
    </div>
  );
}
