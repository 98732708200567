// JobCard.tsx
import axios from "axios";
import { ArrowLeft, GraduationCap } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AvailabilitySection from "./Availability";

const JobCard = ({ id, onBack }: any) => { // Add onBack prop
  const [jobData, setJobData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const fetchJobData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await axios.get(
          `https://api.nadwa-uae.com/api/v2/schoolsupply/jobs/${id}`
        );
        setJobData(response.data);
      } catch (err) {
        setError("Failed to fetch job data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchJobData();
    }
  }, [id]);

  if (loading) {
    return <div className="text-center text-gray-500">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (!jobData) {
    return <div className="text-center text-gray-500">No job data found.</div>;
  }

  return (
    <div className="sm:p-4">
      <button
        className="flex items-center gap-2 relative bottom-2"
        onClick={onBack} // Use the onBack prop
      >
        <ArrowLeft /> {t("back")}
      </button>
      <div className="flex xl:flex-row flex-col gap-5 items-start justify-between">
        {/* left side  */}
        <div>
          <div className="flex sm:flex-row flex-col items-center sm:space-x-4 p-4 bg-white shadow-md rounded-lg">
            <div className="flex justify-center overflow-hidden bg-black text-white items-center w-20 h-20 rounded-full">
              <h3 className="text-[12px]">{jobData.subject}</h3>
            </div>
            <div>
              <h2 className="text-2xl font-semibold text-gray-700">
                {jobData.subject}
              </h2>
              <div className="text-base flex items-center gap-2 text-gray-600 font-semibold">
                <GraduationCap />
                {jobData.level}
              </div>
              <p className="text-sm text-gray-500">
                Requested by <span className="font-bold">Student</span>
              </p>
              <div className="mt-4 flex sm:flex-row flex-col flex-wrap gap-2">
                {jobData.tags.map((tag: any, index: number) => (
                  <span
                    key={index}
                    className="bg-[#f2f9f6] text-[#008847] border-1 border-[#008847] text-sm px-3 py-1 rounded-full"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <span className="text-gray-400 text-sm">{jobData.date}</span>
          </div>

          <div className="mt-6 bg-white roundedmd p-4 shadow-md">
            <p className="text-sm sm:text-lg text-gray-600">
              {t("jobs_board_info")}
            </p>
            <ul className="mt-2 space-y-1 font-semibold text-gray-600 text-xs sm:text-base">
              <li className="bg-[#f2f9f6] text-[#008847] border-1 border-[#008847] px-3 py-1 rounded-md">
                ✔ {t("job_text_1")}
              </li>
              <li className="bg-[#f2f9f6] text-[#008847] border-1 border-[#008847] px-3 py-1 rounded-md">
                ✔ {t("job_text_2")}
              </li>
              <li className="bg-[#f2f9f6] text-[#008847] border-1 border-[#008847] px-3 py-1 rounded-md">
                ✔ {t("job_text_3")}
              </li>
            </ul>
          </div>
        </div>

        {/* right side */}
        <div className="p-4 bg-white shadow-md rounded-lg">
          <div className="mt-6">
            <AvailabilitySection
              jobid={id}
              student_availability={jobData.student_availability}
              jobData={jobData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;