"use client";

import axios from "axios";
import { Edit } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";
import country from "../../../Data/country.mjs";
import PhoneVerificationModal from "../../DashBoardStudent/popups/PhoneVarification";
import EmailVerificationModal from "../../DashBoardStudent/popups/Verify";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const ProfileBasics = ({ setUpdateUi, updateUi }: any) => {
  const { t } = useTranslation();
  const [tutorData, setTutorData] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [profilePic, setProfilePic] = useState<File | null>(null);

  // Phone & Email states
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [tempEmail, setTempEmail] = useState("");

  // Fetch Tutor Data
  const fetchTutorData = async () => {
    try {
      const response = await axios.get(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setTutorData(response.data);
    } catch (error) {
      console.error("Fetch error:", error);
      toast.error("Failed to load profile data.");
    }
  };

  useEffect(() => {
    fetchTutorData();
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  // Handle Profile Picture Upload
  const handleProfilePicChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePic(file);

      try {
        const formData = new FormData();
        formData.append("photo", file);
        formData.append("file_type", "PROFILE_PHOTO_FILE");

        await axios.patch(
          "https://api.nadwa-uae.com/api/v3/profile-update/",
          formData,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Profile picture updated successfully!");
        fetchTutorData();
      } catch (error) {
        console.error("Profile pic update error:", error);
        toast.error("Failed to update profile picture.");
      }
    }
  };

  // Handle Input Changes
  const handleInputChange = (field: string, value: string) => {
    if (field === "phone_number") {
      setTempPhone(value);
      setIsPhoneModalOpen(true); // Open phone verification modal
      setIsPhoneVerified(false);
    } else if (field === "email") {
      setTempEmail(value);
      setIsEmailModalOpen(true); // Open email verification modal
    } else {
      setTutorData((prev: any) => ({
        ...prev,
        user: { ...prev.user, [field]: value },
      }));
    }
  };

  // Save Profile Changes
  const handleSaveChanges = async () => {
    if (!isPhoneVerified && tempPhone && tempPhone !== tutorData.user.phone_number) {
      toast.error("Please verify your phone number before saving.");
      return;
    }
    if (isEmailModalOpen && tempEmail && tempEmail !== tutorData.user.email) {
      toast.error("Please verify your email before saving.");
      return;
    }

    setIsSaving(true);
    const loadingToast = toast.loading("Saving changes...");
    try {
      await axios.patch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          user: {
            first_name: tutorData.user.first_name,
            last_name: tutorData.user.last_name,
            email: tempEmail || tutorData.user.email,
            phone_number: tempPhone || tutorData.user.phone_number,
          },
          tagline: tutorData.tagline,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            "Content-Type": "application/json",
          },
        }
      );
      await fetchTutorData();
      toast.success("Profile updated successfully!");
      setUpdateUi(!updateUi);
      setIsEditing(false);
    } catch (error) {
      console.error("Update error:", error);
      toast.error("Failed to save changes.");
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  if (!tutorData) return <div>Loading...</div>;

  return (
    <div className="space-y-3 sm:space-y-8">
      <ToastContainer transition={Bounce} />
      <h1 className="text-2xl font-semibold">{t("profile")}</h1>

      {/* Profile Picture Section */}
      <div className="bg-white p-4 sm:p-8 flex flex-col sm:flex-row items-center gap-4 rounded-xl">
        <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-100">
          <img
            src={
              profilePic
                ? URL.createObjectURL(profilePic)
                : tutorData.photo || "fallback.jpg"
            }
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
        <button className={`px-10 sm:px-4 py-2 ${tutorData.photo ? "bg-gray-600 cursor-not-allowed" : "bg-green-600 cursor-pointer"} text-white rounded-xl`} disabled={tutorData.photo}>
          {t("change_picture")}
          <input type="file" accept="image/*" className="hidden" onChange={handleProfilePicChange} />
        </button>
      </div>

      {/* Personal Information Section */}
      <div className="bg-white sm:p-6 rounded-xl">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold">{t("personal_information")}</h3>
          <button onClick={() => setIsEditing(!isEditing)} className="text-gray-500 border rounded-xl px-3 py-2">
            <span className="flex items-center gap-1">
              Edit <Edit />
            </span>
          </button>
        </div>

        {/* Form Inputs */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {["first_name", "last_name", "email", "phone_number", "tagline"].map((field) => (
            <div key={field} className="space-y-2">
              <label className="text-sm font-medium">{t(field)}</label>
              {isEditing ? (
                <input
                  type="text"
                  value={
                    field === "phone_number"
                      ? tempPhone || tutorData.user?.phone_number
                      : field === "email"
                        ? tempEmail || tutorData.user?.email
                        : tutorData.user?.[field] ?? tutorData[field] ?? ""
                  }
                  disabled={field === "email" && tutorData.user.email_verified}
                  onChange={(e) => handleInputChange(field, e.target.value)}
                  className="w-full p-2 border rounded"
                />
              ) : (
                <p className="font-medium">{tutorData.user?.[field] ?? tutorData[field]}</p>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Save Button */}
      {isEditing && (
        <button onClick={handleSaveChanges} disabled={isSaving} className="px-6 py-2 rounded-xl border bg-green-600 text-white">
          {isSaving ? "Saving..." : t("save_changes")}
        </button>
      )}

      {/* Modals */}
      <PhoneVerificationModal isOpen={isPhoneModalOpen} onClose={() => setIsPhoneModalOpen(false)} phone={tempPhone} countryData={country} />
      <EmailVerificationModal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)} emails={tempEmail} />
    </div>
  );
};

export default ProfileBasics;
