// TutorFilterSection.tsx
import * as SliderPrimitive from "@radix-ui/react-slider";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import JobCard from "../TutoJobsDetail/TutorJobsDetail";
import TutorJobsResult from "./TutorJobsResult";

function TutorFilterSection({ viewId, setViewJobId, onTabChange }: any) { // Add onTabChange prop
  const [subject, setSubject] = useState([]);
  const [level, setLevel] = useState([]);
  const [selectLevel, setSelectLevel] = useState("");
  const marks = [50, 100, 150, 200, 250, 300, 350, 400];
  const [min, setMin] = useState(50);
  const [max, setMax] = useState(400);
  const [values, setValues] = useState([50, 400]);
  const [selectSubject, setSelectSubject] = useState("");
  const isArabic = i18n.language === "ar";
  const { t }: { t: (key: string) => string } = useTranslation();
  const [isView, setIsView] = useState(true);
  const [id, setID] = useState(0);

  const handler = () => {
    setIsView(!isView);
  };

  useEffect(() => {
    const tutorLevels = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/teachinglevels"
        );
        setLevel(res.data);
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };

    tutorLevels();
    const tutorSubjects = async () => {
      try {
        const res2 = await axios.get(
          "https://api.nadwa-uae.com/api/v3/subjects"
        );
        setSubject(res2.data);
      } catch (error) {
        console.log(error);
      }
    };
    tutorSubjects();

    return () => {
      setViewJobId(null);
    };
  }, []);

  const handleValueChange = (newValues: any) => {
    const roundedValues = newValues.map((value: any) => {
      return marks.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
    });
    setValues(roundedValues);
    if (roundedValues[0] !== min || roundedValues[1] !== max) {
      setMin(roundedValues[0]);
      setMax(roundedValues[1]);
    }
  };

  return (
    <div>
      {viewId ? (
        <JobCard id={viewId} onBack={() => onTabChange("jobsboard")} /> // Pass onBack prop
      ) : isView ? (
        <div className="p-6 bg-white rounded-2xl shadow-2xl max-w-[95%] lg:max-w-[90%] sm:py-20 my-10 x-auto">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 sm:gap-8 lg:gap-4 items-center max-w-[90%] mx-auto">
            <div className="flex flex-col relative">
              <label className="text-lg font-semibold mb-2">
                {t("c_subject")}
              </label>
              <select
                value={selectSubject}
                onChange={(e) => setSelectSubject(e.target.value)}
                className="border border-gray-300 rounded-2xl px-4 py-2 focus:ring-2 focus:ring-green-400 appearance-none pr-10"
              >
                <option value="">{t("AllSubject")}</option>
                {subject.map((ele: any) => (
                  <optgroup key={ele.id} label={ele.name}>
                    {ele.subjects.map((sub: any) => (
                      <option key={sub.id} value={sub.id}>
                        {sub.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
              <span
                className={`absolute top-[72%] ${isArabic ? "left-4" : "right-4"} transform -translate-y-1/2 bg-[#00884780] w-7 h-7 rounded-full flex items-center justify-center pointer-events-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>

            <div className="flex flex-col relative">
              <label className="text-lg font-semibold mb-2">{t("c_level")}</label>
              <select
                value={selectLevel}
                onChange={(e) => setSelectLevel(e.target.value)}
                className="border border-gray-300 rounded-2xl px-4 py-2 focus:ring-2 focus:ring-green-400 appearance-none pr-10"
              >
                <option value="">{t("AllLevel")}</option>
                {level.map((ele: any) => (
                  <option value={ele.id} key={ele.id}>
                    {ele.name}
                  </option>
                ))}
              </select>
              <span
                className={`absolute top-[72%] ${isArabic ? "left-4" : "right-4"} transform -translate-y-1/2 bg-[#00884780] w-7 h-7 rounded-full flex items-center justify-center pointer-events-none`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="lg:w-[90%] mx-auto px-2 mt-10">
            <h1 className="text-lg font-semibold">{t("p_range")}</h1>
          </div>

          {/* Price Bar for Large Screens */}
          <div className="lg:w-[90%] mx-auto px-2 md:block hidden">
            <div className="relative pt-6 pb-10">
              <SliderPrimitive.Root
                className="relative flex w-full touch-none select-none items-center"
                value={values}
                max={400}
                min={50}
                step={1}
                onValueChange={handleValueChange}
              >
                <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-[#8F8F8F33]">
                  <SliderPrimitive.Range className="absolute h-full bg-[#008847]" />
                </SliderPrimitive.Track>
                <SliderPrimitive.Thumb
                  className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                  aria-label="Minimum value"
                />
                <SliderPrimitive.Thumb
                  className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                  aria-label="Maximum value"
                />
              </SliderPrimitive.Root>
              <div className="absolute left-0 right-0 -bottom-2">
                <div className="relative h-8">
                  {marks.map((mark) => (
                    <div
                      key={mark}
                      className="absolute -translate-x-1/2 text-sm text-gray-600"
                      style={{
                        left: `${((mark - 50) / (400 - 50)) * 100}%`,
                      }}
                    >
                      {mark}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Min and Max Input Fields for Mobile */}
          <div className="md:hidden mx-auto px-2 mt-10">
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <label htmlFor="minPrice" className="text-sm text-gray-600 mb-1">
                  {t("MinPrice")}
                </label>
                <input
                  type="number"
                  id="minPrice"
                  className="w-full border rounded px-2 py-1 text-sm"
                  value={min}
                  min="50"
                  max="400"
                  step="50"
                  onChange={(e) => {
                    const value = Math.max(50, Math.min(400, parseInt(e.target.value, 10) || 50));
                    setMin(value);
                    setValues([value, max]);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="maxPrice" className="text-sm text-gray-600 mb-1">
                  {t("MaxPrice")}
                </label>
                <input
                  type="number"
                  id="maxPrice"
                  className="w-full border rounded px-2 py-1 text-sm"
                  value={max}
                  min="50"
                  max="400"
                  step="50"
                  onChange={(e) => {
                    const value = Math.max(50, Math.min(400, parseInt(e.target.value, 10) || 400));
                    setMax(value);
                    setValues([min, value]);
                  }}
                />
              </div>
            </div>
          </div>

          <TutorJobsResult
            setID={setID}
            view={isView}
            setIsView={handler}
            selectedSubject={selectSubject}
            selectedLevel={selectLevel}
            priceRange={{ min, max }}
          />
        </div>
      ) : (
        <JobCard id={id} onBack={() => setIsView(true)} /> // Update this case as well if needed
      )}
    </div>
  );
}

export default TutorFilterSection;