import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  BarChartIcon as ChartNoAxesColumnIncreasing,
  MapPin,
  ChevronDown,
  ChevronUp,
  BookCheck,
} from "lucide-react";
import i18n from "../../i18n";
import { useFilterContext } from "../../ContextApi/FilterContext";
import SkeletonLoader from "../TutorPages/Loading";
import ReactStars from "react-stars";

const TutorCard = ({ tutor, setTutor, setTutorId }: any) => {
  const { t } = useTranslation();

  return (
    <div className="bg-transparent w-full h-full overflow-hidden">
      <div>
        <picture>
          <source srcSet={tutor?.photo} type="image/webp" />
          <img
            src={tutor?.photo.replace(".webp", ".jpg") || "fallback.jpg"}
            alt="img"
            className="object-contain rounded-2xl w-[350px] h-[350px]"
          />
        </picture>
      </div>
      <div className="pt-4 flex flex-col xl:gap-1">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <h3 className="text-xl font-bold flex gap-2 items-center">
              {tutor?.name}{" "}
              <picture>
                <source
                  srcSet={tutor.country?.country_icon}
                  type="image/webp"
                />
                <img
                  src={
                    tutor.country?.country_icon?.replace(".webp", ".jpg") ||
                    "fallback.jpg"
                  }
                  alt="icon"
                  className="w-5 h-5"
                />
              </picture>
            </h3>
            <p className="text-black text-[16px] line-clamp-2 max-w-[90%]">
              {tutor?.experience}
              <Link to="/">see more</Link>
            </p>
            <p className="text-black text-[16px] flex gap-1">
              <ChartNoAxesColumnIncreasing className="text-[#008847]" />{" "}
              {tutor?.experience_years} {t("yearsExperi")}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">
              <span className="font-semibold">
                AED{" "}
                {(() => {
                  const priceInCents = tutor.selected_subject?.price_in_cents;
                  if (!priceInCents) return "0"; // In case price_in_cents is undefined or null

                  const basePrice = priceInCents / 100; // Convert cents to the base price

                  // Calculate the additional price based on increments of 50
                  let additionalPrice = 0;
                  if (basePrice >= 50) {
                    additionalPrice += Math.floor(basePrice / 50) * 2.5; // Add 2.5 for each 50 increment
                  }

                  // Final price after adding the increment
                  const finalPrice = basePrice + additionalPrice;

                  return finalPrice.toFixed(1); // Ensure the result is rounded to two decimal places
                })()}
              </span>
              <br />{" "}
              <span className="text-[#008847] text-sm text-end">
                {t("perhour")}
              </span>
              <br />
              <span className="text-sm">({t("includigVat")})</span>
            </p>
          </div>
        </div>
        {tutor.rating > 0 && (
          <div className="flex gap-1">
            <ReactStars
              count={5}
              value={tutor.rating}
              size={19}
              edit={false}
              color2={"#ffd700"}
            />
            <p className="">({tutor.rating})</p>
          </div>
        )}
        <div className="flex justify-between">
          <p className="text-[15px]  text-black flex gap-2 items-center">
            <span>
              <BookCheck className="text-[#008847]" />
            </span>
            {tutor.experience_years * 50 + "+ lesson complete"}
          </p>
          <span className="text-sm flex gap-1">
            <MapPin className="text-[#008847] h-5 w-4" />
            {t("tutoring")}:{" "}
            {tutor.online_tutoring && tutor.in_person_tutoring
              ? "Online and In person tutoring"
              : tutor?.online_tutoring
              ? "Online"
              : tutor.in_person_tutoring
              ? "In person tutoring"
              : "Online"}
          </span>
        </div>
        <button
          onClick={() => {
            setTutor(true);
            setTutorId(tutor.id);
          }}
          className={`bg-red-500 text-center no-underline text-white py-2 rounded-3xl hover:bg-red-600 transition ${
            i18n.dir(i18n.language) === "rtl" ? "w-44" : "w-36"
          }`}
        >
          {t("Viewprofile")}
        </button>
      </div>
    </div>
  );
};

const AccordionItem = ({
  title,
  children,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="p-4 rounded-xl border-2 border-[#008847]">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full text-2xl font-bold text-[#008847]"
      >
        {title}
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      <div className={`text-[16px] mt-2 ${isOpen ? "block" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
};

const TutorsResult = ({ setTutor, setTutorId }: any) => {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const { otherFilters, loading, setOtherFilters, setLoading } =
    useFilterContext();
  const [isOpen, setIsOpen] = useState(false);

  const getData = async (page: number) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/?subject_id=&levelId=&country=&max_price=200&min_price=100&max_experience=60&min_experience=0&postcode=Online&online=true&search=&page=${page}`
      );
      setOtherFilters(res.data.results);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    if (page < 1) return;
    setPageNumber(page);
    getData(page);
  };

  const sidebarContent = [
    {
      title: t("find_a_tutor_page.contact_us"),
      content:
        i18n.language === "en"
          ? "Contact Us  For Discounted Packages"
          : "اتصل بنا للحصول على حزم مخفضة",
    },
    {
      title: t("Q1"),
      content: t("Ans_1"),
    },
    {
      title: t("Q2"),
      content: t("Ans_2"),
    },
    {
      title: t("Q3"),
      content: t("Ans_3"),
    },
  ];

  return (
    <div className="relative py-20">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <h2 className="text-3xl font-bold mb-8 ml-20">
            {t("find_a_tutor_page.search_results")}
          </h2>
          <div className="grid grid-cols-1 xl:grid-cols-4 gap-10 lg:gap-0">
            <div className="grid lg:grid-cols-3 grid-cols-1 col-span-1 lg:col-span-3 gap-16 xl:gap-14 max-w-[92%] mx-auto">
              {otherFilters.length > 0 ? (
                otherFilters.map((tutor: any) => (
                  <TutorCard
                    key={tutor.id}
                    tutor={tutor}
                    setTutor={setTutor}
                    setTutorId={setTutorId}
                  />
                ))
              ) : (
                <p className="text-center text-2xl text-black">
                  {t("notfound")}
                </p>
              )}
              <div className="flex justify-center mt-10 col-span-1 lg:col-span-3">
                <nav>
                  <ul className="flex gap-2">
                    <li>
                      <button
                        onClick={() => handlePageChange(pageNumber - 1)}
                        disabled={pageNumber === 1}
                        className={`px-4 py-2 rounded-md border border-gray-300 ${
                          pageNumber === 1
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-gray-200"
                        }`}
                      >
                        &lt;
                      </button>
                    </li>
                    {[1, 2, 3].map((num) => (
                      <li key={num}>
                        <button
                          onClick={() => handlePageChange(num)}
                          className={`px-4 py-2 rounded-md transition-all duration-200 
                        ${
                          pageNumber === num
                            ? "bg-red-500 text-white font-bold shadow-md"
                            : "bg-white text-black border border-gray-300 hover:bg-gray-100"
                        }`}
                        >
                          {num}
                        </button>
                      </li>
                    ))}
                    <li>
                      <button
                        onClick={() => handlePageChange(pageNumber + 1)}
                        className="px-4 py-2 rounded-md border border-gray-300 hover:bg-gray-200"
                      >
                        &gt;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div
              className={`hidden lg:block absolute ${
                i18n.language === "ar" ? "left-0" : "right-0"
              } top-0 rotate-[270deg]`}
            >
              {sidebarContent.map((item, index) =>
                item.content === "Contact Us  For Discounted Packages" ||
                item.content === "اتصل بنا للحصول على حزم مخفضة" ? (
                  <div className="relative w-[160px]">
                    <button
                      onClick={() => setIsOpen(!isOpen)}
                      className="w-[160px] h-[50px] bg-green-600 text-white rounded-3xl flex items-center justify-center text-lg font-bold"
                    >
                      {item.title}
                    </button>

                    <div
                      className={`absolute top-full left-1/2 transform -translate-x-1/2 w-[160px] bg-white text-black text-xs p-2 rounded-lg shadow-md transition-all z-40 duration-300 ${
                        isOpen
                          ? "opacity-100 visible translate-y-2"
                          : "opacity-0 invisible"
                      }`}
                    >
                      <a
                        href="https://calendly.com/hend-sabry"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.content}
                      </a>
                    </div>
                  </div>
                ) : null
              )}
            </div>
            <div className="col-span-1 hidden lg:flex flex-col gap-10 max-w-[92%] mx-auto">
              {sidebarContent.map((item, index) =>
                item.content !== "Contact Us  For Discounted Packages" ? (
                  <div
                    key={index}
                    className="p-4 rounded-xl border-2 border-[#008847]"
                  >
                    <h4 className="text-2xl font-bold">{item.title}</h4>
                    <p className="text-[16px]">{item.content}</p>
                  </div>
                ) : null
              )}
            </div>
            <div className="max-w-[92%] mx-auto flex-col gap-4 lg:grid-cols-1 lg:hidden flex">
              {sidebarContent.map((item, index) => (
                <AccordionItem key={index} title={item.title}>
                  {item.content}
                </AccordionItem>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TutorsResult;
