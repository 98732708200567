import axios from "axios";
import { Calendar, SearchX } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import JobApplicationPopup from "../TutoJobsDetail/TutorJobsApply";
import { JobSkeleton } from "./TutorJobResultSkelton";

interface Job {
  id: string;
  subject: string;
  tags: string[];
  start_date: string;
  price_min: number | null;
  price_max: number | null;
}

function TutorJobsResult({
  selectedSubject,
  selectedLevel,
  priceRange,
  setIsView,
  setID,
}: {
  selectedSubject: string;
  selectedLevel: string;
  priceRange: any;
  setIsView: () => void;
  view: boolean;
  setID: any;
}) {
  const [data, setData] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/schoolsupply/jobs/",
          {
            params: {
              online: true,
              postcode: "",
              pricing_min: priceRange.min,
              pricing_max: priceRange.max,
              subject: selectedSubject,
              level: selectedLevel,
            },
          }
        );
        setData(response.data.results);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, [priceRange.min, priceRange.max, selectedSubject, selectedLevel]);

  if (loading) {
    return <JobSkeleton />;
  }

  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  if (data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center mt-10 p-6 rounded-lg">
        <SearchX className="w-16 h-16 text-gray-400 mb-4" />
        <h2 className="text-xl font-semibold text-gray-600 mb-2">
          No Results Found
        </h2>
        <p className="text-gray-500 text-center">
          No jobs match your current selection. Try adjusting your filters.
        </p>
      </div>
    );
  }
  
  return (
    <div className="lg:w-[90%] grid grid-cols-1 2xl:grid-cols-2 gap-3 mx-auto px-2 mt-10">
      {data.map((job: Job, jobIndex: number) => (
        <div
          key={jobIndex}
          className=" border-2 border-[#008847] bg-[#fafdfb] rounded-lg p-4 flex flex-col gap-4 mb-4"
        >
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex justify-center overflow-hidden bg-black text-white items-center w-16 h-16 rounded-full ">
              <h3 className="text-[12px]">{job.subject}</h3>
            </div>
            <div className="flex flex-col flex-wrap sm:w-[50%] gap-2">
              <h3 className="text-[15px] font-bold text-center md:text-left">{job.subject}</h3>
              <h3 className="text-[12px] font-bold text-center md:text-left">{t("req")}</h3>
              <div className="flex gap-2 flex-wrap">
                {job.tags.map((tag: string, tagIndex: number) => (
                  <span
                    key={tagIndex}
                    className="px-2 py-1 bg-[#edf7f2] border-1 border-[#008847] text-[#008847] rounded-lg text-[12px]"
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2 bg-[#e4edfb] p-1 rounded-2xl border-1 border-[#1A5AFF] text-[#1A5AFF] text-[12px]">
                <span className="flex items-center gap-1">
                  <Calendar className="text-[#1A5AFF] w-5" />
                  <span>{new Date(job.start_date).toLocaleDateString()} </span>
                </span>
              </div>
              <div className="flex items-center gap-1 text-[#DFA510] bg-[#faf6e2] border-1 border-[#FFB800] px-3 py-1 rounded-lg text-[12px]">
                <span>
                  {t("AED")} {job.price_min || "N/A"} - {job.price_max || "N/A"} {t("hour")}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-3 justify-between md:mt-4">
            <JobApplicationPopup Jobid={(job.id)} />
            <button
              onClick={() => {
                setIsView();
                setID(job.id);
              }}
              className="bg-[#edf7f2] flex items-center justify-center no-underline md:w-1/2 border-1 border-[#008847] text-[#008847] px-6 py-2 rounded-lg font-semibold"
            >
              {t("posted_jobs_page.view_job")}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TutorJobsResult;
