/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useMemo } from "react";
import { ChevronDown, X } from "lucide-react";
import axios from "axios";
import TrialConfirmationModal from "./bookingLast";
import { toast, Bounce, ToastContainer } from "react-toastify";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

interface TimeSlot {
  time: string;       // Local time for display (e.g., "14:00")
  startUTC: string | null;   // UTC start time (e.g., "2023-10-25T10:00:00Z")
  isBooked: boolean;  // Indicates if the slot is booked or unavailable
}

export default function BookingInterface({ setShowModal, tutorData = {}, setActiveTab }: any) {
  const today = DateTime.now().toISODate();
  const [selectedDate, setSelectedDate] = useState(today);
  const { t } = useTranslation();
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [isThirdModalOpen, setIsThirdModalOpen] = useState(false);
  const [userTimezone, setUserTimezone] = useState("Asia/Dubai"); // Default to Asia/Dubai
  const [bookingDetails, setBookingDetails] = useState<any>({
    customer: null,
    subject: null,
    tutor: null,
  });
  const [selectedTimes, setSelectedTimes] = useState<TimeSlot[]>([]);
  const [bookedSlots, setBookedSlots] = useState<any[]>([]);
  const dateInputRef = useRef<HTMLInputElement>(null);

  // Fetch user data and set customer/tutor/timezone
  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("TH_AUTH_TOKEN");
        if (!token) throw new Error("Authentication token not found.");

        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          { headers: { Authorization: `Token ${token}` } }
        );

        if (res.data?.data?.user?.id) {
          setBookingDetails((prev: any) => ({
            ...prev,
            customer: parseInt(res.data.data.user.id, 10),
            tutor: tutorData.user?.id || tutorData.user_id,
          }));
          setUserTimezone(res.data.data.user.user_timezone || "Asia/Dubai");
        }
      } catch (error: any) {
        toast.error(error.message || "Error fetching user data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    };
    getUser();
  }, [tutorData.user?.id, tutorData.user_id]);

  // Fetch booked slots from API
  useEffect(() => {
    const getSelectedSlot = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${tutorData.user?.id || tutorData.user_id}/bookings/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setBookedSlots(res.data);
      } catch (error) {
        console.log(error);
        toast.error("Error fetching booked slots", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    };
    getSelectedSlot();
  }, [tutorData.user?.id, tutorData.user_id]);

  // Memoize booked periods for overlap checking
  const bookedPeriods = useMemo(() => {
    return bookedSlots.map((booking) => ({
      start: DateTime.fromISO(booking.start_time, { setZone: true }).setZone(userTimezone),
      end: DateTime.fromISO(booking.end_time, { setZone: true }).setZone(userTimezone),
    }));
  }, [bookedSlots, userTimezone]);

  // Generate time slots based on tutor's availability and booked slots
  const generateTimeSlots = (selectedDate: string) => {
    if (!selectedDate || !tutorData?.availability || !userTimezone) return;

    const selectedDt = DateTime.fromISO(selectedDate, { zone: userTimezone });
    const selectedDay = selectedDt.weekday === 7 ? 0 : selectedDt.weekday; // Convert Luxon weekday (1-7, Mon-Sun) to tutor day (0-6, Sun-Sat)
    const availabilityForDay = tutorData.availability.filter(
      (slot: any) => slot.day === selectedDay
    );

    if (availabilityForDay.length === 0) {
      setTimeSlots([]);
      setAllSubjects(tutorData.subjects || []);
      return;
    }

    const slots: TimeSlot[] = [];
    const startOfDay = selectedDt.startOf("day");
    const endOfDay = selectedDt.endOf("day");
    const now = DateTime.now().setZone(userTimezone);

    // Filter time_off periods that overlap with the selected day
    const timeOffPeriods = tutorData.time_off?.filter((to: any) => {
      const toStart = DateTime.fromISO(to.start_datetime, { setZone: true }).setZone(userTimezone);
      const toEnd = DateTime.fromISO(to.end_datetime, { setZone: true }).setZone(userTimezone);
      return toStart < endOfDay && toEnd > startOfDay;
    }).map((to: any) => ({
      start: DateTime.fromISO(to.start_datetime, { setZone: true }).setZone(userTimezone),
      end: DateTime.fromISO(to.end_datetime, { setZone: true }).setZone(userTimezone),
    })) || [];

    for (const avail of availabilityForDay) {
      const [startHour, startMinute] = avail.start_time.split(":").map(Number);
      const [endHour, endMinute] = avail.end_time.split(":").map(Number);

      let currentTime = startOfDay.set({ hour: startHour, minute: startMinute });
      const endTime = startOfDay.set({ hour: endHour, minute: endMinute });

      while (currentTime < endTime) {
        const slotStartUTC = currentTime.toUTC().toISO();
        const formattedTime = currentTime.toFormat("HH:mm");
        const slotEnd = currentTime.plus({ minutes: 30 });

        // Check if the slot is in the past (only for today)
        const isPast = selectedDt.hasSame(now, "day") && currentTime < now;

        // Check if the slot overlaps with any time_off period
        const isTimeOff = timeOffPeriods.some(
          (to: any) => currentTime < to.end && to.start < slotEnd
        );

        if (!isPast && !isTimeOff) {
          const isBooked = bookedPeriods.some(
            (bp) => currentTime < bp.end && bp.start < slotEnd
          );

          slots.push({
            time: formattedTime,
            startUTC: slotStartUTC,
            isBooked,
          });
        }

        currentTime = currentTime.plus({ minutes: 30 });
      }
    }

    // Sort the slots by their start time
    slots.sort((a, b) => {
      const timeA = DateTime.fromFormat(a.time, "HH:mm", { zone: userTimezone });
      const timeB = DateTime.fromFormat(b.time, "HH:mm", { zone: userTimezone });
      return timeA.toMillis() - timeB.toMillis();
    });

    setTimeSlots(slots);
    setAllSubjects(tutorData.subjects || []);
  };

  useEffect(() => {
    generateTimeSlots(selectedDate);
  }, [tutorData, selectedDate, bookedSlots, userTimezone]);

  const handleDateChange = (e: any) => {
    const newDate = e.target.value;
    if (newDate >= today) {
      setSelectedDate(newDate);
      setSelectedTimes([]); // Reset selected times when date changes
    } else {
      toast.error("Cannot select a past date.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();
      dateInputRef.current.showPicker();
    }
  };

  const isSlotSelectable = (slot: TimeSlot) => {
    const slotStart = DateTime.fromISO(slot.startUTC!, { setZone: true }).setZone(userTimezone);
    const slotEnd = slotStart.plus({ minutes: 30 });

    // Check if slot overlaps with booked periods
    const isBooked = bookedPeriods.some(
      (bp) => slotStart < bp.end && bp.start < slotEnd
    );

    // Check if slot is already selected elsewhere
    const isAlreadySelected = selectedTimes.some(
      (s) => s.time !== slot.time && // Exclude current slot if toggling off
        DateTime.fromISO(s.startUTC!, { setZone: true }).setZone(userTimezone).equals(slotStart)
    );

    return !isBooked && !isAlreadySelected;
  };

  const handleTimeSelection = (slot: TimeSlot) => {
    if (slot.isBooked) return;

    if (!tutorData.is_trialed && selectedTimes.length >= 1) {
      setSelectedTimes(selectedTimes.some((s) => s.time === slot.time) ? [] : [slot]);
      return;
    }

    setSelectedTimes((prev) => {
      const isSelected = prev.some((s) => s.time === slot.time);
      if (isSelected) {
        return prev.filter((s) => s.time !== slot.time);
      } else if (isSlotSelectable(slot)) {
        return [...prev, slot];
      } else {
        toast.error("This slot is not available for booking.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        return prev;
      }
    });
  };

  const handleSubjectChange = (e: any) => {
    setBookingDetails((prev: any) => ({
      ...prev,
      subject: parseInt(e.target.value, 10),
    }));
  };

  const handleBooking = async () => {
    if (!tutorData.is_trialed && selectedTimes.length > 1) {
      toast.error("You can only book one time slot for trial users.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (selectedTimes.length === 0) {
      toast.error("Please select at least one time slot.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    // Sort selected times by UTC
    const sortedSelectedTimes = [...selectedTimes].sort((a, b) =>
      a.startUTC!.localeCompare(b.startUTC!)
    );
    const startUTC = sortedSelectedTimes[0].startUTC;
    const lastSlot = sortedSelectedTimes[sortedSelectedTimes.length - 1];
    const endUTC = DateTime.fromISO(lastSlot.startUTC!, { setZone: true })
      .plus({ minutes: 30 * sortedSelectedTimes.length })
      .toUTC()
      .toISO();

    const updatedBookingDetails = {
      customer: bookingDetails.customer,
      tutor: bookingDetails.tutor,
      subject: bookingDetails.subject,
      start_time: startUTC,
      end_time: endUTC,
    };

    try {
      if (
        updatedBookingDetails.customer &&
        updatedBookingDetails.tutor &&
        updatedBookingDetails.subject &&
        updatedBookingDetails.start_time &&
        updatedBookingDetails.end_time
      ) {
        await axios.post(
          "https://api.nadwa-uae.com/api/v2/customer/trials-calls/",
          updatedBookingDetails,
          { headers: { Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}` } }
        );
        setIsThirdModalOpen(true);
      } else {
        toast.error("Please complete all booking details.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error: any) {
      toast.error(
        error?.response?.data?.non_field_errors?.[0] || "Something went wrong, try again",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        }
      );
    }
  };

  return (
    <div className="mx-auto lg:p-6 max-h-[70vh] overflow-y-auto z-50">
      <ToastContainer />
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-6">
          <div className="relative">
            <h2 className="text-lg font-medium mb-3">
              {i18n.language === "en" ? "Available Calendar Days" : "أيام التقويم المتاحة"}
            </h2>
            <input
              ref={dateInputRef}
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              onClick={handleDateClick}
              min={today}
              className="w-full px-4 py-2.5 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500 cursor-pointer"
            />
          </div>

          <div>
            <h2 className="text-lg font-medium mb-3">
              {i18n.language === "en" ? "Available Tutoring Sessions" : "الجلسات التعليمية المتاحة"}
            </h2>
            <div className="grid grid-cols-4 gap-3">
              {timeSlots.length === 0 ? (
                <p className="col-span-4 text-gray-500">{i18n.language === "en" ? "No available slots." : "لا فترات متاحة."}</p>
              ) : (
                timeSlots.map((slot, index) => (
                  <button
                    key={index}
                    disabled={slot.isBooked || (!isSlotSelectable(slot) && !selectedTimes.some((s) => s.time === slot.time))}
                    onClick={() => handleTimeSelection(slot)}
                    className={`px-4 py-2 rounded-xl text-sm font-medium transition-colors duration-200
                      ${selectedTimes.some((s) => s.time === slot.time)
                        ? "bg-[#008847] text-white hover:bg-[#006b38]"
                        : slot.isBooked || !isSlotSelectable(slot)
                          ? "bg-gray-200 text-gray-500 opacity-70 cursor-not-allowed"
                          : "bg-[#0088471A] text-black hover:bg-[#00884733]"
                      }`}
                  >
                    {slot.isBooked || !isSlotSelectable(slot) ? "Booked" : slot.time}
                  </button>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl p-6 space-y-6">
          <X
            size={24}
            className={`absolute top-4 ${i18n.language == "ar" ? "left-4" : "right-4"} cursor-pointer`}
            onClick={() => setShowModal(false)}
          />
          <h2 className="text-lg font-medium">
            {i18n.language === "en" ? "Booking Summary" : "ملخص الحجز"}
          </h2>

          <div className="space-y-2 relative">
            <label className="block text-sm font-medium">{t("subjects")}</label>
            <select
              value={bookingDetails.subject || ""}
              onChange={handleSubjectChange}
              className="w-full px-4 py-2.5 border rounded-lg appearance-none"
            >
              <option value="">{t("select_subject")}</option>
              {allSubjects.map((subject: any, index: number) => (
                <option key={index} value={subject.id}>
                  {subject.name}
                </option>
              ))}
            </select>
            <ChevronDown className="absolute right-3 top-1/2 translate-y-1/4 w-5 h-5 text-gray-400 pointer-events-none" />
          </div>

          {selectedTimes.length > 0 && (
            <div className="mt-4">
              <h3 className="text-sm font-medium text-gray-700">
                {i18n.language === "en" ? "Selected Time Slots" : "الفترات الزمنية المُختارة"}
              </h3>
              <div className="flex flex-wrap gap-2 mt-2">
                {selectedTimes.map((slot, index) => (
                  <div
                    key={index}
                    className="px-3 py-1 bg-green-100 text-green-700 rounded-md text-sm flex items-center"
                  >
                    {slot.time}
                    <X
                      size={16}
                      className="ml-2 cursor-pointer hover:text-red-500"
                      onClick={() => handleTimeSelection(slot)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex gap-3 pt-4">
            <button
              onClick={() => setShowModal(false)}
              className="flex-1 px-4 py-2.5 bg-gray-200 text-gray-700 rounded-lg"
            >
              {t("buttons.cancel")}
            </button>
            <button
              disabled={selectedTimes.length === 0 || bookingDetails.subject === null}
              onClick={handleBooking}
              className={`flex-1 px-4 py-2.5 rounded-lg
                ${selectedTimes.length === 0 || bookingDetails.subject === null
                  ? "bg-gray-400 text-white cursor-not-allowed"
                  : "bg-green-600 text-white hover:bg-green-700"
                }`}
            >
              {t("book_now")}
            </button>
          </div>
        </div>
      </div>

      {isThirdModalOpen && (
        <TrialConfirmationModal
          setActiveTab={setActiveTab}
          setShowModal={setShowModal}
          bookingDetails={bookingDetails}
          tutor={tutorData}
          selectedDate={selectedDate}
          selectedTime={selectedTimes[0].time} // Pass the local time of the first selected slot
          isOpen={isThirdModalOpen}
          onClose={() => setIsThirdModalOpen(false)}
        />
      )}
    </div>
  );
}