import { useEffect } from "react";
import LoginStudentForm from "../../components/AuthComponents/LoginStudentForm";
export default function LoginStudent() {
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])
  return (
    <div className="lg:mt-[-50px] w-screen">
      <LoginStudentForm />
    </div>
  );
}
