import axios from "axios";
import { useEffect, useState } from "react";
import DeleteJobModal from "./DeleteJobs";
import ViewTutorCard from "./ViewJob";
import { MutatingDots } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const TutorCard = ({
  job,
  setViewSingleJob,
  setDeleteId,
  deleteId,
  setAllJobs,
}: any) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
const {t} = useTranslation()
  return (
    <>
      <div className="bg-[#00884705] rounded-2xl border-2 border-[#008847] shadow-md p-4 space-y-4">
        <div className="flex items-start gap-3">
          <div className="flex justify-between w-full lg:items-end flex-col lg:flex-row">
            <div className="flex items-center justify-between gap-1">
              <div className="flex flex-col">
                <h2 className="text-base font-medium">
                  {job?.subject} {job?.level}
                </h2>
              </div>
            </div>
            <div className="relative w-auto">
              <button className="flex items-center gap-2 px-3 py-2 border rounded-lg border-black w-full">
                <span>
                  {job?.applications.length > 0
                    ? `${job.applications.length} ${t('posted_jobs_page.applications_so_far')}`
                    : "No Applications Yet"}
                </span>
                <div className="flex -space-x-2">
                  {job.applications
                    .slice(0, 3)
                    .map((app: any, index: number) => (
                      <picture key={index}>
                        <source srcSet={app?.photo} type="image/webp" />
                        <img
                          src={
                            app?.photo?.replace(".webp", ".jpg") ||
                            "fallback.jpg"
                          }
                          alt="img"
                          className="w-6 h-6 rounded-full border-2 border-white"
                        />
                      </picture>
                    ))}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => {
              setDeleteOpen(true);
              setDeleteId(job.id);
            }}
            className="flex-1 bg-[#EE201C1A] text-[#EE201C] px-4 py-2 rounded-lg font-medium transition-colors border-2 border-[#EE201C] lg:text-base text-sm"
          >
           {t('posted_jobs_page.delete_job_post')}
          </button>
          <button
            onClick={() => setViewSingleJob(job.id)} // Pass the job ID to parent
            className="flex-1 bg-[#008847] text-white lg:px-4 lg:py-2 rounded-lg font-medium transition-colors lg:text-base text-sm"
          >
           { t('posted_jobs_page.view_job')}
          </button>
        </div>
      </div>
      {deleteOpen && (
        <DeleteJobModal
          deleteId={deleteId}
          isOpen={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          onDelete={() => {
            setAllJobs((prevJobs: any) =>
              prevJobs.filter((j: any) => j.id !== job.id)
            );
            setDeleteOpen(false);
          }}
        />
      )}
    </>
  );
};

const PostedJobs = ({ setActiveTab, setSelectedJobId, selectedJobId }: any) => {
  const [allJobs, setAllJobs] = useState<any>([]);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getJobs = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v3/customer/jobs/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setAllJobs(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getJobs();
  }, [deleteId]);

  return (
    <div className="bg-white rounded-xl h-screen overflow-y-scroll">
      <div className="max-w-[95%] mx-auto grid grid-cols-1 gap-8 py-10">
        {selectedJobId ? (
          <ViewTutorCard
            setActiveTab={setActiveTab}
            tutorId={selectedJobId} // This is actually the job ID
            setViewSingleJob={() => setSelectedJobId(null)}
          />
        ) : loading ? (
          <div className="flex justify-center items-center">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              secondaryColor="#4fa94d"
              radius="12.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : allJobs.length > 0 ? (
          allJobs.map((job: any, index: number) => (
            <TutorCard
              key={index}
              job={job}
              setDeleteId={setDeleteId}
              deleteId={deleteId}
              setViewSingleJob={setSelectedJobId}
              setAllJobs={setAllJobs}
            />
          ))
        ) : (
          <p className="text-gray-500">No Jobs Posted</p>
        )}
      </div>
    </div>
  );
};

export default PostedJobs;