import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import ForgotPassword from "./components/AuthComponents/ForgotPassword";
import TutorDetail from "./components/DashBoardComponents/TutorDetail";
import Footer from "./components/Footer";
import NavbarComponent from "./components/NavbarComponent";
import { FilterProvider } from "./ContextApi/FilterContext";
import LoginStudent from "./screens/auth/LoginStudent";
import UpdatePassword from "./screens/auth/reset-password";
import SignupStudent from "./screens/auth/SignupStudent";
import SignupTutor from "./screens/auth/SignupTutor";
import CustomerDashboard from "./screens/Dashboard/CustomerDashboard";
import DashboardTutor from "./screens/Dashboard/DashboardTutor";
import About from "./screens/web-screens/About";
import AboutTutor from "./screens/web-screens/AboutTutor";
import Blog from "./screens/web-screens/Blog";
import ContactForm from "./screens/web-screens/ContactUs";
import EditProfileTutor from "./screens/web-screens/EditProfileTutor";
import ErrorPage from "./screens/web-screens/ErrorPage";
import Home from "./screens/web-screens/Home";
import HowItWork from "./screens/web-screens/HowItWork";
import PrivacyPolices from "./screens/web-screens/PrivacyPolices";
import SingleBlog from "./screens/web-screens/SingleBlog";
import Terms from "./screens/web-screens/Terms";
import Tutor from "./screens/web-screens/Tutor";
import TermlyScript from "./thirdparty/TermlyScript";
function App() {
  return (

    <FilterProvider>
      <div className="overflow-hidden ">
        <Router future={{ v7_startTransition: true }}>
          <TermlyScript />
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Aboutus" element={<About />} />
              <Route path="/Tutor/:id" element={<AboutTutor />} />
              <Route path="/:id" element={<TutorDetail />} />
              <Route path="/About" element={<About />} />
              <Route path="/login" element={<LoginStudent />} />
              <Route path="/SignupTutor" element={<SignupTutor />} />
              <Route path="/SignupStudent" element={<SignupStudent />} />
              <Route path="/dashboard" element={<DashboardTutor />} />
              <Route path="/reset" element={<ForgotPassword />} />
              <Route path="/update/:token" element={<UpdatePassword />} />
              <Route path="/customer-dashboard" element={<CustomerDashboard />} />
              <Route path="/EditProfile" element={<EditProfileTutor />} />
              <Route path="/ContactUs" element={<ContactForm />} />
              <Route path="/how-it-works" element={<HowItWork />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/Blogs/:id" element={<SingleBlog />} />
              <Route path="/Tutor" element={<Tutor />} />
              <Route path="/term-conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolices />} />
              <Route path="*" element={<ErrorPage />} />
              {/* Write Third */}
            </Routes>
          </Layout>
          <Footer />
        </Router>
      </div>
    </FilterProvider>
  );
}

// Layout component
const Layout = ({ children }: any) => {
  const location = useLocation();
  const noNavbarRoutes = ["/Dashboard", "/dashboard", "/SignupTutor", "/login", "/SignupStudent", "/EditProfile", "/customer-dashboard", '/reset', '/update'];
  const showNavbar = !noNavbarRoutes.includes(location.pathname);
  return (
    <>
      {showNavbar && <NavbarComponent />}
      {children}
    </>
  );
};

export default App;
