import axios from "axios";
import { AlignJustify, Eye, Trash2 } from "lucide-react";
import { useEffect, useRef, useState } from "react"; // Added useRef
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import QualificationExperience from "../../components/AboutTutorComponents/QualificationExperience";
import ReviewsCarousel from "../../components/AboutTutorComponents/ReviewsCarousel";
import WeeklyCalendarExact from "../../components/AboutTutorComponents/TutorTimingCalender";
import TutorVideo from "../../components/AboutTutorComponents/TutorVideo";
import FilterSectionCustomer from "../../components/DashBoardStudent/FilterSectionCustomer";
import InboxMessages from "../../components/DashBoardStudent/InboxMessages";
import EmailVerificationModal from "../../components/DashBoardStudent/popups/Verify";
import PostedJobs from "../../components/DashBoardStudent/postedJobs";
import SubjectPopup from "../../components/DashBoardStudent/RequestTutor1";
import StudentProfileCard from "../../components/DashBoardStudent/StudentProfileCard";
import StudentSidebar from "../../components/DashBoardStudent/StudentSideBar";
import TutorProfileCard2 from "../../components/DashBoardStudent/TutorIntroduction2";
import TutorsResultsCustomer from "../../components/DashBoardStudent/TutorResultsCustomer";
import i18n from "../../i18n";
import StudentBooking from "../../components/StudentBookings/StudentBookings";

const CustomerDashboard = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [user, setUser] = useState<any>({});
  const [tutorData, setTutorData] = useState<any[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [tutor, setTutor] = useState(false);
  const [tutorId, setTutorId] = useState(0);
  const [verify, setVerify] = useState<boolean | undefined>();
  const [chatId, setTutorChatId] = useState<any>();
  const [tutorIds, setTutorIds] = useState<any>();
  const [tabs, setTabs] = useState<any>();
  const [data, setData] = useState<any>(null); // Notification data
  const [isTrue, setIsTrue] = useState(false);
  const [unreadMessage, setUnreadMessage] = useState<number | null>(null);
  const [selectedJobId, setSelectedJobId] = useState<any>(null);
  const [message, setMessage] = useState<any>();
  const [activeTab3, setActiveTab3] = useState("upcoming");
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>(null);

  // Sync activeTab with URL hash
  useEffect(() => {
    const hash = location.hash.replace("#", "").toLowerCase();
    const validTabs = ["dashboard", "inbox", "jobs", "booking", "findtutor"];
    if (hash && validTabs.includes(hash)) {
      setActiveTab(hash);
    } else {
      setActiveTab("dashboard");
      navigate("/customer-dashboard#dashboard", { replace: true });
    }
  }, [location.hash, navigate]);

  // Fetch initial tab settings from localStorage
  useEffect(() => {
    setTabs(localStorage.getItem("Active_Tabs"));
    setTutorIds(localStorage.getItem("TutorId"));
  }, []);

  // Fetch user profile
  useEffect(() => {
    const isToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!isToken) {
      navigate("/login");
      return;
    }
    const fetchUserProfile = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setUser(res.data.data.user);
        setVerify(res.data.data.user.email_verified);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    fetchUserProfile();
  }, [navigate]);

  // Fetch tutor applications
  useEffect(() => {
    const isToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!isToken) return;
    const fetchTutorData = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/schoolsupply/applications/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(res.data.results);
      } catch (error) {
        console.error("Error fetching tutor data:", error);
      }
    };
    fetchTutorData();
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])
  // Scroll to top when tutor profile is displayed
  useEffect(() => {
    if (tutor && scrollRef.current) {
      scrollRef.current.scrollTo(0, 0);
    }
  }, [tutor]);

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  // Handle tab change and update URL
  const handleTabChange = (tab: string, subTab?: string) => {
    setActiveTab(tab);
    if (subTab) {
      setActiveTab3(subTab);
    }
    navigate(`/customer-dashboard#${tab.toLowerCase()}`);
    setMenuOpen(false);
  };
  useEffect(() => {
    const fetchLessonsAndCheckNotifications = async () => {
      const isToken = localStorage.getItem("TH_AUTH_TOKEN");
      if (!isToken) return;

      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/customer/bookings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        const allLessons = res.data;
        const currentTime = new Date();

        let notificationSet = false;
        allLessons.forEach((lesson: any) => {
          const lessonStartTime = new Date(lesson.start_time);
          const timeDifference = lessonStartTime.getTime() - currentTime.getTime();
          // Check for lessons within 15 minutes that are accepted
          if (
            lesson.is_accepted_by_customer &&
            lesson.is_accepted_by_tutor &&
            timeDifference > 0 &&
            timeDifference <= 15 * 60 * 1000
          ) {
            const timeLeft = Math.floor(timeDifference / 1000 / 60);
            const message = `Your lesson on ${lesson.subject.name} with ${lesson.tutor.name} is starting in ${timeLeft} minutes.`;
            setData({
              message,
              subTab: lesson.is_trial_call ? "trial-call" : "upcoming",
            });
            setIsTrue(true);
            notificationSet = true;
          }
        });
        // Clear notification if no upcoming lessons within 15 minutes
        if (!notificationSet) {
          setData(null);
          setIsTrue(false);
        }
      } catch (error) {
        console.error("Error fetching lessons for notification:", error);
      }
    };

    fetchLessonsAndCheckNotifications();
    const interval = setInterval(fetchLessonsAndCheckNotifications, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="flex bg-gray-100 h-auto">
        {data && isTrue && (
          <div
            onClick={() => handleTabChange("booking", data.subTab)}
            className="top-5 bg-[#008847] text-white p-4 mb-4 rounded-lg absolute right-10 cursor-pointer"
          >
            <strong>{t("dashboard_page.reminder")}</strong> {data.message}
          </div>
        )}
        {verify !== undefined && (
          <EmailVerificationModal
            isOpen={!verify}
            onClose={() => setVerify(true)}
            emails={user.email ?? ""}
          />
        )}
        <StudentSidebar
          setMessage={setMessage}
          message={message}
          unreadMessage={unreadMessage}
          setUnreadMessage={setUnreadMessage}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
        />
        <div className="flex-1 space-y-4 lg:p-6">
          <div className="flex justify-between items-center lg:bg-none p-4 lg:p-0">
            <AlignJustify
              onClick={() => setMenuOpen(!menuOpen)}
              className="lg:hidden block w-8 h-8 cursor-pointer"
            />
            <h1 className="text-[#000606] font-normal text-2xl lg:text-3xl lg:block hidden">
              {activeTab === "dashboard"
                ? t("dashboard_page.dashboard")
                : activeTab === "inbox"
                  ? t("dashboard_page.overview_menu.inbox")
                  : activeTab === "jobs"
                    ? t("posted_jobs_page.here_are_the_jobs_you_have_posted")
                    : activeTab === "booking"
                      ? t("dashboard_page.overview_menu.bookings")
                      : t("find_a_tutor")}
            </h1>
            <div className="flex bg-white rounded-[24px] justify-center items-center p-2">
              <h1 className="text-sm text-[#292D32] lg:block hidden font-bold uppercase">
                {user.first_name || ""}
              </h1>
            </div>
          </div>

          {activeTab === "dashboard" && (
            <div className="space-y-8 lg:space-y-16 px-6 md:px-0">
              <h1 className="text-[#000606] font-normal text-3xl lg:hidden block">
                {t("dashboard_page.dashboard")}
              </h1>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                <div className="col-span-2 bg-white p-4 rounded-lg shadow">
                  <div className="flex flex-col lg:flex-row items-center justify-between">
                    <h2 className="text-lg font-semibold mb-4 lg:mb-0">
                      {t("dashboard_page.tutor_applications")}
                    </h2>
                    <div className="flex gap-2">
                      <SubjectPopup />
                      <button
                        onClick={() => setActiveTab("findtutor")}
                        className="bg-[#f2f9f6] text-[#008847] lg:p-2 py-1 px-2 no-underline rounded-md text-sm lg:text-base border border-[#008847]"
                      >
                        {t("dashboard_page.browse_other_tutors")}
                      </button>
                    </div>
                  </div>
                  <div className="mt-4 overflow-x-auto">
                    <table className="text-left border-separate border-spacing-y-2 w-full">
                      <thead>
                        <tr>
                          <th className="p-2 border-b">{t("dashboard_page.filters.tutor")}</th>
                          <th className="p-2 border-b lg:table-cell hidden">
                            {t("dashboard_page.filters.message")}
                          </th>
                          <th className="p-2 border-b">{t("dashboard_page.filters.subject")}</th>
                          <th className="p-2 border-b">{t("dashboard_page.filters.date")}</th>
                          <th className="p-2 border-b">{t("dashboard_page.filters.action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tutorData.length > 0 ? (
                          tutorData.map((job, index) => (
                            <tr key={index}>
                              <td className="p-2 text-[#060606] text-sm">{job.tutor.name}</td>
                              <td
                                className="p-2 text-[#008847] font-semibold lg:text-lg lg:table-cell hidden cursor-pointer"
                                onClick={() => handleTabChange("inbox")}
                              >
                                {t("dashboard_page.view_message")}
                              </td>
                              <td className="p-2 text-[#060606] text-xs md:text-sm">
                                {job.job_post.subject} ({job.job_post.level})
                              </td>
                              <td className="p-2 text-[#060606] text-xs md:text-sm">
                                {job.applied_at.toString().slice(0, 10)}
                              </td>
                              <td
                                onClick={() => handleTabChange("jobs")}
                                className="p-2 text-gray-400 cursor-pointer"
                              >
                                <Trash2 color="red" />
                              </td>
                              <td
                                className="p-2 text-[#008847] font-semibold lg:text-lg lg:table-cell hidden cursor-pointer"
                                onClick={() => {
                                  setSelectedJobId(job.job_post.id);
                                  handleTabChange("jobs");
                                }}
                              >
                                <Eye className="text-black" />
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="p-2 text-[#060606] text-sm">
                              {t("dashboard_page.no_applications")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <StudentProfileCard
                  setActiveTab3={setActiveTab3}
                  setActiveTab2={setActiveTab}
                  stuData={user}
                />
              </div>
            </div>
          )}
          {activeTab === "inbox" && (
            <InboxMessages
              unreadMessage={message}
              setUnreadMessage={setMessage}
              setActiveTab={handleTabChange}
              setMenuOpen={setMenuOpen}
              chatId={chatId}
            />
          )}
          {activeTab === "jobs" && (
            <PostedJobs
              setSelectedJobId={setSelectedJobId}
              selectedJobId={selectedJobId}
              setActiveTab={handleTabChange}
            />
          )}
          {activeTab === "booking" && (
            <div className="overflow-y-scroll max-h-screen relative">
              <StudentBooking
                setActiveTab3={setActiveTab3}
                activeTab3={activeTab3}
                setActiveTab={handleTabChange}
                setUnreadMessage={setUnreadMessage}
                unreadMessage={unreadMessage}
                setIsTrue={setIsTrue}
                setData={setData}
                setTutorChatId={setTutorChatId}
                setActiveTabs2={handleTabChange}
              />
            </div>
          )}
          {(activeTab === "findtutor" || tabs === "findTutor") && tutor ? (
            <div ref={scrollRef} className="max-h-screen overflow-y-scroll">
              <TutorProfileCard2
                setActiveTab={setActiveTab}
                tutorId={tutorIds || tutorId}
                setTutor={setTutor}
              />
              <TutorVideo tutorId={tutorId} />
              <QualificationExperience tutorId={tutorId} />
              <WeeklyCalendarExact tutorId={tutorId} />
              <ReviewsCarousel tutorId={tutorId} />
            </div>
          ) : (activeTab === "findtutor" || tabs === "findTutor") ? (
            <div className="max-h-screen overflow-y-scroll">
              <FilterSectionCustomer />
              <TutorsResultsCustomer setTutor={setTutor} setTutorId={setTutorId} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CustomerDashboard;