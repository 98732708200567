import axios from "axios";
import { AlignJustify } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import TutoringPermit from "../../components/ApplyForPermitTutor/ApplyForPermit";
import AvailabilityCalendar from "../../components/AvailabilityTutor/AVailabiltyTutor";
import ProfileCard from "../../components/DashBoardComponents/ProfileCard";
import Sidebar from "../../components/DashBoardComponents/SideBar";
import TutorBookings from "../../components/TutorBookings/TutorBookings";
import TutorInbox from "../../components/TutorInbox/TutorInbox";
import TutorJobsBoard from "../../components/TutorJobsBoard/TutorJobsBoard";
import i18n from "../../i18n";

type Job = {
  student: {
    first_name: string;
    last_name: string;
  };
  job_post: {
    subject: string;
    level: string;
    start_date: string;
    id: number;
  };
  status: string;
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [bookingSubTab, setBookingSubTab] = useState("upcoming"); // State for booking sub-tab
  const [tutorData, setTutorData] = useState<any>([]);
  const [jobsData, setJobsData] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [messageId, setMessageId] = useState(null);
  const [viewjobId, setViewJobId] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [data, setData] = useState<any>(null); // Notification data
  const [isTrue, setIsTrue] = useState(false); // Notification visibility flag
  const [alllesson, setAlllesson] = useState<any>([]); // Renamed for consistency
  const [isJobBoard, setIsJobBoard] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  // Sync active tab with URL hash
  useEffect(() => {
    const hash = location.hash.replace("#", "").toLowerCase();
    const validTabs = [
      "dashboard",
      "applypermit",
      "available",
      "jobsboard",
      "inbox",
      "booking",
    ];
    if (hash && validTabs.includes(hash)) {
      setActiveTab(hash);
    } else {
      setActiveTab("dashboard");
      navigate("/dashboard#dashboard", { replace: true });
    }
  }, [location.hash, navigate]);

  // Handle tab change with optional subTab parameter
  const handleTabChange = (tab: string, subTab?: string) => {
    setActiveTab(tab);
    if (subTab && tab === "booking") {
      setBookingSubTab(subTab);
    }
    navigate(`/dashboard#${tab.toLowerCase()}`);
    setMenuOpen(false);
  };

  // Fetch lessons and check for notifications periodically
  useEffect(() => {
    const fetchAndCheckNotifications = async () => {
      try {
        const response = await axios.get<any>(
          "https://api.nadwa-uae.com/api/v2/tutor/bookings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        const lessons = response.data;
        setAlllesson(lessons);

        const currentTime = new Date();
        for (const lesson of lessons) {
          const lessonStartTime = new Date(lesson.start_time);
          const timeDifference = lessonStartTime.getTime() - currentTime.getTime();
          if (
            lesson.is_accepted_by_customer &&
            lesson.is_accepted_by_tutor &&
            timeDifference > 0 &&
            timeDifference <= 15 * 60 * 1000
          ) {
            const timeLeft = Math.floor(timeDifference / (1000 * 60));
            const message = `Your lesson on ${lesson.subject.subject_name} with ${lesson.customer?.name} is starting in ${timeLeft} minutes.`;
            const subTab = lesson.is_trial_call ? "trial-call" : "upcoming";
            setData({ message, subTab });
            setIsTrue(true);
            return; // Set notification for the first upcoming lesson
          }
        }
        setData(null);
        setIsTrue(false);
      } catch (error) {
        console.error("Error fetching lessons for notification:", error);
      }
    };

    fetchAndCheckNotifications();
    const interval = setInterval(fetchAndCheckNotifications, 60000); // Check every minute
    return () => clearInterval(interval);
  }, []);

  // Fetch unread messages
  useEffect(() => {
    const getAllMessages = async () => {
      try {
        const res = await axios.get("https://api.nadwa-uae.com/api/v2/chat", {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        });
        const unreadMessages = res.data.map((val: any) => val.unread_count);
        const totalUnread = unreadMessages.reduce(
          (acc: any, count: any) => acc + count,
          0
        );
        if (totalUnread > 0) {
          toast.info(`You have ${totalUnread} unread message(s)`, {
            position: "top-right",
            autoClose: 5000,
            pauseOnHover: true,
          });
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    getAllMessages();
  }, []);

  // Fetch tutor and jobs data
  useEffect(() => {
    const isToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!isToken) {
      navigate("/");
      return;
    }
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(response.data);
      } catch (err) {
        setError("Failed to fetch tutor data");
      } finally {
        setLoading(false);
      }
    };

    const fetchJobsData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/schoolsupply/applications/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setJobsData(response.data.results);
      } catch (err) {
        setError("Failed to fetch jobs data");
      }
    };

    fetchTutorData();
    fetchJobsData();
  }, [navigate]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "accepted":
        return "text-[#1A932E] bg-[#d6ebd9]";
      case "delayed":
        return "text-[#DFA510] bg-[#faf1da]";
      case "rejected":
        return "text-[#EE201C] bg-[#fcd7d6]";
      default:
        return "text-gray-500";
    }
  };

  const viewHandler = (id: any) => {
    handleTabChange("jobsboard");
    setViewJobId(id);
  };

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, []);

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
        {data && isTrue && (
          <div
            onClick={() => handleTabChange("booking", data.subTab)}
            className="top-0 cursor-pointer bg-[#008847] text-white p-4 mb-4 rounded-lg fixed right-0 z-50"
          >
            <strong>Reminder:</strong> {data.message}
          </div>
        )}
        <Sidebar
          activeTab={activeTab}
          onTabChange={handleTabChange} // Updated to use handleTabChange
          menuIsOpen={menuOpen}
          setIsMenuOpen={setMenuOpen}
        />
        <div className="flex justify-between items-center lg:hidden lg:bg-none p-4 lg:p-0">
          <AlignJustify
            onClick={() => setMenuOpen(!menuOpen)}
            className="lg:hidden block w-8 h-8 cursor-pointer"
          />
          <h1 className="text-[#000606] font-normal text-2xl lg:text-3xl lg:block hidden">
            {activeTab === "dashboard"
              ? "Dashboard"
              : activeTab === "inbox"
                ? "Inbox"
                : activeTab === "jobsboard"
                  ? "Jobs Board"
                  : activeTab === "booking"
                    ? "Bookings"
                    : activeTab === "applypermit"
                      ? "Apply for Permit"
                      : "Availability"}
          </h1>
          <div className="flex items-center gap-3">
            <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
              <picture>
                <source srcSet={tutorData.photo} type="image/webp" />
                <img
                  src={
                    tutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"
                  }
                  alt={tutorData?.user?.first_name}
                  className="w-9 h-9 rounded-full"
                />
              </picture>
              <h1 className="text-sm text-[#292D32] font-normal lg:block hidden">
                {tutorData.user.first_name}
              </h1>
            </div>
          </div>
        </div>
        <div className="flex-1 space-y-4 p-3 sm:p-6 overflow-y-scroll">
          {activeTab === "dashboard" && (
            <div className="space-y-3">
              <div className="justify-between items-center flex">
                <h1 className="text-[#000606] font-normal text-3xl">
                  {t("dashboard")}
                </h1>
                <div className="items-center gap-3 lg:flex hidden">
                  <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
                    <picture>
                      <source srcSet={tutorData.photo} type="image/webp" />
                      <img
                        src={
                          tutorData?.photo?.replace(".webp", ".jpg") ||
                          "fallback.jpg"
                        }
                        alt={tutorData.user.first_name}
                        className="w-9 h-9 rounded-full"
                      />
                    </picture>
                    <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
                      {tutorData.user.first_name}
                    </h1>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 sm:gap-6">
                <div className="col-span-2 bg-white p-2 sm:p-4 rounded-lg shadow">
                  <h2 className="text-lg font-semibold">{t("jobs_applied")}</h2>
                  <div className="mt-4 overflow-x-auto">
                    <table className="w-full text-base sm:text-lg overflow-x-auto border-separate border-spacing-y-2">
                      <thead>
                        <tr>
                          <th className="sm:p-2 p-1 border-b">
                            {t("student")}
                          </th>
                          <th className="sm:p-2 p-1 border-b">
                            {t("request")}
                          </th>
                          <th className="sm:p-2 p-1 border-b">{t("date")}</th>
                          <th className="sm:p-2 p-1 border-b">{t("status")}</th>
                          <th className="sm:p-2 p-1 border-b">
                            {t("profile")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {error && (
                          <div className="text-red-500 mb-4">{error}</div>
                        )}
                        {jobsData.length > 0 ? (
                          jobsData.map((job: Job, index: number) => (
                            <tr key={index}>
                              <td className="p-2 text-[#060606] text-sm">
                                {job.student.first_name} {job.student.last_name}
                              </td>
                              <td className="p-2 text-[#060606] text-sm">
                                {job.job_post.subject} {job.job_post.level}
                              </td>
                              <td className="p-2 text-[#060606] text-sm">
                                {new Date(
                                  job.job_post.start_date
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </td>
                              <td>
                                <span
                                  className={`p-2 font-medium rounded-xl ${getStatusColor(
                                    job.status
                                  )}`}
                                >
                                  {t(job.status)}
                                </span>
                              </td>
                              <td
                                title={(() => {
                                  const currentDate = new Date();
                                  const jobStartDate = new Date(
                                    job.job_post.start_date
                                  );
                                  const isNotExpired =
                                    job.status === "awaiting" &&
                                    jobStartDate >=
                                    new Date(
                                      currentDate.setHours(0, 0, 0, 0)
                                    );
                                  return !isNotExpired
                                    ? "This job has expired"
                                    : "";
                                })()}
                                onClick={() => {
                                  const currentDate = new Date();
                                  const jobStartDate = new Date(
                                    job.job_post.start_date
                                  );
                                  const isNotExpired =
                                    job.status === "awaiting" &&
                                    jobStartDate >=
                                    new Date(
                                      currentDate.setHours(0, 0, 0, 0)
                                    );
                                  if (isNotExpired) {
                                    viewHandler(job.job_post.id);
                                  }
                                }}
                                className={`p-2 w-fit font-medium text-sm ${(() => {
                                  const currentDate = new Date();
                                  const jobStartDate = new Date(
                                    job.job_post.start_date
                                  );
                                  const isNotExpired =
                                    job.status === "awaiting" &&
                                    jobStartDate >=
                                    new Date(
                                      currentDate.setHours(0, 0, 0, 0)
                                    );
                                  return isNotExpired
                                    ? "text-[#008847] cursor-pointer"
                                    : "text-gray-400 cursor-not-allowed";
                                })()}`}
                              >
                                {t("view")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p>No jobs you have applied</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ProfileCard
                  onSeeAll={() => handleTabChange("jobsboard")}
                  TutorData={tutorData}
                />
              </div>
            </div>
          )}
          {activeTab === "applypermit" && <TutoringPermit />}
          {activeTab === "available" && <AvailabilityCalendar />}
          {activeTab === "jobsboard" && (
            <TutorJobsBoard
              setViewJobId={setViewJobId}
              viewId={viewjobId}
              isJobBoard={isJobBoard}
              onTabChange={handleTabChange} // Pass the tab change handler
            />
          )}
          {activeTab === "booking" && (
            <TutorBookings
              initialSubTab={bookingSubTab} // Pass the sub-tab state
              isTrue={isTrue}
              setIsTrue={setIsTrue}
              setData={setData}
              setMessageId={setMessageId}
              sendMessage={() => handleTabChange("inbox")}
            />
          )}
          {activeTab === "inbox" && <TutorInbox messageId={messageId} />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
