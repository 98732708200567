"use client";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactMediaRecorder } from "react-media-recorder";
import ReactPlayer from "react-player";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function RecordVideo({ updateUi, setUpdateUi }: any) {
  const [videoLink, setVideoLink] = useState<string | null>(null);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showDemoVideo, setShowDemoVideo] = useState(false);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const { t } = useTranslation();
  const API_URL = "https://api.nadwa-uae.com/api/v2/tutor/profile/";
  const AUTH_TOKEN = localStorage.getItem("TH_AUTH_TOKEN");

  // Fetch the video URL from the API
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: { Authorization: `Token ${AUTH_TOKEN}` },
        });
        // Set both video and video_link from API response
        const apiVideoLink =
          response.data.video_link || response.data.video || null;
        if (apiVideoLink) {
          setVideoLink(apiVideoLink);
        }
      } catch (error) {
        console.error("Error fetching video:", error);
        toast.error("Failed to fetch video data.");
      }
    };

    fetchVideo();
  }, [updateUi]);

  // Validate URL
  const isValidUrl = (url: string) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  // Handle updating video link manually
  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVideoLink(e.target.value);
    setVideoBlob(null);
    setRecordedVideoUrl(null);
  };

  // Upload video link or recorded video
  const uploadVideo = async () => {
    if (!videoLink && !videoBlob) {
      toast.error("Please provide a video link or record a video.");
      return;
    }

    if (videoLink && !videoBlob && !isValidUrl(videoLink)) {
      toast.error("Please enter a valid URL.");
      return;
    }

    setIsUploading(true);
    const loadingToast = toast.loading("Uploading video...");

    try {
      const formData = new FormData();

      if (videoBlob) {
        formData.append("video", videoBlob, "recorded-video.webm");
      } else if (videoLink && !recordedVideoUrl) {
        // Only append video_link if it's not a recorded video
        formData.append("video_link", videoLink);
      }

      const response = await axios.patch(API_URL, formData, {
        headers: {
          Authorization: `Token ${AUTH_TOKEN}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const newVideoUrl = response.data.video_link || response.data.video;
      if (newVideoUrl) {
        setVideoLink(newVideoUrl);
      }
      toast.success("Video uploaded successfully!");
      setUpdateUi(!updateUi)
    } catch (error: any) {
      console.error("Error uploading video:", error);
      if (error.response?.data?.video_link) {
        toast.error(error.response.data.video_link[0]);
      } else {
        toast.error("Failed to upload video.");
      }
    } finally {
      toast.dismiss(loadingToast);
      setIsUploading(false);
    }
  };

  // Handle video recording stop
  const handleRecordingStop = (mediaBlobUrl: string, blob: Blob) => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setRecordedVideoUrl(url);
      setVideoLink(url); // Set the URL in the input field
      setVideoBlob(blob);
    }
    setIsRecording(false); // Stop recording and hide the stop button
  };

  // Toggle demo video modal
  const toggleDemoVideo = () => {
    setShowDemoVideo(!showDemoVideo);
  };

  return (
    <div className="space-y-6">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        transition={Bounce}
      />
      <h1 className="text-2xl font-semibold text-gray-800">
        {t("record_or_upload_video")}
      </h1>
      {t("video_preview")}
      {videoLink && (
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">{t("current_video")}</h2>
          <ReactPlayer
            url={recordedVideoUrl || videoLink || ""}
            controls
            width="100%"
            height="300px"
          />
        </div>
      )}
      {/* Video Recorder */}
      <div className="border p-4 rounded bg-gray-100">
        <h2 className="text-lg font-semibold mb-2">{t("start_recording")}</h2>
        <ReactMediaRecorder
          video
          render={({ startRecording, stopRecording, previewStream }) => (
            <>
              <div className="flex flex-wrap gap-3">
                {/* Show only Start Recording button if not recording */}
                {!isRecording && (
                  <button
                    onClick={() => {
                      startRecording();
                      setVideoLink(null); // Clear previous video link when starting new recording
                      setIsRecording(true); // Set recording state to true
                    }}
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    {t("start_recording")}
                  </button>
                )}

                {/* Show Stop Recording button only when recording */}
                {isRecording && (
                  <button
                    onClick={() => {
                      stopRecording();
                      if (previewStream) {
                        previewStream
                          .getTracks()
                          .forEach((track) => track.stop());
                      }
                    }}
                    className="bg-green-100 border-1 border-[#008847] text-[#008847] px-4 py-2 rounded "
                  >
                    Stop Recording
                  </button>
                )}

                <button
                  onClick={toggleDemoVideo}
                  className="bg-green-100 border-1 border-[#008847] text-[#008847] px-4 py-2 rounded "
                >
                  {t("watch_demo_video")}
                </button>
              </div>
            </>
          )}
          onStop={(blobUrl, blob) => handleRecordingStop(blobUrl, blob)}
        />
      </div>
      {/* Upload Video Link */}
      <div className="flex flex-col md:flex-row items-center gap-3">
        <input
          type="text"
          placeholder="e.g., https://www.youtube.com/... or https://vimeo.com/..."
          value={videoLink || ""}
          onChange={handleLinkChange}
          className="border p-2 rounded w-full placeholder-gray-400"
        />
        <button
          onClick={uploadVideo}
          disabled={isUploading}
          className="bg-[#008847] text-white px-4 py-2 rounded  w-[50%]"
        >
          {t("upload_link")}
        </button>
      </div>
      {/* Save Changes Button */}
      <div className="mt-6">
        <button
          onClick={uploadVideo}
          disabled={isUploading}
          className="bg-[#008847] text-white px-3 sm:px-6 py-2 sm:py-3 rounded"
        >
          {t("save_changes")}
        </button>
      </div>
      {/* Demo Video Modal */}
      {showDemoVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-2xl">
            <h2 className="text-xl font-semibold mb-4">{t("watch_demo_video")}</h2>
            <ReactPlayer
              url="https://vimeo.com/337237690"
              controls
              width="100%"
            />
            <button
              onClick={toggleDemoVideo}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              {t("back")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RecordVideo;
