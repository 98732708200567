import React from 'react'
import image1 from '../../images/asserts/Website-Assets/About Us/image2.webp'
import { ArrowRight } from 'lucide-react'
import bgimage from "../../images/asserts/Website-Assets/HomePage/bGimage8.webp";
import { useTranslation } from 'react-i18next';


const AboutWork = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <section className='sm:py-24 py-4 relative'>
      <div className='max-w-[94%] mx-auto'>
        <div className='flex flex-col md:flex-row justify-between gap-4'>
          <div className='md:w-1/2 flex flex-col justify-between'>
            <div className='xl:max-w-[80%]'>
              <img src={bgimage} alt="bgimage" className='absolute -z-10 w-96 md:w-fit md:-left-[50%] top-0' />
              <h3 className='xl:text-5xl text-2xl text-black mb-6 font-bold xl:leading-[65px]'>
                {t('working')}
              </h3>
              <p className='text-[15px] md:text-[22px]'>
                {t('working2')}
              </p>
            </div>
            <div className='flex flex-col lg:flex-row gap-4 lg:items-end'>
              <div className='flex flex-col gap-3 max-w-1/2'>
                <a href={"/tutor"} className='text-[#ED1D24] flex items-center gap-2 xl:text-[22px] no-underline w-fit'>
                  {t('working3')} <ArrowRight className='mt-1' />
                </a>
                <span className='xl:text-[22px] font-semibold'>{t('working4')}</span>
                <span className='xl:text-[22px]'>{t('working5')}</span>
              </div>
              <div className='flex flex-col gap-2 lg:w-1/2'>
                <span className='xl:text-[30px] font-bold text-2xl pr-2'>{t('working3')}</span>
                <p className='xl:text-[22px]'>
                  {t('working6')}
                </p>
              </div>
            </div>
          </div>
          <div className='md:w-1/2'>
            <img src={image1} alt='About Work' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutWork
