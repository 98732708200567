import React from "react";

export const JobSkeleton = () => {
  return (
    <div className="lg:w-[90%] grid grid-cols-1 2xl:grid-cols-2 mx-auto px-2 mt-10">
      {Array.from({ length: 4 }).map((_, index) => (
        <div key={index} className="border-2 lg:w-[520px] border-[#008847] bg-[#fafdfb] rounded-lg p-4 flex flex-col gap-4 mb-4 animate-pulse">
        <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0">
          <div className="flex justify-center overflow-hidden bg-gray-300 text-white items-center w-16 h-16 rounded-full">
            <div className="bg-gray-400 w-full h-full"></div>
          </div>
          <div className="flex flex-col flex-wrap w-[50%] gap-2">
            <div className="h-4 bg-gray-400 w-3/4 rounded"></div>
            <div className="flex gap-2 flex-wrap">
              {Array(3)
                .fill(0)
                .map((_, tagIndex) => (
                  <div
                    key={tagIndex}
                    className="w-20 h-4 bg-gray-300 rounded-lg"
                  ></div>
                ))}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="h-4 bg-gray-300 w-24 rounded-lg"></div>
            <div className="h-4 bg-gray-300 w-24 rounded-lg"></div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-3 justify-between md:mt-4">
          <div className="w-full bg-gray-300 h-10 rounded-lg"></div>
          <div className="w-full bg-gray-300 h-10 rounded-lg"></div>
        </div>
      </div>
      ))}
    </div>
  );
};
