export const texts = [{
    title: "Benefits of Having a Private Tutor Licence in UAE",
    description: "<b>Legally offering tutoring services:</b> With a valid license, you may provide private tutoring without facing fines, penalties, or legal ramifications. This assures that your educational activities are in accordance with UAE rules, offering piece of mind."
}, {
    description: "<b>Gaining Credibility and Trust:</b> A registered private teacher is seen as more dependable and competent. Knowing you fulfil MOHRE's tough criteria increases the likelihood that parents and students will trust your services."
}, {
    description: "<b>Expanding Your Client Base:</b> Having a UAE private teacher work permit boosts your professional standing, helping you to recruit a wider customer base. It also creates opportunities to collaborate with reputable educational institutions and platforms."
}, {
    description: "<b>Enhancing Career Growth:</b> A license not only increases your reputation, but it also places you for future collaborations, partnerships, and greater earnings in the competitive UAE education market."
}, {
    description: "<b>Showcasing Professionalism:</b> Showing that you have completed all of the prerequisites for a private tutor permit demonstrates your commitment to offering high-quality educational services."
}, {
    title: "Tips for Succeeding as a Licensed Tuto",
    description: "<b>Specialize in High-Demand Subjects:</b> Focus on subjects like mathematics, science, and languages to attract more students."
}, {
    description: "<b>Build an Online Presence:</b> Create a professional profile on tutoring platforms to reach a wider audience."
}, {
    description: "<b>Offer Flexible Timings:</b> Cater to students’ schedules to increase your client base."
}]