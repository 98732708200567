import React from 'react';
import CarouselComponent from '../ui/CarouselComponent';
import { useTranslation } from 'react-i18next';

export default function Section7() {
  const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
  const isArabic = i18n.language === 'ar';
  return (
    <div className="flex flex-col justify-start items-start bg-white py-2">
      <div className="flex flex-row mx-5 my-4 justify-center items-center gap-2"> <div className="w-2.5 h-2.5 -mt-1 bg-[#ed1d24] rounded-full" /> <span className={`text-[#ed1d24] text-lg font-normal ${isArabic ? "" : "font-['Helvetica']"} uppercase leading-[18px]`} >{t('blog')} </span></div>
      <div className={`text-black mx-10 text-[50px] font-bold ${isArabic ? "" : "font-['Helvetica']"}  leading-[41.50px]`}>{t('blog2')} </div>
      <CarouselComponent />
    </div>
  )
}
