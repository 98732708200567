import { ArrowLeft, Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import MessageTutorModal from "./sendMessagePopup";
import ApplicationModal2 from "./sendMessagePart2";
import BookingInterface from "../AboutTutorComponents/bookingPart1";
import { MutatingDots } from "react-loader-spinner";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

const ViewTutorCard = ({ tutorId, setViewSingleJob, setActiveTab }: any) => {
  const [tutor, setTutor] = useState<any>([]);
  const [messageOpen, setMessageOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [singleTutor, setSingleTutor] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [fullTutorData, setFullTutorData] = useState<any>(null);
  const [single, setSingle] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // Changed seeMore to an object to track state per tutor card
  const [seeMoreStates, setSeeMoreStates] = useState<Record<number, boolean>>({});

  useEffect(() => {
    const getJobDetails = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v3/customer/jobs/${tutorId}/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setFullTutorData(res.data);
        setTutor(res.data.applications);
      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };
    getJobDetails();
  }, [tutorId]);

  useEffect(() => {
    if (tutor.length > 0 && tutor[0]?.tutor?.user_id) {
      fetchTutorData(tutor[0].tutor.user_id);
    }
  }, [tutor]);

  const fetchTutorData = async (id: number) => {
    if (!id) return;
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/${id}/`,
        {
          headers: {
            authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setSingle(res.data);
    } catch (error) {
      console.error("Error fetching tutor data:", error);
    }
  };

  const tutorIdApi = async (id: number) => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/${id}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setSingleTutor(res.data);
    } catch (error) {
      console.error("Error fetching single tutor data:", error);
    }
  };

  // Toggle see more for specific tutor card
  const toggleSeeMore = (tutorId: number) => {
    setSeeMoreStates((prev) => ({
      ...prev,
      [tutorId]: !prev[tutorId] // Toggle the state for this specific tutor
    }));
  };

  return (
    <>
      <h4 className="lg:block hidden">
        {i18n.language == "en" ? "Here are the Applicants for" : "هنا المتقدمون ل"} {fullTutorData?.subject}
      </h4>
      <div className="flex items-center gap-3 mb-4 lg:justify-end">
        <button
          dir={i18n.language == "ar" ? "ltr" : "ltr"}
          onClick={() => setViewSingleJob(null)}
          className="flex items-center gap-2 text-gray-700 hover:text-black transition-all"
        >
          <ArrowLeft className="w-5 h-5" />
          <span className="text-lg font-medium">{t("back")}</span>
        </button>
      </div>
      <h4 className="lg:hidden block">
        {i18n.language == "en" ? "Here are the Applicants for" : "هنا المتقدمون ل"} {fullTutorData?.subject}
      </h4>
      {loading ? (
        <div className="flex justify-center items-center">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#4fa94d"
            secondaryColor="#4fa94d"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : tutor.length > 0 ? (
        tutor.map((app: any) => (
          <div
            key={app.tutor.id}
            className="bg-[#F6FCF9] border-2 border-[#008847] rounded-2xl shadow-md p-4 space-y-4 cursor-pointer text-black"
          >
            {showModal && (
              <div className="fixed inset-0 flex items-center justify-center bg-black/50 bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
                  <BookingInterface
                    showModal={showModal}
                    setShowModal={setShowModal}
                    tutorData={single}
                  />
                </div>
              </div>
            )}

            <div className="flex items-start gap-3">
              <Link to={`/tutor/${app.tutor.user_id}`}>
                <picture>
                  <source srcSet={app.tutor.photo} type="image/webp" />
                  <img
                    src={
                      app.tutor.photo?.replace(".webp", ".jpg") ||
                      "fallback.jpg"
                    }
                    alt="img"
                    className="w-12 h-12 rounded-full object-cover flex-shrink-0"
                  />
                </picture>
              </Link>

              <div className="flex-1 lg:min-w-0">
                <div className="flex items-start justify-between gap-2 flex-col lg:flex-row">
                  <div className="flex flex-col">
                    <h2 className="text-base font-medium flex gap-2">
                      {app.tutor.name}{" "}
                      <picture>
                        <source
                          srcSet={single?.country?.country_icon}
                          type="image/webp"
                        />
                        <img
                          src={
                            single?.country?.country_icon.replace(
                              ".webp",
                              ".jpg"
                            ) || "fallback.jpg"
                          }
                          alt="img"
                          className="w-6 h-6 rounded-full"
                        />
                      </picture>
                    </h2>
                    <p
                      className={`text-sm text-gray-600 mt-1 ${seeMoreStates[app.tutor.id] ? "" : "line-clamp-2"
                        }`}
                    >
                      {app.tutor_message}
                    </p>
                    <span
                      onClick={() => toggleSeeMore(app.tutor.id)}
                      className="text-sm underline"
                    >
                      {seeMoreStates[app.tutor.id] ? (i18n.language == "en" ? "See Less" : "مشاهدة أقل") : (i18n.language == "en" ? "See More" : "مشاهدة المزيد")}
                    </span>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-1.5">
                      <span className="bg-amber-50 text-amber-600 px-2 py-0.5 rounded text-sm whitespace-nowrap">
                        {t("AED")} {" "}
                        {(() => {
                          const priceInCents = app.tutor.price;
                          if (!priceInCents) return "0";
                          const basePrice = priceInCents / 100;
                          let additionalPrice = 0;
                          if (basePrice >= 50) {
                            additionalPrice += Math.floor(basePrice / 50) * 2.5;
                          }
                          const finalPrice = basePrice + additionalPrice;
                          return finalPrice.toFixed(1);
                        })()}
                        {t("hour")} ({t("find_a_tutor_page.including_vat")})
                      </span>
                    </div>

                    <div className="flex items-center text-sm border-2 border-[#1A5AFF] rounded ml-auto gap-1.5 justify-start px-2 bg-[#1A5AFF1A] text-[#1A5AFF]">
                      <Calendar className="w-4 h-4" />
                      <span>
                        {new Date(app.applied_at).toLocaleDateString()}
                      </span>
                    </div>

                    <div className="flex items-center gap-1">
                      <span className="text-sm text-gray-600">
                        {app.tutor.country}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-3">
              {!app.tutor.is_trialed && (
                <button
                  onClick={() => {
                    fetchTutorData(app.tutor?.user_id);
                    setShowModal(true);
                  }}
                  className="flex-1 bg-[#008847] text-white lg:px-4 lg:py-2 lg:text-base rounded-xl text-sm font-medium transition-colors"
                >
                  {t("free_trial")}
                </button>
              )}

              <button
                onClick={() => {
                  setMessageOpen(true);
                  tutorIdApi(app.tutor.user_id);
                }}
                className="flex-1 bg-[#0088471A] text-[#008847] border-2 border-[#008847] lg:px-4 py-2 rounded-xl text-sm font-medium transition-colors"
              >
                {i18n.language == "en" ? "Ask a Question" : "إسأل سؤالاً."}
              </button>
            </div>
          </div>
        ))
      ) : (
        <p className="text-xl text-gray-400">{i18n.language == "en" ? "No Applicants" : "لا متقدمين."}</p>
      )}

      {messageOpen && (
        <MessageTutorModal
          isOpen={messageOpen}
          onClose={() => setMessageOpen(false)}
          tutorImage={singleTutor.photo}
          onSendMessage={() => {
            setSendMessage(true);
            setMessageOpen(false);
          }}
        />
      )}

      {sendMessage && (
        <ApplicationModal2
          tutor={singleTutor}
          isOpen={sendMessage}
          onClose={() => setSendMessage(false)}
          onSubmit={() => setSendMessage(false)}
        />
      )}
    </>
  );
};

export default ViewTutorCard;