export const locales = {
  en: {
    hero: "Achieve Academic Excellence With",
    trustedTutors: "Trusted",
    trustedTutors2: " Tutors",
    from: " from the UAE and UK",
    customizedLearning:
      "Customized learning experiences, online or in-person,aligned with your educational preferences.",
    how_it_works: "Discover More",
    section2: "ONLINE AND IN PERSON 1 ON-1 OR COLLABORATIVE GROUP SESSIONS",
    Tutors: "Tutors",
    subjecthome: "Subject",
    about: "About us",
    about2:
      "One small step for Edupreneurs, a big step for parents and students",
    about3:
      "At Nadwa, we believe that every student deserves the opportunity to excel,no matter their location or educational background.",
    about4:
      "Founded with a passion for learning and a commitment to quality education, Nadwa connects students with expert tutors across a wide range of subjects and specialties. Our platform is designed to make finding the right tutor easy and accessible, so you can focus on what matters most—achieving your academic goals.",
    aboutbt: "Discover More",
    tutor: "Search",
    tutor2: "Get Access To Your Private Tutor Today!",
    tutorcard1h: "Subject",
    tutorcard1p:
      "Explore Nadwa's extensive range of academic programs spanning diverse disciplines.Choose from a variety of subjects to study and ace it. At Tutors in Dubai, your academic success is our top priority.",
    tutorcard2h: "Location",
    tutorcard2p:
      "We understand the importance of convenience and flexibility in learning. Whether you prefer to have a tutor in your location or choose the flexibility of online tutoring in UAE, we’ve got you covered.",
    tutorcard3h: "Categories",
    tutorcard3p:
      "Our tutors in dubai specialize in a variety of academic categories to ensure that we can cater to every student's learning style and goals. From school tutoring to university-level courses, you’ll find expert tutors across subjects and categories. Explore the categories that best match your educational aspirations.",
    tutor3: "Find a Local Tutor for Your Subject!",
    tutor4: "Tutors - Sharing Expertise",
    tutor5:
      "Share your expertise, follow your passion, and become your own boss. Sign up with Nadwa to start tutoring online or one-on-one and make a difference in students' lives.",
    tutor6: "Find new students",
    tutor7: "Grow your business",
    tutor8: "Grow your business",
    tutorbt: "Become a Tutor",
    work: "STEPS",
    work2: "How it works",
    work3: "Find Your Tutor",
    work4:
      "Looking for top tutors in the UAE? Don’t wait any longer—share your requirements, and start learning from the finest educators in the UAE. Let's begin your journey with Nadwa.Simply complete a short form or email us at support@naadwa.ae to share your learning needs. The more details you provide, the faster and easier it will be to connect you with the right tutor, center, or course.",
    workbt1: "Discover More",
    workbt2: " Free Consultation",
    workcard1: "Find your perfect online tutor",
    workcard2: "Start with a FREE online trial lesson",
    workcard3: "Sign up for package discounts today!",
    blog: "BLOGS",
    blog2: "Get Involved",
    blogcard1: "12 best language exchange apps and websites in 2024",
    blogcard1date: "11 December 2024",
    blogcard2: "12 best language exchange apps and websites in 2024",
    blogcard2date: "11 December 2024",
    blogcard3: "12 best language exchange apps and websites in 2024",
    blogcard3date: "11 December 2024",
    blogcard4: "12 best language exchange apps and websites in 2024",
    blogcard4date: "11 December 2024",
    blogcard5: "12 best language exchange apps and websites in 2024",
    blogcard5date: "11 December 2024",
    form: "CONTACT",
    form2: "Get in touch with us",
    form3: "Schedule A Meeting",
    form4: "Leave your contact details and our team will be in touch shortly.",
    form5: "Full Name",
    form6: "Last Name",
    form7: "Email Address",
    form8: "Write your message",
    form9: "Phone Number",
    formbt: "Submit",
    testimonial: "Testimonials",
    testimonial2: "Hear it from Our Students",
    testimonial3: "Discover the finest teachers in the UAE right here.",
    testimonial4:
      "Looking for top tutors in the UAE? Don’t wait any longer—share your requirements, and start learning from the finest educators in the UAE. Let's begin your journey with Nadwa.",
    testimonialbt1: "Sign Up as a Tutor",
    testimonialbt2: "Start Learning",
    testimonialname1: " Ahmad Ali",
    testimonialgrade1: "11",
    testimonialreview1:
      "In a few months, I went from floundering in math to earning an A+! My teacher offered me useful advice on how to solve problems and very precisely described ideas. For my academics, Nadwa Master has been a godsaver.",
    testimonialname2: " Zara Tariq.",
    testimonialgrade2: "10",
    testimonialreview2:
      "My physics marks were hardly passing before I joined Nadwa. Now among the best students in my class thanks to my tutor's individualized direction. I never have felt so sure about my academics!",
    testimonialname3: "Ali Raza",
    testimonialgrade3: "12",
    testimonialreview3:
      "My perspective on chemistry has totally changed since I have started tutoring utilizing Nadwa Master. From a C to constant A's, this system has changed my life.",
    footer: "About Nadwa",
    footer2: "How it works",
    footer3: "Testimonials",
    footer4: "Blogs",
    footer5: "Subscribe to our Newsletter",
    footer6: "Subscribe",
    footer7: "Copyright © 2025, All Rights Reserved.",
    footer8: "Terms & Condition",
    footer9: "Privacy Policy",
    header: "Language",
    header2: "Login",
    header3: "Home",
    header4: "About Us",
    header5: "Find a Tutor",
    header6: "Join To Learn",
    header7: "Become a tutor",
    header8: "Contact Us",
    header9: "Book Now",
    popup: "In order to book now click on the signup here.",
    popup2: "Sign Up",
    popup3: "Book a Trial Now , Sign Up as a Student",
    aboutpage: "About Nadwa",
    aboutpage2: "Home",
    aboutpage3:
      "Today, Nadwa is one of the leading tutoring platforms, offering tailored tuition both online and in-person to students across the UK and the globe. We provide private tuition, adult learning, retakes for A-levels and GCSEs, homeschooling and more...",
    aboutpage4:
      "Our mission is to make learning available and accessible to all students, no matter their age, level, or budget. We believe that every student should be given the chance to reach their full academic potential.",
    aboutpage5:
      "Education is the cornerstone of everything we do, but our tutors and students are the reason we have come so far.",
    vision2:
      "Our vision is to revolutionize the education system by making high-quality tutoring accessible to all students, regardless of their background or circumstances. We believe that by empowering tutors and providing them with the tools they need to succeed, we can transform the way students learn and ultimately create a more equitable society.",
    vision3: "Mission",
    vision4:
      "Our mission is to connect students with the best possible tutors and provide them with personalized, high-quality education that meets their unique needs and learning styles. We are committed to empowering tutors and giving them the resources they need to establish a successful tutoring business and make a positive impact in their communities. By doing so, we aim to create a world where every student has access to the educational opportunities they need to thrive and succeed, and where tutors are valued and respected for their critical role in shaping the future of our society.",
    working: "See how our working process easily adapts to your needs",
    working2:
      "Everything you need to know about finding a tutor, bookings, payments & reviews.",
    working3: "Find your perfect online tutor",
    working4: "Start with a FREE online trial lesson",
    working5: "Sign up today to receive discounts on packages",
    working6:
      "Use our inbuilt filters to narrow down your search to find the tutor you feel will best suit your needs.",
    discover: "Discover the finest teachers in the UAE right here.",
    discover2:
      "Looking for top tutors in the UAE? Don’t wait any longer—share your requirements, and start learning from the finest educators in the UAE. Let's begin your journey with Nadwa.",
    discoverbt1: "Sign Up As Tutor",
    discoverbt2: "Start learning",
    contactHead: "Contact Us",
    follow: "Follow Us",
    phone: "Phone",
    fax: "Fax",
    email: "Email",
    address: "Address",
    addressValue:
      "Al Moosa Tower 1 - 1503 - Sheikh Zayed Rd - near Emirates Towers Metro (Sea Side - Trade Centre - Trade Centre 1 - Dubai - United Arab Emirates",
    formHead: "Schedule A Meeting",
    namePlaceholder: "Alex Carry",
    EmailPlacholder: "Email Address",
    CountryPlacholder: "Country",
    PhonePlacholder: "Phone Number",
    textAreHolder: "Write your message here..",
    howHear: "How did you hear about us?",
    submit: "Submit",
    Facbook: "Facebook",
    Linkiden: "LinkedIn",
    Friends: "Friends",
    Instagram: "Instagram",
    Twitter: "Twitter",
    GoogleSearch: "Google Search",
    Others: "Others",
    Tiktok: "Tik Tok",
    Snapchat: "Snapchat",
    Boards: "Boards",
    country1: "UAE",
    country2: "USA",
    country3: "UK",
    country4: "India",
    country5: "China",
    country6: "Saudi Arabia",
    country7: "Germany",
    country8: "France",
    HowItWorkHead: "How It Works",
    step1: "Step",
    step1Head: "Browse all Tutors",
    step1Para:
      "Search through our list of highly qualified tutors to find the one that best fits your needs.",
    step2: "Step",
    step2Head: "Match me with a Tutor",
    step2Para:
      "Let us help you find the perfect tutor that matches your goals and learning style.",
    step3: "Step",
    step3Head: "Book a Free Trial Call",
    step3Para:
      "Schedule a complimentary trial session to ensure a good fit before committing to regular lessons.",
    step3Link: "Message Your Tutor",
    step3LinPara:
      "You can also message your tutor of choice and ask about any questions you may have before booking.",
    step4: "Step",
    step4Head: "Book your Lessons",
    step4Para:
      "Select your preferred time slots and schedule your lessons in advance.",
    step5: "Step",
    step5Head: "Pay for your Lessons",
    step5Para: "Securely process your payment to confirm your booking.",
    step6: "Step",
    step6Head: "Track your Goals",
    step6Para:
      "Monitor your progress and stay aligned with your learning objectives.",
    step7: "Step",
    step7Head: "Get Lesson Feedback",
    step7Para:
      "Receive detailed feedback after each session to track your improvement.",
    step8: "Step",
    step8Head: "Leave your Tutor a Review",
    step8Para: "Share your experience and help others find the right tutor.",
    step8Link: "Review us on Trust Pilot or Google",
    step8LinPara:
      "We always appreciate every review we get. Writing us a quick review on either Trust Pilot or Google Reviews can help others find their perfect tutor too!",
    AboutTutor: "About Tutor",
    teaches: "Teaches",
    Experience: "Experience",
    school: "School",
    tutoring: "Tutoring",
    verifiedTutor: "VerifiedTutor",
    perhour: "per hour",
    AED: "AED",
    includigVat: "including vat",
    tryNow: "Try Now! Book a free trial with this teacher ",
    instantbook: "Instant Book",
    SendMessage: "Send Message",
    TypicallyRespond: "Typically Respond in",
    introduction: "Introduction",
    AcademicQualificaion: "Academic Qualifications",
    WeeklyCalender: "Weekly Calender",
    openCalender: "Open Full Calender",
    qualification: "Qualifications",
    online: "Online",
    Reviews: "Reviews",
    HireTutor: "Hire a Tutor",
    namePlaceholderTutor: "Enter tutor name",
    search: "Search",
    subject2: "Subject",
    AllSubject: "All Subjects",
    level: "Level",
    AllLevel: "All Level",
    Country: "Countries",
    AllCountries: "All Countries",
    Uk: "United Kingdom",
    UAE: "United Arab Emirates",
    ResetNow: "Reset Now",
    MinPrice: "Min Price",
    MaxPrice: "Max Price",
    yearsExperi: " Years of Experience",
    seemore: "see more",
    lesson: "200+ Lessons Completed",
    Viewprofile: "View Profile",
    SearchReults: "Search Result",
    notfound: "No Tutors Found",
    LoadMore: "Load More",
    confirmation: "Confirmation",
    cancel: "Cancel",
    confirm: "Confirm",
    ModelMessage: "Are you sure you want to book a free trial with this tutor?",
  },
  ar: {
  hero: "حقق التفوق الأكاديمي مع",
  trustedTutors: "   معلمين  ",
  trustedTutors2: " موثوقين",
  from: "من الإمارات والمملكة المتحدة",
  customizedLearning: "تجارب تعليمية مخصصة، عبر الإنترنت أو حضوريًا، بما يتماشى مع تفضيلاتك التعليمية",
  how_it_works: "اكتشف المزيد",
    section2: "تعليم فردي أو جماعي - عبر الإنترنت أو حضوريًا",
    Tutors: "المعلمون",
    subjecthome: "المواد الدراسية",
    about: "من نحن",
    about2: "خطوة صغيرة لرواد التعليم، خطوة كبيرة للطلاب وأولياء الأمور",
    about3:
      'في "ندوة"، نؤمن بأن كل طالب يستحق فرصة التفوق، بغض النظر عن موقعه أو خلفيته التعليمية',
    about4:
      'تأسست "ندوة" بشغف التعلم والتزام بالجودة، حيث نربط الطلاب بمعلمين خبراء في مجموعة واسعة من التخصصات. منصتنا مصممة لجعل العثور على المعلم المناسب سهلًا ومتاحًا، حتى تتمكن من التركيز على ما يهمك حقًا: تحقيق أهدافك الأكاديمية',
    aboutbt: "اكتشف المزيد",
    tutor: "بحث",
    tutor2: "ابدأ رحلتك مع معلمك الخاص اليوم!",
    tutorcard1h: "موضوع",
    tutorcard1p:
      'استكشف مجموعة واسعة من البرامج الأكاديمية التي تقدمها "ندوة" في مختلف التخصصات. اختر من بين مجموعة متنوعة من المواد الدراسية لتحقيق التفوق. في "ندوة"، نجاحك الأكاديمي هو أولويتنا القصوى',
    tutorcard2h: "الموقع",
    tutorcard2p:
      "نحن ندرك أهمية الراحة والمرونة في التعلم. سواء كنت تفضل وجود معلم في موقعك أو اختيار المرونة من خلال التعليم عبر الإنترنت في الإمارات، نحن هنا لتلبية احتياجاتك.",
    tutorcard3h: "التصنيفات",
    tutorcard3p:
      "معلمونا في دبي متخصصون في مجموعة متنوعة من التصنيفات الأكاديمية لضمان تلبية أساليب وأهداف تعلم كل طالب. من دروس المدارس إلى دورات الجامعات، ستجد معلمين خبراء في جميع المواد والتخصصات. استكشف التصنيفات التي تناسب طموحاتك التعليمية",
    tutor3: "بحث عن معلم محلي لمادتك!",
    tutor4: "المعلمون - شارك خبراتك وتألّق في التدريس",
    tutor5:
      'هل تمتلك خبرة تعليمية وترغب في استثمارها؟ مع "ندوة"، يمكنك تحويل شغفك بالتعليم إلى مسار مهني مستقل. انضم الآن وابدأ بتقديم دروس عبر الإنترنت أو وجهًا لوجه، وصنع فرقًا في حياة الطلاب.',
    tutor6: "جذب المزيد من الطلاب",
    tutor7: "تطوير مهاراتك وتعزيز دخلك",
    tutor8: "استلام مدفوعاتك بأمان وسهولة",
    tutorbt: "أصبح معلمًا",
    work: "الخطوات",
    work2: "كيف يعمل",
    work3: "ابحث عن معلمك",
    work4:
      "هل تبحث عن معلمين مميزين في الإمارات؟ لا تضيع المزيد من الوقت! شارك متطلباتك التعليمية معنا وابدأ التعلم من نخبة المعلمين في الإمارات.املأ نموذجًا مختصرًا أو أرسل بريدًا إلكترونيًا علىpport@naadwa.ae لتحديد احتياجاتك التعليمية. كلما زادت التفاصيل التي تقدمها، كان من الأسهل علينا ربطك بالمعلم المناسب أو الدورة الأنسب لتحقيق أهدافك",
    workbt1: "اكتشف المزيد",
    workbt2: "استشارة مجانية",
    workcard1: "ابحث عن معلمك المثالي عبر الإنترنت",
    workcard2: "ابدأ بدروس تجريبية مجانية عبر الإنترنت",
    workcard3: "اشترك اليوم للحصول على خصومات على الباقات!",
    blog: "المدونات",
    blog2: "كن جزءًا من الحدث",
    blogcard1: "أفضل 12 تطبيقًا وموقعًا لتبادل اللغات في عام 2024",
    blogcard1date: "11 ديسمبر 2024",
    blogcard2: "أفضل 12 تطبيقًا وموقعًا لتبادل اللغات في عام 2024",
    blogcard2date: "11 ديسمبر 2024",
    blogcard3: "أفضل 12 تطبيقًا وموقعًا لتبادل اللغات في عام 2024",
    blogcard3date: "11 ديسمبر 2024",
    blogcard4: "أفضل 12 تطبيقًا وموقعًا لتبادل اللغات في عام 2024",
    blogcard4date: "11 ديسمبر 2024",
    blogcard5: "أفضل 12 تطبيقًا وموقعًا لتبادل اللغات في عام 2024",
    blogcard5date: "11 ديسمبر 2024",
    form: "اتصل بنا",
    form2: "جدولة اجتماع",
    form3: "تفاصيل الاتصال",
    form4: "اترك تفاصيل الاتصال الخاصة بك وسيتواصل معك فريقنا قريبًا.",
    form5: "الاسم الكامل",
    form6: "الاسم الأخير",
    form7: "عنوان البريد الإلكتروني",
    form8: "اكتب رسالتك",
    form9: "رقم الهاتف",
    formbt: "إرسال",
    testimonial: "آراء الطلاب",
    testimonial2: "استمع إلى طلابنا",
    testimonial3: "اكتشف أفضل المعلمين في الإمارات هنا.",
    testimonial4:
      "هل تبحث عن أفضل المعلمين في الإمارات؟ لا تنتظر أكثر—شارك متطلباتك وابدأ التعلم مع نخبة المعلمين في الإمارات. لنبدأ رحلتك مع ندوة.",
    testimonialbt1: "سجل كمعلم",
    testimonialbt2: "ابدأ التعلم",
    testimonialname1: "أحمد علي",
    testimonialgrade1: "الصف 11",
    testimonialreview1:
      "في بضعة أشهر، انتقلت من الفشل في الرياضيات إلى الحصول على A+! قدم لي معلمي نصائح مفيدة حول كيفية حل المشاكل وشرح الأفكار بدقة. بالنسبة لدراستي الأكاديمية، كان ندوة ماستر منقذًا لي.",
    testimonialname2: "زهراء طارق",
    testimonialgrade2: "الصف 10",
    testimonialreview2:
      "كانت درجاتي في الفيزياء بالكاد تجتاز قبل أن أنضم إلى ندوة. الآن أنا من أفضل الطلاب في فصلي بفضل توجيه معلمي الشخصي. لم أشعر أبدًا بمثل هذه الثقة في دراستي الأكاديمية!",
    testimonialname3: "علي رضا",
    testimonialgrade3: "الصف 12",
    testimonialreview3:
      "لقد تغيرت وجهة نظري في الكيمياء تمامًا منذ أن بدأت التدريب باستخدام ندوة ماستر. من درجة C إلى درجات A ثابتة، لقد غيّر هذا النظام حياتي.",
    footer: "حول ندوة",
    footer2: "كيفية العمل",
    footer3: "آراء الطلاب",
    footer4: "المدونات",
    footer5: "اشترك في نشرتنا الإخبارية",
    footer6: "اشتراك",
    footer7: "حقوق الطبع والنشر © 2025، جميع الحقوق محفوظة.",
    footer8: "الشروط والأحكام",
    footer9: "سياسة الخصوصية",
    header: "اللغة",
    header2: "تسجيل الدخول",
    header3: "الرئيسية",
    header4: "عن ندوة",
    header5: "ابحث عن معلم",
    header6: "انضم للتعلم",
    header7: "كن معلمًا",
    header8: "اتصل بنا",
    header9: "احجز الآن",
    popup: "لحجز الآن، اضغط على التسجيل هنا.",
    popup2: "سجل الآن",
    popup3: "احجز تجربة الآن، وسجل كطالب",
    aboutpage: "عن ندوى",
    aboutpage2: "الرئيسية",
    aboutpage3:
      "حيث تقدم خدمات تعليمية مخصصة تشمل:	جلسات تعليمية فردية عبر الإنترنت أو بشكل حضوري	برامج تعليم الكبار	إعادة التحضير لاختبارات A-Levels وGCSE	برامج التعليم المنزلي	وأكثر من ذلك",
    aboutpage4:
      "نسعى لتوفير تعليم عالي الجودة وشخصي يناسب احتياجات كل طالب، مما يمكّنهم من الوصول إلى أقصى إمكانياتهم الأكاديمية.كما نحرص على دعم المعلمين وتزويدهم بالموارد الضرورية لإنشاء مسارات مهنية ناجحة في مجال التعليم، مع تعزيز دورهم في بناء مستقبل مجتمعاتنا.",
    aboutpage5:
      "إحداث نقلة نوعية في نظام التعليم من خلال جعل التدريس المتخصص والمتميز متاحًا لجميع الطلاب، بغض النظر عن خلفياتهم أو ظروفهم الشخصية.",
    vision: "الرؤية",
    vision2:
      "رؤيتنا هي إحداث ثورة في نظام التعليم من خلال جعل التدريس عالي الجودة متاحًا لجميع الطلاب، بغض النظر عن خلفياتهم أو ظروفهم. نحن نؤمن بأنه من خلال تمكين المعلمين وتزويدهم بالأدوات التي يحتاجونها للنجاح، يمكننا تغيير طريقة تعلم الطلاب وخلق مجتمع أكثر عدالة.",
    vision3: "الرسالة",
    vision4:
      "مهمتنا هي ربط الطلاب بأفضل المعلمين وتقديم تعليم مخصص وعالي الجودة يلبي احتياجاتهم الفريدة وأنماط تعلمهم. نحن ملتزمون بتمكين المعلمين وتزويدهم بالموارد التي يحتاجونها لتأسيس أعمال تدريس ناجحة وإحداث تأثير إيجابي في مجتمعاتهم. من خلال القيام بذلك، نسعى إلى خلق عالم يتمتع فيه كل طالب بفرص تعليمية تساعده على الازدهار والنجاح، ويُقدر فيه المعلمون ويحترمون لدورهم الحيوي في تشكيل مستقبل مجتمعنا.",
    working: "اكتشف كيف يتكيف نظام عملنا بسهولة مع احتياجاتك",
    working2:
      "كل ما تحتاج إلى معرفته عن العثور على معلم، الحجوزات، الدفعات والتقييمات.",
    working3: "ابحث عن معلمك المثالي عبر الإنترنت",
    working4: "ابدأ بدرس تجريبي مجاني عبر الإنترنت",
    working5: "سجل اليوم للحصول على خصومات على الباقات",
    working6:
      "استخدم عوامل التصفية المدمجة لدينا لتضييق نطاق البحث والعثور على المعلم الذي يناسب احتياجاتك بشكل أفضل.",
    discover: "اكتشف أفضل المعلمين في الإمارات هنا.",
    discover2:
      "تبحث عن أفضل المعلمين في الإمارات؟ لا تنتظر أكثر—شارك متطلباتك وابدأ التعلم مع أفضل المعلمين في الإمارات. لنبدأ رحلتك مع ندوى.",
    discoverbt1: "سجل كمعلم",
    discoverbt2: "ابدأ التعلم",
    follow: "تابعنا",
    phone: "الهاتف",
    fax: "الفاكس",
    email: "البريد الإلكتروني",
    address: "العنوان",
    addressValue:
      "برج الماسة 1 - 1503 - شارع الشيخ زايد - بالقرب من محطات مترو الإمارات (واجهة بحرية - مركز التجارة - مركز التجارة 1 - دبي - الإمارات العربية المتحدة)",
    formHead:"قم بجدولة اجتماع", 
    contactHead: "اتصل بنا",
    namePlaceholder: "أليكس كاري",
    EmailPlacholder: "عنوان البريد الإلكتروني",
    CountryPlacholder: "الدولة",
    PhonePlacholder: "رقم الهاتف",
    textAreHolder: "اكتب رسالتك هنا..",
    howHear: "كيف سمعت عنا؟",
    submit: "إرسال",
    Facbook: "فيسبوك",
    Linkiden: "لينكد إن",
    Friends: "أصدقاء",
    Instagram: "إنستغرام",
    Twitter: "تويتر",
    GoogleSearch: "بحث جوجل",
    Others: "أخرى",
    Tiktok: "تيك توك",
    Snapchat: "سناب شات",
    Boards: "اللوحات",
    country1: "الإمارات",
    country2: "الولايات المتحدة الأمريكية",
    country3: "المملكة المتحدة",
    country4: "الهند",
    country5: "الصين",
    country6: "المملكة العربية السعودية",
    country7: "ألمانيا",
    country8: "فرنسا",
    HowItWorkHead: "كيف يعمل",
    step1: "الخطوة",
    step1Head: "تصفح جميع المعلمين",
    step1Para:
      "ابحث في قائمتنا من المعلمين المؤهلين للعثور على من يناسب احتياجاتك بشكل أفضل.",

    step2: "الخطوة",
    step2Head: "طابقني مع معلم",
    step2Para:
      "دعنا نساعدك في العثور على المعلم المثالي الذي يناسب أهدافك وأسلوب تعلمك.",

    step3: "الخطوة",
    step3Head: "احجز مكالمة تجريبية مجانية",
    step3Para:
      "حدد موعدًا لجلسة تجريبية مجانية للتأكد من التوافق قبل الالتزام بالدروس المنتظمة.",
    step3Link: "راسل معلمك",
    step3LinPara:
      "يمكنك أيضًا مراسلة المعلم الذي اخترته وطرح أي أسئلة قد تكون لديك قبل الحجز.",

    step4: "الخطوة",
    step4Head: "احجز دروسك",
    step4Para: "حدد الأوقات المفضلة لديك وجدول دروسك مسبقًا.",

    step5: "الخطوة",
    step5Head: "ادفع مقابل دروسك",
    step5Para: "قم بمعالجة الدفع بأمان لتأكيد الحجز الخاص بك.",

    step6: "الخطوة",
    step6Head: "تتبع أهدافك",
    step6Para: "راقب تقدمك وابق على المسار الصحيح مع أهداف التعلم الخاصة بك.",

    step7: "الخطوة",
    step7Head: "احصل على ملاحظات حول الدروس",
    step7Para: "تلقي تعليقات مفصلة بعد كل جلسة لتتبع تحسنك.",

    step8: "الخطوة",
    step8Head: "اترك مراجعة لمعلمك",
    step8Para: "شارك تجربتك وساعد الآخرين في العثور على المعلم المناسب.",
    step8Link: "راجعنا على Trust Pilot أو Google",
    step8LinPara:
      "نحن نقدر دائمًا كل مراجعة نحصل عليها. كتابة مراجعة سريعة لنا على Trust Pilot أو Google Reviews يمكن أن تساعد الآخرين أيضًا في العثور على معلمهم المثالي!",

    AboutTutor: "عن المعلم",
    teaches: "يُدرس",
    Experience: "الخبرة",
    school: "المدرسة",
    tutoring: "التدريس",
    verifiedTutor: "معلم موثوق",
    perhour: "لكل ساعة",
    AED: "درهم إماراتي",
    includigVat: "شامل ضريبة القيمة المضافة",
    tryNow: "جرب الآن! احجز تجربة مجانية مع هذا المعلم",
    instantbook: "الحجز الفوري",
    SendMessage: "إرسال رسالة",
    TypicallyRespond: "عادة ما يتم الرد في أقل من 24 ساعة",
    introduction: "مقدمة",
    AcademicQualificaion: "المؤهلات الأكاديمية",
    WeeklyCalender: "التقويم الأسبوعي",
    qualification: "المؤهلات",
    openCalender: "فتح التقويم الكامل",
    Reviews: "التعليقات",
    online: "عبر الإنترنت",
    HireTutor: "استئجار معلم",
    namePlaceholderTutor: "أدخل اسم المعلم",
    search: "بحث",
    subject: "الموضوع",
    AllSubject: "جميع المواضيع",
    level: "المستوى",
    AllLevel: "جميع المستويات",
    Country: "البلدان",
    AllCountries: "جميع البلدان",
    Uk: "المملكة المتحدة",
    UAE: "الإمارات العربية المتحدة",
    ResetNow: "إعادة تعيين الآن",
    MinPrice: "أدنى سعر",
    MaxPrice: "أعلى سعر",
    HireTutor: "استئجار معلم",
    namePlaceholderTutor: "أدخل اسم المعلم",
    search: "بحث",
    subject2: "الموضوع",
    AllSubject: "جميع المواضيع",
    level: "المستوى",
    AllLevel: "جميع المستويات",
    Country: "البلدان",
    AllCountries: "جميع البلدان",
    Uk: "المملكة المتحدة",
    UAE: "الإمارات العربية المتحدة",
    ResetNow: "إعادة تعيين الآن",
    MinPrice: "أدنى سعر",
    MaxPrice: "أعلى سعر",
    yearsExperi: "سنوات الخبرة",
    seemore: "عرض المزيد",
    lesson: "200+ دروس مكتملة",
    Viewprofile: "عرض الملف الشخصي",
    SearchReults: "نتيجة البحث",
    notfound: "لم يتم العثور على معلمين",
    LoadMore: "تحميل المزيد",
    cancel: "إلغاء",
    confirm: "تأكيد",
    confirmation: "تأكيد",
    ModelMessage: "هل أنت متأكد أنك تريد حجز تجربة مجانية مع هذا المعلم؟",
  },
};
