import React, { useEffect, useRef, useState } from 'react';
import * as SliderPrimitive from "@radix-ui/react-slider";
import axios from 'axios';
import { useFilterContext } from '../../ContextApi/FilterContext';
import { RotateCcw, Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';


const FilterComponent = () => {
  const { i18n }: any = useTranslation();
  const isArabic = i18n.language === 'ar';
    const { t }: { t: (key: string) => string } = useTranslation();
  
  const {
    setOtherFilters,
    setLoading,
    selectSubject,
    setSelectSubject,
    setCountry,
    country,
    setSreach,
    sreach,
  } = useFilterContext();
  const [subject, setSubject] = useState([]);
  const [level, setLevel] = useState([]);
  const [selectLevel, setSelectLevel] = useState('');
  const marks = [50, 100, 150, 200, 250, 300, 350, 400];
  const [min, setMin] = useState(50);
  const [max, setMax] = useState(400);
  const [values, setValues] = useState([50, 400]);
  const ref = useRef<any>(null)

  const resetFilters = async () => {
    try {
      setLoading(true)
      const res = await axios.get('https://api.nadwa-uae.com/api/v2/tutor/', {
        params: {
          max_price: 400,
          min_price: 50,
          postcode: 'Online',
          search: '',
          subject_id: '',
          level_id: '',
          country: '',
          online: true,
        },
      })
      setOtherFilters(res.data.results)
      setSelectSubject('');
      setCountry('');
      setSreach('')
      setSelectLevel('')
      setValues([50, 400]);
      setMin(50);
      setMax(400);
    } catch (error) {
      console.error('Error fetching tutors:', error);
    } finally {
      setLoading(false)
    }
  };

  const getTutorFormSearching = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`https://api.nadwa-uae.com/api/v2/tutor/`, {
        params: {
          max_price: max,
          min_price: min,
          postcode: 'Online',
          search: ref.current.value,
          subject_id: selectSubject,
          level_id: level,
          country: country,
          online: true,
        },
      });
      if (res.data.length !== 0) setOtherFilters(res.data.results);
    } catch (error) {
      console.error('Error fetching tutors:', error);
    } finally {
      setLoading(false)
    }
  };
  useEffect(() => {
    const getTutorFromCountry = async () => {
      try {
        setLoading(true);
        const res = await axios.get('https://api.nadwa-uae.com/api/v2/tutor/', {
          params: {
            max_price: max,
            min_price: min,
            postcode: country,
            search: ref.current.value,
            subject_id: selectSubject,
            level_id: selectLevel,
            country: country,
            online: true,
          },
        });
        setOtherFilters(res.data.results);
      } catch (error) {
        console.error('Error fetching tutors:', error);
      } finally {
        setLoading(false);
      }
    };
    getTutorFromCountry();
  }, [country, selectSubject, selectLevel, max, min, ref.current?.value, setOtherFilters, setLoading]);
  
  useEffect(() => {
    const tutorLevels = async () => {
      try {
        const res = await axios.get("https://api.nadwa-uae.com/api/v2/teachinglevels");
        setLevel(res.data);
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };

    tutorLevels();
    const tutorSubjects = async () => {
      try {
        const res2 = await axios.get('https://api.nadwa-uae.com/api/v3/subjects')
        setSubject(res2.data);
      } catch (error) {
        console.log(error)
      }
    }
    tutorSubjects()
  }, []);
  const handleValueChange = (newValues: any) => {
    const roundedValues = newValues.map((value: any) => {
      return marks.reduce((prev, curr) =>
        Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      );
    });
    setValues(roundedValues);
    if (roundedValues[0] !== min || roundedValues[1] !== max) {
      setMin(roundedValues[0]);
      setMax(roundedValues[1]);
    }
  };
  return (
    <div
      className='rounded-r-[50px] lg:rounded-r-[90px] py-20 lg:-mt-16 -mt-10 relative z-40 bg-white'
      style={{
        background: 'linear-gradient(180deg, rgba(0, 136, 71, 0.20) 0%, rgba(0, 136, 71, 0.00) 50%)',
      }}
    >
      <div className={`flex ${isArabic ? "flex-row-reverse" : ""} items-center bg-white rounded-full pl-12 h-14 w-full max-w-[92%] mx-auto shadow-2xl relative`}>
        <input
          type="text"
          placeholder={t('namePlaceholderTutor')}
          ref={ref}
          className={`flex-grow bg-transparent focus:outline-none w-full text-gray-600 placeholder:text-black px-2`}
        />
        <Search className={`absolute top-1/2 transform -translate-y-1/2 ${isArabic ? "left-4" : "left-4"}`} />
        <button
          onClick={getTutorFormSearching}
          className={`bg-green-500 text-white lg:px-10 px-4 py-[16px] rounded-r-full font-semibold hover:bg-green-600 transition `}
        >
          {t('search')}
        </button>
      </div>

      <div className="p-6 bg-white rounded-2xl shadow-2xl max-w-[95%] lg:max-w-[90%] py-20 my-10 mx-auto">
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-8 lg:gap-4 items-center max-w-[90%] mx-auto">
          <div className="flex flex-col relative">
            <label className="text-lg font-semibold mb-2">{t('subject2')}</label>
            <select
              value={selectSubject}
              onChange={(e) => setSelectSubject(e.target.value)}
             
              className="border border-gray-300 rounded-2xl px-4 py-2 focus:ring-2 focus:ring-green-400 appearance-none pr-10"
            >
              <option value="">{t('AllSubject')}</option>
              {subject.map((ele: any) => (
                <optgroup key={ele.id} label={ele.name}>
                  {ele.subjects.map((sub: any) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            <span className={`absolute top-[72%] ${isArabic ? "left-4" : "right-4"} transform -translate-y-1/2 bg-[#00884780] w-7 h-7 rounded-full flex items-center justify-center pointer-events-none`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>

          <div className="flex flex-col relative">
            <label className="text-lg font-semibold mb-2">{t('level')}</label>
            <select
              value={selectLevel}
              onChange={(e) => setSelectLevel(e.target.value)}
              
              className="border border-gray-300 rounded-2xl px-4 py-2 focus:ring-2 focus:ring-green-400 appearance-none pr-10"
            >
              <option value="">{t('AllLevel')}</option>
              {level.map((ele: any) => (
                <option value={ele.id} key={ele.id}>
                  {ele.name}
                </option>
              ))}
            </select>
            <span className={`absolute top-[72%] ${isArabic ? "left-4" : "right-4"} transform -translate-y-1/2 bg-[#00884780] w-7 h-7 rounded-full flex items-center justify-center pointer-events-none`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>

          <div className="flex flex-col relative">
            <label className="text-lg font-semibold mb-2">{t('Country')}</label>
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="border border-gray-300 rounded-2xl px-4 py-2 focus:ring-2 focus:ring-green-400 appearance-none pr-10"
            >
              <option value="0">{t('All Countries')}</option>
              <option value="1">{t('Uk')}</option>
              <option value="2">{t('UAE')}</option>
            </select>
            <span className={`absolute top-[72%] ${isArabic ? "left-4" : "right-4"} transform -translate-y-1/2 bg-[#00884780] w-7 h-7 rounded-full flex items-center justify-center pointer-events-none`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>

          <div className="lg:mt-8 lg:ml-4">
            <button
              onClick={resetFilters}
              className="bg-red-500 text-white px-4 py-[12px] rounded-3xl font-semibold hover:bg-red-600 transition flex items-center gap-1"
            >
              <RotateCcw />
              {t('ResetNow')}
            </button>
          </div>
        </div>
        <div className="lg:w-[90%] mx-auto px-2 mt-10 md:block hidden">
          <div className="relative pt-6 pb-10">
            <SliderPrimitive.Root
              className="relative flex w-full touch-none select-none items-center"
              value={values}
              max={400}
              min={50}
              step={1}
              onValueChange={handleValueChange}
            >
              {/* Track */}
              <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-[#8F8F8F33]">
                {/* Range */}
                <SliderPrimitive.Range className="absolute h-full bg-[#008847]" />
              </SliderPrimitive.Track>

              {/* Thumbs */}
              <SliderPrimitive.Thumb
                className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                aria-label="Minimum value"
              />
              <SliderPrimitive.Thumb
                className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                aria-label="Maximum value"
              />
            </SliderPrimitive.Root>

            {/* Marks */}
            <div className="absolute left-0 right-0 -bottom-2">
              <div className="relative h-8">
                {marks.map((mark) => (
                  <div
                    key={mark}
                    className="absolute -translate-x-1/2 text-sm text-gray-600"
                    style={{
                      left: `${((mark - 50) / (400 - 50)) * 100}%`,
                    }}
                  >
                    {mark}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>


        {/* Min and Max Input Fields for Mobile */}
        <div className="md:hidden mx-auto px-2 mt-10">
          <div className="flex justify-between items-center">
            {/* Minimum Price Input */}
            <div className="flex flex-col">
              <label htmlFor="minPrice" className="text-sm text-gray-600 mb-1">
              {t('MinPrice')}
              </label>
              <input
                type="number"
                id="minPrice"
                className="w-full border rounded px-2 py-1 text-sm"
                value={min}
                min="50"
                max="400"
                step="50"
                onChange={(e) => {
                  const value = Math.max(50, Math.min(400, parseInt(e.target.value, 10) || 50));
                  setMin(value);
                  setValues([value, max]);
                }}
              />
            </div>

            {/* Maximum Price Input */}
            <div className="flex flex-col">
              <label htmlFor="maxPrice" className="text-sm text-gray-600 mb-1">
              {t('MaxPrice')}
              </label>
              <input
                type="number"
                id="maxPrice"
                className="w-full border rounded px-2 py-1 text-sm"
                value={max}
                min="50"
                max="400"
                step="50"
                onChange={(e) => {
                  const value = Math.max(50, Math.min(400, parseInt(e.target.value, 10) || 400));
                  setMax(value);
                  setValues([min, value]);
                }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default FilterComponent;
