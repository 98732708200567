import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AvailabilityCalendar from "../../components/AvailabilityTutor/AVailabiltyTutor";
import EditProfileTutorSidebar from "../../components/EditProfileComponents/EditProfileTutorSideBar";
import BasicsProfile from "../../components/EditProfileComponents/Tabs/BasicsProfile";
import Country from "../../components/EditProfileComponents/Tabs/Country";
import Experience from "../../components/EditProfileComponents/Tabs/Experience";
import Pricing from "../../components/EditProfileComponents/Tabs/Pricing";
import Qualifications from "../../components/EditProfileComponents/Tabs/Qualifications";
import RecordVideo from "../../components/EditProfileComponents/Tabs/RecordVideo";
import School from "../../components/EditProfileComponents/Tabs/School";
import TeachingSubjects from "../../components/EditProfileComponents/Tabs/TeachingSubjects";
import TravelTime from "../../components/EditProfileComponents/Tabs/TravelTime";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
function EditProfileTutor() {
  const tabs = [
    "basics",
    "country",
    "school",
    "experience",
    "travel",
    "qualifications",
    "subjects",
    "pricing",
    "video",
    "availability",
    "pricingTest"
  ];

  const [activeTab, setActiveTab] = useState("basics");
  const [completedTabs, setCompletedTabs] = useState<string[]>([]);
  const [profileData, setProfileData] = useState<any>(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isProfileSubmitted, setIsProfileSubmitted] = useState(false);
  const [updateUi, setUpdateUi] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])

  useEffect(() => {
    // Fetch API Data
    fetch("https://api.nadwa-uae.com/api/v2/tutor/profile/", {
      headers: {
        "Content-type": "application/json",
        "Authorization": `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setProfileData(data);
        setIsProfileSubmitted(data.profile_submitted);

        // Check required fields
        if (
          data.qualifications?.length > 0 &&
          data.photo &&
          data.postcode &&
          data.tagline &&
          data.subjects?.length > 0 &&
          data.availability?.length > 0 &&
          data.experience &&
          data.experience_years &&
          data.school &&
          data.school_name &&
          data.user?.email &&
          data.user?.first_name &&
          data.user?.last_name &&
          data.user?.phone_number
        ) {
          setIsSubmitEnabled(true);
        };

      })
      .catch((error) => console.error("Error fetching profile data:", error));
  }, [updateUi, isSubmitEnabled]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (!completedTabs.includes(tab)) {
      setCompletedTabs([...completedTabs, tab]);
    }
  };


  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("TH_AUTH_TOKEN");
      if (!token) {
        toast.error("Authentication token is missing. Please log in again.");
        return;
      }

      const response = await axios.post(
        "https://api.nadwa-uae.com/api/v2/tutor/request_profile_evaluation",
        {}, // Empty body
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Profile evaluation requested successfully!");
      setTimeout(() => {
        window.location.reload()
      }, 500);
      console.log("Profile evaluation requested:", response.data);
    } catch (error: any) {
      console.error("Error requesting profile evaluation:", error);
      toast.error(`${error.response.data?.detail}`);
    }
  };


  const progressPercentage = (completedTabs.length / tabs.length) * 100;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="flex items-center justify-center bg-[#e4e4e4] py-3 sm:py-20 lg:py-10">
      <div className="bg-white rounded-lg shadow space-y-10 p-6 w-[90%]">
        <div className="flex flex-col space-y-4 ">
          <h1 className="text-black font-bold text-2xl ">{t("settings")}</h1>
          <div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-[#008847] h-2 rounded-full transition-all duration-300"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col">
            <EditProfileTutorSidebar updateUi={updateUi} activeTab={activeTab} onTabChange={handleTabChange} />
            <button
              onClick={() => window.location.href = "/dashboard"}
              className="border-2 border-gray-500 w-[90%] rounded-2xl px-2 py-2 mt-2 mx-4"
            >
              {t("back")}
            </button>
          </div>

          <div className="flex-1 space-y-4 sm:p-6">
            {activeTab === "basics" && <BasicsProfile setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "country" && <Country setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "school" && <School setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "experience" && <Experience setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "travel" && <TravelTime setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "qualifications" && <Qualifications setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "subjects" && <TeachingSubjects price_in_cents={profileData.subjects.length > 0 ? profileData.subjects[0].price_in_cents : 0} setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "pricing" && <Pricing setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "video" && <RecordVideo setUpdateUi={setUpdateUi} updateUi={updateUi} />}
            {activeTab === "availability" && <AvailabilityCalendar setUpdateUi={setUpdateUi} updateUi={updateUi} />}
          </div>
        </div>

        <div className="text-center">
          {isProfileSubmitted && profileData && !profileData.is_live ? (
            <p className="text-green-600 font-bold">Your Profile is Under Review</p>
          ) : profileData && profileData.is_live ? null : (
            <div className="flex items-center justify-center space-x-4 flex-col gap-3">
              <div>
                <input type="checkbox" id="termsCheckbox" checked={isChecked} onChange={handleCheckboxChange} />
                <label htmlFor="termsCheckbox" className="ml-2">
                  I agree to all the Nadwa terms and I promise not to take customers (students) off the Nadwa website.
                </label>
              </div>

              <button
                className={`px-6 py-3 rounded-full text-white font-bold ${(isSubmitEnabled && isChecked) ? "bg-green-600 hover:bg-green-700" : "bg-gray-400 cursor-not-allowed"
                  }`}
                disabled={!isSubmitEnabled && !isChecked}
                onClick={(isSubmitEnabled && isChecked) ? handleSubmit : () => toast.error("Please Complete The Profile First")}
              >
                Submit Profile
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditProfileTutor;
