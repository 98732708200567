import {
  Facebook,
  Instagram,
  Linkedin,
  MapPin,
  Youtube
} from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "../../components/AboutPages/Banner";
import CalendlyWidget from "../../components/ui/CalendlyWidget";
import bannerImage from "../../images/asserts/Website-Assets/Contact Us/hero_image.webp";

const ContactForm = () => {
  const { t, i18n }: { t: (key: string) => string; i18n: any } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])

  return (
    <div className="max-w-full ">
      <BannerSection title={t("contactHead")} image={bannerImage} />

      <div className="flex flex-col md:flex-row  bg-white ">
        {/* Left Section */}
        <div className="bg-[#008847] text-white space-y-12 p-8 md:w-1/3  z-20 relative lg:-mt-24 -mt-14">
          <div className="border-b border-gray-400 py-10 space-y-6">
            <h2 className="text-3xl font-bold">{t("follow")}</h2>
            <div className="flex gap-4">
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Facebook className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Instagram className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Linkedin className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Youtube className="text-[#008847]" />
              </div>
            </div>
          </div>

          <div className="border-b border-gray-400 py-10 space-y-6">
            <h2 className="text-3xl font-bold ">{t("contactHead")}</h2>
            <div className="space-y-4">
              <p>{t("phone")}: +971 6 711 0000, +971 5 731 5000</p>
              <p>{t("fax")}: +971 5 731 0111</p>
              <p>{t("email")}: info@nadwa.ae</p>
            </div>
          </div>

          <div className=" space-y-6 py-10">
            <h2 className="text-2xl font-bold mb-4">{t("address")}</h2>
            <div className="flex gap-2">
              <MapPin className="w-12" />
              <p>{t("addressValue")}</p>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className={`p-8 md:w-2/3 space-y-8 bg-gradient-to-tr from-[#fff] to-[#d3eadf] z-20 relative lg:-mt-24 -mt-14 ${i18n.dir(i18n.language) === 'rtl' ? 'rounded-tr-[0px] rounded-tl-[100px]' : 'rounded-tr-[100px]  rounded-tl-[0px]'}`}>
          <h1 className="text-5xl font-bold ">{t("formHead")}</h1>
          <div
            dir="ltr">
            < CalendlyWidget />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
