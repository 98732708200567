import axios from "axios";
import { useEffect, useState } from "react";
import EditNumberAndEmail from "../../screens/Test";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const ProfileCard = ({ stuData, setActiveTab2, setActiveTab3 }: any) => {
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [pastLessons, setPastLessons] = useState([]);
  const { t } = useTranslation()
  console.log(stuData);

  const { first_name, photo, last_name, email, phone_number, email_verified
  } = stuData;

  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    if (hour < 22) return "Good Evening";
    return "Good Night";
  };
  const currentTime = new Date().toISOString();
  const [activeTab, setActiveTab] = useState("upcoming");

  useEffect(() => {
    const isToken = localStorage.getItem("TH_AUTH_TOKEN");
    if (!isToken) return;

    const getLessons = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/customer/bookings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );

        const allLessons = res.data;
        const now = new Date().toISOString(); // Get current date/time in ISO format

        setUpcomingLessons(allLessons.filter((lesson: any) => lesson.start_time > currentTime && !lesson.is_trial_call));
        setPastLessons(allLessons.filter((lesson: any) => lesson.start_time < now));
      } catch (error) {
        console.error("Error fetching lessons:", error);
      }
    };

    getLessons();
  }, []);

  return (
    <div className="bg-white p-4 x-auto space-y-5 rounded-lg shadow">
      <div dir={i18n.language === "ar" ? "ltr" : "ltr"} className="flex items-center justify-between">
        <h1 className="text-[#000606] text-2xl">{t('dashboard_page.profile_section.profile')}</h1>
        <EditNumberAndEmail email_verified={email_verified} email={email} phone={phone_number} />
      </div>

      <div

        className="flex flex-col gap-4 justify-center items-center">
        {photo ? (
          <div className="rounded-full border w-40 h-40 flex items-center justify-center bg-white">
            <img
              src={photo || "/images/profile.png"}
              alt={`${first_name}'s profile`}
              className="w-40 h-40 rounded-full object-cover"
            />
          </div>
        ) : (
          <div className="w-32 h-32 bg-[#e5dfe9] text-black text-2xl rounded-full flex items-center justify-center">
            {first_name ? first_name.charAt(0) + last_name.charAt(0) : "U"}
          </div>
        )}
        <div className="ml-4">
          <h2 className="text-lg font-semibold">{getGreeting()} {first_name || "User"}</h2>
        </div>
      </div>

      <div className="overflow-y-scroll max-h-[400px]">
        <h2 className="text-lg font-bold">{t('dashboard_page.profile_section.your_schedule')}</h2>
        <div className="flex border-b mt-4">
          <button
            className={`flex-1 text-center py-2 font-semibold ${activeTab === "upcoming" ? "border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("upcoming")}
          >
            {t('dashboard_page.profile_section.upcoming_lessons')}
            {/* Upcoming Lessons */}
          </button>
          <button
            className={`flex-1 text-center py-2 font-semibold ${activeTab === "past" ? "border-b-2 border-green-500" : "text-gray-500"}`}
            onClick={() => setActiveTab("past")}
          >
            {t('dashboard_page.profile_section.past_lessons')}
            {/* Past Lessons */}
          </button>
        </div>

        <div className="bg-gray-50 p-4 mt-4 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <h1 className="text-base">
              {t('dashboard_page.profile_section.tutor')}
              {/* Tutor */}
            </h1>
            <h1 className="text-base">{t('dashboard_page.profile_section.type')}</h1>
          </div>
          {(activeTab === "upcoming" ? upcomingLessons : pastLessons).length > 0 ? (
            (activeTab === "upcoming" ? upcomingLessons : pastLessons).map((lesson: any, index: number) => (
              <div
                onClick={() => {
                  setActiveTab2('booking')
                  if (activeTab === "upcoming") {
                    setActiveTab3('upcoming')
                  } else {
                    setActiveTab3('past')
                  }
                }}
                key={index}
                className="flex justify-between items-center border-b border-dashed border-[#008847] pb-2 last:border-b-0 cursor-pointer"
              >
                <div>
                  <h3 className="font-medium text-sm">{lesson.tutor.name}</h3>
                  <p className="text-sm text-gray-600">{lesson.start_time.slice(0, 10)}</p>
                </div>
                <span className={`px-3 py-1 text-sm text-white ${!lesson.is_trial_call ? "bg-[#008847]" : "bg-[#E2B133]"}  rounded-full`}>
                  {!lesson.is_trial_call ? t("lesson") : t("trial")}
                </span>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center">{i18n.language == "ar" ? "لم يتم العثور على أي دروس" : "No Lesson Found"}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
