import React from 'react'
import secondSectionImage from '../../images/asserts/Website-Assets/About Us/image1.webp';
import { useTranslation } from 'react-i18next';


export default function AboutSection() {
    const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <div className="w-full bg-gradient-to-b from-[#a4e7c6] via-white to-[#ebf0ee] rounded-tr-[100px] z-20 relative lg:-mt-24 -mt-14 py-10">
            <div className="flex flex-col justify-center items-center mx-auto lg:gap-5 gap-4">
                <div className={`text-[#12182d] text-[16px]  sm:text-[26px] text-center font-bold ${isArabic ? "" : "font-['Inter']"} uppercase sm:leading-[44px] pt-3`}>{t("section2")}</div>
                <div className="flex flex-row  ">
                    <div className="flex flex-col ">
                        <span className={`text-[#ed1d24] text-lg font-bold ${isArabic ? "" : "font-['Inter']"} uppercase leading-[18px]`}>{t("Tutors")}</span>
                        <h3 className="text-black text-2xl sm:text-5xl xl:text-[95px] font-bold font-['Helvetica'] leading-[114px] -mt-6 lg:-mt-0">
                            10,000+
                        </h3>
                    </div>
                    <div className="w-[1px] h-[60px] mx-5 bg-gray-600  " />

                    <div className="flex flex-col ">
                        <span className={`text-[#ed1d24] text-lg font-bold ${isArabic ? "" : "font-['Inter']"} uppercase leading-[18px]`}>
                            {t("subject")}
                        </span>
                        <h3 className="text-black text-2xl sm:text-5xl xl:text-[95px] font-bold font-['Helvetica'] leading-[114px] -mt-6 lg:-mt-0">
                            100+
                        </h3>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 max-w-[95%] mx-auto items-center justify-center ">
                    <div className="max-w-[90%] mx-auto inline-flex relative flex-col justify-start items-start gap-3 sm:gap-1 lg:pt-32 col-span-1 ">
                        <div className="justify-start items-center 2xl:gap-2.5 gap-2 inline-flex">
                            <div className="w-2.5 h-2.5 bg-[#ed1d24] rounded-full mb-1" />
                            <div className={`text-[#ed1d24] 2xl:text-lg text-md font-normal ${isArabic ? "" : "font-['Helvetica']"} uppercase 2xl:leading-[18px] leading-[6px]`}>{t("about")}</div>
                        </div>
                        <div className=" h-[117px] justify-center items-center inline-flex">
                            <div className={`text-[#12182d] text-2xl sm:text-3xl 2xl:text-4xl font-bold ${isArabic ? "" : "font-['Inter']"} 2xl:leading-[44px] leading-[33px]`}>{t("about2")}</div>
                        </div>
                        <div className="2xl:w-[596.22px] w-[300px] sm:w-[456px] h-0.5 bg-gradient-to-l from-white to-[#308042]" />
                        <div className="pr-[22.54px] mt-4 justify-start items-center inline-flex">
                            <div className={`text-[#12182d] 2xl:text-[22px] text-[20px] font-semibold ${isArabic ? "" : "font-['Inter']"} 2xl:leading-[30px] xl:leading-[22px] mb-2`}>{t("about3")} </div>
                        </div>
                        <div className={`text-[#12182d] 2xl:text-[22px] text-[15px] font-normal ${isArabic ? "" : "font-['Inter']"}  2xl:leading-[30px] leading-[20px] mt-2`}>{t("about4")}</div>
                        <div className={`text-[#12182d] 2xl:text-[22px] text-[15px] font-normal ${isArabic ? "" : "font-['Inter']"}  2xl:leading-[30px] leading-[20px] mt-2`}>{t("aboutpage3")}</div>
                        <div className={`text-[#12182d] 2xl:text-[22px] text-[15px] font-normal ${isArabic ? "" : "font-['Inter']"}  2xl:leading-[30px] leading-[20px] mt-2`}>{t("aboutpage4")}</div>
                        <div className={`text-[#12182d] 2xl:text-[22px] text-[15px] font-normal ${isArabic ? "" : "font-['Inter']"}  2xl:leading-[30px] leading-[20px] mt-2`}>{t("aboutpage5")}</div>
                    </div>
                    <div className="col-span-1">
                        <img
                            src={secondSectionImage}
                            className="w-full h-full"
                            alt="2nd Section"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}