import axios from "axios";
import { AlignJustify, CheckCircle, X, XCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const EditProfileTutorSidebar = ({
  activeTab,
  onTabChange,
  updateUi,
}: {
  updateUi: boolean;
  activeTab: string;
  onTabChange: (tab: any) => void;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [completedSections, setCompletedSections] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/profile/`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        // Transform the data if 'education' is present in the response
        let transformedSections = response.data.completed_sections.map((section: string) =>
          section === 'education' ? 'qualifications' : section
        );

        // Check if postcode exists to determine if travel time is completed
        if (response.data.postcode) {
          transformedSections.push('travel');
        }

        // Check if availability array is present and has items to determine if availability is completed
        if (Array.isArray(response.data.availability) && response.data.availability.length > 0) {
          transformedSections.push('availability');
        }

        setCompletedSections(transformedSections);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [updateUi]);

  const tabs = [
    { id: "basics", name: t("basics") },
    { id: "country", name: t("country") },
    { id: "school", name: t("school") },
    { id: "experience", name: t("experience") },
    { id: "travel", name: t("travel_time") },
    { id: "qualifications", name: t("qualifications") },
    { id: "subjects", name: t("teaching_subjects") },
    { id: "pricing", name: t("pricing") },
    { id: "video", name: t("record_video") },
    { id: "availability", name: t("availability") },
  ];

  return (
    <>
      {/* Hamburger Icon */}
      <button className="md:hidden block " onClick={() => setIsMenuOpen(true)}>
        <AlignJustify className="w-8 h-8 text-gray-800" />
      </button>
      {/* Sidebar */}
      <aside
        className={`bg-white border-r space-y-16 p-6 fixed md:static top-0 left-0 h-full w-64 transform transition-transform duration-300 z-50 ${isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } md:translate-x-0`}
      >
        {/* Close Button */}
        <button
          className="md:hidden block p-2 absolute top-4 right-4"
          onClick={() => setIsMenuOpen(false)}
        >
          <X className="w-8 h-8 text-gray-800" />
        </button>

        {/* Tabs */}
        <div className="space-y-10 mt-10">
          <ul className="space-y-3">
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => {
                  onTabChange(tab.id);
                  setIsMenuOpen(false);
                }}
                className={`flex items-center gap-4 p-2 font-medium cursor-pointer ${activeTab === tab.id
                  ? "bg-[#e6f4ed] text-[#008847] rounded-2xl w-fit text-[17px] py-2 px-3"
                  : "text-[#898989] text-base font-medium "
                  }`}
              >
                <div className="flex items-center ">
                  {tab.name}
                  <span className="ml-2">
                    {completedSections.includes(tab.id) ? (
                      <CheckCircle className="w-4 h-4 text-[#008847]" />
                    ) : (
                      <XCircle className="w-4 h-4 bg-red-400 text-white rounded-full" />
                    )}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </aside>

      {/* Overlay when menu is open */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 md:hidden"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </>
  );
};

export default EditProfileTutorSidebar;
