"use client";

import axios from "axios";
import { X } from "lucide-react";
import { toast, ToastContainer } from "react-toastify";

interface CancelLessonPopupProps {
    id: number;
    updateUi: boolean;
    setUpdateUi: (value: boolean) => void;
    onClose: () => void;
    setReject: (value: boolean) => void;
    bookingDetails: any;
    price: number;
    formattedStartTime: string;
    formattedEndTime: string;
    isOpen: boolean;
}

const CancelLessonPopup: React.FC<CancelLessonPopupProps> = ({
    id,
    updateUi,
    setUpdateUi,
    onClose,
    setReject,
    bookingDetails,
    price,
    formattedStartTime,
    formattedEndTime,
    isOpen,
}) => {
    const cenceled = async () => {
        try {
            await axios.post(
                `https://api.nadwa-uae.com/api/v2/customer/bookings/${id}/cancel/`,
                { reason: "" },
                {
                    headers: { Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}` },
                }
            );

            toast.success("Lesson Rejected Successfully", {
                onClose: () => {
                    onClose();
                },
            });
            setTimeout(() => {
                setUpdateUi(!updateUi);
                setReject(false);
            }, 500);
        } catch (error) {
            toast.error("Failed to reject lesson. Try again");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black/30 flex items-center justify-center z-50">
            <ToastContainer />
            <div className="bg-white rounded shadow-lg w-full max-w-md">
                {/* Header */}
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-medium">Cancel lesson with Ravinder?</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X className="h-5 w-5" />
                    </button>
                </div>

                {/* Content */}
                <div className="p-4 space-y-3 text-gray-600">
                    <div>
                        <p>Start Time: {formattedStartTime}</p>
                    </div>
                    <div>
                        <p>End Time: {formattedEndTime}</p>
                    </div>
                    <div>
                        <p>Location: {bookingDetails.location}</p>
                    </div>
                    <div>
                        <p>Price: AED {(((price / 100) / bookingDetails.invitation_dates.length) * 1.05).toFixed(2)}</p>
                    </div>
                </div>

                {/* Footer */}
                <div className="p-4 flex justify-end gap-3 border-t">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 border border-gray-300 rounded bg-white text-gray-700 hover:bg-gray-50"
                    >
                        Back
                    </button>
                    <button
                        onClick={cenceled}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Cancel Lesson
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CancelLessonPopup;