import i18n from "../../../i18n";
import sent from "../../../images/popups/sent.png";

interface EmailSentModalProps {
  isOpen: boolean
  onClose: () => void;
  text?: string;
}

export default function EmailSentModal({ isOpen, onClose, text }: EmailSentModalProps) {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="relative bg-white rounded-lg max-w-[425px] w-full p-6 animate-fade-in">
        {/* Close button */}
        <button onClick={onClose} className="absolute right-4 top-4 text-gray-400 hover:text-gray-600">
          ×
        </button>

        <div className="flex flex-col items-center gap-2">
          {/* Icon */}
          <div className="relative w-16 h-16 flex items-center justify-center">
            <div className="rounded-lg flex items-center justify-center">
              <img src={sent} alt="None" className="w-14 h-14" />
            </div>
          </div>

          {/* Content */}
          <div className="text-center space-y-2">
            <h2 className="text-xl font-semibold">{i18n.language == "ar" ? "تم تحديث البريد الإلكتروني" : `${text ? text : "Email"} Verified`}</h2>

            <p className="text-sm text-gray-500">{i18n.language == "ar" ? "تم التحقق من البريد الإلكتروني الجديد بنجاح" : `New ${text ? text : "Email"} Verified Successfully`}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

