import React from 'react'
import vector from '../../images/AboutImages/Vector.png'
import vector2 from '../../images/AboutImages/Vector-1.png'
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
const BannerSection = (props: { title: string; image: String }) => {
    const { t }: { t: (key: string) => string } = useTranslation();
    return (
        <section
            className='relative h-screen flex justify-start text-start items-center bg-cover bg-top  after:bg-[#008847]/80 after:absolute after:inset-0 after:z-[1] after:opacity-25 after:content-[""] overflow-hidden'
            style={{
                backgroundImage: `url(${props.image})`,
                backgroundRepeat: 'no-repeat',
            }}
        >
            <img src={vector} alt='vector' className='absolute top-0 left-0 z-10 opacity-25' />
            <img src={vector2} alt='vector' className='absolute top-0 -right-10 z-10 opacity-40' />
            <div
                className={`${i18n.language === 'ar' ? 'mr-6 lg:mr-16' : 'lg:ml-16 ml-4 '} relative z-10`}>
                <h2 className='xl:text-7xl text-5xl font-bold text-white'>{props.title}</h2>
                <a href='/' className='text-white text-lg no-underline'>
                    <span className='flex gap-3 text-lg lg:ml-2'>
                        {t('aboutpage2')} <span>|</span> <span> {props.title}</span>
                    </span>
                </a>
            </div>
        </section>
    )
}

export default BannerSection