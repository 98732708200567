import React from "react";
import { Sun, Moon, Sunrise } from "lucide-react";
import TutorJobsApply from "./TutorJobsApply";
import { useTranslation } from "react-i18next";

interface Day {
  id: number;
  name: string;
  name_short: string;
  ordering: number;
}

interface Slot {
  id: number;
  name: string;
  name_short: string;
  ordering: number;
}

interface DayAvailability {
  days: Day[];
  slot: Slot;
}
interface jobData {
  price_min: number;
  price_max: number;
}

interface AvailabilityGridProps {
  student_availability: DayAvailability[];
  jobData: jobData;
  jobid: string;
}

const AvailabilityGrid: React.FC<AvailabilityGridProps> = ({
  student_availability,
  jobData,
  jobid
}) => {
  const allDays = [
    { id: 1, name: "Monday", name_short: "MO", ordering: 1 },
    { id: 2, name: "Tuesday", name_short: "TU", ordering: 2 },
    { id: 3, name: "Wednesday", name_short: "WE", ordering: 3 },
    { id: 4, name: "Thursday", name_short: "TH", ordering: 4 },
    { id: 5, name: "Friday", name_short: "FR", ordering: 5 },
    { id: 6, name: "Saturday", name_short: "SA", ordering: 6 },
    { id: 7, name: "Sunday", name_short: "SU", ordering: 7 },
  ];
  const { t } = useTranslation();
  const timeSlots = [
    {
      id: 1,
      name: "Morning",
      name_short: "AM",
      ordering: 1,
      icon: <Sunrise className="w-4 h-4 text-gray-400" />,
      time: "6-12",
    },
    {
      id: 2,
      name: "Afternoon",
      name_short: "PM",
      ordering: 2,
      icon: <Sun className="w-4 h-4 text-gray-400" />,
      time: "12-18",
    },
    {
      id: 3,
      name: "Evening",
      name_short: "EVE",
      ordering: 3,
      icon: <Moon className="w-4 h-4 text-gray-400" />,
      time: "18-00",
    },
    {
      id: 4,
      name: "Night",
      name_short: "NIGHT",
      ordering: 4,
      icon: <Moon className="w-4 h-4 text-gray-400" />,
      time: "00-6",
    },
  ];

  const isSlotSelected = (day: Day, timeSlot: (typeof timeSlots)[0]) => {
    return student_availability.some(
      (availability) =>
        availability.days[0]?.id === day.id &&
        availability.slot.id === timeSlot.id
    );
  };

  return (
    <div className="sm:w-[600px]">
      <h2 className="text-xl font-semibold mb-4">{t("tutor_availability")}</h2>

      {/* Days header row */}
      <div className="grid grid-cols-8 gap-2 mb-2">
        <div className="col-span-1"></div>
        {allDays.map((day) => (
          <div key={day.id} className="text-center text-sm font-medium">
            {day.name_short}
          </div>
        ))}
      </div>

      {/* Time slots grid */}
      {timeSlots.map((timeSlot) => (
        <div key={timeSlot.id} className="grid grid-cols-8 gap-2 mb-2">
          <div className="flex sm:flex-row flex-col justify-center  bg-[#f2f9f6] border-1 border-[#008847] rounded-md w- items-center gap-1 text-gray-400">
            {timeSlot.icon}
            <span className="text-xs sm:text-sm">{timeSlot.time}</span>
          </div>
          {allDays.map((day) => (
            <div
              key={day.id}
              className={`border rounded-md h-8 flex items-center justify-center
                ${isSlotSelected(day, timeSlot) ? "bg-purple-50" : "bg-white"}`}
            >
              {isSlotSelected(day, timeSlot) && (
                <svg
                  className="w-4 h-4 text-purple-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              )}
            </div>
          ))}
        </div>
      ))}

      {/* Apply section */}
      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-2">{t("apply_job")}</h3>
        <div className="flex justify-between">
          <div className="flex flex-col gap-1 mb-4">
            <h4 className="text-sm text-gray-500">{t("min_price")}</h4>
            <span className="text-2xl font-bold">{t("AED")} {jobData.price_min}</span>
          </div>
          <div className="flex flex-col gap-1 mb-4">
            <h4 className="text-sm text-gray-500">{t("max_price")}</h4>
            <span className="text-2xl font-bold">{t("AED")} {jobData.price_max}</span>
          </div>
        </div>

        <TutorJobsApply Jobid={jobid} />
      </div>
    </div>
  );
};

export default AvailabilityGrid;
