import type React from "react";
import { useRef, useState, useEffect } from "react";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import { Eye, EyeOff, LanguagesIcon } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import countryData from "../../Data/country.mjs";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../i18n";

const languages = [
  { code: "en", lng: "English" },
  { code: "ar", lng: "Arabic" },
];

const SignupStudentForm = () => {
  // Header states and language changer
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
    setIsLanguageDropdownVisible(false);
  };

  // Signup form states
  const [showPassword, setShowPassword] = useState(false);
  const [showGetOtp, setShowGetOtp] = useState(false);
  const [otp, setOtp] = useState(""); // OTP state
  const [selectedCode, setSelectedCode] = useState("+971"); // Default country code
  const [timer, setTimer] = useState(0); // Timer in seconds
  const getFirstName = useRef<HTMLInputElement>(null);
  const getLastName = useRef<HTMLInputElement>(null);
  const getEmail = useRef<HTMLInputElement>(null);
  const getPhone = useRef<HTMLInputElement>(null);
  const getPassword = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Handle phone change to toggle OTP visibility
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = e.target.value;
    setShowGetOtp(phoneValue.length > 7);
  };

  // Handle country code change
  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCode(e.target.value);
  };

  // Timer Effect for OTP button
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  // Handle Signup & Login
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !getFirstName.current?.value ||
      !getLastName.current?.value ||
      !getEmail.current?.value ||
      !getPhone.current?.value ||
      !getPassword.current?.value ||
      !otp
    ) {
      return toast.error("Please enter all details, including OTP.");
    }

    const signupPayload = {
      first_name: getFirstName.current.value,
      last_name: getLastName.current.value,
      email: getEmail.current.value,
      phone_number: selectedCode + getPhone.current.value,
      password: getPassword.current.value,
      sms_verification_code: otp,
      referer: "https://nadwa-uae.com/student/register",
    };

    try {
      const signupResponse = await fetch(
        "https://api.nadwa-uae.com/api/v3/customer/registersms/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(signupPayload),
        }
      );

      if (signupResponse.ok) {
        const signupData = await signupResponse.json();
        if (process.env.NODE_ENV === "development") {
          console.log("Signup response:", signupData);
        }

        const loginPayload = {
          method: "email",
          password: getPassword.current.value,
          phoneLoginMethod: "password",
          sms_verification_code: "",
          username: getEmail.current.value,
        };

        const loginResponse = await fetch(
          "https://api.nadwa-uae.com/api/v2/users/login_new/",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(loginPayload),
          }
        );

        if (loginResponse.ok) {
          const loginData = await loginResponse.json();
          localStorage.setItem("TH_AUTH_TOKEN", loginData.token);
          toast.success("Signup and login successful! Redirecting...");
          setTimeout(() => {
            navigate(loginData.user.is_customer ? "/customer-dashboard" : "/dashboard");
          }, 500);
        } else {
          const loginErrorData = await loginResponse.json();
          const getLoginErrorMessage = (errorData: any): string => {
            if (errorData.error_msg) return errorData.error_msg;
            if (errorData.email?.[0]) return errorData.email[0];
            return "Login failed after signup.";
          };
          throw new Error(getLoginErrorMessage(loginErrorData));
        }
      } else {
        const errorData = await signupResponse.json();
        const getSignupErrorMessage = (errorData: any): string => {
          if (errorData.sms_verification_code) return errorData.sms_verification_code;
          if (errorData.non_field_errors?.[0]) return errorData.non_field_errors[0];
          if (errorData.email?.[0]) return errorData.email[0];
          return "Signup failed.";
        };
        throw new Error(getSignupErrorMessage(errorData));
      }
    } catch (error) {
      if (error instanceof Error)
        toast.error(error.message || "An unexpected error occurred.");
    }
  };

  // Handle OTP request
  const handleOtp = async () => {
    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/users/sms_verification_code/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone: selectedCode + getPhone.current?.value,
            recaptcha_token: process.env.REACT_APP_RECAPCHA_TOKEN,
          }),
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log("OTP sent:", res);
        toast.success("OTP has been sent to your phone.");
        setTimer(60); // start 60-second timer
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Error sending OTP. Please try again.");
    }
  };

  return (
    <>
      {/* Header Section */}
      <header className={`flex justify-between ${i18n.language == "ar" ? "flex-row-reverse" : "flex-row"} items-center py-3 px-8 border-b border-gray-200 sticky top-0 bg-white z-20`}>
        <img src={LogoImage} alt="Logo" className="w-32" />
        <div className="relative">
          <button
            onClick={() => setIsLanguageDropdownVisible(!isLanguageDropdownVisible)}
            className={`flex items-center gap-2 border border-[#008847] px-3 py-1 rounded ${i18n.language === "ar" ? "flex-row-reverse" : "flex-row"
              }`}
          >
            <LanguagesIcon className="w-4 h-4" />
            {i18n.language === "ar" ? "العربية" : "English"}
          </button>
          {isLanguageDropdownVisible && (
            <ul className="absolute right-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
              {languages.map((language) => (
                <li
                  key={language.code}
                  onClick={() => changeLanguage(language.code)}
                  className="cursor-pointer px-4 py-2 hover:bg-[#008847] hover:text-white transition-colors duration-200"
                >
                  {language.lng}
                </li>
              ))}
            </ul>
          )}
        </div>
      </header >

      {/* Main Signup Content */}
      < div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className="flex justify-center items-center h-screen gap-10 xl:gap-20 py-20 xl:py-0 bg-white"
      >
        <ToastContainer />
        <div className="space-y-5 px-4">
          <div className="w-52 h-20 md:block hidden">
            {/* Logo placeholder if needed */}
          </div>
          <div className="flex flex-col space-y-5 px-10 bg-white">
            <div className="space-y-2">
              <h1 className="text-xl md:text-2xl font-semibold">
                {i18n.language === "ar" ? "إنشاء حساب جديد" : "Create Student Account"}
              </h1>
              <p className="text-[#393939] font-normal text-sm md:text-lg leading-6">
                {i18n.language === "ar" ? "لديك حساب بالفعل؟" : "Already have an account?"}{" "}
                <Link to="/login" className="text-[#008847] font-medium underline">
                  {i18n.language === "ar" ? "تسجيل الدخول" : "Log in"}
                </Link>
              </p>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="flex space-x-4 md:flex-row flex-col w-[70%]">
                <div className={`space-y-3  ${i18n.language == "ar" ? "me-3" : ""}`}>
                  <label className="block font-bold text-sm md:text-base text-black">
                    {i18n.language === "ar" ? "الاسم الاول" : "First Name"}
                  </label>
                  <input
                    type="text"
                    ref={getFirstName}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language === "ar" ? "الاسم الاول" : "Enter First Name"}
                  />
                </div>
                <div className="space-y-3">
                  <label className={`block font-bold text-sm md:text-base text-black`}>
                    {i18n.language === "ar" ? "الاسم الاخير" : "Last Name"}
                  </label>
                  <input
                    type="text"
                    ref={getLastName}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language === "ar" ? "الاسم الاخير" : "Enter Last Name"}
                  />
                </div>
              </div>

              <div className="space-y-3">
                <label className="block font-bold text-sm md:text-base text-black">
                  {i18n.language === "ar" ? "البريد الالكتروني" : "Email"}
                </label>
                <input
                  type="email"
                  ref={getEmail}
                  className="w-[70%] px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  placeholder={i18n.language === "ar" ? "البريد الالكتروني" : "Enter Your Email"}
                />
              </div>

              <div className="space-y-3 rounded-[40px]">
                <label className="block font-bold text-sm md:text-base text-black">
                  {i18n.language === "ar" ? "رقم الجوال" : "Telephone"}
                </label>
                <div className="flex items-center w-4/5 border border-[#D2D2D2] rounded-[40px] overflow-hidden focus-within:ring-2 focus-within:ring-[#008847]">
                  <select
                    className="px-2 py-2 outline-none rounded-l-[40px] bg-gray-100 text-sm text-black"
                    value={selectedCode}
                    onChange={handleCountryCodeChange}
                  >
                    {countryData.map((country) => (
                      <option key={country.iso_code} value={country.dial_code}>
                        {country.country.length > 15
                          ? `${country.country.slice(0, 15)}...`
                          : country.country} ({country.dial_code})
                      </option>
                    ))}
                  </select>
                  <input
                    type="tel"
                    ref={getPhone}
                    onChange={handlePhoneChange}
                    className="w-[70%] px-4 py-2 rounded-r-[40px] focus:outline-none"
                    placeholder={i18n.language === "ar" ? "رقم الجوال" : "Enter Your Number"}
                  />
                </div>

                {showGetOtp && (
                  <div className="relative space-y-3">
                    <label className="block font-bold text-sm md:text-base text-black">
                      {i18n.language === "ar" ? "رمز التحقق" : "OTP"}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                        placeholder={i18n.language === "ar" ? "رمز التحقق" : "Enter Your OTP"}
                      />
                      <button
                        type="button"
                        onClick={handleOtp}
                        disabled={timer > 0}
                        className={`absolute right-2 top-1/2 transform -translate-y-1/2 px-6 py-2 font-medium rounded-[28px] ${timer > 0
                          ? "bg-gray-400 text-white cursor-not-allowed"
                          : "bg-[#008847] text-white hover:bg-[#00773a]"
                          }`}
                      >
                        {timer > 0 ? `Resend in ${timer}s` : i18n.language === "ar" ? "احصل على رمز التحقق" : "Get OTP"}
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="space-y-3 w-[70%]">
                <label className="block font-bold text-sm md:text-base text-black">
                  {i18n.language === "ar" ? "كلمة المرور" : "Password"}
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    ref={getPassword}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder={i18n.language === "ar" ? "كلمة المرور" : "Enter your password"}
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className={`absolute inset-y-0 ${i18n.language === "en" ? "right-4" : "left-4"
                      } flex items-center text-[#667085]`}
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="w-[70%] py-3 bg-[#008847] text-white font-bold rounded-[40px] hover:bg-[#006836]"
              >
                {i18n.language === "ar" ? "تسجيل الدخول" : "Register"}
              </button>
            </form>
          </div>
        </div>

        <div className="hidden lg:flex items-center">
          <img src={img2 || "/placeholder.svg"} className="relative right-20 h-[680px] w-[550px] mt-6" alt="Student Registration" />
        </div>
      </div >
    </>
  );
};

export default SignupStudentForm;
