import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TutorUnreadBookings = () => {
  const [unreadMessage, setUnreadMessage] = useState<number | null>(null);

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      try {
        const res = await axios.get('https://api.nadwa-uae.com/api/v2/tutor/bookings/unread-count/', {
          headers: {
            Authorization: `Token ${localStorage.getItem('TH_AUTH_TOKEN')}`,
          },
        });
        setUnreadMessage(res.data.count);
      } catch (error) {
        console.error('Error fetching unread messages:', error);
      }
    };

    fetchUnreadMessages();

    
  }, []);

  return (
    <div className="relative inline-block">
      {/* Only display when unread messages are greater than zero */}
      {unreadMessage !== null && unreadMessage > 0 && (
        <div className="absolute -top-2 right-3 bg-pink-800 my-[-6px] text-white rounded-full w-5 h-5 flex items-center justify-center text-xs font-bold">
          {unreadMessage}
        </div>
      )}
    </div>
  );
};

export default TutorUnreadBookings;
