import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../../i18n";
import { DateTime } from "luxon";

export default function RescheduleModal({
  isOpen,
  onClose,
  id,
  setUpdateUi,
  updateUi,
  time,
  instantBook = false,
}: any) {
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [tutor, setTutor] = useState<any>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen && id) {
      fetchBookingDetails();
    }
  }, [isOpen, id]);

  const fetchBookingDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.nadwa-uae.com/api/v2/customer/bookings/${id}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setTutor(response.data.tutor.name);
      const { start_time } = response.data;
      if (start_time) {
        // Parse the start_time with its offset and convert to user's timezone
        const startDateTime = DateTime.fromISO(start_time, { setZone: true }).setZone(time);
        setDate(startDateTime.toFormat("yyyy-MM-dd"));
        setStartTime(startDateTime.toFormat("HH:mm"));
        const endDateTime = startDateTime.plus({ minutes: instantBook ? 60 : 30 });
        setEndTime(endDateTime.toFormat("HH:mm"));
      }
    } catch (error) {
      console.error("Failed to fetch booking details", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleReschedule = async () => {
    if (!date || !startTime) {
      return;
    }

    // Create DateTime objects in the user's timezone
    const startDateTime = DateTime.fromFormat(
      `${date} ${startTime}`,
      "yyyy-MM-dd HH:mm",
      { zone: time }
    );
    const endDateTime = startDateTime.plus({ minutes: instantBook ? 60 : 30 });

    // Convert to ISO strings with the timezone offset
    const formattedStartTime = startDateTime.toISO();
    const formattedEndTime = endDateTime.toISO();

    setLoading(true);
    try {
      await axios.put(
        `https://api.nadwa-uae.com/api/v2/customer/bookings/${id}/reschedule/`,
        {
          duration: instantBook ? 60 : 30,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      setUpdateUi(!updateUi);
      toast.success("Lesson rescheduled successfully!", {
        onClose: () => {
          setTimeout(() => onClose(), 500);
        },
        autoClose: 2000,
      });
    } catch (error: any) {
      const errorMsg =
        error.response?.data?.non_field_errors?.join(", ") ||
        error.response?.data?.detail ||
        "Failed to reschedule. Please try again.";
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Generate time options for start time (00:00 to 23:45 in 15-minute increments)
  const timeOptions = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute of [0, 15, 30, 45]) {
      const timeStr = `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
      timeOptions.push(timeStr);
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <ToastContainer position="top-right" autoClose={3000} />
      <div
        ref={modalRef}
        className="bg-white p-6 rounded-lg shadow-lg w-[450px] max-w-full mx-4 relative"
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">
            {t("reschedule_lesson")} {tutor}
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 p-1 font-bold"
            aria-label="Close"
          >
            ×
          </button>
        </div>

        {/* Warning Section */}
        <div className="mb-4">
          <p className="text-red-600 font-semibold text-sm">
            {t("reschedule_limitation")}
          </p>
        </div>

        {/* Form Fields */}
        <div className="space-y-4">
          {/* Date Picker */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t("select_date")}
            </label>
            <input
              type="date"
              value={date}
              onChange={handleDateChange}
              min={new Date().toISOString().split("T")[0]}
              className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
            />
          </div>

          {/* Time Inputs */}
          <div className="flex gap-4">
            {/* Start Time */}
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t("start_time")}
              </label>
              <select
                value={startTime}
                onChange={(e) => {
                  const newStartTime = e.target.value;
                  setStartTime(newStartTime);
                  const startDateTime = DateTime.fromFormat(
                    `${date} ${newStartTime}`,
                    "yyyy-MM-dd HH:mm",
                    { zone: time }
                  );
                  const endDateTime = startDateTime.plus({ minutes: instantBook ? 60 : 30 });
                  setEndTime(endDateTime.toFormat("HH:mm"));
                }}
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none"
              >
                <option value="" disabled>
                  {i18n.language === "en" ? "Select time" : "اختر الوقت"}
                </option>
                {timeOptions.map((timeStr) => (
                  <option key={timeStr} value={timeStr}>
                    {timeStr}
                  </option>
                ))}
              </select>
            </div>
            {/* End Time */}
            <div className="w-1/2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t("end_time")}
              </label>
              <div className="w-full border border-gray-300 rounded-md p-2 bg-gray-100 text-gray-700">
                {endTime || "Select start time"}
              </div>
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between mt-6 gap-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 transition-colors w-1/2"
          >
            {t("buttons.cancel")}
          </button>
          <button
            onClick={handleReschedule}
            disabled={loading || !date || !startTime}
            className={`px-4 py-2 rounded-md text-white transition-colors w-1/2 flex justify-center items-center ${loading || !date || !startTime
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
              }`}
          >
            {loading ? "Processing..." : t("reschedule")}
          </button>
        </div>
      </div>
    </div>
  );
}