import React, { useEffect, useState } from "react";
import {
  Home,
  User,
  Inbox,
  Settings,
  LogOut,
  Search,
  X,
  Briefcase,
  Languages as LanguagesIcon,
} from "lucide-react";
import LogoImage from "../../images/Homepageassets/Nadwa-Color.png";
import AccountSettings from "./Account";
import Unread from "../DashBoardComponents/Unread";
import BookingUnread from "../DashBoardComponents/BookingUnread";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

interface StudentSidebarProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setUnreadMessage: (count: number | null) => void;
  unreadMessage: number | null;
  message: number | null;
  setMessage: (count: number | null) => void;
}

const StudentSidebar: React.FC<StudentSidebarProps> = ({
  activeTab,
  onTabChange,
  menuOpen,
  setMenuOpen,
  setUnreadMessage,
  unreadMessage,
  message,
  setMessage,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false);


  const tabs = [
    {
      id: "dashboard",
      name: t("dashboard_page.dashboard"),
      icon: <Home className="w-5 h-5" />,
    },
    {
      id: "findtutor",
      name: t("find_a_tutor_page.find_a_tutor"),
      icon: <Search className="w-5 h-5" />,
    },
    {
      id: "inbox",
      name: t("inbox_page.inbox"),
      icon: <Inbox className="w-5 h-5" />,
      unread: <Unread message={message} setMessage={setMessage} />,
    },
    {
      id: "booking",
      name: t("bookings"),
      icon: <User className="w-5 h-5" />,
      unread: (
        <BookingUnread
          setUnreadMessage={setUnreadMessage}
          unreadMessage={unreadMessage}
        />
      ),
    },
    { id: "jobs", name: t("jobs"), icon: <Briefcase className="w-5 h-5" /> },
  ];

  const languages = [
    { code: "en", lng: "English" },
    { code: "ar", lng: "Arabic" },
  ];

  const logout = () => {
    localStorage.removeItem("TH_AUTH_TOKEN");
    window.location.href = "/login";
  };
  // Toggle language dropdown visibility
  const toggleLanguageDropdown = () =>
    setIsLanguageDropdownVisible(!isLanguageDropdownVisible);

  // Change language and update body class for directional styling
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
    setIsLanguageDropdownVisible(false);
  };

  // Update document direction based on the current language
  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  return (
    <>
      {/* Overlay for mobile menu */}
      <div
        className={`fixed inset-0 z-40 bg-black/60 transition-opacity duration-300 ease-in-out ${menuOpen ? "opacity-100 visible" : "opacity-0 invisible"
          } lg:hidden`}
        onClick={() => setMenuOpen(false)}
      />

      {/* Sidebar */}
      <aside
        className={`fixed lg:relative top-0 left-0 h-screen lg:h-auto transition-transform duration-300 ease-in-out z-50 lg:z-0 ${menuOpen ? "translate-x-0" : "-translate-x-full lg:translate-x-0"
          } bg-white rounded-lg shadow lg:p-6 p-4 space-y-20 w-64`}
      >
        {/* Header with Logo and Close Button */}
        <div className="flex justify-between items-center">
          <img src={LogoImage} className="lg:w-40 w-32" alt="Nadwa Logo" />
          <X
            className={`lg:hidden absolute top-6 -right-16 bg-white rounded-full h-10 w-10 p-2 cursor-pointer ${!menuOpen ? "hidden" : "block"
              }`}
            onClick={() => setMenuOpen(false)}
          />
        </div>

        {/* Navigation Tabs */}
        <div className="space-y-10">
          <h1 className="font-medium text-[#808080] text-sm text-left">
            {t("overview")}
          </h1>
          <ul className="space-y-3">
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => {
                  onTabChange(tab.id);
                  setMenuOpen(false);
                  if (tab.id === "inbox") {
                    setMessage(null); // Reset unread messages for inbox
                  }
                }}
                className={`flex items-center gap-4 p-3 rounded-xl cursor-pointer transition-colors duration-200 ${activeTab === tab.id
                  ? "bg-[#008847] text-white font-normal"
                  : "text-black font-medium hover:bg-gray-100"
                  }`}
              >
                {tab.icon}
                <span className="text-sm">{tab.name}</span>
                {tab.unread && (
                  <span className="ml-auto">{tab.unread}</span> // Align unread badge to the right
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Settings Section */}
        <div className="space-y-2">
          <h1 className="font-medium text-[#808080] text-sm text-left">
            {t("settings")}
          </h1>
          <div className="space-y-2">
            {/* Account Settings */}
            {/* This section toggles the Account Settings modal */}
            <div
              onClick={() => {
                setIsOpen(!isOpen);
                setMenuOpen(false);
              }}
              className="flex items-center gap-4 p-3 rounded-xl text-black font-medium hover:bg-gray-100 cursor-pointer transition-colors duration-200"
            >
              <Settings className="w-5 h-5" />
              <span className="text-sm">{t("account_settings")}</span>
            </div>

            {/* Language Dropdown */}
            <div className="relative">
              <button
                onClick={toggleLanguageDropdown}
                className="flex items-center gap-4 p-3 w-full text-black font-medium rounded-xl hover:bg-gray-100 transition-colors duration-200"
              >
                <LanguagesIcon className="w-5 h-5" />
                <span className="text-sm">{t("header")}</span>
              </button>
              {isLanguageDropdownVisible && (
                <ul className="absolute left-0 mt-2 w-40 bg-white text-[#008847] rounded-lg shadow-md z-10">
                  {languages.map((language) => (
                    <li
                      key={language.code}
                      onClick={() => changeLanguage(language.code)}
                      className="py-2 px-4 hover:bg-[#008847] hover:text-white cursor-pointer transition-colors duration-200"
                    >
                      {language.lng}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Logout */}
            <div
              onClick={logout}
              className="flex items-center gap-4 p-3 rounded-xl text-[#FD5823] font-medium hover:bg-gray-100 cursor-pointer transition-colors duration-200"
            >
              <LogOut className="w-5 h-5" />
              <span className="text-sm">
                {t("dashboard_page.settings_menu.log_out")}
              </span>
            </div>
          </div>
        </div>
      </aside>

      {/* Account Settings Modal */}
      {isOpen && <AccountSettings />}
    </>
  );
};

export default StudentSidebar;
