import axios from "axios";
import {
  ArrowRightLeft,
  Calendar,
  Check,
  GraduationCap,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import BookinBankModal from "./BookingBankDetails";
import BookLessonModal from "./BookLessonModals";
import ChnageLesson from "./ChangeLessonPopup";
import ConfirmTrialModal from "./ConfirmTrialModals";
import DelLessonPopup from "./DeleteLessonPopup";
import { RejectTrialModal } from "./RejectTrialModal";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

type TrialCall = {
  id: number;
  customer: {
    name: string;
    first_name: string;
    photo_card: string | null;
  };
  tutor: {
    name: string;
    first_name: string;
    photo_card: string;
  };
  subject: {
    subject_name: string;
  };
  confirmed_at: string | null;
  confirmed_status: "timeout" | "pending" | "confirmed" | null;
  location: string;
  is_accepted_by_customer?: boolean;
  end_time?: string;
  start_time?: string;
  is_trial_call?: string;
  is_accepted_by_tutor: any;
};

const LessonInterface = ({
  initialSubTab = "trial-call",
  sendMessage,
  setMessageId,
  setData,
  setIsTrue,
  isTrue,
}: any) => {
  const [isBookLessonModalOpen, setIsBookLessonModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("trial-call");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [tutorData, setTutorData] = useState<any>({});
  const [bankModal, setBankModal] = useState<any>();
  const [bankData, setBankData] = useState<any>([]);
  const [isConfirmTrialModalOpen, setIsConfirmTrialModalOpen] = useState(false);
  const [isRejectTrialModalOpen, setIsRejectTrialModalOpen] = useState(false);
  const { t } = useTranslation();
  const [timezone, setTimeZone] = useState<string>("");
  const sendMesageHandler = (id: number) => {
    sendMessage();
    setMessageId(id);
  };

  const [trialCallData, setTrialCallData] = useState<TrialCall[]>([]);
  const [upComingData, setUpcomingData] = useState<TrialCall[]>([]);
  const [PassData, setPassData] = useState<TrialCall[]>([]);
  const [isDelLesson, setIsDelLesson] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [selectedLesson, setSelectedLesson] = useState<{
    startTime: string;
    endTime: string;
    studentName: string;
    id: any;
  } | null>(null);
  const [isChangeLessonOpen, setIsChangeLessonOpen] = useState(false);
  const [selectedChangeLesson, setSelectedChangeLesson] = useState<{
    startTime: string;
    endTime: string;
    studentName: string;
    id: any;
    date?: string;
  } | null>(null);
  const [updateUi, setUpdateUi] = useState(false);

  useEffect(() => {
    setActiveTab(initialSubTab);
  }, [initialSubTab]);

  const delLessonToggle = (student: any = null) => {
    if (student) {
      setSelectedLesson({
        id: student.id,
        startTime: student.start_time,
        endTime: student.end_time,
        studentName: student.customer.name,
      });
      setIsDelLesson(true);
    } else {
      setSelectedLesson(null);
      setIsDelLesson(false);
    }
  };

  const toggleChangeLesson = (student: any = null) => {
    if (student) {
      setSelectedChangeLesson({
        id: student.id,
        startTime: student.start_time,
        endTime: student.end_time,
        studentName: student.customer.name,
        date: student.date,
      });
      setIsChangeLessonOpen(true);
    } else {
      setSelectedChangeLesson(null);
      setIsChangeLessonOpen(false);
    }
  };

  const handleDeleteLesson = async (lessonId: number) => {
    if (!lessonId) return;

    const lessonToDelete: any = upComingData.find((lesson) => lesson.id === lessonId);
    if (!lessonToDelete) {
      toast.error("Lesson not found");
      return;
    }

    const lessonStartTime = new Date(lessonToDelete.start_time);
    const now = new Date();
    const hoursUntilLesson = (lessonStartTime.getTime() - now.getTime()) / (1000 * 60 * 60);

    if (hoursUntilLesson < 48) {
      toast.error("Lessons cannot be cancelled within 48 hours of start time");
      return;
    }

    setIsDeleting(true);

    try {
      const response = await axios.post(
        `https://api.nadwa-uae.com/api/v2/tutor/bookings/${lessonId}/cancel/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      if (response.status === 200) {
        setUpcomingData((prevData) => prevData.filter((lesson) => lesson.id !== lessonId));
        toast.success("Lesson cancelled successfully");
      } else {
        toast.error(response.data?.error_message || "Failed to cancel the lesson");
      }
    } catch (error: any) {
      console.error("Error cancelling lesson:", error);
      const errorMessage = error?.response?.data?.error_message || error?.response?.data?.message || "Error cancelling lesson";
      toast.error(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(response.data);
        setTimeZone(response.data.user_timezone);
      } catch (err) {
        setError("Failed to fetch tutor data");
      } finally {
        setLoading(false);
      }
    };
    fetchTutorData();
  }, [updateUi]);

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get<TrialCall[]>(
          "https://api.nadwa-uae.com/api/v2/tutor/bookings/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        const alllessons = response.data;
        const currentTime = new Date().toISOString();

        setTrialCallData(
          alllessons.filter((lesson: TrialCall) => lesson.is_trial_call)
        );
        setUpcomingData(
          alllessons.filter(
            (lesson: any) =>
              new Date(lesson.end_time) > new Date(currentTime) &&
              !lesson.is_trial_call
          )
        );
        setPassData(
          alllessons.filter(
            (lesson: TrialCall) =>
              !lesson.is_trial_call &&
              lesson.is_accepted_by_customer &&
              lesson.end_time &&
              new Date(lesson.end_time) <= new Date(currentTime)
          )
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLessons();
  }, [updateUi]);

  const fetchDataForBank = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setBankData(response.data.data.user);
    } catch (err) {
      setError("Failed to fetch bank data");
      setBankData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataForBank();
  }, [updateUi]);

  const toggleBookLessonModal = () => {
    if (bankData !== null && !loading) {
      if (!bankData?.pay_via_oktopi) {
        setBankModal(true);
      } else {
        setIsBookLessonModalOpen(true);
      }
    } else if (!loading && !error) {
      fetchDataForBank().then(() => {
        if (bankData && !bankData?.pay_via_oktopi) {
          setBankModal(true);
        } else if (bankData) {
          setIsBookLessonModalOpen(true);
        }
      });
    }
  };

  function formatDubaiTime(utcTime: any, showDate = false) {
    const date = new Date(utcTime);
    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: bankData?.user_timezone || "Asia/Dubai",
    };

    if (showDate) {
      options.weekday = "short";
      options.month = "short";
      options.day = "numeric";
    }

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  const renderContent = () => {
    switch (activeTab) {
      case "upcoming":
        return (
          <div className={`grid grid-cols-1 ${upComingData.length > 1 ? 'xl:grid-cols-2' : 'xl:grid-cols-1'} gap-4 w-full`}>
            {upComingData.length === 0 ? (
              <div className="text-gray-600">{i18n.language == "en" ? "No upcoming lessons scheduled" : "لا توجد دروس قادمة مخطط لها"}</div>
            ) : (
              upComingData.map((student: any) => (
                <div
                  key={student.id}
                  className="border-2 border-[#008847] rounded-lg p-4 overflow-hidden w-full"
                >
                  <div className="flex flex-col sm:flex-row items-center gap-4">
                    <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center bg-[#008847] text-white font-bold text-lg">
                      {student.customer.name
                        .split(" ")
                        .map((word: any) => word[0] + word[1])
                        .slice(0, 2)
                        .join("")
                        .toUpperCase()}
                    </div>
                    <div className="flex-grow">
                      <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div>
                          <p className="text-gray-600 font-medium text-[13px] md:max-w-[190px]">
                            {student.subject.subject_name}{" "}
                            {student.subject.level_name} Student
                          </p>
                          <h3 className="text-[14px] text-center sm:text-start">
                            {student.customer.name}
                          </h3>
                        </div>
                        <div className="space-x-2">
                          <div className="flex items-center justify-center gap-3">
                            {tutorData.in_person_tutoring && (
                              <span className="px-2 py-1 border-1 font-medium border-[#ED1D24] bg-red-50 text-[#ED1D24] text-sm rounded">
                                {t("online")}
                              </span>
                            )}
                            <span className="px-2 py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                              {new Date(student.end_time) < new Date() &&
                                student.is_accepted_by_customer &&
                                !student.cancelled_at
                                ? t("lesson_completed")
                                : t("pending")}
                            </span>
                          </div>
                          <div className="mt-4 flex items-center gap-2 text-[#1A5AFF] bg-[#dde9f7] border-[#1A5AFF] rounded px-2 py-1">
                            <Calendar />
                            <span className="text-sm">
                              {formatDubaiTime(student.start_time, true)} -{" "}
                              {formatDubaiTime(student.end_time)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isDelLesson && selectedLesson && (
                    <DelLessonPopup
                      isOpen={isDelLesson}
                      onClose={() => delLessonToggle(null)}
                      startTime={selectedLesson.startTime}
                      endTime={selectedLesson.endTime}
                      studentName={selectedLesson.studentName}
                      onDelete={handleDeleteLesson}
                      id={selectedLesson.id}
                      isDeleting={isDeleting}
                    />
                  )}
                  {isChangeLessonOpen && (
                    <ChnageLesson
                      updateUi={updateUi}
                      setUpdateUi={setUpdateUi}
                      timezone={timezone}
                      isOpen={isChangeLessonOpen}
                      onClose={() => toggleChangeLesson()}
                      studentName={selectedChangeLesson?.studentName}
                      lessonId={selectedChangeLesson?.id}
                      defaultDate={selectedChangeLesson?.date}
                      defaultStartTime={selectedChangeLesson?.startTime}
                      defaultEndTime={selectedChangeLesson?.endTime}
                    />
                  )}
                  {!student.is_accepted_by_customer && (
                    <p className="text-sm text-center sm:text-right max-w-72">
                      {t("mins")}
                    </p>
                  )}
                  <div className="flex flex-col sm:flex-row items-center justify-between mt-2">
                    {!student.is_accepted_by_customer && (
                      <h1
                        onClick={() => delLessonToggle(student)}
                        className="px-2 py-1 border-1 font-medium border-[#ED1D24] bg-red-50 text-[#ED1D24] text-sm rounded cursor-pointer"
                      >
                        {t("cancel_lesson")}
                      </h1>
                    )}
                    {student.is_accepted_by_customer && student.is_accepted_by_tutor && (
                      (() => {
                        const currentTime = new Date();
                        const startTime = new Date(student.start_time);
                        const endTime = new Date(student.end_time);
                        const canJoin = currentTime >= new Date(startTime.getTime() - 15 * 60 * 1000) && currentTime < endTime;
                        const isPast = currentTime >= endTime;

                        return (
                          <a
                            href={canJoin ? `https://whiteboard.tutorhouse.co.uk/?id=${student.id}&startime=${student.start_time}&endtime=${student.end_time}` : undefined}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button
                              className={`w-full p-1 lg:py-3 px-3 rounded-lg text-sm lg:text-lg mb-2 sm:mb-0 ${isPast
                                ? "bg-gray-500/80 text-white cursor-not-allowed"
                                : canJoin
                                  ? "bg-[#c9f7e2] text-[#008847] border-2 border-[#008847] cursor-pointer"
                                  : "bg-gray-300 text-gray-500 border-2 border-gray-400 cursor-not-allowed"
                                }`}
                              disabled={!canJoin}
                            >
                              {t("join_online_classroom")}
                            </button>
                          </a>
                        );
                      })()
                    )}
                    {setIsTrue(student.is_accepted_by_tutor && student.is_accepted_by_customer)}
                    <button
                      disabled={student.reschedule_count_by_tutor !== 0}
                      onClick={() => toggleChangeLesson(student)}
                      className={`changeButton relative px-2 py-1 flex items-center gap-2 border-1 border-[#008847] text-[#008847] text-sm rounded ${student.reschedule_count_by_tutor !== 0
                        ? "bg-gray-300 text-gray-500 border-2 border-gray-400 cursor-not-allowed group"
                        : "block bg-[#daeee5] cursor-pointer"
                        }`}
                    >
                      <ArrowRightLeft className="h-5 w-5" />
                      {t("change_lesson")}
                      {student.reschedule_count_by_tutor !== 0 && (
                        <div className="absolute hidden group-hover:block bg-[#008847] text-white text-xs rounded p-1 bottom-full mb-1 left-1/2 transform -translate-x-1/2">
                          {t("you_have_already_recheduled")}
                        </div>
                      )}
                    </button>
                  </div>
                  <div className="flex flex-col sm:flex-row items-center justify-between">
                    {student.is_accepted_by_customer ? (
                      <div className="mt-4 flex items-center gap-2">
                        <span className="flex items-center gap-1 px-2 py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                          <Check className="w-5 h-5" />
                          {t("confirmed")}
                        </span>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        <span className="flex items-center gap-1 px-2 py-1 bg-[#f3f2de] border-1 border-[#FFB704] text-[#FFB704] rounded text-sm">
                          <X className="w-5 h-5" />
                          {t("unconfirmed")}
                        </span>
                      </div>
                    )}
                    <button
                      onClick={() => sendMesageHandler(student.chat)}
                      className="w-full mt-3 sm:mt-0 sm:w-1/3 py-2 bg-[#008847] text-white rounded hover:bg-green-700"
                    >
                      {t("send_a_message")}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        );

      case "trial-call":
        return (
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 w-full">
            {trialCallData.length === 0 ? (
              <div className="text-gray-600">{t("no_trial")}</div>
            ) : (
              trialCallData.map((student: any) => (
                <div
                  key={student.id}
                  className="border-2 border-[#008847] rounded-lg p-4 overflow-hidden w-full"
                >
                  <div className="flex flex-col sm:flex-row items-center sm:gap-4">
                    <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center bg-[#008847] text-white font-bold text-lg">
                      {student.customer.name
                        .split(" ")
                        .map((word: any) => word[0] + word[1])
                        .slice(0, 2)
                        .join("")
                        .toUpperCase()}
                    </div>

                    <div className="flex-grow">
                      <div className="flex flex-col sm:flex-row items-center justify-between">
                        <div>
                          <p className="text-gray-600 font-medium text-[13px] md:max-w-[150px]">
                            {student.subject.subject_name}{" "}
                            {student.subject.level_name} Student
                          </p>
                          <h3 className="text-[14px] text-center sm:text-left">
                            {student.customer.name}
                          </h3>
                        </div>
                        <div className="space-x-2">
                          {tutorData.in_person_tutoring && (
                            <span className="px-2 py-1 border-1 font-medium border-[#ED1D24] bg-red-50 text-[#ED1D24] text-sm rounded">
                              {t("online")}
                            </span>
                          )}

                          <span className="px-[2px] py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                            {new Date(student.end_time) < new Date() &&
                              student.is_accepted_by_tutor &&
                              !student.cancelled_at
                              ? t("lesson_completed")
                              : t("lesson_pending")}
                          </span>
                          <div className="mt-4 flex items-center gap-2 text-[#1A5AFF] bg-[#dde9f7] border-[#1A5AFF] rounded px-2 py-1">
                            <Calendar />
                            <span className="text-sm">
                              {formatDubaiTime(student.start_time, true)} -{" "}
                              {formatDubaiTime(student.end_time)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {new Date(student.end_time) >= new Date() &&
                    student.is_accepted_by_customer &&
                    student.is_accepted_by_tutor && (
                      <p className="text-sm sm:w-[50%] text-center sm:text-left">
                        Online lesson space will unlock 15 minutes before your
                        booking begins
                      </p>
                    )}
                  {!student.is_accepted_by_customer && (
                    <p className="text-sm  text-center sm:text-right max-w-72">
                      {t("mins")}
                    </p>
                  )}

                  <div className="flex flex-col sm:flex-row items-center justify-between mt-2 space-y-3">
                    {setIsTrue(
                      student.is_accepted_by_tutor &&
                      student.is_accepted_by_customer
                    )}
                    {new Date(student.end_time) >= new Date() &&
                      student.is_accepted_by_customer &&
                      student.is_accepted_by_tutor && (
                        <a
                          target="_blank"
                          href="https://whiteboard.tutorhouse.co.uk/?id=356254&startime=870&endtime=930"
                        >
                          <button
                            className={`w-full p-1 lg:py-3 px-3 rounded-lg text-sm lg:text-lg ${notification &&
                              student.is_accepted_by_customer &&
                              student.is_accepted_by_tutor &&
                              (() => {
                                const currentTime = new Date();
                                const startTime = new Date(student.start_time);
                                const timeDifference =
                                  (startTime.getTime() -
                                    currentTime.getTime()) /
                                  (1000 * 60);
                                return (
                                  timeDifference > 0 && timeDifference <= 15
                                );
                              })()
                              ? "bg-[#c9f7e2] text-[#008847] border-2 border-[#008847] cursor-pointer"
                              : "bg-gray-300 text-gray-500 border-2 border-gray-400 cursor-not-allowed"
                              }`}
                            disabled={
                              !(
                                notification &&
                                student.is_accepted_by_customer &&
                                student.is_accepted_by_tutor &&
                                (() => {
                                  const currentTime = new Date();
                                  const startTime = new Date(
                                    student.start_time
                                  );
                                  const timeDifference =
                                    (startTime.getTime() -
                                      currentTime.getTime()) /
                                    (1000 * 60);
                                  return (
                                    timeDifference > 0 && timeDifference <= 15
                                  );
                                })()
                              )
                            }
                          >
                            {t("join_online_classroom")}
                          </button>
                        </a>
                      )}

                    {new Date(student.end_time) > new Date() &&
                      student.is_accepted_by_tutor &&
                      !student.cancelled_at && (
                        <button
                          disabled={student.reschedule_count_by_tutor !== 0}
                          onClick={() => toggleChangeLesson(student)}
                          className={`changeButton relative px-2 py-1 flex items-center gap-2 border-1 border-[#008847] text-[#008847] text-sm rounded  ${student.reschedule_count_by_tutor !== 0
                            ? "bg-gray-300 text-gray-500 border-2 border-gray-400 cursor-not-allowed group"
                            : "block bg-[#daeee5] cursor-pointer"
                            }`}
                        >
                          <ArrowRightLeft className="h-5 w-5" />
                          {t("change_lesson")}
                          {/* Tooltip */}
                          {student.reschedule_count_by_tutor !== 0 && (
                            <div className="absolute hidden group-hover:block bg-[#008847] text-white text-xs rounded p-1 bottom-full mb-1 left-1/2 transform -translate-x-1/2">
                              {t("you_have_already_recheduled")}
                            </div>
                          )}
                        </button>
                      )}
                  </div>
                  {!student.is_accepted_by_tutor && (
                    <div className="flex flex-col sm:flex-row items-center justify-between mt-2 gap-2">
                      <div
                        onClick={() =>
                          setIsConfirmTrialModalOpen(!isConfirmTrialModalOpen)
                        }
                        className="flex bg-[#008847] text-white rounded-lg px-3 py- items-center justify-center cursor-pointer  sm:gap-2"
                      >
                        <GraduationCap />
                        <p className="pt-3">{t("confirm_trial")}</p>
                      </div>
                      <div
                        onClick={() =>
                          setIsRejectTrialModalOpen(!isRejectTrialModalOpen)
                        }
                        className="flex items-center gap-2 text-white border border-red-500 bg-red-500 rounded-lg px-3 py- cursor-pointer"
                      >
                        <X className="text-white" />
                        <p className="pt-3">{t("reject_lesson")}</p>
                      </div>
                    </div>
                  )}
                  {isDelLesson && selectedLesson && (
                    <DelLessonPopup
                      isOpen={isDelLesson}
                      onClose={() => delLessonToggle(null)}
                      startTime={selectedLesson.startTime}
                      endTime={selectedLesson.endTime}
                      studentName={selectedLesson.studentName}
                      onDelete={handleDeleteLesson}
                      id={selectedLesson.id}
                      isDeleting={isDeleting}
                    />
                  )}
                  {isChangeLessonOpen && (
                    <ChnageLesson
                      timezone={timezone}
                      updateUi={updateUi}
                      setUpdateUi={setUpdateUi}
                      isOpen={isChangeLessonOpen}
                      onClose={() => toggleChangeLesson()}
                      studentName={selectedChangeLesson?.studentName}
                      lessonId={selectedChangeLesson?.id}
                      defaultDate={selectedChangeLesson?.date || ""}
                      defaultStartTime={selectedChangeLesson?.startTime}
                      defaultEndTime={selectedChangeLesson?.endTime}
                    />
                  )}
                  {/* CONFIRM LESSON MODAL */}
                  {isConfirmTrialModalOpen && (
                    <ConfirmTrialModal
                      updateUi={updateUi}
                      setUpdateUi={setUpdateUi}
                      isOpen={isConfirmTrialModalOpen}
                      onClose={() => setIsConfirmTrialModalOpen(false)}
                      id={student.invitation}
                    />
                  )}
                  {/* REJECT LESSON MODAL */}
                  {isRejectTrialModalOpen && (
                    <RejectTrialModal
                      isOpen={isRejectTrialModalOpen}
                      onClose={() => setIsRejectTrialModalOpen(false)}
                      id={student.id}
                      updateUi={updateUi}
                      setUpdateUi={setUpdateUi}
                    />
                  )}

                  <div className="flex flex-col sm:flex-row items-center justify-between">
                    {student.is_accepted_by_tutor ? (
                      <div className="mt-4 flex items-center gap-2">
                        <span className="flex items-center gap-1 px-2 py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                          <Check className="w-5 h-5" />
                          {t("confirmed")}
                        </span>
                      </div>
                    ) : (
                      <div className="mt-4 flex  items-center gap-2">
                        <span className="flex items-center gap-1 px-2 py-1 bg-[#f3f2de] border-1 border-[#FFB704] text-[#FFB704] rounded text-sm">
                          <X className="w-5 h-5" />
                          {t("unconfirmed")}
                        </span>
                      </div>
                    )}

                    <button
                      onClick={() => sendMesageHandler(student.chat)}
                      className="mt-4 sm:w-1/2 py-2 px-4 sm:px-0 bg-[#008847] text-white rounded hover:bg-green-700"
                    >
                      {t("send_a_message")}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        );
      case "past":
        return (
          <div className="grid grid-cols-1 xl:grid-cols-2 sm:gap-4 w-full">
            {PassData.length === 0 ? (
              <div className="text-gray-600">{t("no_past")}</div>
            ) : (
              PassData.map((student: any) => (
                <div
                  key={student.id}
                  className="border-2 border-[#008847] rounded-lg p-4 overflow-hidden w-full"
                >
                  <div className="flex  flex-col sm:flex-row items-center gap-4">
                    <div className="w-12 h-12 rounded-full overflow-hidden flex items-center justify-center bg-[#008847] text-white font-bold text-lg">
                      {student.customer.name
                        .split(" ")
                        .map((word: any) => word[0] + word[1])
                        .slice(0, 2)
                        .join("")
                        .toUpperCase()}
                    </div>

                    <div className="flex-grow">
                      <div className="flex  flex-col sm:flex-row items-center justify-between">
                        <div>
                          <p className="text-gray-600 font-medium text-[13px] md:max-w-[180px]">
                            {student.subject.subject_name}{" "}
                            {student.subject.level_name} Student
                          </p>
                          <h3 className="text-[14px] text-center sm:text-left">
                            {student.customer.name}
                          </h3>
                        </div>
                        <div className="space-x-2">
                          {student.is_accepted_by_tutor &&
                            student.is_accepted_by_customer ? (
                            <div className="mt-4 flex items-center gap-2">
                              <span className="flex items-center gap-1 px-2 py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                                <Check className="w-5 h-5" />
                                {t("confirmed")}
                              </span>
                            </div>
                          ) : new Date() > new Date(student.end_time) ||
                            (!student.confirmed_at &&
                              student.acceptance_changed_at &&
                              student.cancellation_period_hours &&
                              new Date() >
                              new Date(
                                new Date(
                                  student.acceptance_changed_at
                                ).getTime() +
                                student.cancellation_period_hours *
                                60 *
                                60 *
                                1000
                              )) ? (
                            <span className="px-2 py-1 border-1 font-medium border-[#ED1D24] bg-red-50 text-[#ED1D24] text-sm rounded">
                              Expired
                            </span>
                          ) : null}

                          <div className="mt-4 flex items-center gap-2 text-[#1A5AFF] bg-[#dde9f7] border-[#1A5AFF] rounded px-2 py-1">
                            <Calendar />
                            <span className="text-sm">
                              {formatDubaiTime(student.start_time, true)} -{" "}
                              {formatDubaiTime(student.end_time)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="px-[2px] py-1 bg-[#daeee5] border-1 border-[#008847] text-[#008847] text-sm rounded">
                    {new Date(student.end_time) < new Date() &&
                      student.is_accepted_by_tutor &&
                      !student.cancelled_at
                      ? t("lesson_completed")
                      : t("lesson_pending")}
                  </span>
                  <div className="flex  flex-col sm:flex-row items-center justify-between">
                    <button
                      onClick={() => sendMesageHandler(student.chat)}
                      className="mt-4 sm:w-1/3 py-2 px-3 sm:px-0 bg-[#008847] text-white rounded hover:bg-green-700"
                    >
                      {t("send_a_message")}
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <div className="sm:p-6">
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="flex justify-between items-center">
          <h1 className="text-[#000606] font-normal text-3xl">{t("bookings")}</h1>
          <div className="items-center gap-3 lg:flex hidden">
            <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
              <picture>
                <source srcSet={tutorData.photo} type="image/webp" />
                <img
                  src={
                    tutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"
                  }
                  alt={tutorData?.user?.first_name}
                  className="w-9 h-9 rounded-full"
                />
              </picture>
              <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
                {tutorData.user?.first_name}
              </h1>
            </div>
          </div>
        </div>

        {notification && isTrue && (
          <div className="top-0 bg-[#008847] text-white p-4 mb-4 rounded-lg fixed right-0">
            <strong>Reminder:</strong> {notification}
          </div>
        )}

        <div className="p-3 sm:p-5 rounded-lg bg-white md:mt-10 space-y-10 md:space-y-10">
          <div className="flex items-end justify-end">
            <button
              onClick={toggleBookLessonModal}
              className={`px-2 sm:px-4 py-1 sm:py-2 lg:hidden block border-4 rounded-full ${tutorData.is_live
                ? "bg-[#008847] border-[#80c3a3] text-white hover:bg-green-700"
                : "bg-gray-400 border-gray-500 text-gray-200 cursor-not-allowed"
                }`}
              disabled={!tutorData.is_live}
              title={!tutorData.is_live ? "Profile is not live" : ""}
            >
              {t("book_a_lesson")}
            </button>
          </div>
          <div className="flex justify-between items-center mb-6 text-sm sm:text-lg">
            <div className="flex gap-6">
              <button
                className={`${activeTab === "upcoming"
                  ? "text-[#008847] font-semibold border-b-2 border-[#008847]"
                  : "text-gray-400 hover:text-gray-600"
                  }`}
                onClick={() => setActiveTab("upcoming")}
              >
                {t("upcoming_lessons")}
              </button>
              <button
                className={`${activeTab === "trial-call"
                  ? "text-[#008847] font-semibold border-b-2 border-[#008847]"
                  : "text-gray-400 hover:text-gray-600"
                  }`}
                onClick={() => setActiveTab("trial-call")}
              >
                {t("trial_call")}
              </button>
              <button
                className={`${activeTab === "past"
                  ? "text-[#008847] font-semibold border-b-2 border-[#008847]"
                  : "text-gray-400 hover:text-gray-600"
                  }`}
                onClick={() => setActiveTab("past")}
              >
                {t("past_lessons")}
              </button>
            </div>
            <button
              onClick={toggleBookLessonModal}
              className={`px-4 py-2 lg:block hidden border-4 rounded-full ${tutorData.is_live
                ? "bg-[#008847] border-[#80c3a3] text-white hover:bg-green-700"
                : "bg-gray-400 border-gray-500 text-gray-200 cursor-not-allowed"
                }`}
              disabled={!tutorData.is_live}
              title={!tutorData.is_live ? "Profile is not live" : ""}
            >
              {t("book_a_lesson")}
            </button>

            {bankModal && (
              <BookinBankModal
                onClose={() => setBankModal(false)}
                tutorData={tutorData}
              />
            )}

            {isBookLessonModalOpen && (
              <BookLessonModal
                isOpen={isBookLessonModalOpen}
                onClose={() => setIsBookLessonModalOpen(false)}
                setUpdateUi={setUpdateUi}
                updateUi={updateUi}
                timezone={timezone}
              />
            )}
          </div>
          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default LessonInterface;