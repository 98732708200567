import QualificationExperience from "../../components/AboutTutorComponents/QualificationExperience";
import ReviewsCarousel from "../../components/AboutTutorComponents/ReviewsCarousel";
import TutorTimingCalender from "../../components/AboutTutorComponents/TutorTimingCalender";
import TutorVideo from "../../components/AboutTutorComponents/TutorVideo";
import TutorProfileCard from "../../components/AboutTutorComponents/TutorIntroduction";


function TutorDetail() {

  return (
    <div className="">
 
      <TutorProfileCard />
      <TutorVideo />
      {/* <AcademicsSubject /> */}
      <QualificationExperience />
      <TutorTimingCalender />
      <ReviewsCarousel />
    </div>
  );
}

export default TutorDetail;
