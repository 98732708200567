import React from 'react';
import fourthSectionImage from '../../images/asserts/Website-Assets/HomePage/image5.webp';
import bg from '../../images/asserts/Website-Assets/HomePage/bGimage4.webp';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Section4() {
  const { t, i18n }:{ t: (key: string) => string, i18n:any } = useTranslation();
  const isArabic = i18n.language === 'ar'; // Check if the current language is Arabic

  return (
    <div
      className="bg-left-top 2xl:h-[1000px] h-auto py-16 2xl:py-0 rounded-t-[100px]"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {/* Grid Cols 2 */}
      <div className="flex flex-col-reverse lg:flex-row gap-6 lg:gap-0 lg:max-w-[90%] max-w-[94%] mx-auto">
        <div className="lg:w-1/2">
          {/* Left Content */}
          <div>
            <div className="lg:h-[390px] flex flex-col justify-start items-start gap-4 lg:gap-[13px] 2xl:my-[200px] lg:my-[0px]">
              <h1
                className={`text-black 2xl:text-[65px] lg:text-[40px] font-bold ${
                  isArabic ? '' : "font-['Helvetica']"
                } lg:leading-[71.50px] text-2xl`}
              >
                {t('tutor4')}
              </h1>
              <p
                className={`text-black 2xl:text-[22px] lg:text-[19px] font-normal ${
                  isArabic ? '' : "font-['Inter']"
                } lg:leading-[30px]`}
              >
                {t('tutor5')}
                <br />
                <br />
                <strong
                  className={`flex flex-col gap-2 ${
                    isArabic ? '' : "font-['Inter']"
                  }`}
                >
                  {t('tutor6')}
                  <br />
                  {t('tutor7')}
                  <br />
                  {t('tutor8')}
                </strong>
              </p>
              <div className="relative">
                <Link to="/SignupTutor">
                  <button className="w-[181px] h-[52px] bg-[#ed1d24] rounded-[45px] hover:bg-black transition duration-300 text-white text-lg font-bold font-['Helvetica'] capitalize leading-[18px]">
                    {t('tutorbt')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <img
            src={fourthSectionImage}
            alt="Fourth section illustration"
            className="w-full h-full object-contain"
          />
        </div>
      </div>
    </div>
  );
}
