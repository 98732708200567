import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import i18n from "../../i18n";

function UpdatePassword(): JSX.Element {
  const { token } = useParams<{ token: string }>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match");
      setIsError(true);
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(
        "https://api.nadwa-uae.com/api/v2/users/password/reset/confirm/",
        {
          password: newPassword,
          token: token,
        }
      );
      setMessage("Password updated successfully");
      setIsError(false);
    } catch (error: any) {
      if (error.response && error.response.data.global) {
        setMessage(error.response.data.global);
      } else {
        setMessage("Failed to update password");
      }
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className="flex justify-center items-center h-screen">
      {/* Left Section */}
      <div className="w-[40%] h-[80%] flex flex-col justify-center p-10 bg-white">
        <img
          src={LogoImage}
          className="w-52 mb-6 absolute left-10 top-10"
          alt="Logo"
        />
        <h2 className="text-3xl font-semibold mb-2">
          {i18n.language === "ar" ? "تغيير كلمة المرور" : "Set New Password"}
        </h2>
        <p className="text-gray-600 mb-6">
          {i18n.language === "ar"
            ? "يرجى استخدام النموذج أدناه لتغيير كلمة المرور"
            : "Please use the form below to change your password"}
        </p>
        <form className="w-full" onSubmit={handleSubmit}>
          <label className="block text-gray-700 mb-1">
            {i18n.language === "ar" ? "كلمة المرور الجديدة" : "New Password"}
          </label>
          <input
            type="password"
            placeholder={
              i18n.language === "ar"
                ? "كلمة المرور الجديدة"
                : "Enter New Password"
            }
            className="w-4/5 py-2 px-3 border border-gray-300 rounded-3xl mb-4 focus:outline-none focus:ring-2 focus:ring-green-500"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <label className="block text-gray-700 mb-1">
            {i18n.language === "ar" ? "تأكيد كلمة المرور" : "Confirm Password"}
          </label>
          <input
            type="password"
            placeholder={
              i18n.language === "ar" ? "تأكيد كلمة المرور" : "Confirm Password"
            }
            className="w-4/5 py-2 px-3 border border-gray-300 rounded-3xl mb-4 focus:outline-none focus:ring-2 focus:ring-green-500"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button
            type="submit"
            className="w-4/5 bg-green-700/90 text-white py-2 rounded-3xl text-lg hover:bg-green-700"
            disabled={isLoading}
          >
            {isLoading
              ? `${i18n.language === "ar" ? "تحديث" : "Updating..."}`
              : `${i18n.language === "ar"
                ? "تغيير كلمة المرور"
                : "Change Password"
              }`}
          </button>
        </form>
        {message && (
          <p
            className={`mt-4 text-sm ${isError ? "text-red-600" : "text-green-600"
              }`}
          >
            {message}
          </p>
        )}
      </div>

      {/* Right Section */}
      <div className="w-[42%] h-[90%]">
        <img
          src={img2}
          alt="Green Section"
          className="w-full h-full object-cover rounded-3xl"
        />
      </div>
    </div>
  );
}

export default UpdatePassword;
