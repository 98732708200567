import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import contactimage from "../../images/Homepageassets/4.png";
import CalendlyWidget from "../ui/CalendlyWidget";
const Section8 = () => {
  const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });


  return (
    <div className="flex flex-col items-center py-12 lg:px-6 bg-white ">
      <div className=" w-full rounded-lg p-6 sm:p-10">
        <div className="mb-8">
          <div className="flex flex-row justify-start items-center  ">
            <div className="w-2.5 h-2.5 -mt-1 bg-red-600 rounded-full mb-2 mx-2"></div>
            <h4 className={`text-[#ed1d24] text-lg font-normal ${isArabic ? "" : "font-['Helvetica']"} uppercase leading-[18px]`}>{t('form')}</h4>
          </div>
            
          <h1 className={`lg:text-[65px] text-2xl font-bold ${isArabic ? "" : "font-['Helvetica']"} lg:leading-[71.50px]`}>
            {t('form2')}
          </h1>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 2xl:gap-4 gap-8">
          <div className="me-2 2xl:my-0 my-auto lg:block hidden">
            <img
              src={contactimage}
              alt="Contact"
              className="2xl:w-[700px] 2xl:h-[600px] lg:w-[650px] w-full h-full lg:h-[450px]"
            />
          </div>
          <div className="flex flex-col ">
            <h2 className={`font-bold ${isArabic ? "" : "font-['Helvetica']"} leading-[52.80px]`}>{t('form3')}</h2>
            {/* <p className={`text-black text-xl font-normal ${isArabic ? "" : "font-['Inter']"} leading-[30px]`}>{t('form4')}</p> */}
            <div
      dir="ltr"
              >
                   <CalendlyWidget/> 

            </div>
           
             
            
            
          </div> 
        
        </div>
      </div>
    </div>
  );
};

export default Section8;
