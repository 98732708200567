import React from 'react'

const Section2 = () => {
    return (
        <section className='w-full bg-gradient-to-b from-[#a4e7c6] via-white to-[#ebf0ee] rounded-tr-[100px] lg:pt-40 pt-20 pb-20 -mt-20 z-20 relative'>
            <div className='max-w-[94%] mx-auto'>
                <div className='flex flex-col items-start gap-6 max-w-[90%]'>
                    <div>
                        <h4 className='text-2xl font-bold'>Last Updated: 7th August 2024</h4>
                        <p className='my-4 lg:text-lg'>These Terms of Use ("Terms") governs your use of the  website, located at MyPrivateTutor, any mobile applications, and services ("Site") provided by Learnpick Technologies Inc ("" or "us" or "we" or "our") having its registered office at 2928 Redbud Ave, Oakville ON L6J 7J4, Canada.</p>
                        <p className='lg:text-lg'>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE, DOWNLOADING, INSTALLING OR USING OUR MOBILE APPLICATION. BY ACCESSING, REGISTERING OR USING THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS, INCLUDING THE DISCLAIMERS SET FORTH BELOW AND THE PRIVACY POLICY. IF YOU ARE NOT WILLING TO BE BOUND BY THESE TERMS OF USE, INCLUDING THE DISCLAIMERS AND PRIVACY POLICY, YOU MAY NOT ACCESS OR USE THIS SITE OR DOWNLOD OUR MOBILE APPLICATION.
                            WE RESERVE THE RIGHT, AT OUR DISCRETION, TO MODIFY, ADD, OR REMOVE PORTIONS OF THESE TERMS AT ANY TIME AND EACH SUCH CHANGE SHALL BE EFFECTIVE IMMEDIATELY UPON POSTING. PLEASE CHECK THESE TERMS PERIODICALLY FOR CHANGES. YOUR CONTINUED USE OF THIS SITE FOLLOWING THE POSTING OF CHANGES TO THESE TERMS WILL MEAN YOU ACCEPT THOSE CHANGES.</p>
                    </div>
                    <div>
                        <h4 className='text-2xl font-bold'>General Terms</h4>
                        <p className='my-4 lg:text-lg'>These Terms of Use ("Terms") governs your use of the  website, located at MyPrivateTutor, any mobile applications, and services ("Site") provided by Learnpick Technologies Inc ("" or "us" or "we" or "our") having its registered office at 2928 ReNadwa provides this Site for users seeking tutoring services ("Students") and for users offering tutoring services ("Tutors"), as well as any other entity like "Institutes" on whose behalf users accept these Terms. The terms "you," "You," "User," or "Users" refer to Students, Tutors, or any individual or entity (Institutes) that views, uses, accesses, browses, or submits any content or material to the Site. These Terms are effective upon acceptance and govern the relationship between you and us, including the provision of any Services on our Site. If the Terms conflict with any other document, policy, or content on our Site, the Terms will prevail for the purposes of Site usage. Any express waiver or failure to promptly exercise a right under the Terms will not establish a continuing waiver or any expectation of non-enforcement. If you disagree with the Terms and the Privacy Policy, please refrain from using our Site and exit immediately.</p>
                        <p className='lg:text-lg'>f any provision of the Terms is deemed invalid by any government law or regulation, or by any court or arbitrator, the parties agree that such provision will be replaced with a new provision that achieves the original business purpose. The remaining provisions of the Terms will remain fully effective.</p>
                    </div>
                    <div>
                        <h4 className='text-2xl font-bold'>Services</h4>
                        <p className='my-4 lg:text-lg'>You are responsible for your actions and omissions. The Site serves solely as a platform to connect Students and Tutors. In the event of any disputes with other users, you release Nadwa (including our parents, subsidiaries, officers, directors, shareholders, employees, agents, joint ventures, consultants, successors, and assigns) from any and all claims, demands, and damages (actual and consequential) of all kinds and nature, whether known or unknown, suspected or unsuspected, disclosed or undisclosed, arising from or related to such disputes. Students, Tutors, or representatives of institutes, as independent contractors, control the methods, materials, and all aspects of lessons.</p>
                        <p className='lg:text-lg'>We support the decisions and choices made by Tutors or Institutes. These decisions and choices are yours to make; we do not make decisions on your behalf. Our role is to assist you in finding suitable Tutors and connecting with them. Our Site allows you to ask informational questions and receive educational answers from Tutors. Students are responsible for choosing the right tutor or institute for their requirements. When making hiring decisions, Students should review and investigate each tutor's self-reported credentials, education, experience, and reviews from other students. ALL TUTORING SESSIONS SHOULD BE SUPERVISED BY A RESPONSIBLE ADULT.</p>
                        <p className='lg:text-lg'>The information provided on Nadwaregarding tutors, coaching centers, institutes, courses, etc., has been compiled by gathering data from various publicly available educational websites. The provided details are for general information purposes only. We regularly update the data to ensure accuracy. For any additional educational information displayed on our website, we recommend contacting the respective institutes.
                            Nadwaoperates the Site from various locations and does not guarantee that the website is suitable or accessible for use in all locations. Nadwaservices might not be available in your location, and service availability could vary among locations. Additionally, Nadwawill attempt to deliver all messages sent through the Site's messaging system. However, we cannot guarantee the delivery of all messages..
                            Nadwareserves the right to withdraw or modify aspects of the Nadwaservice, or the entire service, for legal or commercial reasons. There might also be instances when the Nadwaservice becomes inaccessible due to technical difficulties faced by Nadwaor on the Internet. Nevertheless, we will use reasonable skill and care to overcome such difficulties where possible.
                            Restricted Activities</p>
                        <p className='lg:text-lg'>You acknowledge and agree that MyPrivateTutor's services are intended solely to facilitate education and not for any illegal purposes. You are prohibited from using the Site to hire tutors for illegal activities, such as completing assignments, writing test or exam papers, taking quizzes, or any other form of work on your behalf. Additionally, you are not allowed to use MyPrivateTutor's services for any purpose that violates the academic honesty policy or other conduct policies of your school, college, university, academic institution, or workplace. You agree not to use the Site to</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section2