import { X } from "lucide-react";
import i18n from "../../i18n";

export default function MessageTutorModal({ isOpen, onClose, tutorImage, onSendMessage }: any) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl max-w-md w-full p-6 relative">
        {/* Close Button */}
        <button
        onClick={onClose} className={`absolute top-4 ${i18n.language == "en" ? "right-4" : "left-4"}  p-2 hover:bg-gray-100 rounded-full transition-colors`}>
          <X className="w-5 h-5 text-gray-500" />
        </button>

        {/* Tutor Image */}
        <div className="flex justify-center mb-4">
          <div className="w-24 h-24 rounded-full overflow-hidden">
            {/* <img src={tutorImage || "/placeholder.svg"} alt="Tutor" className="w-full h-full object-cover" /> */}
            <picture key={tutorImage}>
              <source srcSet={tutorImage} type="image/webp" />
              <img
                src={
                  tutorImage?.replace(".webp", ".jpg") ||
                  "fallback.jpg"
                }
                alt="img"
                className="w-full h-full object-cover"
              />
            </picture>
          </div>
        </div>

        {/* Content */}
        <div className="text-center mb-6">
          <h2 className={`${i18n.language == "en" ? "" : "text-right"} text-xl font-medium mb-4`}>{i18n.language == "en" ? "Message Your Tutor" : "مراسلة معلمك"}</h2>
          <p className={`${i18n.language == "en" ? "" : "text-right"} text-gray-600 text-sm`}>
            {i18n.language == "en" ? "Message your tutor to ask them any questions or to discuss any specific requirements you may have." : "مراسلة معلمك لطرح أي أسئلة أو مناقشة أي متطلبات محددة قد تكون لديك."}
          </p>
        </div>

        {/* Action Button */}
        <button
          onClick={() => onSendMessage()}
          className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          {i18n.language == "en" ? "Start Messaging" : "ابدأ المراسلة"}
        </button>
      </div>
    </div>
  );
}
