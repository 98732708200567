import axios from "axios";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import i18n from "../../../i18n";

export default function ReviewModal({ isOpen, onClose, feedBackId }: any) {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  if (!isOpen) return null;
  const handleReview = async (token: any) => {
    try {
      await axios.patch(
        `https://api.nadwa-uae.com/api/v2/customer/feedbacks/${token}/`,
        {
          rating: rating,
          comment: review,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      toast.success("Review submitted successfully", {
        onClose: () => {
          onClose();
        },
      });
    } catch (error: any) {
      toast.error(error.response.data.detail);
      console.log(error);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <ToastContainer />
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold text-center">{i18n.language === "en" ? "Leave a Review" : "اترك تقييمًا"}</h2>
        <div className="flex justify-center mt-4">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              className={`cursor-pointer text-2xl ${
                star <= rating ? "text-yellow-400" : "text-gray-300"
              }`}
              onClick={() => setRating(star)}
            >
              ★
            </span>
          ))}
        </div>
        <textarea
          className="w-full p-2 border rounded mt-4"
          rows={4}
          placeholder={ i18n.language === "en" ? "Write your review..." : "اكتب تقييمك..."}
          value={review}
          onChange={(e) => setReview(e.target.value)}
        ></textarea>
        <div className="flex justify-between mt-4">
          <button
            className="px-4 py-2 bg-gray-400 text-white rounded"
            onClick={onClose}
          >
            {i18n.language === "en" ? "Cancel" : "إلغاء"}
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded"
            onClick={() => {
              handleReview(feedBackId);
            }}
          >
               {i18n.language === "en" ? "Submit" : "إرسال"}
          </button>
        </div>
      </div>
    </div>
  );
}
