import axios from "axios";
import { ChevronDown, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Level {
  id: number;
  level: string;
}

interface Subject {
  id: number;
  name: string;
  levels: Level[];
}

interface Category {
  subjects: Subject[];
}

interface UserSubject {
  subject: number;
  level: number;
  experience: number;
  price_in_cents: number;
}

interface SelectedSubject {
  subject: Subject;
  levels: { level: Level; experience: number }[];
}

const priceToExperience: Record<number, number> = {
  50: 1,
  100: 8,
  150: 9,
  200: 3,
  250: 5,
  300: 7,
  350: 10,
  400: 11,
};

function TeachingSubjects({ price_in_cents = 0, setUpdateUi, updateUi }: { price_in_cents: number; setUpdateUi: any; updateUi: boolean }) {
  const [subjects, setSubjects] = useState<Category[]>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<SelectedSubject[]>([]);
  const [isSubjectDropdownOpen, setIsSubjectDropdownOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get<Category[]>("https://api.nadwa-uae.com/api/v3/subjects/");
        setSubjects(response.data);
        fetchUserSubjects(response.data);
      } catch (error) {
        console.error("Error fetching subjects:", error);
        toast.error("Failed to load subjects.");
      }
    };

    const fetchUserSubjects = async (allSubjects: Category[]) => {
      try {
        const profileResponse = await axios.get<{
          subjects: UserSubject[];
        }>("https://api.nadwa-uae.com/api/v2/tutor/profile/", {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        });

        const userSubjects = profileResponse.data.subjects;
        const selectedSubjectsMap = new Map<number, SelectedSubject>();

        userSubjects.forEach((userSubject) => {
          const subject = allSubjects
            .flatMap((category) => category.subjects)
            .find((s) => s.id === userSubject.subject);
          if (subject) {
            const level = subject.levels.find((l) => l.id === userSubject.level);
            if (level) {
              if (!selectedSubjectsMap.has(subject.id)) {
                selectedSubjectsMap.set(subject.id, {
                  subject,
                  levels: [],
                });
              }
              selectedSubjectsMap.get(subject.id)!.levels.push({
                level,
                experience: userSubject.experience,
              });
            }
          }
        });

        const selectedSubjectsArray = Array.from(selectedSubjectsMap.values());
        setSelectedSubjects(selectedSubjectsArray);
      } catch (error) {
        console.error("Error fetching user subjects:", error);
      }
    };

    fetchSubjects();
  }, []);

  const handleSubjectSelect = (subject: Subject) => {
    const isAlreadySelected = selectedSubjects.some(
      (entry) => entry.subject.id === subject.id
    );
    if (isAlreadySelected) {
      toast.info("Subject already selected.");
    } else {
      const priceInDollars = price_in_cents / 100;
      const defaultExperience = priceToExperience[priceInDollars] || 1;
      const newEntry: SelectedSubject = {
        subject,
        levels: subject.levels.map((level) => ({
          level,
          experience: defaultExperience,
        })),
      };
      setSelectedSubjects((prev) => [...prev, newEntry]);
    }
    setIsSubjectDropdownOpen(false);
  };

  const removeLevel = (subjectId: number, levelId: number) => {
    setSelectedSubjects((prev) => {
      const updated = prev.map((entry) => {
        if (entry.subject.id === subjectId) {
          const updatedLevels = entry.levels.filter((l) => l.level.id !== levelId);
          return { ...entry, levels: updatedLevels };
        }
        return entry;
      });
      return updated.filter((entry) => entry.levels.length > 0);
    });
  };

  const removeSubject = (subjectId: number) => {
    setSelectedSubjects((prev) => prev.filter((entry) => entry.subject.id !== subjectId));
  };

  const handleSaveSubjects = async () => {
    if (selectedSubjects.length === 0) {
      toast.error("Please select at least one subject and level.");
      return;
    }

    const payload = {
      subjects: selectedSubjects.flatMap((entry) =>
        entry.levels.map((l) => ({
          subject: entry.subject.id,
          level: l.level.id,
          experience: l.experience,
        }))
      ),
    };

    try {
      await axios.patch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        payload,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      toast.success("Subjects updated successfully!");
      setUpdateUi(!updateUi);
    } catch (error) {
      console.error("Error saving subjects:", error);
      toast.error("Failed to save subjects.");
    }
  };

  return (
    <div className="space-y-8">
      <ToastContainer transition={Bounce} />
      <h1 className="text-2xl font-semibold">{t("subjects")}</h1>
      <div>
        <h1 className="text-lg">{t("which_subjects")}</h1>
      </div>

      {/* Single Bar for Selected Subjects and Dropdown Trigger */}
      <div className="relative w-full">
        <div
          onClick={() => setIsSubjectDropdownOpen(!isSubjectDropdownOpen)}
          className="w-full p-2 bg-white border border-gray-300 rounded-lg flex flex-wrap gap-2 items-center cursor-pointer"
        >
          {selectedSubjects.length === 0 ? (
            <span className="text-gray-400">Select a Subject</span>
          ) : (
            selectedSubjects.map((entry) => (
              <div
                key={entry.subject.id}
                className="flex items-center bg-gray-100 px-2 py-1 rounded-md"
              >
                <span>{entry.subject.name}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering dropdown when removing subject
                    removeSubject(entry.subject.id);
                  }}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  <X size={16} />
                </button>
              </div>
            ))
          )}
          <ChevronDown
            className={`w-5 h-5 text-gray-400 ml-auto transition-transform ${isSubjectDropdownOpen ? "rotate-180" : ""}`}
          />
        </div>

        {isSubjectDropdownOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg">
            <ul className="py-1 max-h-60 overflow-auto">
              {subjects.flatMap((category) =>
                category.subjects.map((subject) => (
                  <li
                    key={subject.id}
                    onClick={() => handleSubjectSelect(subject)}
                    className="px-3 py-2 cursor-pointer hover:bg-gray-50"
                  >
                    {subject.name}
                  </li>
                ))
              )}
            </ul>
          </div>
        )}
      </div>

      {/* Levels for Each Subject */}
      {selectedSubjects.length > 0 && (
        <div className="space-y-4">
          {selectedSubjects.map((entry) => (
            <div key={entry.subject.id} className="p-4 border border-gray-300 rounded-lg">
              <div className="font-semibold text-lg">{entry.subject.name}</div>
              <div className="mt-2 flex flex-wrap gap-2">
                {entry.levels.map((l) => (
                  <div
                    key={l.level.id}
                    className="flex items-center bg-gray-100 px-2 py-1 rounded-md"
                  >
                    <span>{l.level.level}</span>
                    <button
                      onClick={() => removeLevel(entry.subject.id, l.level.id)}
                      className="ml-2 text-red-500 hover:text-red-700"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSaveSubjects}
          className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700"
        >
          {t("save_subjects")}
        </button>
      </div>
    </div>
  );
}

export default TeachingSubjects;