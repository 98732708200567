import { Mail, MoreVertical, Phone } from "lucide-react"
import { useState } from "react"
import EmailSentModal from "../components/DashBoardStudent/popups/EmailSend"
import VerificationErrorModal from "../components/DashBoardStudent/popups/ErrorPopup"
import PhoneVerificationModal from "../components/DashBoardStudent/popups/PhoneVarification"
import VerificationCodeModal from "../components/DashBoardStudent/popups/VerificationCode"
import EmailVerificationModal from "../components/DashBoardStudent/popups/Verify"
import country from "../Data/country.mjs"
import { useTranslation } from "react-i18next"
import i18n from "../i18n"

interface VerificationMenuProps {
    email: string
    phone: string
    email_verified: boolean
}

export default function EditNumberAndEmail({ email, phone, email_verified }: VerificationMenuProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false)
    const [isCodeModalOpen, setIsCodeModalOpen] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
    const [verificationType, setVerificationType] = useState<"email" | "phone">("email");
    const { t } = useTranslation();

    const handleEmailClick = async () => {
        setIsMenuOpen(false);
        setIsEmailModalOpen(true);
    }

    const handlePhoneClick = async () => {
        setIsMenuOpen(false);
        setIsPhoneModalOpen(true);
    }

    return (
        <div
         className="relative">
            {/* Three Dot Menu Button */}
            <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
                {t("dashboard_page.profile_section.edit_profile")}
                {/* <MoreVertical className="w-5 h-5 text-gray-600" /> */}
            </button>

            {/* Dropdown Menu */}
            {isMenuOpen && (
                <div className="absolute right-0 mt-2 bg-white rounded-lg shadow-lg py-1 z-50">
                    <button
                        onClick={handleEmailClick}
                        className={`w-full px-4 py-2 text-left flex items-center gap-2 ${email_verified && "bg-gray-300 cursor-not-allowed"}`}
                        disabled={email_verified}
                    >
                        <Mail className="w-4 h-4" />
                        <span>{email}</span>
                    </button>
                    <button
                        onClick={handlePhoneClick}
                        className="w-full px-4 py-2 text-left flex items-center gap-2 hover:bg-gray-100"
                    >
                        <Phone className="w-4 h-4" />
                        <span>{phone}</span>
                    </button>
                </div>
            )}

            {/* Modals */}
            <EmailVerificationModal isOpen={isEmailModalOpen} onClose={() => setIsEmailModalOpen(false)} emails={email} />

            <PhoneVerificationModal isOpen={isPhoneModalOpen} onClose={() => setIsPhoneModalOpen(false)} phone={phone} countryData={country} />

            <VerificationCodeModal
                email=""
                isOpen={isCodeModalOpen}
                onClose={() => setIsCodeModalOpen(false)}
                onVerify={(code) => {
                    console.log("Verification code:", code)
                    setIsCodeModalOpen(false)
                }}
                onResend={() => {
                    setIsCodeModalOpen(false)
                    if (verificationType === "email") {
                        handleEmailClick()
                    } else {
                        handlePhoneClick()
                    }
                }}
            />

            <EmailSentModal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)} />

            <VerificationErrorModal
                errorMessage=""
                isOpen={isErrorModalOpen}
                onClose={() => setIsErrorModalOpen(false)}
                onTryAgain={() => {
                    setIsErrorModalOpen(false)
                    if (verificationType === "email") {
                        handleEmailClick()
                    } else {
                        handlePhoneClick()
                    }
                }}
            />
        </div>
    )
}

