"use client";

import axios from "axios";
import { ChevronDown } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import i18n from "../../../i18n";

// Define the type for a school
interface School {
  id: number;
  academic_year: string;
  school_category: string;
  school_code: string;
  name: string;
}

const EditSchool = ({ setUpdateUi, updateUi }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [schools, setSchools] = useState<School[]>([]);
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);
  // Fetch user's profile and school list
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all schools
        const schoolsResponse = await axios.get<School[]>(
          "https://api.nadwa-uae.com/api/v2/schools/"
        );
        const schoolsData = schoolsResponse.data;

        if (!Array.isArray(schoolsData))
          throw new Error("Invalid schools data format");

        setSchools(schoolsData);

        // Fetch user's profile to get the selected school
        const profileResponse = await axios.get<{ school: School | null }>(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );

        const userData = profileResponse.data;
        if (userData.school) {
          // Ensure the selected school exists in the fetched schools list
          const matchedSchool = schoolsData.find(
            (sch: any) => sch.id === userData.school
          );
          if (matchedSchool) {
            setSelectedSchool(matchedSchool);
          } else {
            setSelectedSchool(schoolsData[0]); // Default to first school if not found
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data. Please try again.");
      }
    };

    fetchData();
  }, [updateUi]);

  // Handle school selection
  const handleSchoolSelect = (school: School) => {
    setSelectedSchool(school);
    setIsOpen(false);
  };

  const handleSave = async () => {
    if (!selectedSchool) {
      toast.error("Please select a school first.");
      return;
    }

    setIsSaving(true);
    const loadingToast = toast.loading("Saving selected school...");

    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          method: "PATCH",
          body: JSON.stringify({ school: selectedSchool.id }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${await response.text()}`);
      }

      toast.success(`School updated successfully !`);
      setUpdateUi(!updateUi)
    } catch (error) {
      console.error("Error saving school:", error);
      toast.error("Failed to save school. Please try again.");
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-8">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      {/* Title */}
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">{t("edit_school")}</h1>
      </div>

      {/* Dropdown Section */}
      <div>
        <h1 className="text-[#272727] text-lg font-normal">
          {t("choose_school")}
        </h1>
      </div>
      <div className="relative w-full max-w-md" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between"
        >
          <div className="flex items-center gap-2">
            {selectedSchool ? (
              <span className="text-base font-medium">
                {selectedSchool.name}
              </span>
            ) : (
              <span className="text-gray-400">Select a school</span>
            )}
          </div>
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? "rotate-180" : ""
              }`}
          />
        </button>

        {isOpen && schools.length > 0 && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
            <ul className="py-1 max-h-60 overflow-auto">
              {schools.map((school) => (
                <li
                  key={school.id}
                  onClick={() => handleSchoolSelect(school)}
                  className="flex items-center gap-2 px-3 py-2 hover:bg-gray-50 cursor-pointer"
                >
                  <span className="text-sm font-medium ">{school.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Save Button */}
      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={isSaving}
          className={`px-6 py-2 rounded-lg text-sm font-medium ${isSaving ? "bg-gray-400 text-gray-700" : "bg-[#008847] text-white"
            }`}
        >
          {isSaving ? "Saving..." : t("save_changes")}
        </button>
      </div>
    </div>
  );
};

export default EditSchool;
