import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { useFilterContext } from "../../ContextApi/FilterContext";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const TutorMatchPopup = ({
  setComplete,
  setTutorMatch,
  selectSubject,
  selectLevel,
  min,
  max,
  availableDays,
  setShowDays
}: any) => {
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [data, setData] = useState<any>({});
  const route = useNavigate();
  const { t } = useTranslation();
  const { setOtherFilters, setLoading, setSelectSubject, setCountry } =
    useFilterContext();
  const tags: string[] = [
    t("tag_aiming_for_a_grade"),
    t("tag_exam_help"),
    t("tag_dyslexia"),
    t("tag_sen"),
    t("tag_last_minute_revision"),
    t("tag_essay_writing_skills"),
    t("tag_sibling_support"),
    t("tag_coursework_support"),
    t("tag_outgoing"),
    t("tag_rational"),
    t("tag_sensitive"),
    t("tag_efficient"),
    t("tag_emotional"),
  ];

  const toggleTag = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setData(res.data.data.user);
        console.log(res.data.data.user);
      } catch (error: any) {
        console.error("Failed to fetch user data:", error.message);
        toast.error("Failed to fetch user data. Please try again.", {
          theme: "light",
          transition: Bounce,
        });
      }
    };
    getUser();
  }, []);

  const postJob = async () => {
    if (!data) {
      toast.error("User data is not available. Please reload the page.", {
        autoClose: 3000,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    try {
      await axios.post(
        "https://api.nadwa-uae.com/api/v3/customer/jobs/",
        {
          client_name: data.first_name,
          client_email: data.email,
          client_phone_number: data.phone_number,
          full_address: "Test",
          subject: selectSubject,
          tags: selectedTags,
          student_personality: [],
          student_availability: availableDays,
          price_min: min,
          price_max: max,
          level: selectLevel,
          description: "",
          postcode: "",
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setComplete(true);
      setTutorMatch(false);
    } catch (error: any) {
      console.log(error);
      const errorDetails =
        error.response?.data?.non_field_errors?.join(", ") ||
        error.response?.data?.client_phone_number?.join(", ");
      toast.error(errorDetails, {
        autoClose: 3000,
        theme: "light",
        transition: Bounce,
        onClose: () => {
          if (error.response?.data?.non_field_errors) route("/tutor");
          else setTutorMatch(false);
        },
      });
      if (error) {
        try {
          setLoading(true);
          const res = await axios.get(
            `https://api.nadwa-uae.com/api/v2/tutor/?subject_id=${selectSubject}&levelId=${selectLevel}&max_price=${max}&min_price=${min}&max_experience=60&min_experience=0&postcode&online=true`
          );
          setCountry("");
          setSelectSubject(selectSubject);
          setOtherFilters(res.data.results);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleSubmit = () => {
    postJob();
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-40">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick={false}
        pauseOnHover
        draggable
        theme="light"
        transition={Bounce}
      />
      <div className="bg-white rounded-[20px] shadow-lg lg:w-11/12 lg:max-w-3xl lg:p-6 p-3">
        <div className="relative w-full my-6">
          <div className="h-2 bg-[#00884733] w-full" />
          <div className="h-2 bg-green-600 w-[90%] absolute top-0 left-0">
            <div className="absolute -top-5 -right-5">
              <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">
                90%
              </div>
            </div>
          </div>
        </div>
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          {t("tailor_match")}
        </h2>
        <p className="text-black text-sm mb-6 lg:w-[70%]" dangerouslySetInnerHTML={{
          __html: i18n.language == "en" ? `Ok last Step - We want to make sure we match you perfectly with your
          tutor before your
          <span className="font-semibold">Free Trial Lesson</span> – so let us
          know a bit more about your son/daughter (or yourself if the tutoring
          is for you) by selecting at least 
          <span className="font-semibold">2 tags</span> from the below.` : "نريد التأكد من أننا نقوم بتماثل مثالي بينك وبين معلمك قبل درس التجربة المجاني – لذا أخبرنا قليلاً عن ابنك/ابنتك (أو عن نفسك إذا كان التدريس من أجلك) من خلال تحديد ما لا يقل عن علامتين من العلامات أدناه."
        }}>

        </p>
        <div className="flex flex-wrap gap-3 mb-6">
          {tags.map((tag) => (
            <button
              key={tag}
              className={`px-4 py-2 rounded-xl text-sm font-medium ${selectedTags.includes(tag)
                ? "bg-[#008847] text-white"
                : "bg-[#0088471A] border-2 border-[#008847] text-gray-700"
                } hover:bg-[#008847] hover:text-white transition`}
              onClick={() => toggleTag(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
        <div className="flex gap-3">
          <button
            onClick={() => {
              setShowDays(true);
              setTutorMatch(false);
            }}
            className="flex-1 py-2 px-4 text-gray-700 rounded-xl bg-[#0088471A] border-2 border-[#008847] w-1/2"
          >
            {t("back")}
          </button>
          <button
            disabled={selectedTags.length < 2}
            className={`w-1/2 bg-[#008847] text-white py-2 text-xl rounded-xl font-medium ${selectedTags.length < 2 ? "bg-gray-300 cursor-not-allowed" : ""
              } `}
            onClick={handleSubmit}
          >
            {t("submit")}
          </button>
        </div>
      </div>
    </div >
  );
};

export default TutorMatchPopup;
