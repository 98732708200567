import { useTranslation } from "react-i18next";
import BannerSection from "../../components/AboutPages/Banner";
import QualificationExperience from "../../components/AboutTutorComponents/QualificationExperience";
import ReviewsCarousel from "../../components/AboutTutorComponents/ReviewsCarousel";
import TutorProfileCard from "../../components/AboutTutorComponents/TutorIntroduction";
import TutorTimingCalender from "../../components/AboutTutorComponents/TutorTimingCalender";
import TutorVideo from "../../components/AboutTutorComponents/TutorVideo";
import bannerImage from "../../images/AboutImages/colleagues-while-working-process1.png";
import { useEffect } from "react";


function AboutTutor() {
  const { t }: { t: (key: string) => string } = useTranslation();
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])
  return (
    <div className="">
      <BannerSection title={t("AboutTutor")} image={bannerImage} />
      <TutorProfileCard />
      <TutorVideo />
      <QualificationExperience />
      <TutorTimingCalender />
      <ReviewsCarousel />
    </div>
  );
}

export default AboutTutor;
