import axios from "axios";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export default function TutoringPermit() {
  const [tutorData, setTutorData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(response.data);
      } catch (err) {
        setError("Failed to fetch tutor data"); // Hardcoded as no translation key provided
      } finally {
        setLoading(false);
      }
    };
    fetchTutorData();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading... {/* Hardcoded as no "loading" key in root */}
      </div>
    );
  }

  const tabs = [
    t("private_tutoring_permit"), // "Private Tutoring Permit – UAE"
    t("how_to_apply"), // "How to apply?"
    t("where_apply"), // "Where do I apply?"
  ];

  return (
    <div className="bg-gray-100 min-h-screen p-1 sm:p-6">
      <div className="max-w-7xl mx-auto">
        {error && <div className="text-red-500 mb-4">{error}</div>}

        <div className="flex justify-between items-center mb-6">
          <h1 className="text-[#000606] font-normal text-xl sm:text-3xl">
            {t("apply_license")} {/* "Apply to the UAE private tutoring license" */}
          </h1>
          <div className="items-center gap-3 lg:flex hidden">
            <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
              <picture>
                <source srcSet={tutorData.photo} type="image/webp" />
                <img
                  src={
                    tutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"
                  }
                  alt={tutorData?.user?.first_name}
                  className="w-9 h-9 rounded-full object-cover"
                />
              </picture>
              <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
                {tutorData.user?.first_name}
              </h1>
            </div>
          </div>
        </div>

        {/* Steps Navigation */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3grid-cols-3 2xl:grid-cols-4 gap-4 bg-gray-100 py-3">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className="no-underline text-white w-[95%] sm:w-[289px] xl:w-full h-10 text-center justify-center rounded-full flex items-center pt-3 cursor-pointer"
              onClick={() => setActiveTab(index)}
            >
              {index === 0 && (
                <svg
                  width="30"
                  height="41"
                  viewBox="0 0 34 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.3543 28.7027C15.5315 28.3925 15.5298 28.0112 15.3496 27.7026L0.377804 2.04935C-0.0112719 1.38269 0.469586 0.545292 1.24147 0.545292L32.5109 0.54529C33.0632 0.54529 33.5109 0.993006 33.5109 1.54529L33.5109 55.4548C33.5109 56.0071 33.0632 56.4548 32.5109 56.4548L1.22296 56.4548C0.455187 56.4548 -0.0261862 55.6254 0.354663 54.9588L15.3543 28.7027Z"
                    fill={activeTab === index ? "#008847" : "#c3ded1"}
                  />
                </svg>
              )}
              {index === 1 && (
                <svg
                  width="30"
                  height="41"
                  viewBox="0 0 34 57"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0678 29.2041C15.3474 28.7147 15.4872 28.47 15.486 28.202C15.4847 27.9341 15.3427 27.6907 15.0586 27.2039L1.25561 3.55341C0.475866 2.21737 0.0859965 1.54936 0.374277 1.04733C0.662558 0.545292 1.43602 0.545292 2.98295 0.545292L31.5109 0.545291C32.4537 0.54529 32.9251 0.54529 33.218 0.838183C33.5109 1.13108 33.5109 1.60248 33.5109 2.54529L33.5109 54.4548C33.5109 55.3976 33.5109 55.869 33.218 56.1619C32.9251 56.4548 32.4537 56.4548 31.5109 56.4548L2.94592 56.4548C1.41339 56.4548 0.64713 56.4548 0.358078 55.9568C0.0690259 55.4588 0.449126 54.7934 1.20933 53.4627L15.0678 29.2041Z"
                    fill={activeTab === index ? "#008847" : "#c3ded1"}
                  />
                </svg>
              )}
              {index === 2 && (
                <svg
                  width="30"
                  height="41"
                  viewBox="0 0 34 57"
                  fill="#008847"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.0678 29.2041C15.3474 28.7147 15.4872 28.47 15.486 28.202C15.4847 27.9341 15.3427 27.6907 15.0586 27.2039L1.25561 3.55341C0.475866 2.21737 0.0859965 1.54936 0.374277 1.04733C0.662558 0.545292 1.43602 0.545292 2.98295 0.545292L31.5109 0.545291C32.4537 0.54529 32.9251 0.54529 33.218 0.838183C33.5109 1.13108 33.5109 1.60248 33.5109 2.54529L33.5109 54.4548C33.5109 55.3976 33.5109 55.869 33.218 56.1619C32.9251 56.4548 32.4537 56.4548 31.5109 56.4548L2.94592 56.4548C1.41339 56.4548 0.64713 56.4548 0.358078 55.9568C0.0690259 55.4588 0.449126 54.7934 1.20933 53.4627L15.0678 29.2041Z"
                    fill={activeTab === index ? "#008847" : "#c3ded1"}
                  />
                </svg>
              )}
              <div
                className={`bg-[#008847] no-underline w-[95%] -ml-3 sm:w-[289px] xl:w-full h-10 text-center justify-center rounded-r-full flex items-center pt-3 cursor-pointer ${activeTab === index
                  ? "bg-[#008847] text-white"
                  : "bg-[#c3ded1] text-[#008847]"
                  }`}
              >
                <p>{tab}</p>
              </div>
            </div>
          ))}

          <div className="no-underline text-white w-[95%] sm:w-[289px] xl:w-full h-10 text-center justify-center rounded-full flex items-center pt-3 cursor-pointer">
            <svg
              width="50"
              height="41"
              viewBox="0 0 34 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3543 28.7027C15.5315 28.3925 15.5298 28.0112 15.3496 27.7026L0.377804 2.04935C-0.0112719 1.38269 0.469586 0.545292 1.24147 0.545292L32.5109 0.54529C33.0632 0.54529 33.5109 0.993006 33.5109 1.54529L33.5109 55.4548C33.5109 56.0071 33.0632 56.4548 32.5109 56.4548L1.22296 56.4548C0.455187 56.4548 -0.0261862 55.6254 0.354663 54.9588L15.3543 28.7027Z"
                fill="#008847"
              />
            </svg>
            <Link
              to="https://publicservices.mohre.gov.ae/UserNotifications/MohrePrivateTeacherWorkPermit"
              target="_blank"
              className="bg-[#008847] no-underline text-white w-[95%] -ml-3 sm:w-[289px] xl:w-full h-10 text-center justify-center rounded-r-full flex items-center pt-3 cursor-pointer"
            >
              <p>{t("apply_online_now")}</p> {/* "Apply Online Now" */}
            </Link>
          </div>
        </div>

        {/* Tab Content */}
        <div className="p-3 sm:p-5 bg-white shadow-md rounded-lg mt-4">
          {activeTab === 0 && (
            <div className="space-y-6">
              <div>
                <h2 className="text-xl font-bold mb-2">
                  {t("about_permit")} {/* "About the permit" */}
                </h2>
                <p className="text-gray-700">
                  {t("about_the_permit_paragraph")}
                </p>
                {i18n.language === "en" && (
                  <p className="text-gray-700 mt-2">
                    {/* Hardcoding English-only part as per original logic */}
                    "The introduction of a permit for individuals qualified to provide private lessons will help curb illegal and unregulated practices when recruiting private teachers, which risk affecting the learning process as a whole," Mr Al Mualla added.
                  </p>
                )}
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("permit_cost")} {/* "How much does it cost?" */}
                </h2>
                <p className="text-gray-700">{t("how_much_does_it_cost")}</p>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("who_eligible")} {/* "Who is eligible?" */}
                </h2>
                <p className="text-gray-700">{t("who_is_eligible")}</p>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("who_can_apply.description")}
                </h2>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("who_can_apply.list", { returnObjects: true })).map(
                    (item: string, index: number) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="space-y-6">
              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("how_to_apply")} {/* "How to apply?" */}
                </h2>
                <h3 className="text-base font-semibold mb-2">
                  {t("required_documents")} {/* "What documents do you need?" */}
                </h3>
                <p>{t("what_documents_do_you_need.intro")}</p>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">
                  {t("what_documents_do_you_need.sections.A.title")}
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("what_documents_do_you_need.sections.A.items", {
                    returnObjects: true,
                  })).map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">
                  {t("what_documents_do_you_need.sections.B.title")}
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("what_documents_do_you_need.sections.B.items", {
                    returnObjects: true,
                  })).map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">
                  {t("what_documents_do_you_need.sections.C.title")}
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("what_documents_do_you_need.sections.C.items", {
                    returnObjects: true,
                  })).map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">
                  {t("what_documents_do_you_need.sections.D.title")}
                </h3>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("what_documents_do_you_need.sections.D.items", {
                    returnObjects: true,
                  })).map((item: string, index: number) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("what_if_not_apply")} {/* "What if you don't apply?" */}
                </h2>
                <p>{t("what_if_not_apply_paragraph")}</p>
              </div>

              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("work_from_home")} {/* "Can you work from home?" */}
                </h2>
                <p>{t("work_from_home_paragraph")}</p>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div className="space-y-6">
              <div>
                <h2 className="text-lg font-semibold mb-2">
                  {t("tutoring_permit_where_d_i_apply.where_apply")}
                </h2>
                <p>
                  <Trans i18nKey="tutoring_permit_where_d_i_apply.apply_online_intro">
                    You can apply{" "}
                    <a
                      className="text-[#008847] hover:underline"
                      href="https://publicservices.mohre.gov.ae/UserNotifications/MohrePrivateTeacherWorkPermit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      online
                    </a>
                    . The process is simple:
                  </Trans>
                </p>

                <p className="mb-2">
                  {t("tutoring_permit_where_d_i_apply.apply_step1")}
                </p>
                <ul className="list-disc pl-5">
                  <li>{t("tutoring_permit_where_d_i_apply.apply_step1_detail")}</li>
                </ul>

                <p className="mb-2">
                  {t("tutoring_permit_where_d_i_apply.apply_step2")}
                </p>
                <ul className="list-disc pl-5 space-y-1">
                  {Object.values(t("tutoring_permit_where_d_i_apply.apply_categories", {
                    returnObjects: true,
                  })).map((category: string, index: number) => (
                    <li key={index}>{category}</li>
                  ))}
                </ul>

                <p>{t("tutoring_permit_where_d_i_apply.apply_step3")}</p>
                <p>{t("tutoring_permit_where_d_i_apply.apply_step4")}</p>
                <p>{t("tutoring_permit_where_d_i_apply.apply_step5")}</p>
              </div>

              <div>
                <h2 className="text-lg font-bold mb-2">
                  {t("processing_time")} {/* "How long does it take to get the Private Tutor visa?" */}
                </h2>
                <p>{t("how_long_does_it_take_to_get_the_private_tutor_visa")}</p>
              </div>

              <div>
                <h2 className="text-lg font-bold mb-2">
                  {t("it_has_been_more_than_5_days_and_i_have_not_heard_anything")}
                </h2>
                <p>
                  <Trans i18nKey="it_has_been_more_than_5_days_and_i_have_not_heard_anything">
                    You can reach out to MOHRE through their{" "}
                    <a
                      href="https://support.mohre.gov.ae/"
                      className="text-[#008847] hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      support website
                    </a>{" "}
                    - or by calling them on 600 590000.
                  </Trans>
                </p>
              </div>

              <div className="space-y-4">
                <div>
                  <h2 className="text-lg font-bold mb-2">
                    {t("reapply")} {/* "My application was declined, may I reapply?" */}
                  </h2>
                  <p>{t("my_application_was_declined_may_i_reapply")}</p>
                </div>

                <div>
                  <h2 className="text-lg font-bold mb-2">
                    {t("permit_validity")} {/* "How long is the permit valid for?" */}
                  </h2>
                  <p>{t("how_long_is_the_permit_valid_for")}</p>
                </div>

                <div>
                  <h2 className="text-lg font-bold mb-2">
                    {t("permit_fees")} {/* "What are the permit fees?" */}
                  </h2>
                  <p>{t("what_are_the_permit_fees")}</p>
                </div>

                <div>
                  <h2 className="text-lg font-bold mb-2">
                    {t("work_from_country")} {/* "If I get a license, can I work from my home country?" */}
                  </h2>
                  <p>{t("if_i_get_a_license_can_i_work_from_my_home_country")}</p>
                </div>

                <div>
                  <h2 className="text-lg font-bold mb-2">
                    {t("online_classes")} {/* "Can I use this permit to take online classes?" */}
                  </h2>
                  <p>{t("can_i_use_this_permit_to_take_online_classes")}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Removed the Nadwa acceptance section as it’s not in your translation file */}
      </div>
    </div>
  );
}