import { useEffect } from 'react';
import BannerSection from '../../components/AboutPages/Banner';
import CardSection from '../../components/Blogs/Card-Section';
import bannerImage from '../../images/Blogs/Hero.png';



function Blog() {
    useEffect(() => {
        const lang = localStorage.getItem("i18nextLng");
        if (!lang || lang === "en-US") {
            localStorage.setItem("i18nextLng", "en");
            window.location.reload();
        };
    }, [])
    return (
        <div>
            <BannerSection title='Blogs' image={bannerImage} />
            <CardSection />
        </div>
    )
}

export default Blog;