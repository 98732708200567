import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { CreditCard, Shield, Users, X } from "lucide-react";
import { useState } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import PaymentSuccessModal from "./PaymentSuccess";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { DateTime } from "luxon"; // Import Luxon for consistent date-time handling

export const stripePromise = loadStripe(
  "pk_live_51NEAKSIHYM23CUT7EtimUFQIvohxBtJYPlwijz9a5KIWlS9b0Q2XvBpqf18ID6x9CGyHV2FJOErD27eSnFLzX0d900nGLNLEew"
);

export default function CheckoutForm({
  tutor,
  bookingDetails,
  setActiveTab,
  setIsSecondModalOpen,
  price,
  time, // User's time zone passed from BookingInterface2
}: any) {
  const [discountCode, setDiscountCode] = useState("");
  const [desc, setDesc] = useState<string | null>(null);
  const [confirmed, setConfirmed] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  });
  const [originalPrice, setOriginalPrice] = useState<number | null>(null);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePayment = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      toast.error("Stripe has not loaded yet. Please wait.");
      setLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("Invalid card details. Please check and try again.");
      setLoading(false);
      return;
    }

    try {
      // Step 1: Create a setup intent
      const { data } = await axios.post(
        "https://api.nadwa-uae.com/api/v2/card/setupintent/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      const clientSecret = data.client_secret;
      if (!clientSecret) throw new Error("No client secret returned");

      // Step 2: Confirm card setup with Stripe
      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { name: formData.name, email: formData.email },
        },
      });

      if (error) throw new Error(error.message);

      // Step 3: Store the card
      await axios.post(
        "https://api.nadwa-uae.com/api/v2/card/",
        {
          stripe_id: setupIntent.payment_method,
          stripe_token: setupIntent.id,
          user: bookingDetails.customer, // Use dynamic customer ID
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      // Step 4: Retrieve stored cards
      const response = await axios.get("https://api.nadwa-uae.com/api/v2/card/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
        },
      });

      const cards = response.data;
      if (!cards || cards.length === 0) {
        throw new Error("No saved cards found.");
      }

      const defaultCard = cards.find((card: any) => card.is_default);
      const selectedCard = defaultCard ? defaultCard.id : cards[0].id;

      if (!selectedCard) {
        throw new Error("No valid credit card found.");
      }

      // Step 5: Make booking with selected card
      const updatedBookingDetails = {
        ...bookingDetails,
        credit_card: selectedCard,
      };
      const resp = await fetch(
        "https://api.nadwa-uae.com/api/v2/customer/invitations/book_instant/",
        {
          method: "POST",
          body: JSON.stringify(updatedBookingDetails),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      const responses = await resp.json();

      if (resp.ok) {
        toast.success("Payment successful");
        setTimeout(() => {
          setConfirmed(true);
        }, 500);
      } else {
        throw new Error(responses.non_field_errors?.[0] || "Booking failed");
      }
    } catch (error: any) {
      toast.error(error.message || "Payment failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const calculatePricePerLesson = (unitPrice: number) => {
    const basePrice = unitPrice;
    const additionalPrice = basePrice >= 50 ? Math.floor(basePrice / 100) * 2.5 : 0;
    return basePrice + additionalPrice;
  };

  const calculateTotalPrice = (unitPrice: number, numLessons: number, vatRate = 0.05) => {
    const pricePerLesson = calculatePricePerLesson(unitPrice);
    const totalBeforeVAT = pricePerLesson * numLessons;
    const totalWithVAT = totalBeforeVAT * (1 + vatRate);
    return totalWithVAT.toFixed(2);
  };

  const originalUnitPrice = tutor.selected_subject?.price_in_cents
    ? tutor.selected_subject.price_in_cents / 100
    : price
      ? price
      : 0;

  const discountedCode = async () => {
    try {
      const pairedTimes = bookingDetails.invitation_dates.map((invitation: any) => ({
        start_time: invitation.start_time,
        end_time: invitation.end_time,
      }));

      const res = await axios.post(
        `https://api.nadwa-uae.com/api/v2/coupon/check-code/?code=${discountCode}`,
        {
          invitation_dates: pairedTimes,
          customer: bookingDetails.customer,
          subject: bookingDetails.subject,
          tutor: bookingDetails.tutor,
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      const original = res.data.original_unit_price_in_cents / 100;
      const discounted = res.data.present_unit_price_in_cents / 100;

      setOriginalPrice(original);
      setDiscountedPrice(discounted);
      toast.success("Discount applied successfully!");
      setDesc(res.data.description);
    } catch (error: any) {
      toast.error(error.response?.data?.error_message || "Invalid discount code");
    }
  };

  return (
    <div className="bg-white inset-0 top-0 rounded-xl shadow-lg max-h-[80vh] overflow-y-scroll mt-5 max-w-[90%] mx-auto fixed z-[100]">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <X
        onClick={() => setIsSecondModalOpen(false)}
        className={`absolute ${i18n.language == "ar" ? "left-4" : "right-4"} top-2 cursor-pointer`}
      />

      <div className="grid md:grid-cols-2 gap-8 p-6">
        {/* Left Column */}
        <div className="space-y-6">
          {/* Tutor Info */}
          <div className="flex items-center gap-4">
            {tutor.photo !== null ? (
              <picture>
                <source srcSet={tutor?.photo} type="image/webp" />
                <img
                  src={tutor.photo?.replace(".webp", ".jpg") || "fallback.jpg"}
                  alt="Tutor"
                  className="w-10 h-10 rounded-full"
                />
              </picture>
            ) : (
              <div className="w-10 h-10 border border-black rounded-full bg-white flex justify-center items-center">
                {tutor.user?.first_name?.slice(0, 2) || "TU"}
              </div>
            )}
            <div>
              <h3 className="font-medium">{tutor?.name || tutor.user?.first_name}</h3>
              <p className="text-gray-600 text-sm">
                {tutor?.subject || tutor.selected_subject?.name}
              </p>
            </div>
          </div>

          {/* Total Amount */}
          <div>
            <div className="text-sm text-gray-600 mb-1">
              {i18n.language === "en" ? "Total" : "الإجمالي"}
            </div>
            <div className="text-2xl font-medium">
              {t("AED")}{" "}
              {discountedPrice !== null ? (
                <>
                  <span className="line-through text-gray-400">
                    {calculateTotalPrice(
                      originalPrice || originalUnitPrice,
                      bookingDetails.invitation_dates.length
                    )}
                  </span>{" "}
                  <span className="text-green-600">
                    {calculateTotalPrice(discountedPrice, bookingDetails.invitation_dates.length)}
                  </span>
                </>
              ) : (
                calculateTotalPrice(originalUnitPrice, bookingDetails.invitation_dates.length)
              )}
            </div>
            <div className="text-sm text-gray-500">
              {t("AED")}{" "}
              {discountedPrice !== null ? (
                <>
                  <span className="line-through text-gray-400 mr-2">
                    {calculatePricePerLesson(originalPrice || originalUnitPrice).toFixed(1)}
                  </span>
                  <span className="text-green-600 font-semibold">
                    {calculatePricePerLesson(discountedPrice).toFixed(1)}
                  </span>
                </>
              ) : (
                <span className="font-semibold">
                  {calculatePricePerLesson(originalUnitPrice).toFixed(1)}
                </span>
              )}{" "}
              {i18n.language === "en" ? "/per hour + 5% VAT" : "لكل ساعة + 5% ضريبة القيمة المضافة"}
            </div>
          </div>

          {/* Discount Code */}
          <div className="space-y-2">
            <label className="text-sm font-medium">
              {i18n.language === "en" ? "Discount Code" : "كود الخصم"}
            </label>
            {!desc ? (
              <div className="flex gap-2">
                <input
                  type="text"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholder={
                    i18n.language === "en"
                      ? "You can enter discount code here"
                      : "يمكنك إدخال كود الخصم هنا"
                  }
                  className="flex-1 px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-green-600"
                />
                <button
                  onClick={discountedCode}
                  className="px-4 py-2 bg-green-600 text-white rounded-lg text-sm hover:bg-green-700 transition-colors"
                >
                  {i18n.language === "en" ? "Apply" : "تطبيق"}
                </button>
              </div>
            ) : (
              <div className="flex items-center justify-between bg-gray-100 border rounded-md px-3 py-2 w-64 shadow-sm">
                <div>
                  <div className="flex items-center gap-2 font-semibold">
                    <span className="text-gray-600">💎 {discountCode}</span>
                  </div>
                  <div className="text-sm text-gray-700">{desc}</div>
                </div>
                <button
                  onClick={() => {
                    setDesc(null);
                    setDiscountCode("");
                    setDiscountedPrice(null);
                    setOriginalPrice(null);
                  }}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={16} />
                </button>
              </div>
            )}
          </div>

          {/* Lesson Details with Luxon */}
          <div className="bg-gray-50 rounded-lg p-4 space-y-2">
            <div className="font-medium">
              {bookingDetails.invitation_dates.length}{" "}
              {bookingDetails.invitation_dates.length === 1 ? t("lesson") : t("lessons")}
            </div>
            <div className="text-sm text-gray-600 max-h-[100px] overflow-y-auto flex flex-col gap-1">
              {bookingDetails.invitation_dates.map((invitation: any, index: number) => {
                const startTime = invitation.start_time
                  ? DateTime.fromISO(invitation.start_time, { zone: "utc" }).setZone(time)
                  : null;
                const endTime = startTime ? startTime.plus({ minutes: 60 }) : null;

                return (
                  <div key={index} className="text-sm">
                    <span className="font-bold">
                      {index + 1} {t("lesson")}
                    </span>{" "}
                    {startTime && endTime
                      ? `${startTime.toFormat("EEEE, MMMM d, yyyy")} from ${startTime.toFormat(
                        "HH:mm"
                      )} to ${endTime.toFormat("HH:mm")}`
                      : "Invalid Date"}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Trust Badges */}
          <div className="grid grid-cols-3 gap-4">
            {[
              {
                icon: Shield,
                text: i18n.language === "en" ? "Money Back Guarantee" : "ضمان استرداد الأموال",
              },
              {
                icon: Users,
                text: i18n.language === "en" ? "Curated Quality Tutors" : "مدربين مُختارون بجودة عالية",
              },
              {
                icon: CreditCard,
                text:
                  i18n.language === "en"
                    ? "Secure Online Payment System"
                    : "نظام دفع إلكتروني آمن",
              },
            ].map((badge, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center p-3 bg-gray-50 rounded-lg"
              >
                <badge.icon className="w-5 h-5 text-green-600 mb-2" />
                <span className="text-xs text-gray-600 leading-tight">{badge.text}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Right Column - Payment Form */}
        <form className="space-y-6 my-auto" onSubmit={handlePayment}>
          <div className="space-y-2">
            <label className="text-sm font-medium">{t("email")}</label>
            <input
              type="email"
              name="email"
              placeholder={i18n.language === "en" ? "Enter your email" : "أدخل بريدك الإلكتروني"}
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-green-600"
              required
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">
              {i18n.language === "en" ? "Name on Card" : "الاسم على البطاقة"}
            </label>
            <input
              type="text"
              name="name"
              placeholder={i18n.language === "en" ? "Your Name" : "اسمك"}
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg text-sm focus:ring-2 focus:ring-green-600"
              required
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">
              {i18n.language === "en" ? "Card Information" : "معلومات البطاقة"}
            </label>
            <CardElement className="w-full px-3 py-2 border rounded-lg text-sm" />
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors disabled:bg-gray-400"
            disabled={loading || !stripe}
          >
            {loading
              ? "Processing..."
              : i18n.language === "en"
                ? "Confirm Lesson"
                : "تأكيد الدرس"}
          </button>
        </form>
      </div>

      {confirmed && (
        <PaymentSuccessModal
          isOpen={confirmed}
          onClose={() => setConfirmed(false)}
        />
      )}
    </div>
  );
}