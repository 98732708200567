"use client";

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Profile = ({ updateUi, setUpdateUi }: any) => {
  const [tutorData, setTutorData] = useState({
    image: "",
    email: "",
    phone: "",
    dob: "",
    experience: 0,
    tagline: "",
    description: "",
  });
  const { t }: { t: (key: string) => string } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Fetch profile data
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );

        const data = response.data;

        setTutorData({
          image: data.image || "",
          email: data.email || "",
          phone: data.phone || "",
          dob: data.dob || "",
          experience: data.experience_years || 0, // Adjusted to use years of experience directly
          tagline: data.tagline || "",
          description: data.experience ?? "",
        });
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [updateUi]);

  // Handle description change
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTutorData({ ...tutorData, description: e.target.value });
  };

  // Handle experience change
  const handleExperienceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const experienceValue = parseInt(value);

    // Validate if the entered experience is a valid number within range
    if (experienceValue >= 0 && experienceValue <= 25) {
      setTutorData({ ...tutorData, experience: experienceValue });
    } else {
      toast.error("Experience must be between 0 and 25 years.");
    }
  };

  // Handle saving profile
  const handleSaveChanges = async () => {
    if (tutorData.description.split(/\s+/).length < 100) {
      toast.error("Description must be at least 100 words!");
      return;
    }

    setIsSaving(true);
    const loadingToast = toast.loading("Saving changes...");

    try {
      const res = await axios.patch(
        "https://api.nadwa-uae.com/api/v2/tutor/profile/",
        {
          email: tutorData.email,
          phone: tutorData.phone,
          dob: tutorData.dob,
          experience_years: tutorData.experience,
          tagline: tutorData.tagline,
          experience: tutorData.description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        toast.success("Profile updated successfully!");
        setUpdateUi(!updateUi);
      } else {
        throw new Error(res.data?.tagline?.[0] ?? "Something went wrong");
      }
    } catch (error: any) {
      console.error("Error saving profile:", error);

      const errorMessage =
        error.response?.data?.tagline?.[0] ||
        error.response?.data?.message ||
        error.message ||
        "Failed to update profile";

      toast.error(errorMessage);
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };


  return (
    <div className="max-w-xl mx-auto p-2 sm:p-4">
      <div className="mb-4">
        <label className="block font-semibold">{t("tagline")}</label>
        <input
          type="text"
          className="w-full p-2 border rounded"
          value={tutorData.tagline}
          onChange={(e) =>
            setTutorData({ ...tutorData, tagline: e.target.value })
          }
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold">{t("description")}</label>
        <textarea
          className="w-full p-2 border rounded"
          rows={5}
          value={tutorData.description}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block font-semibold">{t("experience_years")}</label>
        <input
          type="number"
          className="w-full p-2 border rounded"
          value={tutorData.experience}
          onChange={handleExperienceChange}
          min={0}
          max={25}
          placeholder="Enter years of experience"
        />
      </div>

      <button
        className={`px-4 py-2 text-white font-semibold rounded ${isSaving ? "bg-gray-400" : "bg-[#008847] "
          }`}
        onClick={handleSaveChanges}
        disabled={isSaving}
      >
        {isSaving ? "Saving..." : t("save_changes")}
      </button>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        transition={Bounce}
      />
    </div>
  );
};

export default Profile;
