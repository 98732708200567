import { blogs } from '../../Data/Blogs'
import Card from './Card'

function CardSection() {
    return (
        <div className="w-full relative bottom-48 sm:bottom-20 bg-gradient-to-b from-[#98e6bf] via-white to-[#ebf0ee] rounded-tr-[100px] py-14 flex justify-center">
            <div className='w-[92%] md:mx-0 space-y-10' dir='ltr'>
                <h1 className={`text-black text-3xl md:text-[60px] font-bold font-[Helvetica]`}>Get Involved</h1>
                <div className='flex flex-wrap gap-4 justify-center'>
                    {blogs.map((blog, index) => <Card image={blog.image} title={blog.title} id={index} />)}
                </div>
                <h2 className="text-red-600 font-bold underline text-lg text-center font-['Inter'] leading-[30px] cursor-pointer mt-5">Load More</h2>
            </div>
        </div>
    )
}

export default CardSection;