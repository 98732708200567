import { useEffect } from 'react'
import BannerSection from '../../components/AboutPages/Banner'
import Section2 from '../../components/PrivacyPages/Section2'
import bannerImage from '../../images/asserts/Website-Assets/Privacy Policy/Hero_image1.webp'
const PrivacyPolices = () => {
    useEffect(() => {
        const lang = localStorage.getItem("i18nextLng");
        if (!lang || lang === "en-US") {
            localStorage.setItem("i18nextLng", "en");
            window.location.reload();
        };
    }, [])
    return (
        <main>
            <BannerSection title='Privacy Policy' image={bannerImage} />
            <Section2 />
        </main>
    )
}

export default PrivacyPolices