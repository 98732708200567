const countries = [
    {
        "country": "Afghanistan",
        "iso_code": "AF",
        "dial_code": "+93"
    },
    {
        "country": "Albania",
        "iso_code": "AL",
        "dial_code": "+355"
    },
    {
        "country": "Algeria",
        "iso_code": "DZ",
        "dial_code": "+213"
    },
    {
        "country": "Andorra",
        "iso_code": "AD",
        "dial_code": "+376"
    },
    {
        "country": "Angola",
        "iso_code": "AO",
        "dial_code": "+244"
    },
    {
        "country": "Antigua and Barbuda",
        "iso_code": "AG",
        "dial_code": "+1-268"
    },
    {
        "country": "Argentina",
        "iso_code": "AR",
        "dial_code": "+54"
    },
    {
        "country": "Armenia",
        "iso_code": "AM",
        "dial_code": "+374"
    },
    {
        "country": "Australia",
        "iso_code": "AU",
        "dial_code": "+61"
    },
    {
        "country": "Austria",
        "iso_code": "AT",
        "dial_code": "+43"
    },
    {
        "country": "Azerbaijan",
        "iso_code": "AZ",
        "dial_code": "+994"
    },
    {
        "country": "Bahrain",
        "iso_code": "BH",
        "dial_code": "+973"
    },
    {
        "country": "Bangladesh",
        "iso_code": "BD",
        "dial_code": "+880"
    },
    {
        "country": "Belarus",
        "iso_code": "BY",
        "dial_code": "+375"
    },
    {
        "country": "Belgium",
        "iso_code": "BE",
        "dial_code": "+32"
    },
    {
        "country": "Belize",
        "iso_code": "BZ",
        "dial_code": "+501"
    },
    {
        "country": "Benin",
        "iso_code": "BJ",
        "dial_code": "+229"
    },
    {
        "country": "Bhutan",
        "iso_code": "BT",
        "dial_code": "+975"
    },
    {
        "country": "Bolivia",
        "iso_code": "BO",
        "dial_code": "+591"
    },
    {
        "country": "Bosnia and Herzegovina",
        "iso_code": "BA",
        "dial_code": "+387"
    },
    {
        "country": "Botswana",
        "iso_code": "BW",
        "dial_code": "+267"
    },
    {
        "country": "Brazil",
        "iso_code": "BR",
        "dial_code": "+55"
    },
    {
        "country": "Brunei",
        "iso_code": "BN",
        "dial_code": "+673"
    },
    {
        "country": "Bulgaria",
        "iso_code": "BG",
        "dial_code": "+359"
    },
    {
        "country": "Burkina Faso",
        "iso_code": "BF",
        "dial_code": "+226"
    },
    {
        "country": "Burundi",
        "iso_code": "BI",
        "dial_code": "+257"
    },
    {
        "country": "Cambodia",
        "iso_code": "KH",
        "dial_code": "+855"
    },
    {
        "country": "Cameroon",
        "iso_code": "CM",
        "dial_code": "+237"
    },
    {
        "country": "Canada",
        "iso_code": "CA",
        "dial_code": "+1(514)"
    },
    {
        "country": "Cape Verde",
        "iso_code": "CV",
        "dial_code": "+238"
    },
    {
        "country": "Central African Republic",
        "iso_code": "CF",
        "dial_code": "+236"
    },
    {
        "country": "Chad",
        "iso_code": "TD",
        "dial_code": "+235"
    },
    {
        "country": "Chile",
        "iso_code": "CL",
        "dial_code": "+56"
    },
    {
        "country": "China",
        "iso_code": "CN",
        "dial_code": "+86"
    },
    {
        "country": "Colombia",
        "iso_code": "CO",
        "dial_code": "+57"
    },
    {
        "country": "Comoros",
        "iso_code": "KM",
        "dial_code": "+269"
    },
    {
        "country": "Congo",
        "iso_code": "CG",
        "dial_code": "+242"
    },
    {
        "country": "Costa Rica",
        "iso_code": "CR",
        "dial_code": "+506"
    },
    {
        "country": "Croatia",
        "iso_code": "HR",
        "dial_code": "+385"
    },
    {
        "country": "Cuba",
        "iso_code": "CU",
        "dial_code": "+53"
    },
    {
        "country": "Cyprus",
        "iso_code": "CY",
        "dial_code": "+357"
    },
    {
        "country": "Czech Republic",
        "iso_code": "CZ",
        "dial_code": "+420"
    },
    {
        "country": "Denmark",
        "iso_code": "DK",
        "dial_code": "+45"
    },
    {
        "country": "Djibouti",
        "iso_code": "DJ",
        "dial_code": "+253"
    },
    {
        "country": "Dominican Republic",
        "iso_code": "DO",
        "dial_code": "+1-809"
    },
    {
        "country": "Ecuador",
        "iso_code": "EC",
        "dial_code": "+593"
    },
    {
        "country": "Egypt",
        "iso_code": "EG",
        "dial_code": "+20"
    },
    {
        "country": "El Salvador",
        "iso_code": "SV",
        "dial_code": "+503"
    },
    {
        "country": "Estonia",
        "iso_code": "EE",
        "dial_code": "+372"
    },
    {
        "country": "Eswatini",
        "iso_code": "SZ",
        "dial_code": "+268"
    },
    {
        "country": "Ethiopia",
        "iso_code": "ET",
        "dial_code": "+251"
    },
    {
        "country": "Fiji",
        "iso_code": "FJ",
        "dial_code": "+679"
    },
    {
        "country": "Finland",
        "iso_code": "FI",
        "dial_code": "+358"
    },
    {
        "country": "France",
        "iso_code": "FR",
        "dial_code": "+33"
    },
    {
        "country": "Gabon",
        "iso_code": "GA",
        "dial_code": "+241"
    },
    {
        "country": "Gambia",
        "iso_code": "GM",
        "dial_code": "+220"
    },
    {
        "country": "Georgia",
        "iso_code": "GE",
        "dial_code": "+995"
    },
    {
        "country": "Germany",
        "iso_code": "DE",
        "dial_code": "+49"
    },
    {
        "country": "Ghana",
        "iso_code": "GH",
        "dial_code": "+233"
    },
    {
        "country": "Greece",
        "iso_code": "GR",
        "dial_code": "+30"
    },
    {
        "country": "Guatemala",
        "iso_code": "GT",
        "dial_code": "+502"
    },
    {
        "country": "Honduras",
        "iso_code": "HN",
        "dial_code": "+504"
    },
    {
        "country": "Hungary",
        "iso_code": "HU",
        "dial_code": "+36"
    },
    {
        "country": "Iceland",
        "iso_code": "IS",
        "dial_code": "+354"
    },
    {
        "country": "India",
        "iso_code": "IN",
        "dial_code": "+91"
    },
    {
        "country": "Indonesia",
        "iso_code": "ID",
        "dial_code": "+62"
    },
    {
        "country": "Iran",
        "iso_code": "IR",
        "dial_code": "+98"
    },
    {
        "country": "Iraq",
        "iso_code": "IQ",
        "dial_code": "+964"
    },
    {
        "country": "Ireland",
        "iso_code": "IE",
        "dial_code": "+353"
    },
    {
        "country": "Israel",
        "iso_code": "IL",
        "dial_code": "+972"
    },
    {
        "country": "Italy",
        "iso_code": "IT",
        "dial_code": "+39"
    },
    {
        "country": "Jamaica",
        "iso_code": "JM",
        "dial_code": "+1-876"
    },
    {
        "country": "Japan",
        "iso_code": "JP",
        "dial_code": "+81"
    },
    {
        "country": "Jordan",
        "iso_code": "JO",
        "dial_code": "+962"
    },
    {
        "country": "Kazakhstan",
        "iso_code": "KZ",
        "dial_code": "+7"
    },
    {
        "country": "Kenya",
        "iso_code": "KE",
        "dial_code": "+254"
    },
    {
        "country": "Kiribati",
        "iso_code": "KI",
        "dial_code": "+686"
    },
    {
        "country": "Kuwait",
        "iso_code": "KW",
        "dial_code": "+965"
    },
    {
        "country": "Kyrgyzstan",
        "iso_code": "KG",
        "dial_code": "+996"
    },
    {
        "country": "Laos",
        "iso_code": "LA",
        "dial_code": "+856"
    },
    {
        "country": "Latvia",
        "iso_code": "LV",
        "dial_code": "+371"
    },
    {
        "country": "Lebanon",
        "iso_code": "LB",
        "dial_code": "+961"
    },
    {
        "country": "Lesotho",
        "iso_code": "LS",
        "dial_code": "+266"
    },
    {
        "country": "Liberia",
        "iso_code": "LR",
        "dial_code": "+231"
    },
    {
        "country": "Libya",
        "iso_code": "LY",
        "dial_code": "+218"
    },
    {
        "country": "Liechtenstein",
        "iso_code": "LI",
        "dial_code": "+423"
    },
    {
        "country": "Lithuania",
        "iso_code": "LT",
        "dial_code": "+370"
    },
    {
        "country": "Luxembourg",
        "iso_code": "LU",
        "dial_code": "+352"
    },
    {
        "country": "Madagascar",
        "iso_code": "MG",
        "dial_code": "+261"
    },
    {
        "country": "Malawi",
        "iso_code": "MW",
        "dial_code": "+265"
    },
    {
        "country": "Malaysia",
        "iso_code": "MY",
        "dial_code": "+60"
    },
    {
        "country": "Maldives",
        "iso_code": "MV",
        "dial_code": "+960"
    },
    {
        "country": "Mali",
        "iso_code": "ML",
        "dial_code": "+223"
    },
    {
        "country": "Malta",
        "iso_code": "MT",
        "dial_code": "+356"
    },
    {
        "country": "Marshall Islands",
        "iso_code": "MH",
        "dial_code": "+692"
    },
    {
        "country": "Mauritania",
        "iso_code": "MR",
        "dial_code": "+222"
    },
    {
        "country": "Mauritius",
        "iso_code": "MU",
        "dial_code": "+230"
    },
    {
        "country": "Mexico",
        "iso_code": "MX",
        "dial_code": "+52"
    },
    {
        "country": "Micronesia",
        "iso_code": "FM",
        "dial_code": "+691"
    },
    {
        "country": "Moldova",
        "iso_code": "MD",
        "dial_code": "+373"
    },
    {
        "country": "Monaco",
        "iso_code": "MC",
        "dial_code": "+377"
    },
    {
        "country": "Mongolia",
        "iso_code": "MN",
        "dial_code": "+976"
    },
    {
        "country": "Montenegro",
        "iso_code": "ME",
        "dial_code": "+382"
    },
    {
        "country": "Morocco",
        "iso_code": "MA",
        "dial_code": "+212"
    },
    {
        "country": "Mozambique",
        "iso_code": "MZ",
        "dial_code": "+258"
    },
    {
        "country": "Myanmar",
        "iso_code": "MM",
        "dial_code": "+95"
    },
    {
        "country": "Namibia",
        "iso_code": "NA",
        "dial_code": "+264"
    },
    {
        "country": "Nauru",
        "iso_code": "NR",
        "dial_code": "+674"
    },
    {
        "country": "Nepal",
        "iso_code": "NP",
        "dial_code": "+977"
    },
    {
        "country": "Netherlands",
        "iso_code": "NL",
        "dial_code": "+31"
    },
    {
        "country": "New Zealand",
        "iso_code": "NZ",
        "dial_code": "+64"
    },
    {
        "country": "Nicaragua",
        "iso_code": "NI",
        "dial_code": "+505"
    },
    {
        "country": "Niger",
        "iso_code": "NE",
        "dial_code": "+227"
    },
    {
        "country": "Nigeria",
        "iso_code": "NG",
        "dial_code": "+234"
    },
    {
        "country": "North Korea",
        "iso_code": "KP",
        "dial_code": "+850"
    },
    {
        "country": "North Macedonia",
        "iso_code": "MK",
        "dial_code": "+389"
    },
    {
        "country": "Norway",
        "iso_code": "NO",
        "dial_code": "+47"
    },
    {
        "country": "Oman",
        "iso_code": "OM",
        "dial_code": "+968"
    },
    {
        "country": "Palau",
        "iso_code": "PW",
        "dial_code": "+680"
    },
    {
        "country": "Panama",
        "iso_code": "PA",
        "dial_code": "+507"
    },
    {
        "country": "Papua New Guinea",
        "iso_code": "PG",
        "dial_code": "+675"
    },
    {
        "country": "Paraguay",
        "iso_code": "PY",
        "dial_code": "+595"
    },
    {
        "country": "Peru",
        "iso_code": "PE",
        "dial_code": "+51"
    },
    {
        "country": "Philippines",
        "iso_code": "PH",
        "dial_code": "+63"
    },
    {
        "country": "Poland",
        "iso_code": "PL",
        "dial_code": "+48"
    },
    {
        "country": "Portugal",
        "iso_code": "PT",
        "dial_code": "+351"
    },
    {
        "country": "Qatar",
        "iso_code": "QA",
        "dial_code": "+974"
    },
    {
        "country": "Romania",
        "iso_code": "RO",
        "dial_code": "+40"
    },
    {
        "country": "Russia",
        "iso_code": "RU",
        "dial_code": "+7"
    },
    {
        "country": "Rwanda",
        "iso_code": "RW",
        "dial_code": "+250"
    },
    {
        "country": "Saint Kitts and Nevis",
        "iso_code": "KN",
        "dial_code": "+1-869"
    },
    {
        "country": "Saint Lucia",
        "iso_code": "LC",
        "dial_code": "+1-758"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "iso_code": "VC",
        "dial_code": "+1-784"
    },
    {
        "country": "Samoa",
        "iso_code": "WS",
        "dial_code": "+685"
    },
    {
        "country": "San Marino",
        "iso_code": "SM",
        "dial_code": "+378"
    },
    {
        "country": "Sao Tome and Principe",
        "iso_code": "ST",
        "dial_code": "+239"
    },
    {
        "country": "Saudi Arabia",
        "iso_code": "SA",
        "dial_code": "+966"
    },
    {
        "country": "Senegal",
        "iso_code": "SN",
        "dial_code": "+221"
    },
    {
        "country": "Serbia",
        "iso_code": "RS",
        "dial_code": "+381"
    },
    {
        "country": "Seychelles",
        "iso_code": "SC",
        "dial_code": "+248"
    },
    {
        "country": "Sierra Leone",
        "iso_code": "SL",
        "dial_code": "+232"
    },
    {
        "country": "Singapore",
        "iso_code": "SG",
        "dial_code": "+65"
    },
    {
        "country": "Slovakia",
        "iso_code": "SK",
        "dial_code": "+421"
    },
    {
        "country": "Slovenia",
        "iso_code": "SI",
        "dial_code": "+386"
    },
    {
        "country": "Solomon Islands",
        "iso_code": "SB",
        "dial_code": "+677"
    },
    {
        "country": "Somalia",
        "iso_code": "SO",
        "dial_code": "+252"
    },
    {
        "country": "South Africa",
        "iso_code": "ZA",
        "dial_code": "+27"
    },
    {
        "country": "South Korea",
        "iso_code": "KR",
        "dial_code": "+82"
    },
    {
        "country": "South Sudan",
        "iso_code": "SS",
        "dial_code": "+211"
    },
    {
        "country": "Spain",
        "iso_code": "ES",
        "dial_code": "+34"
    },
    {
        "country": "Sri Lanka",
        "iso_code": "LK",
        "dial_code": "+94"
    },
    {
        "country": "Sudan",
        "iso_code": "SD",
        "dial_code": "+249"
    },
    {
        "country": "Suriname",
        "iso_code": "SR",
        "dial_code": "+597"
    },
    {
        "country": "Sweden",
        "iso_code": "SE",
        "dial_code": "+46"
    },
    {
        "country": "Switzerland",
        "iso_code": "CH",
        "dial_code": "+41"
    },
    {
        "country": "Syria",
        "iso_code": "SY",
        "dial_code": "+963"
    },
    {
        "country": "Taiwan",
        "iso_code": "TW",
        "dial_code": "+886"
    },
    {
        "country": "Tajikistan",
        "iso_code": "TJ",
        "dial_code": "+992"
    },
    {
        "country": "Tanzania",
        "iso_code": "TZ",
        "dial_code": "+255"
    },
    {
        "country": "Thailand",
        "iso_code": "TH",
        "dial_code": "+66"
    },
    {
        "country": "Timor-Leste",
        "iso_code": "TL",
        "dial_code": "+670"
    },
    {
        "country": "Togo",
        "iso_code": "TG",
        "dial_code": "+228"
    },
    {
        "country": "Tonga",
        "iso_code": "TO",
        "dial_code": "+676"
    },
    {
        "country": "Trinidad and Tobago",
        "iso_code": "TT",
        "dial_code": "+1-868"
    },
    {
        "country": "Tunisia",
        "iso_code": "TN",
        "dial_code": "+216"
    },
    {
        "country": "Turkey",
        "iso_code": "TR",
        "dial_code": "+90"
    },
    {
        "country": "Turkmenistan",
        "iso_code": "TM",
        "dial_code": "+993"
    },
    {
        "country": "Tuvalu",
        "iso_code": "TV",
        "dial_code": "+688"
    },
    {
        "country": "Uganda",
        "iso_code": "UG",
        "dial_code": "+256"
    },
    {
        "country": "Ukraine",
        "iso_code": "UA",
        "dial_code": "+380"
    },
    {
        "country": "United Arab Emirates",
        "iso_code": "AE",
        "dial_code": "+971"
    },
    {
        "country": "United Kingdom",
        "iso_code": "GB",
        "dial_code": "+44"
    },
    {
        "country": "United States",
        "iso_code": "US",
        "dial_code": "+1"
    },
    {
        "country": "Uruguay",
        "iso_code": "UY",
        "dial_code": "+598"
    },
    {
        "country": "Uzbekistan",
        "iso_code": "UZ",
        "dial_code": "+998"
    },
    {
        "country": "Vanuatu",
        "iso_code": "VU",
        "dial_code": "+678"
    },
    {
        "country": "Vatican City",
        "iso_code": "VA",
        "dial_code": "+379"
    },
    {
        "country": "Venezuela",
        "iso_code": "VE",
        "dial_code": "+58"
    },
    {
        "country": "Vietnam",
        "iso_code": "VN",
        "dial_code": "+84"
    },
    {
        "country": "Yemen",
        "iso_code": "YE",
        "dial_code": "+967"
    },
    {
        "country": "Zambia",
        "iso_code": "ZM",
        "dial_code": "+260"
    },
    {
        "country": "Zimbabwe",
        "iso_code": "ZW",
        "dial_code": "+263"
    }
]
export default countries