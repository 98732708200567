import axios from "axios";
import { Trash2 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { toast, Bounce, ToastContainer } from "react-toastify";
import i18n from "../../i18n";

export default function DeleteJobModal({ isOpen, onClose, onDelete, deleteId }: any) {
    const { t } = useTranslation()
    if (!isOpen) return null;
    const deleteJob = async () => {
        try {
            await axios.delete(`https://api.nadwa-uae.com/api/v3/customer/jobs/${deleteId}/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
                },
            });
            toast.success("Job deleted successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                onClose: () => {
                    onDelete();
                    onClose()
                }
            });
        } catch (error) {
            toast.error("Failed to delete job.Try again", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    return (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4 z-50">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="bg-white rounded-2xl max-w-sm w-full p-6">
                {/* Icon */}
                <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4">
                    <Trash2 className="w-8 h-8 text-red-500" />
                </div>

                {/* Content */}
                <div className="text-center mb-6">
                    <h2 className="text-xl font-semibold mb-2">{t('posted_jobs_page.delete_job_post')}</h2>
                    <p className="text-gray-600">{i18n.language == "en" ? "Are you sure you want to delete this Job Post?" : "هل أنت متأكد من رغبتك في حذف هذا الإعلان عن الوظيفة؟"}</p>
                </div>

                {/* Buttons */}
                <div className="flex gap-3">
                    <button
                        onClick={onClose}
                        className="flex-1 px-4 py-2.5 border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
                    >
                        {t("cancel")}
                    </button>
                    <button
                        onClick={deleteJob}
                        className="flex-1 px-4 py-2.5 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                    >
                        {i18n.language == "en" ? "Delete" : "حذف."}
                    </button>
                </div>
            </div>
        </div>
    );
}
