const Modal = ({ message, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h3 className="text-lg font-semibold mb-4">Booking</h3>
        <p className="text-sm mb-4">{message}</p>
        <div className="flex justify-between">
          <button
            className="bg-gray-500 text-white py-2 px-4 rounded-md"
            onClick={onClose}
          >
            Close
          </button>
          <a href={'/login'}>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-md"
              onClick={onConfirm}
            >
              Login
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Modal;
