import React, { useEffect, useRef, useState } from "react";
import image3 from "../../../images/popups/code.png";
import VerificationErrorModal from "./ErrorPopup";
import i18n from "../../../i18n";

interface VerificationCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onVerify: (code: string) => void;
  onResend: () => void;
  email: string;
  cooldown?: boolean;
  remainingTime?: number;
  formatTime?: (seconds: number) => string;
}

export default function VerificationCodeModal({
  email,
  isOpen,
  onClose,
  onVerify,
  onResend,
  cooldown,
  remainingTime,
  formatTime,
}: VerificationCodeModalProps) {
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [errorModalOpen, isErrorModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      inputRefs.current[0]?.focus();
    }
    return () => {
      setError("");
      setCode(["", "", "", "", "", ""]);
    };
  }, [isOpen]);

  const handleChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return; // Allow only numbers

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value !== "" && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newCode.every((digit) => digit !== "")) {
      verifyCode(newCode.join(""));
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && code[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const digits = pastedData.match(/\d/g)?.slice(0, 6) || [];

    if (digits.length > 0) {
      const newCode = ["", "", "", "", "", ""];
      digits.forEach((digit, index) => {
        if (index < 6) newCode[index] = digit;
      });
      setCode(newCode);

      // Focus on the next empty input or last input if full
      const nextEmptyIndex = newCode.findIndex((digit) => digit === "");
      const focusIndex = nextEmptyIndex === -1 ? 5 : nextEmptyIndex;
      inputRefs.current[focusIndex]?.focus();

      // Trigger verification if 6 digits are pasted
      if (digits.length === 6) {
        verifyCode(newCode.join(""));
      }
    }
  };

  const verifyCode = async (code: string) => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v2/customer/email_confirm/confirm/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
          body: JSON.stringify({
            email,
            key: code,
          }),
        }
      );

      const text = await response.text();
      let data;

      if (text) {
        data = JSON.parse(text);
      }
      if (!response.ok) {
        throw new Error(
          data?.non_field_errors || data?.email || "Verification failed."
        );
      }

      onVerify(code);
      setTimeout(() => window.location.reload(), 500);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        isErrorModalOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="relative bg-white rounded-lg max-w-[425px] w-full p-6 animate-fade-in">
        <button
          onClick={onClose}
          className={`absolute ${i18n.language == "ar" ? "left-4" : "right-4"} top-4 hover:text-gray-600 text-2xl text-zinc-950`}
        >
          ×
        </button>

        <div className="flex flex-col items-center gap-4">
          <div className="w-16 h-16 rounded-full bg-emerald-100 flex items-center justify-center">
            <img src={image3} alt="Verification Icon" className="w-16 h-14" />
          </div>

          <div className="text-center space-y-2">
            <h2 className="text-xl font-semibold">{i18n.language == "ar" ? "رمز التحقق" : "Verification Code"}</h2>
            <p className="text-sm text-gray-500">
              {i18n.language == "ar" ? "تم إرسال رمز التحقق إلى عنوان بريدك الإلكتروني." : "A verification code has been sent to your email address"}
            </p>
          </div>

          <div className="flex gap-2 my-4">
            {code.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                onPaste={(e) => handlePaste(e)}
                ref={(el) => (inputRefs.current[index] = el)}
                className="w-12 h-12 text-center text-2xl font-semibold border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-emerald-600 focus:border-transparent"
              />
            ))}
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}
          {loading && <p className="text-gray-500">{i18n.language == "ar" ? "التحقق" : "Verifying"}...</p>}

          <div className="text-sm">
            {i18n.language == "ar" ? "لم أستلم الرمز." : "I didn't receive the code."} {" "}
            <button
              onClick={onResend}
              disabled={cooldown}
              className={`text-emerald-600 hover:text-emerald-700 font-medium ${cooldown ? "text-gray-400 cursor-not-allowed" : ""
                }`}
            >
              {i18n.language == "ar" ? "إعادة الإرسال" : "Resend"}
            </button>
            {cooldown && remainingTime !== undefined && formatTime && (
              <span className="text-gray-500 ml-2">
                ({formatTime(remainingTime)})
              </span>
            )}
          </div>
        </div>
      </div>

      <VerificationErrorModal
        isOpen={errorModalOpen}
        errorMessage={error}
        onClose={() => isErrorModalOpen(false)}
        onTryAgain={() => {
          isErrorModalOpen(false);
        }}
      />
    </div>
  );
}