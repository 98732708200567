import { useState, useEffect } from "react";
import { Sun, Moon } from "lucide-react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

interface TimeSlot {
    id: string;
    time: string;
    selected: boolean;
}

interface DaySchedule {
    name: string;
    slots: TimeSlot[];
}

export default function AvailabilitySelector({ setTutorMatch, setShowBudgetPopup, setShowDays, setAvailableDays, availableDays }: any) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const { t } = useTranslation();
    const initialSchedule: DaySchedule[] = [
        t("monday"),
        t("tuesday"),
        t("wednesday"),
        t("thursday"),
        t("friday"),
        t("saturday"),
        t("sunday"),
    ].map((day) => ({
        name: day,
        slots: [
            { id: `${day}-1`, time: "1", name: t("time_of_day_morning"), selected: false },
            { id: `${day}-2`, time: "2", name: t("time_of_day_afternoon"), selected: false },
            { id: `${day}-3`, time: "3", name: t("time_of_day_evening"), selected: false }
        ],
    }));

    const [schedule, setSchedule] = useState(initialSchedule);

    // Effect to update the button's disabled state based on availableDays
    useEffect(() => {
        setIsButtonDisabled(availableDays.length === 0);
    }, [availableDays]);

    const toggleSlot = (dayIndex: number, slotIndex: number) => {
        setSchedule((prev) => {
            const newSchedule = [...prev];
            newSchedule[dayIndex] = {
                ...newSchedule[dayIndex],
                slots: newSchedule[dayIndex].slots.map((slot, idx) =>
                    idx === slotIndex ? { ...slot, selected: !slot.selected } : slot
                ),
            };

            // Update availableDays state
            const selectedDay = newSchedule[dayIndex];
            const selectedSlot = selectedDay.slots[slotIndex];
            setAvailableDays((prevAvailableDays: any) => {
                if (!selectedSlot.selected) {
                    return prevAvailableDays.filter(
                        (entry: any) => !(entry.slot === selectedSlot.time && entry.days.includes(dayIndex + 1))
                    );
                }

                const updatedDays = [...prevAvailableDays];
                const existingEntry = updatedDays.find((entry) => entry.slot === selectedSlot.time);

                if (existingEntry) {
                    existingEntry.days.push(dayIndex + 1); // Add day starting from 1
                } else {
                    updatedDays.push({ slot: selectedSlot.time, days: [dayIndex + 1] });
                }

                return updatedDays;
            });

            return newSchedule;
        });
    };

    return (
        <div className="fixed inset-0 bg-black/70 bg-opacity-50 flex justify-center items-center z-40">
            <div className="lg:max-w-[50%] w-full">
                <div className="bg-white rounded-[20px] overflow-hidden p-6">
                    {/* Header */}
                    <div className="relative w-full my-6">
                        <div className="h-2 bg-[#00884733] w-full" />
                        <div className="h-2 bg-green-600 w-[70%] absolute top-0 left-0">
                            <div className="absolute -top-5 -right-5">
                                <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">70%</div>
                            </div>
                        </div>
                    </div>
                    {/* Content */}
                    <div>
                        <h2 className="text-lg font-medium mb-2">{t("availability_selection")}</h2>
                        <p className="text-sm text-gray-600 lg:mb-6 mb-3">
                            {i18n.language == "en" ? "When are you available for your Free Trial Lesson? Our tutors will work around your busy schedule, but we recommend selecting at least 5 options from the below." : "متى ستكون متاحًا لدرس التجربة المجاني؟ سيعمل معلمونا حول جدولك المزدحم، ولكن ننصح بتحديد ما لا يقل عن خمس خيارات من الخيارات أدناه."}
                        </p>

                        {/* Calendar Grid */}
                        <div className="col-span-7  bg-[#008847] flex overflow-x-auto justify-between px-3 rounded-[20px] lg:mb-4 mb-3">
                            {schedule.map((day) => (
                                <div key={day.name} className="text-center bg-[#008847]">
                                    <div className=" text-white py-2 px-1 rounded">{day.name}</div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end items-end gap-2 lg:mb-4 mb-3">
                            <button
                                onClick={() => {
                                    setSchedule((prev) =>
                                        prev.map((day) => ({
                                            ...day,
                                            slots: day.slots.map((slot) => ({ ...slot, selected: true })),
                                        }))
                                    );
                                    setAvailableDays([
                                        { slot: 1, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                        { slot: 2, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                        { slot: 3, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                    ]);
                                }}
                                className="px-2 py-1 bg-[#0088471A] border border-[#008847] text-[#008847] rounded-[7px] text-sm font-bold transition-colors"
                            >
                                {t("select_all")}
                            </button>
                            <button
                                onClick={() => {
                                    setSchedule((prev) =>
                                        prev.map((day) => ({
                                            ...day,
                                            slots: day.slots.map((slot) => ({ ...slot, selected: false })),
                                        }))
                                    );
                                    setAvailableDays([]);
                                }}
                                className="px-2 py-1 bg-[#ED1D241A] text-[#ED1D24] rounded-[7px] border border-[#ED1D24] transition-colors text-sm font-bold"
                            >
                                {t("clear")}
                            </button>
                        </div>
                        <div className="grid lg:grid-cols-7 grid-cols-3 gap-3 mb-6 relative">
                            {schedule.map((day, dayIndex) =>
                                day.slots.map((slot: any, slotIndex) => (
                                    <div key={`${day.name}-${slot.time}`} className="text-center">
                                        <button
                                            onClick={() => toggleSlot(dayIndex, slotIndex)}
                                            className={`w-full p-2 rounded border transition-colors ${slot.selected
                                                ? "bg-[#008847] text-white"
                                                : "bg-[#0088471A] border border-[#008847]"
                                                }`}
                                        >
                                            {slot.time === "1" || slot.time === "2" ? (
                                                <Sun className="w-4 h-4 mx-auto mb-1" />
                                            ) : (
                                                <Moon className="w-4 h-4 mx-auto mb-1" />
                                            )}
                                            <span className="text-xs">{slot.name}</span>
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                        <div className="flex justify-between gap-3 w-full">
                            <button
                                onClick={() => {
                                    setShowBudgetPopup(true)
                                    setShowDays(false);
                                }}
                                className="flex-1 py-2 px-4 text-gray-700 rounded-xl bg-[#0088471A] border-2 border-[#008847] w-1/2"
                            >
                                {t("back")}
                            </button>
                            <button
                                disabled={isButtonDisabled}
                                className={`w-1/2 py-2 text-white rounded-xl transition-colors ${isButtonDisabled ? "bg-gray-300 cursor-not-allowed" : "bg-[#008847]"
                                    }`}
                                onClick={() => {
                                    setTutorMatch(true);
                                    setShowDays(false);
                                }}
                            >
                                {t("buttons.next")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
