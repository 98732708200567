import {
  BookCheck,
  ChartNoAxesColumnIncreasing,
  ChevronDown,
  ChevronUp,
  MapPin,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useFilterContext } from "../../ContextApi/FilterContext";
import SkeletonLoader from "./Loading";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const TutorCard = ({ tutor }: any) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className="bg-transparent col-span-1 h-full">
      <div>
        <a href={`/Tutor/${tutor.id}`}>
          <picture>
            <source srcSet={tutor?.photo} type="image/webp" />
            <img
              src={tutor?.photo?.replace(".webp", ".jpg") || "fallback.jpg"}
              alt="img"
              className="object-contain rounded-2xl w-full h-[350px]"
            />
          </picture>
        </a>
      </div>
      <div className="pt-4 flex flex-col xl:gap-1 w-full">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <h3 className="text-xl font-bold flex gap-2 items-center">
              {tutor?.name}{" "}
              <picture>
                <source
                  srcSet={tutor.country?.country_icon}
                  type="image/webp"
                />
                <img
                  src={
                    tutor.country?.country_icon?.replace(".webp", ".jpg") ||
                    "fallback.jpg"
                  }
                  alt="img"
                  className="w-5 h-5"
                />
              </picture>
            </h3>
            <p className="text-black text-[16px] line-clamp-2">
              {tutor?.experience}
              <Link to="/">see more</Link>
            </p>
            <p className="text-black text-[16px] flex gap-1">
              <ChartNoAxesColumnIncreasing className="text-[#008847]" />{" "}
              {tutor?.experience_years} {t("yearsExperi")}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">
              <p className="text-[#008847] font-semibold text-lg">
                {t("AED")}{" "}
                <span className="font-semibold">
                  {(() => {
                    const priceInCents = tutor.selected_subject?.price_in_cents;
                    if (!priceInCents) return "0"; // In case price_in_cents is undefined or null

                    const basePrice = priceInCents / 100; // Convert cents to the base price

                    // Calculate the additional price based on increments of 50
                    let additionalPrice = 0;
                    if (basePrice >= 50) {
                      additionalPrice += Math.floor(basePrice / 50) * 2.5; // Add 2.5 for each 50 increment
                    }

                    // Final price after adding the increment
                    const finalPrice = basePrice + additionalPrice;

                    return finalPrice.toFixed(1); // Ensure the result is rounded to two decimal places
                  })()}
                </span>
                <br />
                <span className="text-[#008847] text-sm text-end">
                  {t("perhour")}
                </span>
                <br />
                <span className="text-sm">({t("includigVat")})</span>
              </p>
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-[15px] text-black flex gap-2 items-center">
            <span>
              <BookCheck className="text-[#008847]" />
            </span>
            {tutor.experience_years * 50 + " + lesson completed"}
          </p>
          <span className="text-sm flex gap-1">
            <MapPin className="text-[#008847] h-5 w-4" />
            {t("tutoring")}:{" "}
            {tutor.online_tutoring && tutor.in_person_tutoring
              ? "Online and In person tutoring"
              : tutor?.online_tutoring
                ? "Online"
                : tutor.in_person_tutoring
                  ? "In person tutoring"
                  : "Online"}
          </span>
        </div>
        {/* Resolved Linked */}
        <a
          href={`/Tutor/${tutor.id}`}
          className={`bg-red-500 text-center no-underline text-white py-2 rounded-3xl hover:bg-red-600 transition ${i18n.dir(i18n.language) === "rtl" ? "w-44" : "w-36"
            }`}
        >
          {t("Viewprofile")}
        </a>
      </div>
    </div>
  );
};

const TutorsResult = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const { otherFilters, loading, setOtherFilters, setLoading } =
    useFilterContext();
  const [isOpen, setIsOpen] = useState(false);

  const getData = async (page: number) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/?subject_id=&levelId=&country=&max_price=200&min_price=100&max_experience=60&min_experience=0&postcode=Online&online=true&search=&page=${page}`
      );
      setOtherFilters(res.data.results);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    if (page < 1) return;
    setPageNumber(page);
    getData(page); // Fetch data for the selected page
  };

  return (
    <div className="relative py-20">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <h2 className="text-3xl font-bold mb-8 ml-20">
            {i18n.language == "en" ? "Search Results" : "نتائج البحث"}
          </h2>
          <div className="grid grid-cols-1 xl:grid-cols-4 gap-10 lg:gap-0">
            <div className="grid lg:grid-cols-3 grid-cols-1 col-span-1 lg:col-span-3 gap-16 xl:gap-14 max-w-[92%] mx-auto">
              {otherFilters.length > 0 ? (
                otherFilters.map((tutor: any) => (
                  <TutorCard key={tutor.id} tutor={tutor} />
                ))
              ) : (
                <p className="text-center text-2xl text-black">
                  {t("notfound")}
                </p>
              )}
              <div className="flex justify-center mt-10 col-span-1 lg:col-span-3">
                <nav>
                  <ul className="flex gap-2">
                    {/* Previous Button */}
                    <li>
                      <button
                        onClick={() => handlePageChange(pageNumber - 1)}
                        disabled={pageNumber === 1}
                        className={`px-4 py-2 rounded-md border border-gray-300 ${pageNumber === 1
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-gray-200"
                          }`}
                      >
                        &lt;
                      </button>
                    </li>

                    {/* Page Numbers (always show 1, 2, 3 but API can fetch more) */}
                    {[1, 2, 3].map((num) => (
                      <li key={num}>
                        <button
                          onClick={() => handlePageChange(num)}
                          className={`px-4 py-2 rounded-md transition-all duration-200 
                        ${pageNumber === num
                              ? "bg-red-500 text-white font-bold shadow-md"
                              : "bg-white text-black border border-gray-300 hover:bg-gray-100"
                            }`}
                        >
                          {num}
                        </button>
                      </li>
                    ))}

                    {/* Next Button (Works even after page 3) */}
                    <li>
                      <button
                        onClick={() => handlePageChange(pageNumber + 1)}
                        className="px-4 py-2 rounded-md border border-gray-300 hover:bg-gray-200"
                      >
                        &gt;
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className={`hidden lg:block absolute ${i18n.language === "ar" ? "left-2" : "right-2"}  top-0 rotate-[270deg] z-50`}>
              <div className="relative w-[160px]">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  className="w-[160px] h-[50px] bg-green-600 text-white rounded-3xl flex items-center justify-center text-lg font-bold"
                >
                  {i18n.language == "en" ? "Contact Us" : "اتصل بنا"}
                </button>

                <div
                  className={`absolute top-full left-1/2 transform -translate-x-1/2 w-[160px] bg-white text-black text-xs p-2 rounded-lg shadow-md transition-all z-40 duration-300 ${isOpen
                    ? "opacity-100 visible translate-y-2"
                    : "opacity-0 invisible"
                    }`}
                >
                  <a href="https://calendly.com/hend-sabry" target="_blank" rel="noopener noreferrer"> {i18n.language == "en" ? "Contact Us For Discounted Packages" : "اتصل بنا للحصول على حزم مخفضة"}</a>
                </div>

              </div>
            </div>
            <div className="col-span-1 hidden lg:flex flex-col gap-10 max-w-[92%] mx-auto ">
              <div className="p-4 rounded-xl border-2 border-[#008847]">
                <h4 className="text-2xl font-bold">{t("Q1")}</h4>
                <p className="text-[16px]">
                  {t("Ans_1")}
                </p>
              </div>
              <div className="p-4 rounded-xl border-2 border-[#008847]">
                <h4 className="text-2xl font-bold">
                  {t("Q2")}
                </h4>
                <p className="text-[16px]">
                  {t("Ans_2")}
                </p>
              </div>
              <div className="p-4 rounded-xl border-2 border-[#008847]">
                <h4 className="text-2xl font-bold">{t("Q3")}</h4>
                <p className="text-[16px]">
                  {t("Ans_3")}
                </p>
              </div>
            </div>
            <div className="max-w-[92%] mx-auto flex-col gap-4 lg:grid-cols-1 lg:hidden flex">
              <AccordionItem
                title={
                  <a
                    href="https://calendly.com/hend-sabry"
                    className="text-[#008847]"
                  >
                    {t("find_a_tutor_page.contact_us")}
                  </a>
                }
              >
              </AccordionItem>
              <AccordionItem title={t("Q1")}>
                {t("Ans_1")}
              </AccordionItem>
              <AccordionItem title={t("Q2")}>
                {t("Ans_2")}
              </AccordionItem>
              <AccordionItem title={t("Q3")}>
                {t("Ans_3")}
              </AccordionItem>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TutorsResult;

const AccordionItem = ({ title, children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="p-4 rounded-xl border-2 border-[#008847]">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center w-full text-2xl font-bold text-[#008847]"
      >
        {title}
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </button>
      <div className={`text-[16px] mt-2 ${isOpen ? "block" : "hidden"}`}>
        {children}
      </div>
    </div>
  );
};
