import axios from "axios";
import { GraduationCap, Search, Send } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MutatingDots } from "react-loader-spinner";
import i18n from "../../i18n";
import BookinBankModal from "../TutorBookings/BookingBankDetails";
import BookLessonInboxModal from "./BookLessonInboxModal";

export default function TutorInbox({ messageId }: any) {
  const [isBookLessonModalOpen, setIsBookLessonModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("latest");
  const [allMessages, setAllMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<any>(null);
  const [newMessage, setNewMessage] = useState("");
  const [searchMessage, setSearchMessage] = useState<any>("");
  const [tutorData, setTutorData] = useState<any>({});
  const [bankModal, setBankModal] = useState(false);
  const [bankData, setBankData] = useState<any>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [customerName, setCustomerName] = useState<any>(null);
  const [achivedloading, setAchivedloading] = useState(false);
  const [messageIds, setMessageId] = useState<any>();
  const chatEndRef = useRef<any>(null);
  const { t } = useTranslation();
  const [noarchived, setnonarchived] = useState<any>();

  const fetchDataForBank = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setBankData(response.data.data.user);
    } catch (err) {
      setError("Failed to fetch tutor data");
    } finally {
      setLoading(false);
    }
  };

  const toggleBookLessonModal = async (messId: any) => {
    setCustomerName(messId);
    await fetchDataForBank();
    if (bankData !== null && !loading) {
      if (!bankData?.pay_via_oktopi) {
        setBankModal(true);
      } else {
        setIsBookLessonModalOpen(true);
      }
    } else if (!loading && !error) {
      fetchDataForBank().then(() => {
        if (bankData && !bankData?.pay_via_oktopi) {
          setBankModal(true);
        } else if (bankData) {
          setIsBookLessonModalOpen(true);
        }
      });
    }
  };

  const getAllMessages = async () => {
    setAchivedloading(true);
    try {
      const res = await axios.get("https://api.nadwa-uae.com/api/v2/chat", {
        headers: {
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
        },
      });

      // Sort by unread_count first, then by last_message.created
      const sortedMessages = res.data.sort((a: any, b: any) => {
        if ((b.unread_count || 0) !== (a.unread_count || 0)) {
          return (b.unread_count || 0) - (a.unread_count || 0); // Prioritize unread
        }
        const aTime = a.last_message
          ? new Date(a.last_message.created).getTime()
          : new Date(a.created).getTime();
        const bTime = b.last_message
          ? new Date(b.last_message.created).getTime()
          : new Date(b.created).getTime();
        return bTime - aTime; // Sort by most recent last message
      });

      setAllMessages(sortedMessages);

      if (!messageId && res.data.length > 0) {
        const firstNonArchivedMessage = res.data.find(
          (msg: any) => !msg.is_archived
        );
        if (firstNonArchivedMessage) {
          setnonarchived(firstNonArchivedMessage);
          singleMessage(firstNonArchivedMessage.id);
        }
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setAchivedloading(false);
    }
  };

  useEffect(() => {
    getAllMessages();
  }, [messageId]);

  const singleMessage = async (id: number) => {
    if (typeof id !== "number") {
      console.error("Invalid message ID received:", id);
      return;
    }

    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/chat/${id}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setMessage(res.data);

      if (res.data.unread_count > 0) {
        await axios.post(
          `https://api.nadwa-uae.com/api/v2/chat/${id}/mark_messages_as_read/`,
          {},
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setAllMessages((prevMessages) =>
          prevMessages.map((mess) =>
            mess.id === id ? { ...mess, unread_count: 0 } : mess
          )
        );
      }

      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        console.error(`Message with ID ${id} not found`);
      } else {
        console.error("Error fetching message:", error.message);
      }
      setMessage(null);
    }
  };

  useEffect(() => {
    if (messageId) singleMessage(messageId);
  }, [messageId]);

  const postMessage = async (e: any) => {
    e.preventDefault();

    if (!newMessage.trim()) return;

    const newMsg = {
      id: Date.now(),
      chat: message.id,
      content: newMessage,
      created_by: message.customer.id,
      type: "text",
    };

    setMessage((prev: any) => ({
      ...prev,
      messages: [...prev.messages, newMsg],
    }));

    setNewMessage("");

    try {
      await axios.post(
        "https://api.nadwa-uae.com/api/v2/chat/messages/",
        {
          chat: message.id,
          content: newMessage,
          socket_id: "",
          type: "text",
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      // Refetch all messages to update the list order
      await getAllMessages();
      singleMessage(message.id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const AchivedSet = async (id: number) => {
    try {
      await axios.post(
        `https://api.nadwa-uae.com/api/v2/chat/${id}/archive/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setAllMessages((prevMessages) =>
        prevMessages.map((mess: any) =>
          mess.id === id ? { ...mess, is_archived: true } : mess
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const UnAchivedSet = async (id: number) => {
    try {
      await axios.post(
        `https://api.nadwa-uae.com/api/v2/chat/${id}/unarchive/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setAllMessages((prevMessages) =>
        prevMessages.map((mess: any) =>
          mess.id === id ? { ...mess, is_archived: false } : mess
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setTutorData(response.data);
      } catch (err) {
        console.log("Fetch error:", err);
      }
    };
    fetchTutorData();
  }, []);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message?.messages]);

  const isValidDate = (dateString: any) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };
  
  return (
    <div className="relative h-screen ">
      <div className="justify-between items-center flex">
        <h1 className="text-[#000606] font-normal text-3xl">{t("inbox")}</h1>
        <div className="items-center gap-3 lg:flex hidden">
          <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
            <picture>
              <source srcSet={tutorData.photo} type="image/webp" />
              <img
                src={
                  tutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"
                }
                alt={tutorData?.user?.first_name}
                className="w-9 h-9 rounded-full"
              />
            </picture>
            <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
              {tutorData?.user?.first_name}
            </h1>
          </div>
        </div>
      </div>

      {/* TUTOR INBOX  */}
      <div className="w-full bg-white py-1 rounded-t-[20px] px-10 mt-2">
        <h2 className="text-3xl mt-3">{t("inbox_page.messages")}</h2>
        {/* <hr className="w-full absolute left-0 " /> */}
      </div>
      <div className="flex bg-gray-50 border-none h-screen flex-col lg:flex-row">
        <div className="lg:w-[25%] rounded-l-[20px] bg-white pt-6 flex flex-col w-full">
          <div className="p-3 overflow-y-scroll">
            <div className="flex border-b mb-4">
              <button
                onClick={() => setActiveTab("latest")}
                className={`pb-2 px-4 text-sm font-medium ${activeTab === "latest"
                    ? "text-green-600 border-b-2 border-green-600"
                    : "text-gray-500"
                  }`}
              >
                {t("inbox_page.latest_messages")}
              </button>
              <button
                onClick={() => setActiveTab("achieved")}
                className={`pb-2 px-4 text-sm font-medium ${activeTab === "achieved"
                    ? "text-green-600 border-b-2 border-green-600"
                    : "text-gray-500"
                  }`}
              >
                {t("inbox_page.archived")}
              </button>
            </div>
            <div className="relative mb-4">
              <input
                type="text"
                value={searchMessage}
                onChange={(e) => setSearchMessage(e.target.value)}
                placeholder={t("inbox_page.search_chat")}
                className={`w-full pl-4 py-3 bg-transparent border border-gray-300 rounded-xl text-sm ${i18n.language === "ar" && "ps-8"
                  }`}
              />
              <Search className="w-4 h-4 absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
            {achivedloading ? (
              <div className="flex justify-center items-center">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#4fa94d"
                  secondaryColor="#4fa94d"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="space-y-4">
                {allMessages.length === 0 ? (
                  <p className="text-center text-gray-500">
                    {t("no_messages")}
                  </p>
                ) : (
                  <>
                    {allMessages
                      .filter(
                        (mess) =>
                          mess.customer?.first_name
                            ?.toLowerCase()
                            .includes(searchMessage?.toLowerCase() || "") || ""
                      )
                      .map(
                        (mess: any) =>
                          !mess.is_archived &&
                          activeTab === "latest" && (
                            <div
                              key={mess.id}
                              onClick={() => {
                                singleMessage(mess.id);
                                setMessageId(mess.id);
                              }}
                              className={`p-3 bg-[#0088470D] border-2 rounded-xl cursor-pointer 
                                ${(messageIds === mess.id ||
                                  (!messageIds &&
                                    mess.id === noarchived?.id)) &&
                                "border-2 border-[#008847] scale-105"
                                }`}
                            >
                              <div className="flex gap-3 mb-2 flex-col relative">
                                <div className="flex gap-2">
                                  <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-black font-bold text-lg">
                                    {mess.customer.first_name
                                      ?.slice(0, 2)
                                      .toUpperCase()}
                                  </div>
                                  <h3 className="font-medium text-xl">
                                    {mess.customer.first_name}
                                  </h3>
                                </div>
                                <div className="flex items-center gap-2 flex-col">
                                  <button
                                    onClick={() => toggleBookLessonModal(mess)}
                                    className={`w-full mt-2 py-1 border rounded text-sm ${tutorData.is_live
                                        ? "bg-[#008847] border-[#008847] text-white"
                                        : "bg-gray-400 border-gray-500 text-gray-200 cursor-not-allowed"
                                      }`}
                                    disabled={!tutorData.is_live}
                                    title={
                                      !tutorData.is_live
                                        ? "Profile is not live"
                                        : ""
                                    }
                                  >
                                    {t("book_a_lesson")}
                                  </button>

                                  <button
                                    className="w-full mt-2 py-1 border border-[#008847] rounded text-sm bg-[#0088471A]"
                                    onClick={() => AchivedSet(mess.id)}
                                  >
                                    {t("inbox_page.archive")}
                                  </button>
                                  {mess.unread_count > 0 && (
                                    <span className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                                      {mess.unread_count}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                      )}
                    {/* Bank Modal - only shown if pay_via_oktopi is false */}

                    {allMessages
                      .filter(
                        (mess) =>
                          mess.customer?.first_name
                            ?.toLowerCase()
                            .includes(searchMessage?.toLowerCase() || "") || ""
                      )
                      .map(
                        (mess: any) =>
                          mess.is_archived &&
                          activeTab === "achieved" && (
                            <div
                              key={mess.id}
                              onClick={() => {
                                singleMessage(mess.id);
                                setMessageId(mess.id);
                              }}
                              className={`p-3 bg-[#0088470D] border-2 rounded-xl cursor-pointer 
                                ${(messageIds === mess.id ||
                                  (!messageIds &&
                                    mess.id === noarchived?.id)) &&
                                "border-2 border-[#008847] scale-105"
                                }`}
                            >
                              <div className="flex gap-3 mb-2 flex-col relative">
                                <div className="flex gap-2">
                                  <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-black font-bold text-lg">
                                    {mess.customer.first_name
                                      ?.slice(0, 2)
                                      .toUpperCase()}
                                  </div>
                                  <h3 className="font-medium text-xl">
                                    {mess.customer.first_name}
                                  </h3>
                                </div>
                                <div className="flex items-center gap-2 flex-col">
                                  <button
                                    onClick={() => toggleBookLessonModal(mess)}
                                    className={`w-full mt-2 py-1 border rounded text-sm ${tutorData.is_live
                                        ? "bg-[#008847] border-[#008847] text-white"
                                        : "bg-gray-400 border-gray-500 text-gray-200 cursor-not-allowed"
                                      }`}
                                    disabled={!tutorData.is_live}
                                    title={
                                      !tutorData.is_live
                                        ? "Profile is not live"
                                        : ""
                                    }
                                  >
                                    {t("book_a_lesson")}
                                  </button>

                                  {mess.unread_count > 0 && (
                                    <span className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                                      {mess.unread_count}
                                    </span>
                                  )}
                                  <button
                                    className="w-full mt-2 py-1 border border-[#008847] rounded text-sm bg-[#0088471A]"
                                    onClick={() => UnAchivedSet(mess.id)}
                                  >
                                    {t("unarchive")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                      )}
                  </>
                )}
              </div>
            )}
            {bankModal && (
              <BookinBankModal
                onClose={() => setBankModal(false)}
                tutorData={tutorData}
                data={customerName}
              />
            )}
            {isBookLessonModalOpen && (
              <BookLessonInboxModal
                data={customerName}
                isOpen={isBookLessonModalOpen}
                onClose={() => setIsBookLessonModalOpen(false)}
              />
            )}
          </div>
        </div>
        <div className="flex-1 flex flex-col h-screen">
          <div className="bg-white  w-full rounded-x sticky">
            <div className="p-4  mb-2 border-2  border-[#008847] flex flex-col sm:flex-row items-center justify-between rounded-xl">
              {message && allMessages.length > 0 && (
                <>
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-black font-bold text-lg">
                      {message.customer.first_name?.slice(0, 2).toUpperCase()}
                    </div>
                    <h2 className="font-medium text-2xl">
                      {message.customer.first_name}
                    </h2>
                  </div>
                  <button
                    onClick={() => toggleBookLessonModal(message)}
                    className={`lg:px-4 px-2 text-sm lg:text-lg py-2 flex items-center gap-2 rounded-xl ${tutorData.is_live
                        ? "bg-[#008847] text-white"
                        : "bg-gray-400 text-gray-200 cursor-not-allowed"
                      }`}
                    disabled={!tutorData.is_live}
                    title={!tutorData.is_live ? "Profile is not live" : ""}
                  >
                    <GraduationCap />
                    {t("book_a_lesson")}
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="flex-1 overflow-y-auto p-4 border-b border-[#008847]">
            <div className="lg:space-y-8 space-y-10 ">
              {message?.messages?.length > 0 ? (
                message.messages.map((msg: any) => (
                  <div
                    key={msg.id}
                    className={`flex w-full ${msg.created_by !== message.tutor.id
                        ? "justify-start"
                        : "justify-end"
                      }`}
                  >
                    <div className="flex w-1/2 items-start gap-2 relative">
                      {msg.created_by !== message.tutor.id && (
                        <div className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-black font-bold text-lg">
                          {message.customer.first_name
                            ?.slice(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                      <div
                        className={`rounded-xl w-full p-3 ${msg.created_by !== message.tutor.id
                            ? "bg-[#F1F1F1] border"
                            : "bg-[#008847] text-white"
                          }`}
                      >
                        <p className="text-sm">{msg.content}</p>
                      </div>

                      {msg.created_by === message.tutor.id && (
                        <picture>
                          <source
                            srcSet={message.tutor.photo}
                            type="image/webp"
                          />
                          <img
                            src={
                              message.tutor.photo?.replace(".webp", ".jpg") ||
                              "fallback.jpg"
                            }
                            alt={message.tutor.photo}
                            className="w-9 h-9 rounded-full"
                          />
                        </picture>
                      )}
                      <span
                        className={`text-xs text-gray-600 absolute -bottom-5 ${msg.created_by !== message.tutor.id
                            ? "right-0"
                            : "left-0"
                          }`}
                      >
                        {isValidDate(msg.created)
                          ? new Date(msg.created)
                            .toLocaleString("en-US", {
                              weekday: "short", // Mon, Tue, Wed...
                              day: "2-digit", // 01, 02, 03...
                              month: "short", // Jan, Feb, Mar...
                              year: "numeric", // 2025
                              hour: "2-digit", // 21
                              minute: "2-digit", // 21
                              second: "2-digit", // 06
                              hour12: false, // 24-hour format
                            })
                            .replace(",", "")
                          : 'sending...'}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500">
                  {t("no_messages")}
                </div>
              )}
            </div>
            <div ref={chatEndRef} className="w-2 h-2" />
          </div>
          <form className="p-4 bg-white border-t" onSubmit={postMessage}>
            <div className="relative">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t("type_message")}
                className="w-full pl-4 pr-12 py-3 bg-gray-50 rounded-lg"
              />
              <button
                type="submit"
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2"
              >
                <Send className="w-5 h-5 text-[#008847]" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
