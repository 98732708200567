import React, { createContext, useContext, useState, ReactNode } from 'react';
const FilterContext = createContext<undefined>(undefined);

export const FilterProvider = ({ children }: { children: ReactNode }) => {
  const [otherFilters, setOtherFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [sreach, setSreach] = useState('');
  const [selectSubject, setSelectSubject] = useState('');
  const value: any = {
    setOtherFilters,
    otherFilters,
    setLoading,
    loading,
    setCountry,
    country,
    setSreach,
    sreach,
    setSelectSubject,
    selectSubject
  };

  return (
    <FilterContext.Provider value={value}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext: any = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
};
