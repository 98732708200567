export async function sendEmailVerification(email: string) {
    try {
        const response = await fetch('https://api.nadwa-uae.com/api/v2/users/resent_email_verification_code/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`
            },
            body: JSON.stringify({ email }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return {
            success: false,
            message: 'Failed to send email verification',
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}

export async function sendPhoneVerification(phone: string) {
    try {
        const response = await fetch('https://api.nadwa-uae.com/api/v2/customer/phonenumber/resend/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`
            },
            body: JSON.stringify({ phone_number_send_to: Number(phone) }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        return {
            success: false,
            message: 'Failed to send phone verification',
            error: error instanceof Error ? error.message : 'Unknown error occurred',
        };
    }
}
