import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BannerSection from "../../components/AboutPages/Banner";
import StepsData from "../../components/HowItWorkComponents/StepData";
import bannerImage from "../../images/asserts/Website-Assets/HowIt Work/howitwork_hero.png";

function HowItWork() {
  const { t }: { t: (key: string) => string } = useTranslation();
  useEffect(() => {
    const lang = localStorage.getItem("i18nextLng");
    if (!lang || lang === "en-US") {
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    };
  }, [])
  return (
    <div>
      <BannerSection title={t("HowItWorkHead")} image={bannerImage} />
      <StepsData />
      {/* Changes Steps */}
    </div>
  );
}

export default HowItWork;
