"use client";

import axios from "axios";
import { BadgeAlert } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bounce, ToastContainer, toast } from "react-toastify";

const TravelTime = ({ updateUi, setUpdateUi }: any) => {
  const [selectedTime, setSelectedTime] = useState("");
  const [postcode, setPostcode] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const timeOptions = [
    { label: "Online Only", value: "online" },
    { label: "30 Minutes", value: "30" },
    { label: "45 Minutes", value: "45" },
    { label: "60 Minutes", value: "60" },
    { label: "90 Minutes", value: "90" },
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const { postcode, commute, online_tutoring } = response.data;
          setPostcode(postcode || "");
          setSelectedTime(online_tutoring ? "online" : commute.toString());
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
        toast.error("Failed to load profile data.");
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [updateUi]);

  const handleTimeSelect = (value: string) => {
    setSelectedTime(value);
  };

  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(e.target.value);
  };

  const handleSaveChanges = async () => {
    if (!postcode || !selectedTime) {
      toast.error("Please enter a postcode and select a travel time.");
      return;
    }

    setIsSaving(true);
    const loadingToast = toast.loading("Saving changes...");

    try {
      const payload = {
        postcode,
        in_person_tutoring: selectedTime !== "online",
        online_tutoring: selectedTime === "online",
        commute: selectedTime === "online" ? 0 : parseInt(selectedTime),
      };

      const response = await axios.patch(
        "https://api.nadwa-uae.com/api/v3/profile-update/",
        payload,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Travel preferences updated successfully!");
        setUpdateUi(!updateUi);
      }
    } catch (error) {
      console.error("Error saving travel preferences:", error);
      toast.error("Failed to update travel preferences. Please try again.");
    } finally {
      toast.dismiss(loadingToast);
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-md space-y-6">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <h1 className="text-2xl text-[#272727] font-semibold">{t("travel_time")}</h1>

      {loading ? (
        <p className="text-gray-500">Loading profile data...</p>
      ) : (
        <>
          {/* Postcode Input */}
          <div className="space-y-5">
            <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
              {t("postcode")}
              <BadgeAlert className="text-[#008847]" />
            </div>
            <input
              type="text"
              value={postcode}
              onChange={handlePostcodeChange}
              className="w-full p-2 text-base text-[#272727] border border-gray-300 rounded-md focus:outline-none"
              placeholder="Enter postcode"
            />
          </div>

          {/* Travel Time Options */}
          <div className="space-y-2">
            <p className="text-sm font-medium text-gray-700">
              {t("travel_to_lesson")}
            </p>
            <div className="flex flex-wrap gap-2">
              {timeOptions.map((option) => (
                <button
                  key={option.value}
                  onClick={() => handleTimeSelect(option.value)}
                  className={`px-4 py-2 rounded-md transition-colors ${selectedTime === option.value
                    ? "bg-[#008847] text-white"
                    : "bg-green-100 text-[#008847] hover:bg-green-200"
                    }`}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          {/* Save Changes Button */}
          <div className="flex justify-end">
            <button
              onClick={handleSaveChanges}
              disabled={isSaving}
              className={`px-6 py-2 rounded-md text-white font-medium ${isSaving ? "bg-gray-400 cursor-not-allowed" : "bg-[#008847] hover:bg-green-700"
                }`}
            >
              {isSaving ? "Saving..." : t("save_changes")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TravelTime;
