export default {
    en: {
        "Q1":"Why Nadwa tutors?",
        "Ans_1":"Nadwa was founded by teachers with years of experience in education. Therefore, we know what makes a good tutor - that is why only 20% of tutors who apply to tutor with us are successful.",
        "Q2":"How many lessons do I need?",
        "Ans_2":"The number of lessons you will need will vary depending on your requirements. However, we recommend a minimum of 20 sessions to ensure that you have developed all the necessary skills and knowledge needed to succeed - some students have over 100 lessons with us! Once you've had your first session you can book further sessions in blocks of 5.",
        "Q3":"How do I book a lesson?",
        "Ans_3":"Once you have contacted your tutor, you can discuss everything with them, including lesson times, start dates and which specific subjects or areas you need help with. Tutors will then send you a booking request, and once you have accepted, you're good to go! All payments are made securely via the site.",
        "contact_us":"Contact us",
    
    },
    ar:{
        "Q1":"لماذا مدرسون نادوا؟",
        "Ans_1":"تأسست نادوا بواسطة معلمين لديهم سنوات من الخبرة في التعليم. لذلك، نعرف ما يجعل معلمًا جيدًا - هذا هو السبب في أن فقط 20% من المعلمين الذين يتقدمون لتدريس معنا ينجحون  ",
        "Q2":"كم عدد الدروس التي أحتاجها؟",
        "Ans_2":"عدد الدروس التي تحتاجها سيختلف حسب احتياجاتك. ومع ذلك، نوصي بحد أدنى من 20 جلسة لضمان أنك قد اكتسبت جميع المهارات والمعرفة اللازمة للنجاح - بعض الطلاب لديهم أكثر من 100 درس معنا! بمجرد أن تحصل على الجلسة الأولى، يمكنك حجز جلسات إضافية في مجموعات من 5.",
        "Q3":"كيف أحجز درسًا؟",
        "Ans_3":"بمجرد أن تتصل بمعلمك، يمكنك مناقشة كل شيء معهم، بما في ذلك أوقات الدروس وتواريخ البدء والمواد أو المجالات المحددة التي تحتاج إلى مساعدة فيها. سيقوم المعلمون بعد ذلك بإرسال طلب حجز لك، وبمجرد قبولك، أنت جاهز للبدء! يتم إجراء جميع المدفوعات بشكل آمن عبر الموقع.",
        "contact_us":"اتصل بنا" ,
    },
}