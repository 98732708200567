import axios from "axios";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import ReactStars from "react-stars";
import i18n from "../../i18n";

const ReviewCard = ({ first_name, comment, photo, isMobile, rating }) => {
  const location = useLocation();

  return (
    <div
      className={`reviewsParent flex-shrink-0 bg-white rounded-2xl shadow-lg overflow-hidden
      ${isMobile ? "w-full mx-2 h-[300px]" : "w-[48%] h-auto"}  sm:h-[350px]`}
    >
      <div className="flex h-full flex-col lg:flex-row items-center">
        <div className="flex-1 p-6 sm:p-8 flex flex-col justify-center">
          <div className="flex items-center gap-2">
            <div
              className="mb-2 sm:mb-6 w-[28px] sm:w-[80px] h-[28px] sm:h-[60px] 
                       flex items-center justify-center bg-gray-200 rounded-full"
            >
              <span className="text-sm sm:text-xl font-semibold text-gray-600">
                {first_name ? first_name?.substring(0, 2) : "St"}
              </span>
            </div>
            <p className="font-semibold text-xl text-gray-800">
              {first_name ? first_name : "Student"}
            </p>
            {/* MOBILE */}
            <div className="sm:hidden w-32 h-10 relative flex items-center justify-center">
              <div className="absolute inset-0 flex items-center justify-center p-6 -top-4 right-0">
                <ReactStars
                  count={5}
                  value={rating}
                  size={18}
                  edit={false}
                  color2={"#ffd700"}
                />
              </div>
            </div>
            <div className="hidden sm:block h-40 relative w-full">
              <div className="absolute inset-0 flex items-center justify-end p-6 -top-5 right-0 ">
                <ReactStars
                  count={5}
                  value={rating}
                  size={24}
                  edit={false}
                  color2={"#ffd700"}
                />
              </div>
            </div>
          </div>
          <p
            className={`text-[#12182D] text-sm sm:text-lg leading-[30px] mb-4 sm:mb-6 ${
              location.pathname === "/customer-dashboard" ||
              location.pathname === "/Tutor/:id"
                ? "sm:mr-0 w-full"
                : "sm:mr-24"
            }`}
          >
            {comment}
          </p>
        </div>
      </div>
    </div>
  );
};

const ReviewsCarousel = (props) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [combinedReviews, setCombinedReviews] = useState([]);
  const { id } = useParams();

  // Fetch both references and reviews
  useEffect(() => {
    const getUser = async () => {
      try {
        // First API call to get tutor data
        const tutorResponse = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${id || props.tutorId}`
        );

        // Check if user ID exists
        if (tutorResponse.data?.user?.id) {
          const userId = tutorResponse.data.user.id;

          // Config object for authenticated requests
          const authConfig = {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          };

          // Parallel API calls for references and reviews
          const [referencesResponse, reviewsResponse] = await Promise.all([
            axios.get(
              `https://api.nadwa-uae.com/api/v2/tutor/${userId}/references/`,
              authConfig
            ),
            axios.get(
              `https://api.nadwa-uae.com/api/v2/tutor/${userId}/reviews/`,
              authConfig
            ),
          ]);

          // Combine results from both APIs
          const references = referencesResponse.data.results || [];
          const reviews = reviewsResponse.data.results || [];
          const combined = [...references, ...reviews];
          setCombinedReviews(combined);
        }
      } catch (error) {
        console.error("Error fetching tutor data:", error);
      }
    };

    getUser();
  }, [id, props.tutorId]);

  // Handle resize
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 640);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Auto-slide (only if more than 2 reviews)
  useEffect(() => {
    if (!combinedReviews.length || combinedReviews.length <= 2) return;

    const interval = setInterval(() => {
      setCurrentIndex((prev) => {
        const total = combinedReviews.length;
        return (prev + 1) % total;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, [combinedReviews.length]);

  // Touch handlers
  const handleTouchStart = (e) => setTouchStart(e.touches[0].clientX);
  const handleTouchMove = (e) => setTouchEnd(e.touches[0].clientX);
  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd || !combinedReviews.length) return;

    const distance = touchStart - touchEnd;
    const total = combinedReviews.length;

    if (distance > 50) {
      // Left swipe
      setCurrentIndex((prev) => (prev + 1) % total);
    } else if (distance < -50) {
      // Right swipe
      setCurrentIndex((prev) => (prev - 1 + total) % total);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const nextReview = () => {
    setCurrentIndex((prev) => (prev + 1) % combinedReviews.length);
  };

  const prevReview = () => {
    setCurrentIndex((prev) =>
      prev === 0 ? combinedReviews.length - 1 : prev - 1
    );
  };

  if (!combinedReviews.length) {
    return (
      <div className="ml-10 my-4">
        {combinedReviews.length || 0} Reviews Available
      </div>
    );
  }

  return (
    <div className="pt-10 space-y-10 overflow-hidden sm:relative">
      <h1 className="text-3xl px-6 sm:px-10 font-bold">
        {t("Reviews")} {combinedReviews?.length}
      </h1>

      {/* Mobile View */}
      <div
        className="sm:hidden px-2"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className="flex gap-2 transition-transform duration-700 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            width: `${combinedReviews.length * 30}%`,
          }}
        >
          {combinedReviews.map((review, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <ReviewCard {...review} isMobile={isMobile} />
            </div>
          ))}
        </div>
      </div>

      {/* Desktop View */}
      <div
      className="hidden sm:block relative sm:static  w-full py-10 mx-auto overflow-hidden">
        <button
          className={`absolute ${i18n.language === "ar" ? "left-6" : "right-20"}  p-2 top-10 bg-gray-200 rounded-full shadow-md`}
          onClick={prevReview}
        >
          <ArrowLeft/>
        </button>
        <div
          dir="ltr"
          className="flex transition-transform duration-700 ease-in-out gap-10"
          style={{
            transform: `translateX(-${currentIndex * 600}px)`,
          }}
        >
          {combinedReviews.map((review, index) => (
            <ReviewCard key={index} {...review} isMobile={isMobile} />
          ))}
        </div>
        <button
          className={`absolute ${i18n.language === "ar" ? "left-20" : "right-5"}   top-10 p-2 bg-gray-200 rounded-full shadow-md`}
          onClick={nextReview}
        >
          <ArrowRight/>
        </button>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
