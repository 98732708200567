"use client"

import { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

interface ScrollLineProps {
    sectionId: string
}

export function ScrollLine({ sectionId }: ScrollLineProps) {
    const lineRef = useRef<HTMLDivElement>(null)
    const dotRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const ctx = gsap.context(() => {
            // Set up the line animation
            gsap.fromTo(
                lineRef.current,
                {
                    height: 0,
                },
                {
                    height: "100%",
                    duration: 0.5,
                    ease: "none",
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: "top 40%",
                        end: "bottom 60%",
                        scrub: 1,
                    },
                },
            )

            // Set up the dot animation
            gsap.fromTo(
                dotRef.current,
                {
                    top: "0%",
                },
                {
                    top: "100%",
                    ease: "none",
                    scrollTrigger: {
                        trigger: containerRef.current,
                        start: "top 40%",
                        end: "bottom 60%",
                        scrub: 1,
                    },
                },
            )
        })

        return () => ctx.revert()
    }, [])

    return (
        <div
            ref={containerRef}
            className="absolute md:left-1/2 left-6 h-full -translate-x-1/2 top-0 bottom-0"
            style={{ height: "calc(100% - 200px)", marginTop: "100px" }}
        >
            <div className="relative h-full">
                <div ref={lineRef} className="absolute top-0 left-1/2 w-0.5 bg-green-600 -translate-x-1/2" />
                <div
                    ref={dotRef}
                    className="absolute left-1/2 -translate-x-1/2 w-8 h-8 bg-green-600 rounded-full flex items-center justify-center"
                    style={{ top: "0%" }}
                >
                    <div className="absolute bottom-0 left-1/2 top-1/3 -translate-x-1/2 w-3 h-3 bg-white rounded-full"></div>
                </div>
            </div>
        </div>
    )
}

