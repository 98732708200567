import React, { useEffect, useState } from "react";
import { ShieldCheckIcon, X } from "lucide-react";
import { Link } from "react-router-dom";
import axios from "axios";

const BookinBankModal = ({ onClose, tutorData, data }: any) => {
  const [userDetail, setUserDetail] = useState<any>({});
  useEffect(() => {
    const getUserDetail = async () => {
      try {
        const res = await axios.get(
          "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );

        const userData = res.data.data;

        const formattedData = {
          client_id: userData?.user?.stripe_user_id || "",
          email: userData?.user?.email || "",
          first_name: userData?.user?.first_name || "",
          last_name: userData?.user?.last_name || "",
          dob_day: userData?.user?.date_of_birth
            ? new Date(userData.user.date_of_birth).getDate()
            : "",
          dob_month: userData?.user?.date_of_birth
            ? new Date(userData.user.date_of_birth).getMonth() + 1
            : "",
          dob_year: userData?.user?.date_of_birth
            ? new Date(userData.user.date_of_birth).getFullYear()
            : "",
        };

        setUserDetail(formattedData);
      } catch (error) {
        console.log(error);
      }
    };
    getUserDetail();
  });

  return (
    <div className="fixed inset-0 bg-black/30 z-20  flex items-center justify-center p-4">
      <div className="w-full max-w-lg bg-white h-[80%] sm:h-fit overflow-y-auto sm:overflow-y-hidden rounded-lg relative">
        <button
          onClick={onClose}
          className="absolute right-2 top-0 p-2 rounded-full hover:bg-gray-100"
        >
          <X className="h-6 w-6 text-gray-700" />
        </button>
        <div className="p-6">
          <div className="flex flex-col items-center justify-center">
            <picture>
              <source srcSet={tutorData.photo} type="image/webp" />
              <img
                src={tutorData?.photo?.replace(".webp", ".jpg") || "fallback.jpg"}
                alt={tutorData?.user?.first_name}
                className="w-20 h-20 rounded-full"
              />
            </picture>
            <h2 className="text-2xl font-bold text-center mb-6">
              Book a lesson <span>{data?.customer.first_name}</span>
            </h2>
          </div>

          <div className="space-y-6 text-sm sm:text-base">
            <p className="text-gray-600">
              Please add your bank account details before booking a lesson so
              that we can ensure that you get paid as quickly as possible
              following each lesson.
            </p>

            <p className="text-gray-600">
              We work with the market-leading payments company,{" "}
              <Link
                to={"https://docs.stripe.com/security"}
                className="text-[#008847] no-underline"
              >
                Stripe
              </Link>{" "}
              to ensure that your bank details are held securely.
            </p>

            <p className="text-gray-600">
              Please click on the button below to be taken to Stripe so that you
              can set up a payment account and receive funds for any lessons you
              arrange through Nadwa.
            </p>

            <Link
              target="_blank"
              to={(() => {
                const params: any = {
                  "stripe_user[email]": userDetail?.email,
                  "stripe_user[first_name]": userDetail?.first_name,
                  "stripe_user[last_name]": userDetail?.last_name,
                  "stripe_user[dob_day]": userDetail?.dob_day,
                  "stripe_user[dob_month]": userDetail?.dob_month,
                  "stripe_user[dob_year]": userDetail?.dob_year,
                  "stripe_user[business_name]": "Tutor House Ltd",
                  "stripe_user[url]": "https://tutorhouse.co.uk",
                  "stripe_user[country]": "GB",
                  "stripe_user[currency]": "GBP",
                  "stripe_user[product_description]":
                    "Experienced Freelance Private Tutor, in the education sector. Paid on an hourly rate.",
                  "stripe_user[street_address]":
                    "9 Great Newport Street, Floor 4, WC2H 7JA",
                  "stripe_user[city]": "London",
                  "stripe_user[zip]": "WC2H 7JA",
                  "stripe_user[phone_number]": "02039500320",
                  scope: "read_write",
                  response_type: "code",
                  client_id: process.env.REACT_APP_STRIPE_CLIENT_ID || "",
                  redirect_uri: "https://www.nadwa-uae.com",
                  state: "123123",
                };

                const queryString = new URLSearchParams(params).toString();
                return `https://connect.stripe.com/oauth/v2/authorize?${queryString}`;
              })()}
            >
              <button className="w-full bg-[#008847] hover:bg-green-700 text-white mt-4 py-2 rounded-md text-sm sm:text-lg">
                Register Your Bank Details
              </button>
            </Link>

            <div className="flex items-center justify-between text-xs sm:text-sm text-gray-600 pt-4">
              <div className="flex items-center gap-2">
                <ShieldCheckIcon />
                <span>Secure Payments</span>
              </div>
              <div className="flex items-center gap-4">
                <span>Powered by</span>
                <Link
                  to="https://stripe.com/"
                  className="no-underline font-semibold text-blue-950"
                >
                  Stripe
                </Link>
                <span>|</span>
                <Link to="" className="no-underline text-black">
                  Terms
                </Link>
                <Link to="" className="no-underline text-black">
                  Privacy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookinBankModal;
