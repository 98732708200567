import "bootstrap/dist/css/bootstrap.min.css";
import whitelogo from "../images/Homepageassets/Nadwa-WhiteLogo.png";
import icon from "../images/asserts/Website-Assets/HomePage/language 1.png";
import icon2 from "../images/asserts/Website-Assets/HomePage/user 1.png";
import { AlignJustify, X } from "lucide-react";
import menu from "../images/asserts/Website-Assets/HomePage/Menu.jpg";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
export type Language = {
  code: string;
  lng: string;
};

const NavbarComponent: React.FC = () => {
  const { t, i18n }: { t: (key: string) => string; i18n: any } =
    useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] =
    useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const token = localStorage.getItem("TH_AUTH_TOKEN");

  const Languages: Language[] = [
    { code: "en", lng: "English" },
    { code: "ar", lng: "Arabic" },
  ];

  const togglePopup = () => setIsPopupVisible(!isPopupVisible);
  const toggleLanguageDropdown = () =>
    setIsLanguageDropdownVisible(!isLanguageDropdownVisible);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    document.body.className = lng === "ar" ? "lang-ar" : "lang-en";
  };

  useEffect(() => {
    document.body.dir = i18n.dir(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  useEffect(() => {
    if (!token) return;
    const user = async () => {
      const res = await axios.get(
        "https://api.nadwa-uae.com/api/v2/users/profile/user_profile/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setUser(res.data.data.user);
    };
    user();
  }, [token]);

  return (
    <>
      <ToastContainer />
      <header
        className={`${scrolled ? "bg-black/45 shadow" : "bg-transparent"
          } hidden lg:block fixed transition-colors duration-300 ease-in-out top-0 w-full z-[50] `}
      >
        <div className="max-w-[89%] mx-auto">
          <div className="flex flex-col gap-2">
            <div className="flex justify-end items-center">
              <div className="relative">
                <button
                  onClick={toggleLanguageDropdown}
                  className="bg-transparent text-white rounded-full flex gap-2 items-center"
                >
                  <img src={icon} alt="icon" />
                  {t("header")}
                </button>
                {isLanguageDropdownVisible && (
                  <ul className="absolute mt-2 bg-white text-[#008847] rounded shadow-md overflow-hidden p-0 w-[150px]">
                    {Languages.map((language) => (
                      <li
                        key={language.code}
                        className="cursor-pointer hover:bg-[#008847] hover:text-white py-2 px-4"
                        onClick={() => {
                          changeLanguage(language.code);
                          setIsLanguageDropdownVisible(
                            !isLanguageDropdownVisible
                          );
                        }}
                        style={{ margin: 0 }}
                      >
                        {language.lng}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {user ? (
                <a
                  href={user.is_customer ? "/customer-dashboard" : "/dashboard"}
                  className="bg-transparent no-underline py-1 xl:py-[10px] px-4 text-white rounded-full flex gap-2 items-center"
                >
                  <img src={icon2} alt="icon" />
                  {user.first_name}
                </a>
              ) : (
                <a
                  href="/login"
                  className="bg-transparent no-underline py-1 xl:py-[10px] px-4 text-white rounded-full flex gap-2 items-center"
                >
                  <img src={icon2} alt="icon" />
                  {t("header2")}
                </a>
              )}
            </div>
            <hr className="absolute w-full top-[17%] left-0 text-white" />
            <div className="flex justify-between items-center">
              <div>
                <a href="/">
                  <img
                    src={whitelogo}
                    alt="Logo"
                    className="xl:w-[200px] w-[180px]"
                  />
                </a>
              </div>

              <div className="flex justify-between items-center xl:gap-16 gap-8">
                <ul className="flex xl:gap-12 lg:gap-4 gap-40 text-[14px] xl:text-lg items-center">
                  <a href="/" className="no-underline">
                    <li className="text-white">{t("header3")}</li>
                  </a>
                  <a href="/about" className="no-underline">
                    <li className="text-white">{t("header4")}</li>
                  </a>
                  <a
                    href="/tutor"
                    className={`${user?.is_customer ||
                        !localStorage.getItem("TH_AUTH_TOKEN")
                        ? ""
                        : "hidden"
                      } no-underline `}
                  >
                    <li className="text-white">{t("header5")}</li>
                  </a>
                  {user?.is_customer ||
                    !localStorage.getItem("TH_AUTH_TOKEN") ? (
                    <a href="/SignupStudent" className="no-underline">
                      <li className="text-white">{t("header6")}</li>
                    </a>
                  ) : (
                    <li
                      onClick={() => {
                        toast.info("You are already logged in as a tutor");
                      }}
                      className="cursor-pointer">
                      <li className="text-white">{t("header7")}</li>
                    </li>
                  )}

                  <a href="/ContactUs" className="no-underline">
                    <li className="text-white">{t("header8")}</li>
                  </a>
                </ul>
                <div>
                  <button
                    onClick={() =>
                      (window.location.href = "/customer-dashboard")
                    }
                    className={`${user?.is_customer ||
                        !localStorage.getItem("TH_AUTH_TOKEN")
                        ? ""
                        : "hidden"
                      } bg-[#ED1D24] xl:py-3 py-2 mb-3 xl:px-8 px-6 text-white rounded-full`}
                  >
                    {t("header9")}
                  </button>
                  {isPopupVisible && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                      <div className="bg-gray-200 w-96 h-80 p-6 flex flex-col justify-center rounded-lg shadow-lg relative">
                        <button
                          onClick={togglePopup}
                          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
                        >
                          ✖
                        </button>
                        <p className="text-lg text-gray-700 text-center font-medium mb-6">
                          {t("popup")}
                        </p>
                        <div className="flex items-center justify-center">
                          <a
                            href="/SignupStudent"
                            onClick={togglePopup}
                            className="bg-green-600 hover:bg-green-500 no-underline text-white font-semibold px-4 py-2 rounded-md"
                          >
                            {t("popup2")}
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Mobile Navbar */}
      <header
        className={`${scrolled ? "bg-black/70 shadow" : "bg-transparent"
          } block lg:hidden pt-3 pb-2 fixed top-0 w-full z-50`}
      >
        <div className="max-w-[90%] mx-auto">
          <div className="flex justify-between items-center gap-2">
            <div>
              <img src={whitelogo} alt="Logo" className="w-[150px]" />
            </div>
            <div className="p-2 bg-white rounded-full relative z-10">
              {isOpen ? (
                <X
                  className="cursor-pointer text-lg"
                  onClick={() => setIsOpen(!isOpen)}
                />
              ) : (
                <AlignJustify
                  className="cursor-pointer"
                  onClick={() => setIsOpen(!isOpen)}
                />
              )}
            </div>
            <div
              className={`fixed top-0 right-0 w-full h-screen transition-opacity duration-500 ease-linear ${isOpen
                  ? "opacity-100 pointer-events-auto"
                  : "opacity-0 pointer-events-none"
                }`}
            >
              {isOpen && (
                <div
                  className="bg-cover bg-no-repeat bg-center p-6 h-screen overflow-hidden"
                  style={{ backgroundImage: `url(${menu})` }}
                >
                  <div className="flex justify-start gap-2">
                    <div className="relative">
                      <button
                        onClick={toggleLanguageDropdown}
                        className="bg-transparent border border-white xl:py-[10px] py-1 xl:text-xl text-sm xl:px-10 px-3 text-white rounded-full flex gap-2 items-center"
                      >
                        <img src={icon} alt="icon" />
                        {t("header")}
                      </button>
                      {isLanguageDropdownVisible && (
                        <ul className="absolute mt-2 bg-white text-[#008847] rounded shadow-md overflow-hidden p-0 w-[150px]">
                          {Languages.map((language) => (
                            <li
                              key={language.code}
                              className="cursor-pointer hover:bg-[#008847] hover:text-white py-2 px-4"
                              onClick={() => {
                                changeLanguage(language.code);
                                setIsLanguageDropdownVisible(
                                  !isLanguageDropdownVisible
                                );
                                setIsOpen(!isOpen);
                              }}
                              style={{ margin: 0 }}
                            >
                              {language.lng}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    {user ? (
                      <a
                        href={
                          user.is_customer
                            ? "/customer-dashboard"
                            : "/dashboard"
                        }
                        className="bg-transparent no-underline py-1 xl:py-[10px] px-4 text-white rounded-full flex gap-2 items-center"
                      >
                        <img src={icon2} alt="icon" />
                        {user.first_name}
                      </a>
                    ) : (
                      <a
                        href="/login"
                        className="bg-transparent no-underline py-1 xl:py-[10px] px-4 text-white rounded-full flex gap-2 items-center"
                      >
                        <img src={icon2} alt="icon" />
                        {t("header2")}
                      </a>
                    )}
                  </div>
                  <div className="flex gap-4 flex-col mt-10">
                    <ul className="flex gap-8 flex-col pl-2 font-bold">
                      <a href="/" className="no-underline">
                        <li className="text-white">{t("header3")}</li>
                      </a>
                      <a href="/about" className="no-underline">
                        <li className="text-white">{t("header4")}</li>
                      </a>
                      <a
                        href="/tutor"
                        className={`${user?.is_customer ||
                            !localStorage.getItem("TH_AUTH_TOKEN")
                            ? ""
                            : "hidden"
                          } no-underline `}
                      >
                        <li className="text-white">{t("header5")}</li>
                      </a>
                      {user?.is_customer ||
                        !localStorage.getItem("TH_AUTH_TOKEN") ? (
                        <a href="/SignupStudent" className="no-underline">
                          <li className="text-white">{t("header6")}</li>
                        </a>
                      ) : (
                        <li
                          onClick={() => {
                            toast.info("You are already logged in as a tutor");
                          }}
                          className="cursor-pointer">
                          <li className="text-white">{t("header7")}</li>
                        </li>
                      )}

                      <a href="/ContactUs" className="no-underline">
                        <li className="text-white">{t("header8")}</li>
                      </a>
                    </ul>
                    <div>
                      <button
                        onClick={() =>
                          (window.location.href = "/customer-dashboard")
                        }
                        className={`${user?.is_customer ||
                            !localStorage.getItem("TH_AUTH_TOKEN")
                            ? ""
                            : "hidden"
                          } bg-[#ED1D24] xl:py-3 py-2 mb-3 xl:px-8 px-6 text-white rounded-full`}
                      >
                        {t("header9")}
                      </button>
                      {isPopupVisible && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                          <div className="bg-gray-200 lg:w-96 lg:h-80 w-[80%] h-[25%] p-6 flex flex-col justify-center rounded-lg shadow-lg relative">
                            {/* Close button */}
                            <button
                              onClick={togglePopup}
                              className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
                            >
                              ✖
                            </button>

                            {/* Content */}
                            <p className="text-lg text-gray-700 text-center font-medium mb-6">
                              {t("popup")}
                            </p>

                            <div className="flex items-center justify-center">
                              <a
                                href={
                                  "https://app.nadwa-uae.com/student/register/"
                                }
                                onClick={() => {
                                  togglePopup();
                                }}
                                className="bg-green-600 hover:bg-green-500 no-underline text-white font-semibold px-4 py-2 rounded-md"
                              >
                                {t("popup2")}
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavbarComponent;
