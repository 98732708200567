import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { locales } from './locales';
import tutorLocales from './tutor-locales';
import customerLocales from './customer-locales';
import latestLocales from './latest-locales';
import locales_test from './locales_test';

const i18nConfig = {
  debug: true,
  fallbackLng: 'en',
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        ...locales.en,
        ...tutorLocales.en,
        ...customerLocales.en,
        ...latestLocales.en,
        ...locales_test.en,
      },
    },
    ar: {
      translation: {
        ...locales.ar,
        ...tutorLocales.ar,
        ...customerLocales.ar,
        ...latestLocales.ar,
        ...locales_test.ar,
      },
    },
  },
  detection: {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupLocalStorage: 'i18nextLng',
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig);

export default i18n;
