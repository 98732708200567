export default {
  en: {
    "overview": "Overview",
    "dashboard": "Dashboard",
    "apply_for_permit": "Apply for Permit",
    "availability": "Availability",
    "jobs_board": "Jobs Board",
    "inbox": "Inbox",
    "bookings": "Bookings",
    "settings": "Settings",
    "view_profile": "View Profile",
    "account_settings": "Account Settings",
    "logout": "Logout",
    "jobs_applied": "Jobs you've applied for",
    "student": "Student",
    "request": "Request",
    "date": "Date",
    "status": "Status",
    "profile": "Profile",
    "my_profile": "My Profile",
    "apply_for_jobs": "Apply for Jobs",
    "apply_license": "Apply to the UAE private tutoring license",
    "private_tutoring_permit": "Private Tutoring Permit - UAE",
    "how_to_apply": "How to apply?",
    "where_apply": "Where do I apply?",
    "apply_online_now": "Apply Online Now",
    "about_permit": "About the permit",
    "permit_cost": "How much does it cost?",
    "who_eligible": "Who is eligible?",
    "required_documents": "What documents do you need?",
    "what_if_not_apply": "What if you don't apply?",
    "work_from_home": "Can you work from home?",
    "processing_time": "How long does it take to get the Private Tutor visa?",
    "reapply": "My application was declined, may I reapply?",
    "permit_validity": "How long is the permit valid for?",
    "permit_fees": "What are the permit fees?",
    "work_from_country": "If I get a license, can I work from my home country?",
    "online_classes": "Can I use this permit to take online classes?",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "save_availability": "Save Availability",
    "about_the_permit_paragraph": "A permit has been introduced to protect the rights of private teachers and to “ensure students receive supplemental education that meets their learning style and needs\", said Muhammad Al Mualla, undersecretary for academic affairs at the Ministry of Education. The move is also intended to combat illegal private tuition. “The introduction of a permit for individuals qualified to provide private lessons will help curb illegal and unregulated practices when recruiting private teachers, which risk affecting the learning process as a whole,” Mr Al Mualla added.",
    "what_if_not_apply_paragraph": "Anyone offering private lessons without a permit will be subject to fines and penalties. The cost of these penalties has not been confirmed but, when private tuition was previously banned, educators could face fines as high as Dh 50,000.",
    "work_from_home_paragraph": "A single permit covers online and in-person tutoring. Educators can also work from their home country, provided they have a valid residency.",
    "basics": "Basics",
    "country": "Country",
    "school": "School",
    "experience": "Experience",
    "travel_time": "Travel Time",
    "qualifications": "Qualifications",
    "teaching_subjects": "Teaching Subjects",
    "pricing": "Pricing",
    "record_video": "Record a Video",
    "back": "Back",
    "change_picture": "Change Picture",
    "personal_information": "Personal Information",
    "first_name": "FIRST NAME",
    "last_name": "LAST NAME",
    "phone_number": "PHONE NUMBER",
    "email": "EMAIL",
    "tagline": "TAGLINE",
    "edit_school": "Edit School",
    "choose_school": "Please choose your school",
    "description": "Description",
    "experience_years": "Experience (Years)",
    "postcode": "Enter Your Postcode",
    "travel_to_lesson": "How long would you be happy to travel to do a face-to-face lesson?",
    "save_changes": "Save Changes",
    "add_qualification": "+Add Qualification",
    "institution": "Institution",
    "qualification_subject": "Qualification & Subject",
    "remove": "Remove",
    "subjects": "Subjects",
    "which_subjects": "Which subjects and levels would you like to teach?",
    "save_subjects": "Save Subjects",
    "hourly_rate": "Please enter your preferred hourly rate",
    "record_or_upload_video": "Record or Upload a Video",
    "video_preview": "Video Preview",
    "current_video": "Current Video",
    "start_recording": "Start Recording",
    "watch_demo_video": "Watch Demo Video",
    "upload_link": "Upload Link",
    "select_timezone": "Select your time zone",
    "bank_details_settings": "Bank Details Settings",
    "pay_to_nadwa": "Pay to Nadwa, Nadwa Pays me Monthly",
    "get_paid_stripe": "Get Paid Using my Stripe Account",
    "notification_settings": "Notification Settings",
    "new_message": "When I receive a new message",
    "lesson_cancel": "Customer cancels a lesson",
    "offers_news": "There's any offers or news from Nadwa",
    "sms_notification": "Send me an SMS when I receive a new message",
    "new_enquiry": "When I receive a new enquiry",
    "lesson_accept_reject": "A customer accepts or rejects a lesson",
    "payment_received": "I am paid",
    "request_a_tutor": "Request A Tutor",
    "choose_subject_and_level": "Choose Subject and Level",
    "intro_text": "At Hches we know how hard it is to find the right tutor. So we decided to make it easy! It’s 3 simple steps you can book for Free Text Lesson with a tutor to make sure they are the right fit for you. Let's start with choosing your subject and level.",
    "all_levels": "All Levels",
    "level_7_plus": "7+",
    "level_graduate": "Graduate",
    "level_post_graduate": "Post Graduate",
    "level_primary_level": "Primary Level",
    "level_a_level": "A-Level",
    "level_gcse_igcse": "GCSE/IGCSE",
    "level_ib": "IB",
    "level_primary_ks1_ks2": "Primary (KS1, KS2)",
    "level_secondary_ks3_ks4": "Secondary (KS3, KS4)",
    "level_ks5": "KS5",
    "level_cycle_1_primary_grade_1_5": "Cycle 1 (Primary, Grade 1-5)",
    "level_cycle_2_preparatory_grade_6_9": "Cycle 2 (Preparatory, Grade 6-9)",
    "level_cycle_3_secondary_grade_10_12": "Cycle 3 (Secondary, Grade 10-12)",
    "level_undergraduate": "Undergraduate",
    "level_postgraduate": "Postgraduate",
    "button_next": "Next",
    "button_cancel": "Cancel",
    "button_back": "Back",
    "choose_budget": "Choose Budget (Drag the slider to select)",
    "price_range": "Price Range Per Hour (AED)",
    "free_trial": "Free 'Best Fit' Trial",
    "availability_selection": "When are you available?",
    "day_monday": "Monday",
    "day_tuesday": "Tuesday",
    "day_wednesday": "Wednesday",
    "day_thursday": "Thursday",
    "day_friday": "Friday",
    "day_saturday": "Saturday",
    "day_sunday": "Sunday",
    "select_all": "Select All",
    "clear": "Clear",
    "time_of_day_morning": "Morning",
    "time_of_day_afternoon": "Afternoon",
    "time_of_day_evening": "Evening",
    "tailor_match": "Tailor your tutor match",
    "tag_aiming_for_a_grade": "Aiming for 'A' grade",
    "tag_exam_help": "Exam help",
    "tag_dyslexia": "Dyslexia",
    "tag_sen": "SEN",
    "tag_last_minute_revision": "Last-minute revision",
    "tag_essay_writing_skills": "Essay writing skills",
    "tag_sibling_support": "Sibling support",
    "tag_coursework_support": "Coursework support",
    "tag_outgoing": "Outgoing",
    "tag_rational": "Rational",
    "tag_sensitive": "Sensitive",
    "tag_efficient": "Efficient",
    "tag_emotional": "Emotional",
    "go_to_dashboard": "Go to Dashboard",
    "subsection_art_title": "Art",
    "subsection_art_politics": "Politics",
    "subsection_art_philosophy": "Philosophy",
    "subsection_art_psychology": "Psychology",
    "subsection_languages_title": "Languages",
    "subsection_languages_science": "Science",
    "subsection_languages_spanish": "Spanish",
    "subsection_languages_greek": "Greek",
    "subsection_languages_coding": "Coding",
    "subsection_languages_chemistry": "Chemistry",
    "subsection_languages_business_management": "Business Management",
    "subsection_languages_arabic": "Arabic",
    "subsection_languages_accounts": "Accounts",
    "subsection_languages_computer": "Computer",
    "subsection_languages_computer_science": "Computer Science",
    "subsection_languages_economics": "Economics",
    "subsection_languages_finances": "Finances",
    "subsection_languages_french": "French",
    "subsection_languages_history": "History",
    "subsection_languages_german": "German",
    "subsection_languages_income_tax_law": "Income Tax Law",
    "subsection_languages_islamic_education": "Islamic Education",
    "subsection_languages_italian": "Italian",
    "subsection_languages_java": "Java",
    "subsection_languages_law": "Law",
    "subsection_languages_management": "Management",
    "subsection_languages_marketing": "Marketing",
    "subsection_languages_maths": "Maths",
    "subsection_languages_personal_development": "Personal Development",
    "subsection_languages_physics": "Physics",
    "subsection_languages_accounting": "Accounting",
    "subsection_languages_english": "English",
    "subsection_languages_social_sciences": "Social Sciences",
    "subsection_languages_chinese": "Chinese",
    "subsection_languages_mandarin": "Mandarin",
    "subsection_languages_biology": "Biology",
    "subsection_languages_geography": "Geography",
    "subsection_languages_korean": "Korean",
    "subsection_languages_turkish": "Turkish",
    "subsection_music_title": "Music",
    "subsection_music_music_practical": "Music Practical",
    "subsection_music_music_theory": "Music Theory",
    "subsection_sport_title": "Sport",
    "subsection_sport_yoga": "Yoga",
    "popup_apply_for_job_comment": "Apply_for_Job_popup",
    "popup_apply_for_job_title": "Your Application For This Job",
    "popup_apply_for_job_description": "Our unique Job Posting function allows tutors to apply for jobs that suit them. Find & connect with students easily with Nadwa.",
    "popup_apply_for_job_send_message": "Send Message",
    "popup_apply_for_job_type_message_here": "Type your message here",
    "popup_apply_for_job_back": "Back",
    "popup_apply_for_job_submit_application": "Submit Job Application",
    "popup_jobs_board_comment": "Jobs_Board_popup",
    "popup_jobs_board_description": "Customers post 100s of jobs every week with Nadwa, so we encourage you to apply to as many jobs as you would like. Once you apply for a job, your profile will be sent to the customer, who will then message you if they wish to work with you. Just so you know, up to five other tutors can apply to the same job (so don’t be discouraged if you don’t hear back from the client). To improve your chances of being selected, we recommend: Responding to the customer quickly; customers love tutors who get back to them ASAP. Improve your profile; make sure you sell your experience and past success stories. Make sure your photo looks great and your strap-line is informative.",
    "popup_jobs_board_availability": "Availability",
    "popup_jobs_board_apply_for_this_job": "Apply for this job",
    "popup_jobs_board_min_price_per_hour": "MIN PRICE /hour",
    "popup_jobs_board_max_price_per_hour": "MAX PRICE /hour",
    "popup_jobs_board_apply_for_job": "Apply for Job",
    "popup_reschedule_lesson_comment": "Reschedule_Lesson_popup",
    "popup_reschedule_lesson_title": "Reschedule lesson with Student?",
    "popup_reschedule_lesson_important_limitation": "Important: Rescheduling Limitation",
    "popup_reschedule_lesson_select_date": "Select Date",
    "popup_reschedule_lesson_start_time": "Start Time",
    "popup_reschedule_lesson_end_time": "End Time",
    "popup_reschedule_lesson_cancel": "Cancel",
    "popup_reschedule_lesson_reschedule": "Reschedule",
    "popup_book_lesson_comment": "Book_Lesson_popup",
    "popup_book_lesson_student": "Student",
    "popup_book_lesson_select_from_your_students": "Select from your students",
    "popup_book_lesson_subject": "Subject",
    "popup_book_lesson_select_a_subject": "Select a subject",
    "popup_book_lesson_select_a_date": "Select a date",
    "popup_book_lesson_select_duration": "Select Duration",
    "popup_book_lesson_1_hour": "1 Hour",
    "popup_book_lesson_90_minutes": "90 Minutes",
    "popup_book_lesson_2_hours": "2 Hours",
    "popup_book_lesson_number_of_weeks_same_time_day_every_week": "Number of weeks (Same time/day every week)",
    "popup_book_lesson_number_of_lessons": "Number of Lessons",
    "popup_book_lesson_start_time": "Start Time",
    "popup_book_lesson_select_start_time": "Select Start Time",
    "popup_book_lesson_end_time": "End Time",
    "popup_book_lesson_select_end_time": "Select End Time",
    "popup_book_lesson_cancel": "Cancel",
    "popup_book_lesson_book_lesson": "Book Lesson",
    "popup_cancel_lesson_comment": "Cancel_Lesson_popup",
    "popup_cancel_lesson_title": "Cancel Lesson with Student",
    "popup_cancel_lesson_start_time": "Start Time",
    "popup_cancel_lesson_end_time": "End Time",
    "popup_cancel_lesson_back": "Back",
    "popup_cancel_lesson_cancel_lesson": "Cancel Lesson",
    "tutor_applications": "Tutor Applications",
    "browse_other_tutors": "Browse Other Tutors",
    "tutor": "Tutor",
    "message": "Message",
    "action": "Action",
    "find_a_tutor": "Find a Tutor",
    "posted_jobs": "Posted Jobs",
    "log_out": "Log Out",
    "edit_profile": "Edit Profile",
    "good_evening_student": "Good Evening Student",
    "your_schedule": "Your Schedule",
    "type": "Type",
    "lesson": "Lesson",
    "enter_tutor_name": "Enter Tutor Name",
    "search": "Search",
    "subject": "Subject",
    "all_subjects": "All Subjects",
    "level": "Level",
    "countries": "Countries",
    "all_countries": "All Countries",
    "reset_now": "Reset Now",
    "search_results": "Search Results",
    "contact_us": "Contact us",
    "tutoring_in_person": "Tutoring: In person tutoring",
    "tutoring_online": "Tutoring: Online",
    "per_hour": "Per Hour",
    "including_vat": "Including VAT",
    "years_of_experience": "## Years of Experience",
    "messages": "Messages",
    "latest_messages": "Latest Messages",
    "archived": "Archived",
    "archive": "Archive",
    "search_chat": "Search Chat",
    "upcoming_lessons": "Upcoming Lessons",
    "trial_call": "Trial Call",
    "past_lessons": "Past Lessons",
    "online": "Online",
    "confirmed": "Confirmed",
    "unconfirmed": "Unconfirmed",
    "lesson_completed": "Lesson Completed",
    "send_a_message": "Send a Message",
    "cancel_lesson": "Cancel Lesson",
    "change_lesson": "Change Lesson",
    "here_are_the_jobs_you_have_posted": "Here are the jobs you have posted",
    "applications_so_far": "## Applications So Far",
    "delete_job_post": "Delete Job Post",
    "view_job": "View Job",
    "select_your_time_zone": "Select your time zone",
    "when_i_receive_a_new_message": "When I receive a new message",
    "tutor_cancels_a_lesson": "Tutor cancels a lesson",
    "any_offers_or_news_from_nadwa": "There's any offers or news from Nadwa",
    "send_me_an_sms_when_i_receive_a_new_message": "Send me an SMS when I receive a new message",
    "payment_is_made_to_a_tutor": "Payment is made to a tutor",
    "i_receive_a_lesson_invitation_from_a_tutor": "I receive a lesson invitation from a tutor",
    "about_nadwa": "About Nadwa",
    "how_it_works": "How It Works",
    "blogs": "Blogs",
    "copyright": "Copyright © 2024, All Rights Reserved.",
    "terms_and_conditions": "Terms & Conditions",
    "privacy_policy": "Privacy Policy",
    "home": "Home",
    "about_us": "About Us",
    "join_to_learn": "Join to Learn",
    "book_now": "Book Now",
    "language": "Language",
    "login": "Login",
    "achieve_academic_excellence": "Achieve Academic Excellence With Trusted Tutors from the UAE and UK",
    "customized_learning_experiences": "Customized learning experiences, online or in-person, aligned with your educational preferences.",
    "discover_more": "Discover More",
    "online_in_person_sessions": "ONLINE AND IN PERSON 1-ON-1 OR COLLABORATIVE GROUP SESSIONS",
    "one_small_step": "One small step for Edupreneurs, a big step for parents and students",
    "nadwa_mission": "Founded with a passion for learning and a commitment to quality education, Nadwa connects students with expert tutors across a wide range of subjects and specialties.",
    "our_vision": "Our vision is to revolutionize the education system by making high-quality tutoring accessible to all students, regardless of their background or circumstances.",
    "hire_a_tutor": "Hire a Tutor",
    "why_nadwa": "Why Nadwa tutors?",
    "years_experience": "Years of Experience",
    "create_student_account": "Create Student Account",
    "telephone": "Telephone",
    "password": "Password",
    "register": "Register",
    "follow_us": "Follow Us",
    "phone": "Phone",
    "schedule_meeting": "Schedule A Meeting",
    "where_do_i_apply_paragraph": "You can apply online. The process is simple:\n1. Enter your Emirates ID number and then click on 'Send OTP' (One-time password).\n- The system will then show you some of the details on your file with the Ministry, including your full name, nationality, date of birth and phone number.\n2. You will then have four categories under which you can apply for the permit:\n- Student category (university student/school student)\n- Category of the unemployed\n- Category of workers in different sectors\n- A teacher registered in a public or private school\n3. Select the category relevant to you and upload the required documents.\n4. Download the 'Code of Conduct' document next, sign it and upload a digital copy to attach to your application.\n5. Click on 'Submit'.",
    "how_long_does_it_take_to_get_the_private_tutor_visa": "The application will be processed within five working days.",
    "it_has_been_more_than_5_days_and_i_have_not_heard_anything": "You can reach out to MOHRE through their support website - or by calling them on 600 590000.",
    "my_application_was_declined_may_i_reapply": "Yes, but only after a period of six months.",
    "how_long_is_the_permit_valid_for": "Once your application is approved, your permit will be valid for two years, and needs to be renewed accordingly.",
    "what_are_the_permit_fees": "The permit application is free of cost.",
    "if_i_get_a_license_can_i_work_from_my_home_country": "According to MOHRE, this is also an option available to residents, provided that your UAE residence visa is valid, and you comply with the agreements between the two parties.",
    "can_i_use_this_permit_to_take_online_classes": "According to MOHRE, this permit covers both online and in-person activities.",
    "what_documents_do_you_need": {
      "intro": "Documents required vary depending on your current position and status.",
      "sections": {
        "A": {
          "title": "If you're a student (university student/school student) you need:",
          "items": [
            "A certificate of continuity of study for a student in the secondary stage/indicating that the student is enrolled at the university",
            "The student's last academic certificate",
            "No Objection Certificate (NOC) from the guardian",
            "Certificate of good conduct",
            "Medical fitness certificate",
            "Valid identification documents (passport/residence visa/Emirates ID)",
            "A clear personal photo with a white background"
          ]
        },
        "B": {
          "title": "If you're unemployed and are not a student you need:",
          "items": [
            "Latest academic degree",
            "Certificate of good conduct",
            "Valid identification documents (passport/residence visa/Emirates ID)",
            "Medical fitness certificate",
            "Experience certificate (if applicable)",
            "A clear personal photo with a white background"
          ]
        },
        "C": {
          "title": "If you're employed but don't work in education directly:",
          "items": [
            "Latest academic degree",
            "Certificate of good conduct",
            "No Objection Certificate (NOC) from the employer",
            "Medical fitness certificate",
            "Experience certificate (if applicable)",
            "Valid identification documents (passport/residence visa/Emirates ID)",
            "A clear personal photo with a white background"
          ]
        },
        "D": {
          "title": "If you're a teacher registered in a public or private school:",
          "items": [
            "Certificate of good conduct",
            "No Objection Certificate (NOC) from the employer",
            "Medical fitness certificate",
            "Experience certificate (if any)",
            "Valid identification documents (passport/residence visa/Emirates ID)",
            "A clear personal photo with a white background"
          ]
        }
      }
    },
    "how_much_does_it_cost": "The permit is free and is valid for two years, after which holders can apply for it to be renewed. Rather than a fee, educators are asked to sign a code of conduct document approved by the Ministry of Human Resources and Emiratisation.",
    "who_is_eligible": "The new permit allows \"different groups of specialised and qualified professionals from the community to offer private lessons for students, individually or in groups,\" the Ministry of Human Resources and Emiratisation says.",
    "who_can_apply": {
      "description": "It is open to all over-15s to apply, subject to approval:",
      "list": [
        "Registered teachers in government or private schools",
        "Employees in the government and private sectors",
        "Unemployed individuals",
        "School students aged 15 to 18",
        "University students"
      ]
    }
  },
  "ar": {
    "overview": "نظرة عامة",
    "dashboard": "لوحة التحكم",
    "apply_for_permit": "التقدم بطلب للحصول على تصريح",
    "availability": "التوفر",
    "jobs_board": "لوحة الوظائف",
    "inbox": "صندوق الوارد",
    "bookings": "الحجوزات",
    "settings": "الإعدادات",
    "view_profile": "عرض الملف الشخصي",
    "account_settings": "إعدادات الحساب",
    "logout": "تسجيل الخروج",
    "jobs_applied": "الوظائف التي تقدمت لها",
    "student": "طالب",
    "request": "طلب",
    "date": "التاريخ",
    "status": "الحالة",
    "profile": "الملف الشخصي",
    "my_profile": "ملفي الشخصي",
    "apply_for_jobs": "التقدم للوظائف",
    "apply_license": "التقدم بطلب للحصول على رخصة التدريس الخصوصي في دولة الإمارات العربية المتحدة",
    "private_tutoring_permit": "تصريح التدريس الخصوصي",
    "how_to_apply": "كيف تقدم؟",
    "where_apply": "أين أقدم؟",
    "apply_online_now": "قدم الآن عبر الإنترنت",
    "about_permit": "حول التصريح",
    "permit_cost": "كم يكلف؟",
    "who_eligible": "من المؤهل؟",
    "required_documents": "ما هي المستندات المطلوبة؟",
    "what_if_not_apply": "ماذا لو لم تتقدم؟",
    "work_from_home": "هل يمكنك العمل من المنزل؟",
    "processing_time": "كم من الوقت يستغرق الحصول على تأشيرة المعلم الخاص؟",
    "reapply": "تم رفض طلبي، هل يمكنني إعادة التقديم؟",
    "permit_validity": "كم مدة صلاحية التصريح؟",
    "permit_fees": "ما هي رسوم التصريح؟",
    "work_from_country": "إذا حصلت على ترخيص، هل يمكنني العمل من بلدي؟",
    "online_classes": "هل يمكنني استخدام هذا التصريح لإعطاء دروس عبر الإنترنت؟",
    "monday": "الاثنين",
    "tuesday": "الثلاثاء",
    "wednesday": "الأربعاء",
    "thursday": "الخميس",
    "saturday": "السبت",
    "sunday": "الأحد",
    "save_availability": "حفظ التوفر",
    "about_the_permit_paragraph": "تم إتاحة تصريح لحماية حقوق المعلمين الخصوصيين ولـ\"ضمان حصول الطلاب على التعليم الإضافي الذي يناسب أسلوب تعلمهم واحتياجاتهم\"، بحسب ما قال محمد المعلا، وكيل وزارة التربية والتعليم للشؤون الأكاديمية. ويهدف القرار أيضًا إلى مكافحة الدروس الخصوصية غير القانونية. وأضاف المعلا: \"سيساعد تقديم تصريح للأفراد المؤهلين لتقديم الدروس الخصوصية في الحد من الممارسات غير القانونية وغير المنظمة عند تعيين المعلمين الخصوصيين، مما قد يؤثر على العملية التعليمية ككل\".",
    "what_if_not_apply_paragraph": "أي شخص يقدم دروسًا خصوصية دون تصريح سيكون عرضة للغرامات والعقوبات. لم يتم تأكيد قيمة هذه الغرامات بعد، ولكن عندما كانت الدروس الخصوصية محظورة سابقًا، كان يمكن للمعلمين مواجهة غرامات تصل إلى 50,000 درهم.",
    "work_from_home_paragraph": "يغطي التصريح الواحد التدريس عبر الإنترنت وشخصيًا. يمكن للمعلمين أيضًا العمل من بلدهم الأصلي بشرط أن يكون لديهم إقامة سارية المفعول.",
    "basics": "الأساسيات",
    "country": "الدولة",
    "school": "المدرسة",
    "experience": "الخبرة",
    "travel_time": "مدة السفر",
    "qualifications": "المؤهلات",
    "teaching_subjects": "المواد التي يتم تدريسها",
    "pricing": "التسعير",
    "record_video": "تسجيل فيديو",
    "back": "رجوع",
    "change_picture": "تغيير الصورة",
    "personal_information": "المعلومات الشخصية",
    "first_name": "الاسم الأول",
    "last_name": "اسم العائلة",
    "phone_number": "رقم الهاتف",
    "email": "البريد الإلكتروني",
    "tagline": "الشعار",
    "edit_school": "تعديل المدرسة",
    "choose_school": "يرجى اختيار مدرستك",
    "description": "الوصف",
    "experience_years": "الخبرة (بالسنوات)",
    "postcode": "أدخل الرمز البريدي",
    "travel_to_lesson": "ما المدة التي ترغب في السفر خلالها لإجراء درس حضوري؟",
    "save_changes": "حفظ التغييرات",
    "add_qualification": "+إضافة مؤهل",
    "institution": "المؤسسة",
    "qualification_subject": "المؤهل والمادة",
    "remove": "إزالة",
    "subjects": "المواد",
    "which_subjects": "ما المواد والمستويات التي ترغب في تدريسها؟",
    "save_subjects": "حفظ المواد",
    "hourly_rate": "يرجى إدخال السعر المفضل للساعة",
    "record_or_upload_video": "تسجيل أو رفع فيديو",
    "video_preview": "معاينة الفيديو",
    "current_video": "الفيديو الحالي",
    "start_recording": "بدء التسجيل",
    "watch_demo_video": "مشاهدة فيديو توضيحي",
    "upload_link": "رفع الرابط",
    "select_timezone": "اختر منطقتك الزمنية",
    "bank_details_settings": "إعدادات تفاصيل البنك",
    "pay_to_nadwa": "أدفع لندوة، وندوة تدفع لي شهرياً",
    "get_paid_stripe": "استلام المدفوعات عبر حساب Stripe الخاص بي",
    "notification_settings": "إعدادات الإشعارات",
    "new_message": "عند استلام رسالة جديدة",
    "lesson_cancel": "عندما يلغي العميل درسًا",
    "offers_news": "عند وجود عروض أو أخبار من ندوة",
    "sms_notification": "أرسل لي رسالة نصية عند استلام رسالة جديدة",
    "new_enquiry": "عند استلام استفسار جديد",
    "lesson_accept_reject": "عندما يقبل أو يرفض العميل درسًا",
    "payment_received": "تم الدفع لي",
    "request_a_tutor": "طلب معلم",
    "choose_subject_and_level": "اختر المادة والمستوى",
    "intro_text": "في Hches، ندرك مدى صعوبة العثور على المعلم المناسب. لذلك قررنا جعل الأمر سهلاً! في 3 خطوات بسيطة، يمكنك حجز درس نصي مجاني مع معلم للتأكد من أنه مناسب لك. لنبدأ باختيار المادة والمستوى.",
    "all_levels": "جميع المستويات",
    "level_7_plus": "7+",
    "level_graduate": "الخريجين",
    "level_post_graduate": "الدراسات العليا",
    "level_primary_level": "المرحلة الابتدائية",
    "level_a_level": "المستوى A",
    "level_gcse_igcse": "GCSE/IGCSE",
    "level_ib": "البكالوريا الدولية",
    "level_primary_ks1_ks2": "المرحلة الابتدائية (KS1, KS2)",
    "level_secondary_ks3_ks4": "المرحلة الثانوية (KS3, KS4)",
    "level_ks5": "KS5",
    "level_cycle_1_primary_grade_1_5": "الدورة 1 (ابتدائي، الصف 1-5)",
    "level_cycle_2_preparatory_grade_6_9": "الدورة 2 (إعدادي، الصف 6-9)",
    "level_cycle_3_secondary_grade_10_12": "الدورة 3 (ثانوي، الصف 10-12)",
    "level_undergraduate": "البكالوريوس",
    "level_postgraduate": "الدراسات العليا",
    "button_next": "التالي",
    "button_cancel": "إلغاء",
    "button_back": "رجوع",
    "choose_budget": "اختر الميزانية (اسحب الشريط للتحديد)",
    "price_range": "نطاق السعر لكل ساعة (درهم إماراتي)",
    "free_trial": "درس تجريبي مجاني'أنسب'",
    "availability_selection": "متى تكون متاحًا؟",
    "day_monday": "الإثنين",
    "day_tuesday": "الثلاثاء",
    "day_wednesday": "الأربعاء",
    "day_thursday": "الخميس",
    "day_friday": "الجمعة",
    "day_saturday": "السبت",
    "day_sunday": "الأحد",
    "select_all": "تحديد الكل",
    "clear": "مسح",
    "time_of_day_morning": "الصباح",
    "time_of_day_afternoon": "بعد الظهر",
    "time_of_day_evening": "المساء",
    "tailor_match": "خصص معلمك المثالي",
    "tag_aiming_for_a_grade": "استهداف درجة 'A'",
    "tag_exam_help": "مساعدة في الامتحانات",
    "tag_dyslexia": "عسر القراءة",
    "tag_sen": "الاحتياجات الخاصة",
    "tag_last_minute_revision": "مراجعة اللحظات الأخيرة",
    "tag_essay_writing_skills": "مهارات كتابة المقالات",
    "tag_sibling_support": "دعم الأشقاء",
    "tag_coursework_support": "دعم الأعمال الدراسية",
    "tag_outgoing": "اجتماعي",
    "tag_rational": "منطقي",
    "tag_sensitive": "حساس",
    "tag_efficient": "فعال",
    "tag_emotional": "عاطفي",
    "go_to_dashboard": "الانتقال إلى لوحة التحكم",
    "subsection_art_title": "الفنون",
    "subsection_art_politics": "السياسة",
    "subsection_art_philosophy": "الفلسفة",
    "subsection_art_psychology": "علم النفس",
    "subsection_languages_title": "اللغات",
    "subsection_languages_science": "العلوم",
    "subsection_languages_spanish": "الإسبانية",
    "subsection_languages_greek": "اليونانية",
    "subsection_languages_coding": "البرمجة",
    "subsection_languages_chemistry": "الكيمياء",
    "subsection_languages_business_management": "إدارة الأعمال",
    "subsection_languages_arabic": "العربية",
    "subsection_languages_accounts": "المحاسبة",
    "subsection_languages_computer": "الحاسوب",
    "subsection_languages_computer_science": "علوم الحاسوب",
    "subsection_languages_economics": "الاقتصاد",
    "subsection_languages_finances": "التمويل",
    "subsection_languages_french": "الفرنسية",
    "subsection_languages_history": "التاريخ",
    "subsection_languages_german": "الألمانية",
    "subsection_languages_income_tax_law": "قانون الضرائب",
    "subsection_languages_islamic_education": "التربية الإسلامية",
    "subsection_languages_italian": "الإيطالية",
    "subsection_languages_java": "جافا",
    "subsection_languages_law": "القانون",
    "subsection_languages_management": "الإدارة",
    "subsection_languages_marketing": "التسويق",
    "subsection_languages_maths": "الرياضيات",
    "subsection_languages_personal_development": "التطوير الشخصي",
    "subsection_languages_physics": "الفيزياء",
    "subsection_languages_accounting": "المحاسبة",
    "subsection_languages_english": "الإنجليزية",
    "subsection_languages_social_sciences": "العلوم الاجتماعية",
    "subsection_languages_chinese": "الصينية",
    "subsection_languages_mandarin": "الماندرين",
    "subsection_languages_biology": "الأحياء",
    "subsection_languages_geography": "الجغرافيا",
    "subsection_languages_korean": "الكورية",
    "subsection_languages_turkish": "التركية",
    "subsection_music_title": "الموسيقى",
    "subsection_music_music_practical": "التطبيقات الموسيقية",
    "subsection_music_music_theory": "النظريات الموسيقية",
    "subsection_sport_title": "الرياضة",
    "subsection_sport_yoga": "اليوغا",
    "popup_apply_for_job_comment": "Apply_for_Job_popup",
    "popup_apply_for_job_title": "طلبك لهذا الوظيفة",
    "popup_apply_for_job_description": "وظيفة النشر الفريدة لدينا تسمح للمعلمين بالتقدم للوظائف التي تناسبهم. ابحث واتصل بالطلاب بسهولة من خلال نادوا.",
    "popup_apply_for_job_send_message": "إرسال رسالة",
    "popup_apply_for_job_type_message_here": "اكتب رسالتك هنا",
    "popup_apply_for_job_back": "رجوع",
    "popup_apply_for_job_submit_application": "إرسال طلب الوظيفة",
    "popup_jobs_board_comment": "Jobs_Board_popup",
    "popup_jobs_board_description": "يقوم العملاء بنشر مئات الوظائف كل أسبوع مع نادوا، لذلك نشجعك على التقدم لأكبر عدد ممكن من الوظائف التي ترغب فيها. بمجرد أن تتقدم لوظيفة، سيتم إرسال ملفك الشخصي إلى العميل، الذي سيقوم بالتواصل معك إذا رغب في العمل معك. فقط لعلمك، يمكن لما يصل إلى خمسة معلمين آخرين التقدم لنفس الوظيفة (لذلك لا تيأس إذا لم تتلقى ردًا من العميل). لتحسين فرص اختيارك، نوصي بما يلي: الرد سريعًا على العميل؛ العملاء يحبون المعلمين الذين يعودون إليهم في أسرع وقت ممكن. قم بتحسين ملفك الشخصي؛ تأكد من تسويق خبرتك وقصص نجاحك السابقة. تأكد من أن صورتك تبدو رائعة وأن شعارك مفيد.",
    "popup_jobs_board_availability": "التوافر",
    "popup_jobs_board_apply_for_this_job": "التقدم لهذه الوظيفة",
    "popup_jobs_board_min_price_per_hour": "الحد الأدنى للسعر / ساعة",
    "popup_jobs_board_max_price_per_hour": "الحد الأقصى للسعر / ساعة",
    "popup_jobs_board_apply_for_job": "التقدم للوظيفة",
    "popup_reschedule_lesson_comment": "Reschedule_Lesson_popup",
    "popup_reschedule_lesson_title": "إعادة جدولة الدرس مع الطالب؟",
    "popup_reschedule_lesson_important_limitation": "مهم: قيود إعادة الجدولة",
    "popup_reschedule_lesson_select_date": "اختر التاريخ",
    "popup_reschedule_lesson_start_time": "وقت البدء",
    "popup_reschedule_lesson_end_time": "وقت الانتهاء",
    "popup_reschedule_lesson_cancel": "إلغاء",
    "popup_reschedule_lesson_reschedule": "إعادة الجدولة",
    "popup_book_lesson_comment": "Book_Lesson_popup",
    "popup_book_lesson_student": "طالب",
    "popup_book_lesson_select_from_your_students": "اختر من بين طلابك",
    "popup_book_lesson_subject": "المادة",
    "popup_book_lesson_select_a_subject": "اختر مادة",
    "popup_book_lesson_select_a_date": "اختر تاريخ",
    "popup_book_lesson_select_duration": "اختر المدة",
    "popup_book_lesson_1_hour": "ساعة واحدة",
    "popup_book_lesson_90_minutes": "90 دقيقة",
    "popup_book_lesson_2_hours": "ساعتان",
    "popup_book_lesson_number_of_weeks_same_time_day_every_week": "عدد الأسابيع (نفس الوقت/اليوم كل أسبوع)",
    "popup_book_lesson_number_of_lessons": "عدد الدروس",
    "popup_book_lesson_start_time": "وقت البدء",
    "popup_book_lesson_select_start_time": "اختر وقت البدء",
    "popup_book_lesson_end_time": "وقت الانتهاء",
    "popup_book_lesson_select_end_time": "اختر وقت الانتهاء",
    "popup_book_lesson_cancel": "إلغاء",
    "popup_book_lesson_book_lesson": "حجز الدرس",
    "popup_cancel_lesson_comment": "Cancel_Lesson_popup",
    "popup_cancel_lesson_title": "إلغاء الدرس مع الطالب",
    "popup_cancel_lesson_start_time": "وقت البدء",
    "popup_cancel_lesson_end_time": "وقت الانتهاء",
    "popup_cancel_lesson_back": "رجوع",
    "popup_cancel_lesson_cancel_lesson": "إلغاء الدرس",
    "tutor_applications": "طلبات المعلم",
    "browse_other_tutors": "تصفح معلمين آخرين",
    "tutor": "معلم",
    "message": "رسالة",
    "action": "الإجراء",
    "find_a_tutor": "ابحث عن معلم",
    "posted_jobs": "الوظائف المنشورة",
    "log_out": "تسجيل الخروج",
    "edit_profile": "تعديل الملف الشخصي",
    "good_evening_student": "مساء الخير أيها الطالب",
    "your_schedule": "جدول مواعيدك",
    "type": "نوع",
    "lesson": "درس",
    "enter_tutor_name": "أدخل اسم المعلم",
    "search": "بحث",
    "subject": "الموضوع",
    "all_subjects": "كل المواد",
    "level": "المستوى",
    "countries": "الدول",
    "all_countries": "جميع الدول",
    "reset_now": "إعادة التعيين الآن",
    "search_results": "نتائج البحث",
    "tutoring_in_person": "الدروس: التدريس الشخصي",
    "tutoring_online": "الدروس: عبر الإنترنت",
    "per_hour": "لكل ساعة",
    "including_vat": "شامل ضريبة القيمة المضافة",
    "years_of_experience": "سنوات من الخبرة",
    "messages": "الرسائل",
    "latest_messages": "أحدث الرسائل",
    "archived": "المؤرشف",
    "archive": "أرشيف",
    "search_chat": "بحث في الدردشة",
    "upcoming_lessons": "الدروس القادمة",
    "trial_call": "مكالمة تجريبية",
    "past_lessons": "الدروس السابقة",
    "online": "عبر الإنترنت",
    "confirmed": "تم التأكيد",
    "unconfirmed": "غير مؤكد",
    "lesson_completed": "تم الانتهاء من الدرس",
    "send_a_message": "إرسال رسالة",
    "cancel_lesson": "إلغاء الدرس",
    "change_lesson": "تغيير الدرس",
    "here_are_the_jobs_you_have_posted": "هنا الوظائف التي قمت بنشرها",
    "applications_so_far": "عدد الطلبات حتى الآن",
    "delete_job_post": "حذف إعلان الوظيفة",
    "view_job": "عرض الوظيفة",
    "select_your_time_zone": "حدد منطقتك الزمنية",
    "when_i_receive_a_new_message": "عندما أستلم رسالة جديدة",
    "tutor_cancels_a_lesson": "يقوم المعلم بإلغاء درس",
    "any_offers_or_news_from_nadwa": "يوجد أي عروض أو أخبار من ندوى",
    "send_me_an_sms_when_i_receive_a_new_message": "أرسل لي رسالة نصية عندما أستلم رسالة جديدة",
    "payment_is_made_to_a_tutor": "تم دفع المبلغ للمعلم",
    "i_receive_a_lesson_invitation_from_a_tutor": "أستلم دعوة درس من معلم",
    "about_nadwa": "عن ندوى",
    "how_it_works": "كيف تعمل",
    "blogs": "المدونات",
    "copyright": "حقوق النشر © 2024، جميع الحقوق محفوظة.",
    "terms_and_conditions": "الشروط والأحكام",
    "privacy_policy": "سياسة الخصوصية",
    "home": "الرئيسية",
    "about_us": "من نحن",
    "join_to_learn": "انضم للتعلم",
    "contact_us": "تواصل معنا",
    "book_now": "احجز الآن",
    "language": "اللغة",
    "login": "تسجيل الدخول",
    "achieve_academic_excellence": "حقق التفوق الأكاديمي مع معلمين موثوقين من الإمارات والمملكة المتحدة",
    "customized_learning_experiences": "تجارب تعليمية مخصصة، عبر الإنترنت أو بالحضور الشخصي، تتماشى مع تفضيلاتك التعليمية.",
    "discover_more": "اكتشف المزيد",
    "online_in_person_sessions": "جلسات فردية أو جماعية - عبر الإنترنت أو بالحضور الشخصي",
    "one_small_step": "خطوة صغيرة لرواد التعليم، خطوة كبيرة للطلاب وأولياء الأمور",
    "nadwa_mission": "تأسست ندوى بشغف للتعلم والتزام بالجودة، لربط الطلاب بمعلمين خبراء في مختلف التخصصات.",
    "our_vision": "رؤيتنا هي إحداث ثورة في نظام التعليم من خلال توفير دروس عالية الجودة لكل الطلاب، بغض النظر عن خلفياتهم أو ظروفهم.",
    "hire_a_tutor": "استأجر معلمًا",
    "why_nadwa": "لماذا معلموا ندوى؟",
    "years_experience": "سنوات الخبرة",
    "create_student_account": "إنشاء حساب طالب",
    "telephone": "رقم الهاتف",
    "password": "كلمة المرور",
    "register": "تسجيل",
    "follow_us": "تابعنا",
    "phone": "الهاتف",
    "schedule_meeting": "حدد موعدًا",
    "where_do_i_apply_paragraph": "يمكنك التقديم عبر الإنترنت. العملية بسيطة:\n1. أدخل رقم الهوية الإماراتية الخاص بك ثم اضغط على 'إرسال رمز التحقق' (كلمة المرور لمرة واحدة).\n- سيعرض لك النظام بعض التفاصيل من ملفك لدى الوزارة، بما في ذلك الاسم الكامل، الجنسية، تاريخ الميلاد ورقم الهاتف.\n2. سيكون لديك أربع فئات يمكنك التقديم من خلالها للحصول على التصريح:\n- فئة الطلاب (طالب جامعي/طالب مدرسي)\n- فئة العاطلين عن العمل\n- فئة العاملين في مختلف القطاعات\n- معلم مسجل في مدرسة حكومية أو خاصة\n3. اختر الفئة المناسبة لك وقم برفع المستندات المطلوبة.\n4. قم بتنزيل وثيقة 'مدونة السلوك' بعد ذلك، ووقعها ورفع نسخة رقمية لإرفاقها مع طلبك.\n5. اضغط على 'إرسال'.",
    "how_long_does_it_take_to_get_the_private_tutor_visa": "سيتم معالجة الطلب خلال خمسة أيام عمل.",
    "it_has_been_more_than_5_days_and_i_have_not_heard_anything": "يمكنك التواصل مع وزارة الموارد البشرية والتوطين عبر موقع الدعم الخاص بهم - أو بالاتصال على الرقم 600590000.",
    "my_application_was_declined_may_i_reapply": "نعم، ولكن فقط بعد مرور ستة أشهر.",
    "how_long_is_the_permit_valid_for": "بمجرد الموافقة على طلبك، سيكون التصريح صالحًا لمدة عامين، ويجب تجديده وفقًا لذلك.",
    "what_are_the_permit_fees": "طلب التصريح مجاني تمامًا.",
    "if_i_get_a_license_can_i_work_from_my_home_country": "وفقًا لوزارة الموارد البشرية والتوطين، هذا الخيار متاح أيضًا للمقيمين بشرط أن تكون تأشيرتك الإماراتية سارية المفعول، وأن تلتزم بالاتفاقيات بين الطرفين.",
    "can_i_use_this_permit_to_take_online_classes": "وفقًا لوزارة الموارد البشرية والتوطين، يغطي هذا التصريح الدروس عبر الإنترنت والحضور الشخصي.",
    "what_documents_do_you_need": {
      "intro": "تختلف المستندات المطلوبة حسب وضعك الحالي وحالتك.",
      "sections": {
        "A": {
          "title": "إذا كنت طالبًا (طالب جامعي/طالب مدرسي) تحتاج إلى:",
          "items": [
            "شهادة استمرارية الدراسة لطالب في المرحلة الثانوية/توضيح أن الطالب مسجل في الجامعة",
            "آخر شهادة أكاديمية للطالب",
            "شهادة عدم ممانعة من ولي الأمر",
            "شهادة حسن سيرة وسلوك",
            "شهادة اللياقة الطبية",
            "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
            "صورة شخصية واضحة بخلفية بيضاء"
          ]
        },
        "B": {
          "title": "إذا كنت عاطلًا عن العمل ولست طالبًا تحتاج إلى:",
          "items": [
            "أحدث شهادة أكاديمية",
            "شهادة حسن سيرة وسلوك",
            "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
            "شهادة اللياقة الطبية",
            "شهادة خبرة (إذا كانت متوفرة)",
            "صورة شخصية واضحة بخلفية بيضاء"
          ]
        },
        "C": {
          "title": "إذا كنت موظفًا ولا تعمل في مجال التعليم بشكل مباشر:",
          "items": [
            "أحدث شهادة أكاديمية",
            "شهادة حسن سيرة وسلوك",
            "شهادة عدم ممانعة من جهة العمل",
            "شهادة اللياقة الطبية",
            "شهادة خبرة (إذا كانت متوفرة)",
            "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
            "صورة شخصية واضحة بخلفية بيضاء"
          ]
        },
        "D": {
          "title": "إذا كنت معلمًا مسجلًا في مدرسة حكومية أو خاصة:",
          "items": [
            "شهادة حسن سيرة وسلوك",
            "شهادة عدم ممانعة من جهة العمل",
            "شهادة اللياقة الطبية",
            "شهادة خبرة (إن وجدت)",
            "وثائق تعريفية سارية (جواز سفر/تأشيرة إقامة/هوية إماراتية)",
            "صورة شخصية واضحة بخلفية بيضاء"
          ]
        }
      }
    },
    "how_much_does_it_cost": "التصريح مجاني وصالح لمدة عامين، وبعد ذلك يمكن لحامليه التقدم بطلب لتجديده. بدلاً من دفع رسوم، يُطلب من المعلمين التوقيع على وثيقة قواعد السلوك المعتمدة من وزارة الموارد البشرية والتوطين.",
    "who_is_eligible": "يتيح التصريح الجديد \"لمجموعات مختلفة من المهنيين المتخصصين والمؤهلين من المجتمع تقديم دروس خصوصية للطلاب، بشكل فردي أو ضمن مجموعات\"، وفقًا لوزارة الموارد البشرية والتوطين.",
    "who_can_apply": {
      "description": "يمكن لأي شخص فوق سن 15 عامًا التقديم، وذلك بعد الحصول على الموافقة:",
      "list": [
        "المعلمون المسجلون في المدارس الحكومية أو الخاصة",
        "الموظفون في القطاعين الحكومي والخاص",
        "الأفراد العاطلون عن العمل",
        "طلاب المدارس الذين تتراوح أعمارهم بين 15 و18 عامًا",
        "طلاب الجامعات"
      ]
    }
  }
}