/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import { TriangleAlert, X } from "lucide-react";
import axios from "axios";
import BookingPopup from "../TutorBookings/BookLesson";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

type Subject = {
  id: number;
  name: string;
};

const BookLessonInboxModal = ({
  isOpen,
  onClose,
  data,
}: {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}) => {
  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [duration, setDuration] = useState("1 Hour");
  const [numberOfLessons, setNumberOfLessons] = useState("1 Lesson");
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [date, setDate] = useState(getCurrentDate()); // Initialize with current date
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState<any>(null);
  const [isLessonModalOpen, setIsBookLessonModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation();
  const toggleBookLessonModal = () => {
    setIsBookLessonModalOpen(!isLessonModalOpen);
  };

  const generateTimeOptions = () => {
    let options = [];
    for (let hour = 6; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute+=15) {
        const formattedMinute = minute.toString().padStart(2, "0");
        const time = `${hour.toString().padStart(2, "0")}:${formattedMinute}`;
        options.push(
          <option key={time} value={time}>
            {time}
          </option>
        );
      }
    }
    return options;
  };

  // Form validation state
  const isFormValid = date && startTime && endTime && selectedSubject;

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setSubjects(response.data.subjects);
      } catch (err) {
        console.log(err);
      }
    };
    fetchSubjects();
  }, [isOpen]);

  // Reset date to current date when modal opens
  useEffect(() => {
    if (isOpen) {
      setDate(getCurrentDate());
    }
  }, [isOpen]);

  const calculateEndTime = (start: string, duration: string) => {
    const [hours, minutes] = start.split(":").map(Number);
    let endHours = hours;
    let endMinutes = minutes;

    if (duration === "1 Hour") {
      endHours += 1;
    } else if (duration === "90 Minutes") {
      endMinutes += 90;
      if (endMinutes >= 60) {
        endMinutes -= 60;
        endHours += 1;
      }
    } else if (duration === "2 Hours") {
      endHours += 2;
    }

    // Ensuring the endMinutes and endHours are within bounds
    if (endMinutes >= 60) {
      endMinutes -= 60;
      endHours += 1;
    }
    if (endHours >= 24) {
      endHours -= 24;
    }

    return `${endHours.toString().padStart(2, "0")}:${endMinutes
      ?.toString()
      .padStart(2, "0")}`;
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newStartTime = e.target.value;
    setStartTime(newStartTime);
    const calculatedEndTime = calculateEndTime(newStartTime, duration);
    setEndTime(calculatedEndTime); // Automatically calculate end time
  };

  const handleDurationChange = (durationOption: string) => {
    setDuration(durationOption);
    if (startTime) {
      const newEndTime = calculateEndTime(startTime, durationOption);
      setEndTime(newEndTime); // Recalculate the end time whenever the duration changes
    }
  };
  const handleBookLesson = () => {
    if (!isFormValid) return;

    const selectedSubjectObj = subjects.find(
      (subject) => subject.name === selectedSubject
    );

    const startDateTime = new Date(`${date}T${startTime}`);
    const endDateTime = new Date(`${date}T${endTime}`);
    const dates = new Date(date);

    const lessonDay = startDateTime.toLocaleString("en-US", {
      weekday: "long",
    });
    const lessons = [
      {
        numberOfLessons,
        startTime,
        day: lessonDay,
        date: dates,
      },
    ];

    const numWeeks = parseInt(numberOfLessons.split(" ")[0]);
    // Store selected booking data using customer info from props
    setBookingData({
      customer: data.customer.id,
      end_time: endDateTime.toISOString(),
      location: "online",
      start_time: startDateTime.toISOString(),
      subject: selectedSubjectObj?.id,
      tutor: data.tutor.id,
      lessons,
      num_weeks: numWeeks,
    });

    // Open the BookingPopup
    toggleBookLessonModal();
  };

  if (!isOpen) return null;

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const lessonOptions = [
    "1 Lesson",
    "5 Lessons",
    "10 Lessons",
    "15 Lessons",
    "20 Lessons",
  ];

  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 bg-gray-950 bg-opacity-50 z-50 flex items-center justify-center p-2 sm:p-4"
    >
      <div className="bg-white rounded-lg w-full max-w-xl p-4 sm:p-6 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4 sm:mb-6">
          <h2 className="text-xl sm:text-2xl font-semibold">
            {t("book_a_lesson")} {i18n.language == "en" && "with"} <span>{data.customer.first_name}</span>
          </h2>
          <button
            onClick={onClose}
            className="text-gray-400 cursor-pointer hover:text-gray-600"
          >
            <X size={20} />
          </button>
        </div>

        <div className="space-y-4 sm:space-y-6">
          {/* Subject Selection */}
          <div>
            <label className="block text-base sm:text-lg font-medium mb-2">
              {t("subject")}
            </label>
            <div className="relative">
              <select
                className="w-full p-2 border rounded-md appearance-none pr-10 text-sm sm:text-base text-gray-500"
                value={selectedSubject}
                onChange={(e) => setSelectedSubject(e.target.value)}
              >
                <option value="">{t("select_subject")}</option>
                {subjects.map((subject: any) => (
                  <option key={subject.id} value={subject.name}>
                    {subject.name}{" "}
                    {subject.price_in_cents
                      ? `  AED ${(subject.price_in_cents / 100).toFixed(
                        2
                      )} /hour`
                      : null}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Date Selection */}
          <div>
            <label className="block text-base sm:text-lg font-medium mb-2">
              {t("select_a_date")}
            </label>
            <div>
              <input
                type="date"
                className="w-full p-2 border rounded-md pr-10 text-sm sm:text-base"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                min={getCurrentDate()}
                onFocus={(e) => e.target.showPicker()}
              />
            </div>
          </div>

          {/* Duration Selection */}
          <div>
            <label className="block text-base sm:text-lg font-medium mb-2">
              {t("select_duration")}
            </label>
            <div className="flex gap-2 flex-wrap justify-start">
              {[t("one_hour"), t("ninety_minutes"), t("two_hours")].map((durationOption) => (
                <button
                  key={durationOption}
                  className={`px-3 sm:px-4 py-1 text-sm sm:text-base rounded ${duration === durationOption
                    ? "bg-[#008847] text-white"
                    : "bg-green-50 border-1 border-[#008847] text-[#008847]"
                    }`}
                  onClick={() => {
                    handleDurationChange(durationOption);
                    setDuration(durationOption);
                    const newEndTime = calculateEndTime(
                      startTime,
                      durationOption
                    );
                    setEndTime(newEndTime);
                  }}
                >
                  {durationOption}
                </button>
              ))}
            </div>
          </div>

          {/* Lesson Selection */}
          <div>
            <label className="block text-base sm:text-lg font-medium mb-2">
              {t("number_lessons")}
            </label>
            <div className="relative">
              <select
                className="w-full p-2 border rounded-md appearance-none pr-10 text-sm sm:text-base text-gray-500"
                value={numberOfLessons}
                onChange={(e) => setNumberOfLessons(e.target.value)}
              >
                {lessonOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex items-center gap-2 relative">
            <h3 className="text-base sm:text-lg font-medium mb-2">
              {t("number_weeks")}
            </h3>

            {/* Wrapper for Icon + Tooltip to prevent onMouseLeave issue */}
            <div
              className="relative flex items-center"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <TriangleAlert className="w-5 h-5 text-[#008847] mb-2 cursor-pointer" />

              {/* Tooltip */}
              {showTooltip && (
                <div className="absolute right-2 -top-12 bg-[#008847] text-white text-xs p-2 rounded-md shadow-lg w-48">
                  We recommend 10 lessons to get started - why not suggest this
                  to your student...
                  <div className="absolute right-4 -bottom-2 w-3 h-3 bg-[#008847] rotate-45"></div>
                </div>
              )}
            </div>
          </div>

          {/* Time Selection */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-base sm:text-lg font-medium mb-2">
                {t("start_time")}
              </label>
              <div className="relative">
                <select
                  className="w-full p-2 border rounded-md pr-10 text-sm sm:text-base"
                  value={startTime}
                  onChange={(e: any) => handleStartTimeChange(e)}
                >
                  <option value="">{t("select_start_time")}</option>
                  {generateTimeOptions()}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-base sm:text-lg font-medium mb-2">
                {t("end_time")}
              </label>
              <div className="relative">
                <select
                  className="w-full p-2 border rounded-md pr-10 text-sm sm:text-base cursor-not-allowed"
                  value={endTime}
                  disabled
                >
                  <option value="">{t("select_end_time")}</option>
                  {generateTimeOptions()}
                </select>
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className="flex justify-end gap-3 mt-4 sm:mt-6">
            <button
              onClick={onClose}
              className="w-full sm:w-auto py-2 px-4 text-sm sm:text-base font-semibold border border-gray-300 rounded-md text-gray-700 hover:bg-gray-100"
            >
              {t("cancel")}
            </button>
            <button
              onClick={handleBookLesson}
              className={`w-full sm:w-auto py-2 px-4 text-sm sm:text-base font-semibold rounded-md text-white ${isFormValid
                ? "bg-[#008847] hover:bg-[#006c36]"
                : "bg-gray-400 cursor-not-allowed"
                }`}
              disabled={!isFormValid}
            >
              {isLoading ? "Booking..." : t("book_lesson")}
            </button>
          </div>
        </div>
      </div>

      {/* Booking Popup */}
      {isLessonModalOpen && (
        <BookingPopup
          data={data}
          bookingData={bookingData}
          onClose={toggleBookLessonModal}
          onCloseBack={onClose}
        />
      )}
    </div>
  );
};

export default BookLessonInboxModal;
