import axios from "axios";
import { Search, Send } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { MutatingDots } from "react-loader-spinner";
import BookingInterface from "../AboutTutorComponents/bookingPart1";
import BookingInterface2 from "../AboutTutorComponents/InstantBooking";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
export default function InboxMessages({
  chatId,
  setActiveTab,
  setUnreadMessage,
  unreadMessage,
}: any) {
  const [activeTab, setActiveTab2] = useState("latest");
  const [allMessages, setAllMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<any>(null);
  const [newMessage, setNewMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [tutorData, setTutorData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [messageId, setMessageId] = useState<null | number>(null);
  const [instant, setInstant] = useState(false);
  const chatEndRef = useRef<any>(null);
  const { t } = useTranslation();
  const [noarchived, setNoarchived] = useState<any>(null);
  const [searchMessage, setSearchMessage] = useState<any>("");

  useEffect(() => setUnreadMessage(null), [unreadMessage]);

  const fetchTutorData = async (tutorId: any) => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/${tutorId}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setTutorData(res.data);
      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTutorData2 = async (tutorId: any) => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/${tutorId}/`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setTutorData(res.data);
      setInstant(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Function to fetch and sort all messages
  const getAllMessages = async () => {
    try {
      setLoading(true);
      const res = await axios.get("https://api.nadwa-uae.com/api/v2/chat", {
        headers: {
          Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
        },
      });

      // Sort by unread_count first, then by last_message.created
      const sortedMessages = res.data.sort((a: any, b: any) => {
        if ((b.unread_count || 0) !== (a.unread_count || 0)) {
          return (b.unread_count || 0) - (a.unread_count || 0); // Prioritize unread
        }
        const aTime = a.last_message
          ? new Date(a.last_message.created).getTime()
          : new Date(a.created).getTime();
        const bTime = b.last_message
          ? new Date(b.last_message.created).getTime()
          : new Date(b.created).getTime();
        return bTime - aTime; // Sort by most recent last message
      });

      setAllMessages(sortedMessages);

      if (!chatId && !messageId && res.data.length > 0) {
        const firstNonArchivedMessage = res.data.find(
          (msg: any) => !msg.is_archived
        );
        if (firstNonArchivedMessage) {
          setNoarchived(firstNonArchivedMessage);
          singleMessage(firstNonArchivedMessage.id);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllMessages();
  }, []);

  const singleMessage = async (id: number) => {
    try {
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/chat/${id || chatId}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      setMessage(res.data);

      if (res.data.unread_count > 0) {
        await axios.post(
          `https://api.nadwa-uae.com/api/v2/chat/${id}/mark_messages_as_read/`,
          {},
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
            },
          }
        );
        setAllMessages((prevMessages) =>
          prevMessages.map((mess) =>
            mess.id === id ? { ...mess, unread_count: 0 } : mess
          )
        );
      }

      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    if (chatId) singleMessage(chatId);
  }, [chatId]);

  const postMessage = async (e: any) => {
    e.preventDefault();

    if (!newMessage.trim()) return;

    const newMsg = {
      id: Date.now(),
      chat: message.id,
      content: newMessage,
      created_by: message.customer.id,
      type: "text",
    };

    setMessage((prev: any) => ({
      ...prev,
      messages: [...prev.messages, newMsg],
    }));

    setNewMessage("");

    try {
      await axios.post(
        "https://api.nadwa-uae.com/api/v2/chat/messages/",
        {
          chat: message.id,
          content: newMessage,
          socket_id: "",
          type: "text",
        },
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );

      // Refetch all messages to update the list order
      await getAllMessages();
      singleMessage(message.id);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const AchivedSet = async (id: number) => {
    try {
      await axios.post(
        `https://api.nadwa-uae.com/api/v2/chat/${id}/archive/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setAllMessages((prevMessages) =>
        prevMessages.map((mess: any) =>
          mess.id === id ? { ...mess, is_archived: true } : mess
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const UnAchivedSet = async (id: number) => {
    try {
      await axios.post(
        `https://api.nadwa-uae.com/api/v2/chat/${id}/unarchive/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}`,
          },
        }
      );
      setAllMessages((prevMessages) =>
        prevMessages.map((mess: any) =>
          mess.id === id ? { ...mess, is_archived: false } : mess
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message?.messages]);

  const isValidDate = (dateString: any) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  return (
    <div className="relative h-auto">
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface
              setActiveTab={setActiveTab}
              showModal={showModal}
              setShowModal={setShowModal}
              tutorData={tutorData}
            />
          </div>
        </div>
      )}
      {instant && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/70 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative w-[90%]">
            <BookingInterface2
              setActiveTab={setActiveTab}
              showModal={showModal}
              setInstant={setInstant}
              tutorData={tutorData}
            />
          </div>
        </div>
      )}
      <div className="w-full bg-white py-1 rounded-t-[20px] px-10">
        <h2 className="text-3xl mt-3">{t("inbox_page.messages")}</h2>
        <hr className="w-full absolute left-0 border-black top-12" />
      </div>
      <div className="flex bg-gray-50 border-none h-screen flex-col overflow-y-auto lg:flex-row gap-10 lg:gap-0">
        <div className="lg:w-[25%] rounded-l-[20px] bg-white pt-6 flex flex-col w-full">
          <div className="p-3 overflow-y-scroll">
            <div className="flex border-b mb-4">
              <button
                onClick={() => setActiveTab2("latest")}
                className={`pb-2 px-4 text-sm font-medium ${activeTab === "latest"
                    ? "text-green-600 border-b-2"
                    : "text-gray-500"
                  }`}
              >
                {t("inbox_page.latest_messages")}
              </button>
              <button
                onClick={() => setActiveTab2("achieved")}
                className={`pb-2 px-4 text-sm font-medium ${activeTab === "achieved"
                    ? "text-green-600 border-b-2"
                    : "text-gray-500"
                  }`}
              >
                {t("inbox_page.archive")}
              </button>
            </div>
            <div className={`relative mb-4`}>
              <input
                type="text"
                placeholder={t("inbox_page.search_chat")}
                value={searchMessage}
                onChange={(e) => setSearchMessage(e.target.value)}
                className={`w-full pl-4 py-3 bg-transparent border border-gray-300 rounded-xl text-sm ${i18n.language == "ar" && "px-8"
                  }`}
              />
              <Search className="w-4 h-4 absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
            {loading ? (
              <div className="flex justify-center items-center">
                <MutatingDots
                  visible={true}
                  height="100"
                  width="100"
                  color="#4fa94d"
                  secondaryColor="#4fa94d"
                  radius="12.5"
                  ariaLabel="mutating-dots-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              </div>
            ) : (
              <div className="space-y-4">
                {allMessages.length > 0 ? (
                  allMessages
                    .filter(
                      (mess) =>
                        mess.tutor?.first_name
                          ?.toLowerCase()
                          .includes(searchMessage?.toLowerCase() || "") || ""
                    )
                    .map(
                      (mess: any) =>
                        !mess.is_archived &&
                        activeTab === "latest" && (
                          <div
                            key={mess.id}
                            onClick={() => {
                              singleMessage(mess.id);
                              setMessageId(mess.id);
                            }}
                            className={`p-3 bg-[#0088470D] border-2 rounded-xl cursor-pointer 
                            ${(messageId === mess.id ||
                                (!messageId && mess.id === noarchived?.id)) &&
                              "border-2 border-[#008847] scale-105"
                              }`}
                          >
                            <div className="flex gap-3 mb-2 flex-col relative">
                              <div className="flex gap-2">
                                <picture>
                                  <source
                                    srcSet={mess.tutor.photo}
                                    type="image/webp"
                                  />
                                  <img
                                    src={
                                      mess.tutor.photo?.replace(
                                        ".webp",
                                        ".jpg"
                                      ) || "fallback.jpg"
                                    }
                                    alt="img"
                                    className="w-10 h-10 rounded-full"
                                  />
                                </picture>
                                <h3 className="font-medium text-xl uppercase">
                                  {mess.tutor.first_name}
                                </h3>
                              </div>
                              <div className="flex items-center gap-2 flex-col">
                                <div className="flex gap-2 mt-2 justify-start">
                                  {!mess.is_trialed && (
                                    <button
                                      onClick={() => {
                                        fetchTutorData(mess.tutor.id);
                                      }}
                                      className="px-2 py-1 bg-[#008847] text-white text-xs rounded"
                                    >
                                      {i18n.language == "en" ? "Free Fit Trial" : "تجربة مجانية لللياقة"}
                                    </button>
                                  )}
                                  <button
                                    onClick={() => {
                                      fetchTutorData2(mess.tutor.id);
                                    }}
                                    className="px-2 py-1 bg-[#ED1D24] text-white text-xs rounded"
                                  >
                                    {t("instantbook")}
                                  </button>
                                </div>
                                <button
                                  className="w-full mt-2 py-1 border border-[#008847] rounded text-sm bg-[#0088471A]"
                                  onClick={() => AchivedSet(mess.id)}
                                >
                                  {t("inbox_page.archive")}
                                </button>
                                {mess.unread_count > 0 && (
                                  <span
                                    className={`absolute top-2  ${i18n.language == "en" ? "right-2" : "left-2"}   bg-red-500 text-white text-xs px-2 py-1 rounded-full`}>
                                    {mess.unread_count}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                    )
                ) : (
                  <p className="flex justify-center text-gray-300">
                    {i18n.language == "en" ? "No Messages" : "لا رسائل"}
                  </p>
                )}

                {allMessages.length > 0 ? (
                  allMessages
                    .filter(
                      (mess) =>
                        mess.tutor?.first_name
                          ?.toLowerCase()
                          .includes(searchMessage?.toLowerCase() || "") || ""
                    )
                    .map(
                      (mess: any) =>
                        mess.is_archived &&
                        activeTab === "achieved" && (
                          <div
                            key={mess.id}
                            onClick={() => {
                              singleMessage(mess.id);
                              setMessageId(mess.id);
                            }}
                            // className={`p-3 ${message &&
                            //   messageId === mess.id &&
                            //   "border-2 border-[#008847] scale-105"
                            //   } bg-[#0088470D] border-2 rounded-xl cursor-pointer`}
                            className={`p-3 bg-[#0088470D] border-2 rounded-xl cursor-pointer 
                            ${(messageId === mess.id ||
                                (!messageId && mess.id === noarchived?.id)) &&
                              "border-2 border-[#008847] scale-105"
                              }`}
                          >
                            <div className="flex gap-3 mb-2 flex-col relative">
                              <div className="flex gap-2">
                                <picture>
                                  <source
                                    srcSet={mess.tutor.photo}
                                    type="image/webp"
                                  />
                                  <img
                                    src={
                                      mess.tutor.photo?.replace(
                                        ".webp",
                                        ".jpg"
                                      ) || "fallback.jpg"
                                    }
                                    alt="img"
                                    className="w-10 h-10 rounded-full"
                                  />
                                </picture>
                                <h3 className="font-medium text-xl">
                                  {mess.tutor.first_name}
                                </h3>
                              </div>
                              <div className="flex items-center gap-2 flex-col">
                                <div className="flex gap-2 mt-2">
                                  {!mess.is_trialed && (
                                    <button
                                      onClick={() => {
                                        fetchTutorData(mess.tutor.id);
                                      }}
                                      className="px-2 py-1 bg-[#008847] text-white text-xs rounded"
                                    >
                                      {i18n.language == "en" ? "Free Fit Trial" : "تجربة مجانية لللياقة"}
                                    </button>
                                  )}
                                  <button
                                    onClick={() => {
                                      fetchTutorData2(mess.tutor.id);
                                    }}
                                    className="px-2 py-1 bg-[#ED1D24] text-white text-xs rounded"
                                  >
                                    {t("instantbook")}
                                  </button>
                                </div>
                                <button
                                  className="w-full mt-2 py-1 border border-[#008847] rounded text-xs bg-[#0088471A]"
                                  onClick={() => UnAchivedSet(mess.id)}
                                >
                                  {i18n.language == "en" ? "UnArchive" : "إلغاء الأرشفة"}
                                </button>
                                {mess.unread_count > 0 && (
                                  <span
                                    className={`absolute top-2 ${i18n.language == "en" ? "right-2" : "left-2"}  bg-red-500 text-white text-xs px-2 py-1 rounded-full`}>
                                    {mess.unread_count}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                    )
                ) : (
                  <p className="flex justify-center text-gray-300">
                    {i18n.language == "en" ? "No Messages" : "لا رسائل"}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="p-4 border lg:mb-2 mb-5 bg-white flex items-center justify-between rounded-xl sticky">
            {message && (
              <>
                <div className="flex items-center gap-3">
                  <picture>
                    <source srcSet={message.tutor.photo} type="image/webp" />
                    <img
                      src={
                        message.tutor.photo?.replace(".webp", ".jpg") ||
                        "fallback.jpg"
                      }
                      alt="img"
                      className="w-10 h-10 rounded-full"
                    />
                  </picture>
                  <h2 className="font-medium text-2xl">
                    {message.tutor.first_name}
                  </h2>
                </div>
                <a href={`/tutor/${message.tutor.id}`}>
                  <button className="lg:px-4 px-2 text-sm lg:text-lg py-2 bg-[#008847] text-white rounded-xl">
                    {t("inbox_page.view_profile")}
                  </button>
                </a>
              </>
            )}
          </div>
          <div className="flex-1 overflow-y-auto p-4 mb-6">
            <div className="lg:space-y-8 space-y-10">
              {message?.messages?.map((msg: any) => (
                <div
                  key={msg.id}
                  className={`flex ${msg.created_by === message.tutor.id
                      ? "justify-start"
                      : "justify-end"
                    }`}
                >
                  <div className="flex items-start gap-2 lg:min-w-[45%] min-w-[80%] relative">
                    {msg.created_by === message.tutor.id && (
                      <img
                        src={message.tutor.photo}
                        alt="Tutor"
                        className="w-8 h-8 rounded-full"
                      />
                    )}
                    <div
                      className={`rounded-xl min-w-full p-3 ${msg.created_by === message.tutor.id
                          ? "bg-[#F1F1F1] border"
                          : "bg-[#008847] text-white"
                        }`}
                    >
                      <p className="text-sm">{msg.content}</p>
                    </div>
                    <span
                      className={`text-xs text-gray-600 absolute -bottom-5 ${msg.created_by == message.tutor.id
                          ? "right-0"
                          : "left-0"
                        }`}
                    >
                      {isValidDate(msg.created)
                        ? new Date(msg.created)
                          .toLocaleString("en-US", {
                            weekday: "short", // Mon, Tue, Wed...
                            day: "2-digit", // 01, 02, 03...
                            month: "short", // Jan, Feb, Mar...
                            year: "numeric", // 2025
                            hour: "2-digit", // 21
                            minute: "2-digit", // 21
                            second: "2-digit", // 06
                            hour12: false, // 24-hour format
                          })
                          .replace(",", "")
                        : 'sending...'}
                    </span>
                    {msg.created_by !== message.tutor.id && (
                      <div className="w-9 h-9 rounded-full flex items-center justify-center p-1 bg-[#0088470D] border border-[#008847] font-bold uppercase">
                        {message.customer.first_name.slice(0, 2)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div ref={chatEndRef} className="w-2 h-2" />
          </div>
          <form className="p-4 bg-white border-t border-green-800">
            <div className="relative border border-green-700">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={t("message_placeholder")}
                className="w-full pl-4 pr-12 py-3 bg-gray-50 rounded-lg"
              />
              <button
                onClick={postMessage}
                type="submit"
                className="absolute right-2 top-1/2 -translate-y-1/2 p-2"
              >
                <Send className="w-5 h-5 text-[#008847]" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
